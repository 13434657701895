import { axiosGet, axiosPost } from './axiosService';
const notificationsURL = 'Notifications/Me';

export class NotificationsService {
	getAllNotifications(currentPage = 1, perPage = 10) {
		return axiosGet(
			'',
			`${notificationsURL}/All?Paging.CurrentPage=${currentPage}&Paging.PerPage=${perPage}`
		);
	}

	getUnReadNotifications(currentPage = 1, perPage = 10) {
		return axiosGet(
			'',
			`${notificationsURL}/Unread?Paging.CurrentPage=${currentPage}&Paging.PerPage=${perPage}`
		);
	}

	markAsReadNotification(ids = []) {
		return axiosPost(ids, `${notificationsURL}/markasread`);
	}
}
