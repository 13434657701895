export const SET_INVESTOR_COLLECTIONS = 'SET_INVESTOR_COLLECTIONS';
export const SET_BORROWER_COLLECTIONS = 'SET_BORROWER_COLLECTIONS';

export const setInvestorCollections = (investorCollections) => (dispatch) => {
	dispatch({
		type: SET_INVESTOR_COLLECTIONS,
		payload: investorCollections,
	});
};

export const setBorrowerCollections = (borrowerCollections) => (dispatch) => {
	dispatch({
		type: SET_BORROWER_COLLECTIONS,
		payload: borrowerCollections,
	});
};
