import { AvailableLanguages } from '../config/languages';
import i18next from 'i18next';
import { LocalStorageKey } from '../constants/localStorageKeys';

export class LanguageService {
	getCurrentLanguage = () => {
		return i18next.language || AvailableLanguages[0].lang;
	};

	getActiveLanguageObject = () => {
		return (
			AvailableLanguages.filter((resp) => resp.lang === i18next.language)[0] ||
			AvailableLanguages[0]
		);
	};

	toggleLanguage() {
		return i18next.language === 'en' ? 'ar' : 'en';
	}

	isArabic() {
		return this.getCurrentLanguage() === 'ar';
	}

	onBeforeLangChange() {
		localStorage.setItem(LocalStorageKey.investorsCollections, null);
		localStorage.setItem(LocalStorageKey.borrowersCollections, null);
	}

	changeLanguage = (lang) => {
		this.onBeforeLangChange();
		let pathArray = window.location.pathname.split('/');
		let isAvailable = AvailableLanguages.find(
			(language) => language.lang === lang
		);
		if (isAvailable) {
			i18next.changeLanguage(lang);
			pathArray[2] = lang;
		} else {
			pathArray[2] = AvailableLanguages[0].lang;
			i18next.changeLanguage(AvailableLanguages[0].lang);
		}
		document.body.dir = i18next.dir(lang);
		let newURL = pathArray.join('/');
		window.location.replace(newURL);
	};

	handleWrongLanguage = () => {
		let pathArray = window.location.pathname.split('/');
		let isAvailable = AvailableLanguages.find(
			(language) => language.lang === pathArray[2]
		);
		if (!isAvailable) {
			pathArray[2] = AvailableLanguages[0].lang;
			i18next.changeLanguage(AvailableLanguages[0].lang);
			document.body.dir = i18next.dir(AvailableLanguages[0].lang);
			let newURL = pathArray.join('/');
			window.location.replace(newURL);
		}
		document.body.dir = i18next.dir(pathArray[2]);
	};
}
