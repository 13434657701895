/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import { DialogWrapper } from '../../../../shared/components/common/dialogWrapper';
import { Box, CircularProgress, Paper, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { SAMLService } from '../../../../services/samlService';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../constants/appRoutes';
import { getRegistrationData, getUser } from '../../../../services/authService';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { OTP_TIMEOUT } from '../../../../config/appConfig';
import { interval, mergeMap } from 'rxjs';
import { Roles } from '../../../../constants/roles';

function getListner(e) {
	var confirmationMessage = 'o/';
	e.returnValue = confirmationMessage;
	return confirmationMessage;
}

export const SAMLAuthDialog = ({ state, onClose = (e) => {} }) => {
	const samlService = new SAMLService();
	const counter = OTP_TIMEOUT;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [authNumber, setAuthNumber] = useState(null);
	const [resendCounter, setResendCounter] = useState(0);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getSAMLNumber();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		window.addEventListener('beforeunload', getListner);
	}, []);

	useEffect(() => {
		resendCounter > 0 &&
			setTimeout(() => setResendCounter(resendCounter - 1), 1000);
	}, [resendCounter]);

	useEffect(() => {
		const subscription = interval(3000)
			.pipe(mergeMap(() => samlService.isUserVerified()))
			.subscribe({
				next: (resp) => {
					if (resp.data) {
						removeListner();
						gotoThankYouPage();
					}
				},
				error: (err) => {},
			});
		return () => {
			removeListner();
			subscription.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authNumber]);

	const getSAMLNumber = () => {
		setLoading(true);
		let nationalID = getRegistrationData()?.nationalId || '';
		samlService
			.getAuthNumber(nationalID)
			.then((resp) => {
				setAuthNumber(resp.data);
				setLoading(false);
			})
			.catch((err) => {
				setResendCounter(0);
				setLoading(false);
			});
	};

	const onBack = (value = false) => {
		removeListner();
		onClose(value);
	};

	const gotoThankYouPage = () => {
		onBack(true);
		const user = getUser();
		let url =
			user?.role === Roles.Investor
				? AppRoutes.Investor
				: user?.role === Roles.Borrower
				? AppRoutes.Borrower
				: AppRoutes.Investor;
		url = `${url}/${AppRoutes.ThankYou}`;
		navigate(buildLocalizeRoute(url));
	};

	const onResendCode = () => {
		setResendCounter(counter);
		getSAMLNumber();
	};

	const removeListner = () => {
		window.removeEventListener('beforeunload', getListner);
	};

	return (
		<DialogWrapper
			maxWidth="xs"
			width={'360px'}
			showLogo
			disableLogo
			state={state}
			onClose={() => {
				onClose(false);
			}}
			title={t('verifyItsYou')}
			actionFooterClass="!px-0"
			content={
				<Box>
					<p className="text-center">{t('toKeepYourAccountSafe')}</p>
					<Box className="my-4 flex justify-center">
						<Box className="rounded-full text-primary relative flex justify-center items-center border w-[60px] h-[60px] font-bold text-xl">
							{loading ? (
								<CircularProgress className="!w-[60px] !h-[60px] !absolute" />
							) : null}
							<span className="!absolute">
								{!loading ? (
									authNumber
								) : (
									<Skeleton className="w-[20px]"></Skeleton>
								)}
							</span>
						</Box>
					</Box>
					<Box
						component={Paper}
						className="p-3 flex flex-col mb-6">
						<strong className="text-center mb-3">
							{t('checkTheNafathApp')}
						</strong>
						<p className="!text-sm !text-center">
							{t('samlInfoText')}{' '}
							<strong className="text-primary">{authNumber}</strong>{' '}
							{t('samlInfoText1')}
						</p>
					</Box>
					<Box className="flex justify-center items-center !text-[14px] mb-4">
						{resendCounter === 0 ? (
							<Box className="text-center flex justify-center items-center">
								<RestartAltOutlinedIcon
									color="primary"
									className="!text-[20px]"
								/>
								<a
									onClick={() => {
										onResendCode();
									}}
									className={`underline-link ${
										loading ? 'pointer-events-none cursor-default' : ''
									}`}>
									{t('resendCode')}
								</a>
							</Box>
						) : (
							<span>
								{t('resendCodeAfter')}&nbsp;{resendCounter}&nbsp;{t('seconds')}
							</span>
						)}
					</Box>
					<Box className="bg-[#f4f4f4] p-1 !text-[11px] !text-center">
						<small className="caption">{t('authInfoText')}</small>
					</Box>
				</Box>
			}></DialogWrapper>
	);
};
