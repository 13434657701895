import { DateHelper } from './dateHelper';

export class CapitalBarGraphData {
	static currentYear = new Date().getFullYear();
	static currentDate = new Date();
	static months = {
		jan: `01-01-2022`,
		feb: `02-02-2022`,
		mar: `03-03-2022`,
		apr: `04-04-2022`,
		may: `05-05-2022`,
		jun: `06-06-2022`,
		jul: `07-07-2022`,
		aug: `08-08-2022`,
		sep: `09-09-2022`,
		oct: `10-10-2022`,
		nov: `11-11-2022`,
		dec: `12-12-2022`,
	};
	static graphData = {
		series: [
			{
				name: 'Repayment',
				data: [
					{ x: CapitalBarGraphData.months.jan, y: 9416 },
					{ x: CapitalBarGraphData.months.feb, y: 9416 },
					{ x: CapitalBarGraphData.months.mar, y: 9416 },
					{ x: CapitalBarGraphData.months.apr, y: 9416 },
					{ x: CapitalBarGraphData.months.may, y: 9416 },
					{ x: CapitalBarGraphData.months.jun, y: 9416 },
					{ x: CapitalBarGraphData.months.jul, y: 9416 },
					{ x: CapitalBarGraphData.months.aug, y: 9416 },
					{ x: CapitalBarGraphData.months.sep, y: 9416 },
					{ x: CapitalBarGraphData.months.oct, y: 9416 },
					{ x: CapitalBarGraphData.months.nov, y: 9416 },
					{ x: CapitalBarGraphData.months.dec, y: 9416 },
				],
			},
		],
	};

	static sortByCurrentDate() {
		let formattedSeries = [];
		let currentDateIndex = 0;
		let sameDateIndex = 0;
		let seriesData = this.graphData.series[0].data;
		let formattedDate = DateHelper.formatDate(new Date(), 'MMM');
		sameDateIndex = seriesData.findIndex(
			(data) => DateHelper.formatDate(new Date(data.x), 'MMM') === formattedDate
		);
		currentDateIndex = sameDateIndex;
		if (currentDateIndex < 11) {
			currentDateIndex += 1;
			formattedSeries.push({
				x: DateHelper.formatDate(
					new Date(seriesData[currentDateIndex].x),
					'MMM'
				),
				y: seriesData[currentDateIndex].y,
			});
		}
		seriesData.forEach((data, index) => {
			if (currentDateIndex < index && data.x !== 'Total') {
				formattedSeries.push({
					x: DateHelper.formatDate(new Date(data.x), 'MMM'),
					y: data.y,
				});
			}
		});
		seriesData.forEach((data, index) => {
			if (currentDateIndex > index) {
				formattedSeries.push({
					x: DateHelper.formatDate(new Date(data.x), 'MMM'),
					y: data.y,
				});
			}
		});
		if (sameDateIndex >= 11) {
			formattedSeries[currentDateIndex] = {
				x: DateHelper.formatDate(
					new Date(seriesData[currentDateIndex].x, 'MMM')
				),
				y: seriesData[currentDateIndex].y,
			};
		}
		return formattedSeries;
	}
}
