export const AppRoutes = {
	//aduth
	Login: 'login',
	ForgotPassword: 'forgot-password',
	WindDown: 'wind_down',
	ThankYou: 'thanks',
	ResetPassword: 'reset-password',

	//Borrowers
	BorrowerSummary: '',
	Borrower: 'borrower',
	BPaymentSchedule: 'payment-schedule',
	BTransfers: 'transfers',
	BBankTransfer: 'transfers/bank-transfer',
	BProfile: 'profile',
	BChangePassword: 'change-password',
	BDepositHistory: 'transfers/deposit-history',

	//Investors
	Investor: 'investor',
	Marketplace: 'marketplace',
	MarketplaceBusinessDetails: 'business-details',
	MarketplaceCollectionDetails: 'collection',
	MarketplaceBusinessHistory: 'business-history-details',
	MyInvestments: 'my-portfolio',
	InvestmentSettings: 'auto-investing',
	ITransfer: 'transfers',
	IDeposit: 'deposit',
	IWithdraw: 'withdraw',
	ITransactionHistory: 'transaction-history',
	ITransactionStatements: 'transaction-statements',
	BecomeAPro: 'become-pro',
	IDepositHistory: 'deposit-history',
	Profile: 'profile',
	IProfileUpdate: 'update-details',
	IProfileMyDocs: 'my-documents',
	IChangePassword: 'change-password',
	IPaymentSchedule: 'payment-schedule',
	IDeactivateAccount: 'deactivate-account',

	// Registration
	InvestorRegistration: 'registration-investor',
	IRegFirstStep: 'register',
	IRegPersonalInfoExtra: 'personal-information-extra',
	IRegPersonalInfo: 'additional-information',
	IRegNafathVerification: 'nafath-verification',
	iRegBusinessInfo: 'business-information',
	iRegDocumentVarification: 'document-verification',

	BorrowerRegistartion: 'registration-sme',
	BRegFirstStep: 'register',
	BRegEligibilityCheck: 'eligibility-checker',
	BRegPersonalInfo: 'personal-information',
	CommercialRegistration:'commercial-registration',
	BRegBusinessInfo: 'business-information',
	BRegFinancialInfo: 'financial-information',
	BRegDocumentVarification: 'document-verification',
};
