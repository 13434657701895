import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Logo from '../../../../../components/common/logo';
import LanguagePopover from './components/languagePopover';
import AccountPopover from './components/accountPopover';
import { useTranslation } from 'react-i18next';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import CloseIcon from '@mui/icons-material/Close';
import { LanguageService } from '../../../../../../services/languageService';
import { useState, useEffect } from 'react';
import { InvestorService } from '../../../../../../pages/investor/investorService';
import { DateHelper } from '../../../../../../helpers/dateHelper';
import { Observables } from '../../../../../../observables';
import { InvestorGuidelineService } from '../../../../../../services/guidelineService';
import { InvestorGuidelineSteps } from '../../../../../components/investors/constants/guidlineSteps';
import NotificationsPopover from './components/notificationPopover';
import { isMobile } from 'react-device-detect';

const APPBAR_MOBILE = 80;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(6px)',
	WebkitBackdropFilter: 'blur(6px)',
	backgroundColor: alpha(theme.palette.background.default, 0.72),
	[theme.breakpoints.up('lg')]: {
		width: `100%`,
	},
	// [theme.breakpoints.down('md')]: {
	// 	top: '53px',
	// },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	minHeight: APPBAR_MOBILE,
	[theme.breakpoints.up('lg')]: {
		minHeight: APPBAR_DESKTOP,
		padding: 0,
	},
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
	onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
	const { t } = useTranslation();
	const guideService = new InvestorGuidelineService();
	const languageService = new LanguageService();
	const guideSteps = InvestorGuidelineSteps;
	const [isShowPopup, setIsShowPopup] = useState(false);
	const [investMode, setInvestMode] = useState(null);
	const investorService = new InvestorService();

	useEffect(() => {
		setIsShowPopup(DateHelper.dateIsAfter(autoInvestmentPopupClosingDate()));
		investorService
			.getAutoInvestingMode()
			.then((resp) => {
				setInvestMode(resp.data);
			})
			.catch((err) => { });

		if (
			isMobile &&
			(guideService.stepCount() === guideSteps.NavMarketPlace ||
				guideService.stepCount() === guideSteps.NavPortfolio ||
				guideService.stepCount() === guideSteps.NavInvestSettings)
		) {
			Observables.investorSummaryRefresh.next(1);
			onOpenSidebar();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const autoInvestmentPopupClosingDate = () => {
		return localStorage.getItem('autoInvestPopupClosingTime')
			? localStorage.getItem('autoInvestPopupClosingTime')
			: null;
	};

	const onDismissPopup = () => {
		localStorage.setItem(
			'autoInvestPopupClosingTime',
			DateHelper.formatDate(new Date(), 'yyyy-MM-dd')
		);
		setIsShowPopup(false);
	};

	const isAr = () => languageService.isArabic();

	const autoInvestBanner = () => {
		return (
			<Box
				className={`absolute h-8 !text-[#343434] bg-white z-[99999]  w-full flex justify-center items-center auto-invest-banner ${investMode === 'Manual' && isShowPopup ? 'show' : ''
					}`}>
				<span className="!text-sm !font-[500] p-2">
					<span>{t('autoActivatePopupContent')}</span>
					<a
						className="underline-link mx-1"
						href={buildLocalizeRoute(
							`${AppRoutes.Investor}/${AppRoutes.InvestmentSettings}`
						)}>
						{t('here')}
					</a>
				</span>
				<Box
					className={`cursor-pointer p-2 md:absolute ${languageService.isArabic() ? '!left-3' : '!right-3'
						}`}>
					<CloseIcon
						onClick={() => {
							onDismissPopup();
						}}
						className="!text-[18px]"></CloseIcon>
				</Box>
			</Box>
		);
	};

	return (
		<Box className="flex flex-col">
			<RootStyle>
				{autoInvestBanner()}
				<Box className="primary-bg shadow-md flex justify-center">
					<ToolbarStyle className="md:w-[1140px] w-full">
						<Box>
							<Logo
								url={buildLocalizeRoute(`${AppRoutes.Investor}`)}
								classList={`${isAr() ? 'md:!-ml-[26px] md:!-mr-[26px]' : ''} -ml-[12px] -mr-[12px]  md:!w-[150px] !w-[100px]`}
								sx={{ height: 'auto' }}
								logoWhite="true"></Logo>
						</Box>
						<Stack
							width="100%"
							direction="row"
							alignItems="center"
							justifyContent="flex-end">
							<Box>
								<LanguagePopover />
							</Box>
							<Box className='mx-2'>
								<NotificationsPopover></NotificationsPopover>
							</Box>
							<Box className="!hidden md:!block">
								<AccountPopover></AccountPopover>
							</Box>
							<IconButton
								className="md:!hidden !block !-mt-[5px]"
								onClick={() => {
									Observables.investorSummaryRefresh.next(1);
									onOpenSidebar();
								}}
								sx={{
									color: 'text.primary',
								}}>
								<MenuOutlinedIcon className="!text-white !text-[28px]"></MenuOutlinedIcon>
							</IconButton>
						</Stack>
					</ToolbarStyle>
				</Box>
			</RootStyle>
		</Box>
	);
}
