export function checkImageRatio(actualImgUrl) {
	if (!actualImgUrl) {
		return Promise.resolve(true);
	}
	let img = new Image();
	img.src = actualImgUrl;
	return new Promise((resolve, reject) => {
		img.onload = () => {
			//aspect ratio 4/3
			if (img.width >= 640 && img.height >= 480) {
				return resolve(true);
			}
			return resolve(false);
		};
	});
}
