import { axiosGet, axiosPatch } from './axiosService';

const bucketsBaseURI = 'buckets';
const activeInvestmentCountURL = 'LenderSummary/ActiveInvestmentsCount';

export class BucketService {
	activateInvestmentsCount() {
		return axiosGet(null, activeInvestmentCountURL);
	}

	getAll() {
		return axiosGet(null, bucketsBaseURI);
	}

	getIndividualLendingSettings() {
		return axiosGet(null, bucketsBaseURI + '/me');
	}

	setInvestingMode(selectedBucked) {
		return axiosPatch(bucketsBaseURI + `/me`, { modeId: selectedBucked });
	}
}
