import { createContext, useEffect, useState } from 'react';
import { AppRoutes } from '../constants/appRoutes';
import { IS_SA } from '../config/appConfig';

export const BorrowerStepperContext = createContext({});

export const BorrowerStepperProvider = ({ children, isSA = IS_SA }) => {
	const ksaRegSteps = [
		{
			path: AppRoutes.IRegNafathVerification,
			name: AppRoutes.IRegNafathVerification,
		},
		{
			path: AppRoutes.CommercialRegistration,
			name: AppRoutes.CommercialRegistration,
		},
		{
			path: AppRoutes.BRegDocumentVarification,
			name: AppRoutes.BRegDocumentVarification,
		},
	];

	const globalRegSteps = [
		{
			path: AppRoutes.BRegPersonalInfo,
			name: AppRoutes.BRegPersonalInfo,
		},
		{
			path: AppRoutes.BRegBusinessInfo,
			name: AppRoutes.BRegBusinessInfo,
		},
		{
			path: AppRoutes.BRegDocumentVarification,
			name: AppRoutes.BRegDocumentVarification,
		},
	];

	const [steps, setSteps] = useState(isSA ? ksaRegSteps : globalRegSteps);

	useEffect(() => {
		getAllSteps();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAllSteps = () => {
		return steps;
	};

	return (
		<BorrowerStepperContext.Provider
			value={{
				getAllSteps,
				setSteps,
				steps,
			}}>
			{children}
		</BorrowerStepperContext.Provider>
	);
};
