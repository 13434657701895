import { useTranslation } from 'react-i18next';
import { InvestorService } from '../../../investorService';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { InvestorStatus } from '../../../../../constants/investorStatus';
import { useEffect } from 'react';
import { Toaster } from '../../../../../shared/components/common/toaster';
import { Logout } from '../../../../../services/authService';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { Box } from '@mui/material';
import { InvestorDocuments } from '../documents';
import { InvestorPersonalInfo } from '../personalInfo';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { Section } from '../../../../../shared/components/common/section';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { ConfirmPasswordDialog } from '../../../../../shared/components/confirmPasswordDialog';
import { CorporateInformations } from '../corporateInfo';
import { InvestorType } from '../../../../../constants/investorType';

export const InvestorUpdateDeatils = () => {
	const investorService = new InvestorService();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [investorDetails, setInvestorDetails] = useState({});
	const [showOTPAuth, setShowOTPAuth] = useState(false);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	const [isArchived, setIsArchived] = useState(false);

	const isApprovedByCEO = () => {
		return investorDetails?.status?.name === InvestorStatus.ApprovedByCO;
	};

	useEffect(() => {
		getInvestorDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInvestorDetails = () => {
		investorService
			.getInvestor()
			.then((resp) => {
				setIsArchived(resp.data?.status?.name === InvestorStatus.Archived);
				setInvestorDetails(resp.data);
			})
			.catch((err) => {});
	};

	const onSavePersonalInfo = () => {
		getInvestorDetails();
	};

	const onCloseOTPAuth = (value) => {
		setShowOTPAuth(false);
		if (value) {
			checkActivateOrDeactivate();
		}
	};

	const checkActivateOrDeactivate = () => {
		if (isArchived) {
			onActivate();
		} else {
			onDeactivate();
		}
	};

	const onActivate = () => {
		investorService
			.activateAccount()
			.then((resp) => {
				Toaster({ msg: t('accountDeactivated') });
				onAfterActiveDeactive();
			})
			.catch((err) => {});
	};
	const onDeactivate = () => {
		investorService
			.deactivateAccount()
			.then((resp) => {
				Toaster({ msg: t('accountActivated') });
				onAfterActiveDeactive();
			})
			.catch((err) => {});
	};

	const onAfterActiveDeactive = () => {
		Logout();
		navigate(`${buildLocalizeRoute(AppRoutes.Login)}`);
	};

	const onPasswordDialogClose = (value) => {
		setShowPasswordDialog(false);
		if (value) {
			checkActivateOrDeactivate();
		}
	};

	return (
		<Box className="flex flex-col">
			<Box className="grid-flow-row grid gap-3">
				{investorDetails && !isApprovedByCEO() ? (
					<InvestorDocuments
						investorType={
							investorDetails?.investorType?.name
						}></InvestorDocuments>
				) : null}
				<InvestorPersonalInfo
					investorDetails={investorDetails}
					onSavePersonalInfo={() => {
						onSavePersonalInfo();
					}}></InvestorPersonalInfo>
				{investorDetails?.investorType?.name === InvestorType.Corporate ? (
					<CorporateInformations
						id={investorDetails?.id}></CorporateInformations>
				) : null}
				<Section
					children={
						<TextWrapper
							classList="!info-text"
							text={
								<div>
									<div>
										{t('changeSignInDetailsAdminText')}{' '}
										<a
											className="underline-link"
											href="mailto:support@fundingsouq.com">
											support@fundingsouq.com
										</a>
									</div>
								</div>
							}></TextWrapper>
					}></Section>
			</Box>
			{showOTPAuth ? (
				<OTPAuthenticationDialog
					type={OTPTypes.Other}
					state={showOTPAuth}
					onClose={(e) => {
						onCloseOTPAuth(e);
					}}></OTPAuthenticationDialog>
			) : null}
			{showPasswordDialog ? (
				<ConfirmPasswordDialog
					state={showPasswordDialog}
					onClose={(e) => {
						onPasswordDialogClose(e);
					}}></ConfirmPasswordDialog>
			) : null}
		</Box>
	);
};
