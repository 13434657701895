import { Box, Skeleton, Stack } from '@mui/material';
import { Section } from '../../../common/section';

export const MarketplaceLoader = () => {
	return (
		<Section
			classList="!p-0"
			children={
				<Box>
					<Box>
						<Skeleton
							height={'240px'}
							variant="rectangular"
							sx={{ fontSize: '10px', width: '100%' }}
						/>
					</Box>
					<Stack
						gap={1}
						className="p-4">
						<Stack
							direction={'row'}
							gap={1}
							className="flex flex-0 items-center">
							<Box className="flex justify-center items-center px-2 py-1 w-auto border rounded-md">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '140px' }}
								/>
							</Box>

							<Box className="flex justify-center items-center px-2 py-1 w-auto border rounded-md">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '100px' }}
								/>
							</Box>
						</Stack>
						<Stack gap={1}>
							<Skeleton
								variant="text"
								sx={{ fontSize: '10px', width: '180px' }}
							/>
							<Box className="flex justify-between items-center">
								<Stack>
									<Skeleton
										variant="text"
										sx={{ fontSize: '10px', width: '100px' }}
									/>
									<Skeleton
										variant="text"
										sx={{ fontSize: '10px', width: '80px' }}
									/>
								</Stack>
								<Stack>
									<Skeleton
										variant="text"
										sx={{ fontSize: '10px', width: '100px' }}
									/>
									<Skeleton
										variant="text"
										sx={{ fontSize: '10px', width: '80px' }}
									/>
								</Stack>
							</Box>
						</Stack>
						<Box className="flex flex-col space-y-4 mt-6">
							<Box className="flex justify-between items-center">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '70px' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '100px' }}
								/>
							</Box>
							<Box className="flex justify-between items-center">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '70px' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '100px' }}
								/>
							</Box>
							<Box className="flex justify-between items-center">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '70px' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '100px' }}
								/>
							</Box>
						</Box>
						<Box className="flex justify-center mt-14">
							<Skeleton
								variant="text"
								sx={{ fontSize: '2rem', width: '100px' }}
							/>
						</Box>
					</Stack>
				</Box>
			}></Section>
	);
};
