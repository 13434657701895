import { Navigate, useRoutes } from 'react-router-dom';
// import MainLayout from '../shared/layouts/mainLayout';
import BorrowerLayout from '../shared/layouts/mainLayout/borrowerLayout';
import { Borrower } from '../pages/borrower';
import { AppRoutes } from '../constants/appRoutes';
import Login from '../auth/views/login';
import { PageNotFound } from '../pages/pageNotFound/pageNotFound';
import {
	AlreadyLogin,
	BorrowerRole,
	CheckLogin,
	CheckProfileAuth,
	InvestorRole,
	NafathVerificationGuard,
	RegistrationLoginCheck,
	ThankYouPageGuard,
} from '../auth/authContext';
import { BorrowerSummary } from '../pages/borrower/view/summary';
import { BorrowerPaymentSchedule } from '../pages/borrower/view/paymentSchedule';
import { BorrowerTransfers } from '../pages/borrower/view/transfers';
import BankTransfer from '../pages/borrower/view/bankTransfer';
import { DepositHistory } from '../pages/borrower/view/depositHistory';
import { BorrowerProfile } from '../pages/borrower/view/profile';
import { BorrowerChangePassword } from '../pages/borrower/view/changePassword';
import InvestorLayout from '../shared/layouts/mainLayout/investorLayout';
import { Investor } from '../pages/investor';
import { InvestorSummary } from '../pages/investor/views/summary';
import { Marketplace } from '../pages/investor/views/marketplace';
import { MarketplaceDetails } from '../pages/investor/views/marketplace/businessDetails';
import { MarketplaceCollectionDetails } from '../pages/investor/views/marketplace/collectionDetails/index';
import { Portfolio } from '../pages/investor/views/portfolio';
import { AutoInvesting } from '../pages/investor/views/auto-investing';
import { InvestorTransfer } from '../pages/investor/views/transfers';
import { InvestorDeposit } from '../pages/investor/views/transfers/deposit';
import { InvestorWithdraw } from '../pages/investor/views/transfers/withdraw';
import { InvestorTransferHistory } from '../pages/investor/views/transfers/history';
import { InvestorProfile } from '../pages/investor/views/profile';
import { BecomeProInvestor } from '../pages/investor/views/becomePro';
import { TransactionStatements } from '../pages/investor/views/transactionStatements';
import { InvestorChangePassword } from '../pages/investor/views/changePassword';
import { BusinessDetailsHistory } from '../pages/investor/views/marketplace/businesDetailHistory';
import { PaymentSchedule } from '../pages/investor/views/marketplace/businesDetailHistory/paymentSchedule';
import { ForgotPassword } from '../auth/views/forgotPassword';
import { InvestorRegistration } from '../pages/registration/investor';
import { BorrowerRegistration } from '../pages/registration/borrower';
import { InvestorBaseRegistration } from '../pages/registration/investor/views/base';
import { InvestorPersonalInfo } from '../pages/registration/investor/views/personalInformation';
import { InvestorStepper } from '../pages/registration/investor/views/stepper';
import { InvestorDocumentVerification } from '../pages/registration/investor/views/documentVerification';
import { ThankYouPage } from '../pages/thankYou';
import { BorrowerBaseRegistration } from '../pages/registration/borrower/views/base';
import { BorrowerStepper } from '../pages/registration/borrower/views/stepper';
import { BorrowerPersonalInfo } from '../pages/registration/borrower/views/personalInformation';
import { BorrowerBusinessInformation } from '../pages/registration/borrower/views/businessInformation';
import { CommercialRegistration } from '../pages/registration/borrower/views/commercialRegistration';
import { BorrowerDocumentVerification } from '../pages/registration/borrower/views/document-verification';
import { Winddown } from '../pages/winddown';
import { InvestorDepositHistory } from '../pages/investor/views/transfers/depositHistory';
import { InvestorUpdateDeatils } from '../pages/investor/views/profile/updateDeatils';
import { ProfileSections } from '../pages/investor/views/profile/profileSections';
import { InvestorDeactivateAccount } from '../pages/investor/views/profile/deactivateAccount';
import { BorrowerProfileSections } from '../pages/borrower/view/profile/profileSections';
import { BorrowerUpdateDetails } from '../pages/borrower/view/profile/updateDetails';
import { BorrowerDeactivateAccount } from '../pages/borrower/view/profile/deactivateAccount';
import { ResetPassword } from '../auth/views/resetPassword';
import { NafathVerification } from '../pages/registration/components/nafathVerification';
import { lazy } from 'react';

const lazyWithRetry = (componentImport) =>
	lazy(async () => {
		const pageHasAlreadyBeenForceRefreshed = JSON.parse(
			window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
		);

		try {
			const component = await componentImport();

			window.localStorage.setItem('page-has-been-force-refreshed', 'false');

			return component;
		} catch (error) {
			if (!pageHasAlreadyBeenForceRefreshed) {
				// Assuming that the user is not on the latest version of the application.
				// Let's refresh the page immediately.
				window.localStorage.setItem('page-has-been-force-refreshed', 'true');
				return window.location.reload();
			}

			// The page has already been reloaded
			// Assuming that user is already using the latest version of the application.
			// Let's let the application crash and raise the error.
			throw error;
		}
	});

const RegionHandler = lazyWithRetry(() =>
	import('../shared/layouts/regionHanlder')
);
const MainLayout = lazyWithRetry(() => import('../shared/layouts/mainLayout'));

export const routes = [
	{
		path: '/',
		element: <RegionHandler />,
	},
	{
		path: ':region',
		element: <MainLayout />,
		children: [
			{
				path: '',
				element: <Navigate to={'en'} />,
			},
			{
				path: ':lang',
				children: [
					{ path: '', element: <Navigate to={AppRoutes.Login}></Navigate> },
					{
						path: AppRoutes.Borrower,
						element: (
							<CheckLogin>
								<BorrowerRole>
									<BorrowerLayout></BorrowerLayout>
								</BorrowerRole>
							</CheckLogin>
						),
						children: [
							{
								path: '',
								element: <Borrower></Borrower>,
								children: [
									{ path: '', element: <BorrowerSummary></BorrowerSummary> },
									{
										path: AppRoutes.BPaymentSchedule,
										element: (
											<BorrowerPaymentSchedule></BorrowerPaymentSchedule>
										),
									},
									{
										path: AppRoutes.BTransfers,
										element: <BorrowerTransfers></BorrowerTransfers>,
									},
									{
										path: AppRoutes.BBankTransfer,
										element: <BankTransfer></BankTransfer>,
									},
									{
										path: AppRoutes.BDepositHistory,
										element: <DepositHistory></DepositHistory>,
									},
									{
										path: AppRoutes.Profile,
										element: <BorrowerProfile></BorrowerProfile>,
										children: [
											{
												path: '',
												element: (
													<BorrowerProfileSections></BorrowerProfileSections>
												),
												children: [
													{
														path: '',
														element: (
															<Navigate
																to={AppRoutes.IProfileUpdate}></Navigate>
														),
													},
													{
														path: AppRoutes.IProfileUpdate,
														element: (
															<BorrowerUpdateDetails></BorrowerUpdateDetails>
														),
													},
													{
														path: AppRoutes.IChangePassword,
														element: (
															<BorrowerChangePassword></BorrowerChangePassword>
														),
													},
													{
														path: AppRoutes.IDeactivateAccount,
														element: (
															<BorrowerDeactivateAccount></BorrowerDeactivateAccount>
														),
													},
												],
											},
										],
									},
									{
										path: AppRoutes.BChangePassword,
										element: <BorrowerChangePassword></BorrowerChangePassword>,
									},
								],
							},
						],
					},
					{
						path: AppRoutes.Investor,
						element: (
							<InvestorRole>
								<InvestorLayout></InvestorLayout>
							</InvestorRole>
						),
						children: [
							{
								path: '',
								element: <Investor></Investor>,
								children: [
									{
										path: '',
										element: (
											<CheckLogin>
												<InvestorSummary></InvestorSummary>
											</CheckLogin>
										),
									},
									{
										path: AppRoutes.Marketplace,
										children: [
											{
												path: '',
												element: (
													<CheckLogin>
														<Marketplace></Marketplace>
													</CheckLogin>
												),
											},
											{
												path: `${AppRoutes.MarketplaceBusinessDetails}/:id`,
												element: (
													<CheckLogin>
														<MarketplaceDetails></MarketplaceDetails>
													</CheckLogin>
												),
											},
											{
												path: `${AppRoutes.MarketplaceCollectionDetails}/:id`,
												element: (
													<CheckLogin>
														<MarketplaceCollectionDetails></MarketplaceCollectionDetails>
													</CheckLogin>
												),
											},
											{
												path: AppRoutes.MarketplaceBusinessHistory,
												children: [
													{
														path: `:id`,
														element: (
															<CheckLogin>
																<BusinessDetailsHistory></BusinessDetailsHistory>
															</CheckLogin>
														),
													},
													{
														path: `:id/${AppRoutes.IPaymentSchedule}`,
														element: (
															<CheckLogin>
																<PaymentSchedule></PaymentSchedule>
															</CheckLogin>
														),
													},
												],
											},
										],
									},
									{
										path: AppRoutes.MyInvestments,
										element: (
											<CheckLogin>
												<Portfolio></Portfolio>
											</CheckLogin>
										),
									},
									{
										path: AppRoutes.InvestmentSettings,
										element: (
											<CheckLogin>
												<AutoInvesting></AutoInvesting>
											</CheckLogin>
										),
									},
									{
										path: AppRoutes.ITransfer,
										element: (
											<CheckLogin>
												<InvestorTransfer></InvestorTransfer>
											</CheckLogin>
										),
										children: [
											{
												path: '',
												element: <Navigate to={AppRoutes.IDeposit}></Navigate>,
											},
											{
												path: AppRoutes.IDeposit,
												element: (
													<CheckLogin>
														<InvestorDeposit></InvestorDeposit>
													</CheckLogin>
												),
											},
											{
												path: AppRoutes.IWithdraw,
												element: (
													<CheckLogin>
														<InvestorWithdraw></InvestorWithdraw>
													</CheckLogin>
												),
											},
											{
												path: AppRoutes.ITransactionHistory,
												element: (
													<CheckLogin>
														<InvestorTransferHistory></InvestorTransferHistory>
													</CheckLogin>
												),
											},
											{
												path: AppRoutes.IDepositHistory,
												element: (
													<CheckLogin>
														<InvestorDepositHistory></InvestorDepositHistory>
													</CheckLogin>
												),
											},
										],
									},
									{
										path: AppRoutes.Profile,
										element: (
											<CheckProfileAuth>
												<InvestorProfile></InvestorProfile>
											</CheckProfileAuth>
										),
										children: [
											{
												path: '',
												element: <ProfileSections></ProfileSections>,
												children: [
													{
														path: '',
														element: (
															<Navigate
																to={AppRoutes.IProfileUpdate}></Navigate>
														),
													},
													{
														path: AppRoutes.IProfileUpdate,
														element: (
															<InvestorUpdateDeatils></InvestorUpdateDeatils>
														),
													},
													{
														path: AppRoutes.BecomeAPro,
														element: <BecomeProInvestor></BecomeProInvestor>,
													},
													{
														path: AppRoutes.IChangePassword,
														element: (
															<InvestorChangePassword></InvestorChangePassword>
														),
													},
													{
														path: AppRoutes.IDeactivateAccount,
														element: (
															<InvestorDeactivateAccount></InvestorDeactivateAccount>
														),
													},
												],
											},
										],
									},
									{
										path: AppRoutes.ITransactionStatements,
										element: (
											<CheckLogin>
												<TransactionStatements></TransactionStatements>
											</CheckLogin>
										),
									},
									{
										path: AppRoutes.ITransactionStatements,
										element: (
											<CheckLogin>
												<TransactionStatements></TransactionStatements>
											</CheckLogin>
										),
									},
								],
							},
						],
					},
					{
						path: AppRoutes.InvestorRegistration,
						element: (
							<RegistrationLoginCheck>
								<InvestorRegistration></InvestorRegistration>
							</RegistrationLoginCheck>
						),
						children: [
							{
								path: '',
								element: <Navigate to={AppRoutes.IRegFirstStep}></Navigate>,
							},
							{
								path: AppRoutes.IRegFirstStep,
								element: <InvestorBaseRegistration></InvestorBaseRegistration>,
							},
							{
								path: '',
								element: <InvestorStepper></InvestorStepper>,
								children: [
									{
										path: AppRoutes.IRegNafathVerification,
										element: (
											<NafathVerificationGuard>
												<NafathVerification></NafathVerification>
											</NafathVerificationGuard>
										),
									},
									{
										path: AppRoutes.IRegPersonalInfo,
										element: <InvestorPersonalInfo></InvestorPersonalInfo>,
									},
									{
										path: AppRoutes.iRegDocumentVarification,
										element: (
											<InvestorDocumentVerification></InvestorDocumentVerification>
										),
									},
								],
							},
						],
					},
					{
						path: AppRoutes.BorrowerRegistartion,
						element: (
							<RegistrationLoginCheck>
								<BorrowerRegistration></BorrowerRegistration>
							</RegistrationLoginCheck>
						),
						children: [
							{
								path: '',
								element: <Navigate to={AppRoutes.BRegFirstStep}></Navigate>,
							},
							{
								path: AppRoutes.IRegFirstStep,
								element: <BorrowerBaseRegistration></BorrowerBaseRegistration>,
							},
							{
								path: '',
								element: <BorrowerStepper></BorrowerStepper>,
								children: [
									{
										path: AppRoutes.IRegNafathVerification,
										element: (
											<NafathVerificationGuard>
												<NafathVerification></NafathVerification>
											</NafathVerificationGuard>
										),
									},
									{
										path: AppRoutes.BRegPersonalInfo,
										element: <BorrowerPersonalInfo></BorrowerPersonalInfo>,
									},
									{
										path: AppRoutes.CommercialRegistration,
										element: <CommercialRegistration></CommercialRegistration>,
									},
									{
										path: AppRoutes.BRegBusinessInfo,
										element: (
											<BorrowerBusinessInformation></BorrowerBusinessInformation>
										),
									},
									{
										path: AppRoutes.BRegDocumentVarification,
										element: (
											<BorrowerDocumentVerification></BorrowerDocumentVerification>
										),
									},
								],
							},
						],
					},
					{
						path: AppRoutes.Login,
						element: (
							<AlreadyLogin>
								<Login></Login>
							</AlreadyLogin>
						),
					},
					{
						path: AppRoutes.WindDown,
						element: <Winddown></Winddown>,
					},
					{
						path: `${AppRoutes.Investor}/${AppRoutes.ThankYou}`,
						element: (
							<ThankYouPageGuard>
								<ThankYouPage></ThankYouPage>
							</ThankYouPageGuard>
						),
					},
					{
						path: `${AppRoutes.Borrower}/${AppRoutes.ThankYou}`,
						element: (
							<ThankYouPageGuard>
								<ThankYouPage></ThankYouPage>
							</ThankYouPageGuard>
						),
					},
					{
						path: AppRoutes.ForgotPassword,
						element: (
							<AlreadyLogin>
								<ForgotPassword></ForgotPassword>
							</AlreadyLogin>
						),
					},
					{
						path: AppRoutes.ResetPassword,
						element: (
							<AlreadyLogin>
								<ResetPassword></ResetPassword>
							</AlreadyLogin>
						),
					},
					{
						path: '*',
						element: <PageNotFound />,
					},
				],
			},
		],
	},

	{
		path: '*',
		element: <PageNotFound />,
	},
];

export default function Router() {
	return useRoutes(routes);
}
