import { createContext, useEffect, useState } from 'react';
import { AppRoutes } from '../constants/appRoutes';
import { IS_SA } from '../config/appConfig';
import { InvestorType } from '../constants/investorType';

export const InvestorStepperContext = createContext({});

export const InvestorStepperProvider = ({ children, isSA = IS_SA }) => {
	const ksaRegSteps = [
		{
			path: AppRoutes.IRegNafathVerification,
			name: AppRoutes.IRegNafathVerification,
		},
		{
			path: AppRoutes.IRegPersonalInfo,
			name: AppRoutes.IRegPersonalInfo,
		},
	];

	const globalRegSteps = [
		{
			path: AppRoutes.IRegPersonalInfo,
			name: AppRoutes.IRegPersonalInfo,
		},
		{
			path: AppRoutes.iRegDocumentVarification,
			name: AppRoutes.iRegDocumentVarification,
		},
	];

	const [steps, setSteps] = useState(isSA ? ksaRegSteps : globalRegSteps);
	const [investorType, setInvestorType] = useState(null);

	useEffect(() => {
		getAllSteps();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		replacePathName();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investorType, steps]);

	const getAllSteps = () => {
		return steps;
	};

	const handleStepper = (investorType) => {
		if (typeof investorType == 'object') {
			investorType = investorType?.name;
		}
		setInvestorType(investorType);
		if (IS_SA) {
			if (investorType !== InvestorType.Retail) {
				addSteps(AppRoutes.iRegDocumentVarification);
			} else {
				removeStep(AppRoutes.iRegDocumentVarification);
			}
		}
	};

	const addSteps = (step) => {
		let allSteps = steps;
		if (allSteps.find((resp) => resp?.path === step)) {
			return;
		}
		allSteps.push({ name: step, path: step });
		setSteps([...allSteps]);
	};

	const removeStep = (step) => {
		let requiredSteps = steps.filter((resp) => resp.path !== step);
		setSteps([...requiredSteps]);
	};

	const replacePathName = () => {
		let allSteps = steps;
		let currentStepName = null;
		let replacedStepName = null;
		if (investorType === InvestorType.Corporate) {
			currentStepName = AppRoutes.IRegPersonalInfo;
			replacedStepName = 'information';
		} else {
			currentStepName = 'information';
			replacedStepName = AppRoutes.IRegPersonalInfo;
		}
		const index = allSteps.findIndex((resp) => resp.name === currentStepName);
		if (index !== -1) {
			allSteps[index].name = replacedStepName;
			setSteps([...allSteps]);
		}
	};

	return (
		<InvestorStepperContext.Provider
			value={{
				getAllSteps,
				addSteps,
				removeStep,
				setSteps,
				handleStepper,
				steps,
				investorType,
			}}>
			{children}
		</InvestorStepperContext.Provider>
	);
};
