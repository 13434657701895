import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Section } from '../../../../../shared/components/common/section';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { BecomeProService } from '../../../../../services/becomeProService';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { useState } from 'react';
import { useEffect } from 'react';
import { BecomeProStatuses } from '../../../../../constants/becomeProStatus';
import { IS_SA } from '../../../../../config/appConfig';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { LanguageService } from '../../../../../services/languageService';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { RegionService } from '../../../../../services/regionService';
import { getUser } from '../../../../../services/authService';

export const ProfileSections = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const becomeProService = new BecomeProService();
	const regionService = new RegionService();
	const languageService = new LanguageService();
	const systemSettingsService = new SystemSettingsService();
	const [isExpand, setIsExpand] = useState(false);
	const location = useLocation();
	const user = getUser();
	const [sections, setSections] = useState([
		{
			mainSection: 'myProfile',
			subSection: [
				{
					name: 'updateDetails',
					icon: <EditIcon />,
					linkTo: `${AppRoutes.Investor}/${AppRoutes.Profile}/${AppRoutes.IProfileUpdate}`,
				},
				{
					hide: true,
					name: 'becomePro',
					icon: <EmojiEventsOutlinedIcon />,
					linkTo: `${AppRoutes.Investor}/${AppRoutes.Profile}/${AppRoutes.BecomeAPro}`,
				},
			],
		},
		{
			mainSection: 'security',
			subSection: [
				{
					name: 'resetPassword',
					icon: <LockOutlinedIcon />,
					linkTo: `${AppRoutes.Investor}/${AppRoutes.Profile}/${AppRoutes.IChangePassword}`,
				},
				{
					hide: !(!IS_SA && user?.isApproved),
					name: 'deactivateAccount',
					icon: <PersonRemoveAlt1OutlinedIcon className="!text-red-600" />,
					linkTo: `${AppRoutes.Investor}/${AppRoutes.Profile}/${AppRoutes.IDeactivateAccount}`,
				},
			],
		},
		{
			mainSection: 'agreements',
			subSection: [
				{
					name: 'termsConditionsInvestors',
					icon: <TextSnippetOutlinedIcon />,
					externalLink: true,
					onClick: () => {
						openUrl();
					},
					linkTo: '',
				},
			],
		},
	]);

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	const isAr = () => {
		return languageService.isArabic();
	};

	const isActiveSection = (subSection) => {
		return (
			location.pathname ===
			`/${regionService.currentRegion()}/${languageService.getCurrentLanguage()}/${
				subSection?.linkTo
			}`
		);
	};

	const openUrl = () => {
		window.open(settings()?.investorTermsAndConditions, '_blank');
	};

	const openLink = (link) => {
		setIsExpand(!isExpand);
		if (link) {
			navigate(buildLocalizeRoute(link));
		}
	};

	useEffect(() => {
		getBecomeProStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBecomeProStatus = () => {
		becomeProService.getBecomeProStatus().then((resp) => {
			if (
				resp.data?.status !== BecomeProStatuses.Approved &&
				user?.isApproved
			) {
				let sectionsData = sections;
				sectionsData[0].subSection.forEach((resp) => {
					if (resp?.name === 'becomePro') {
						resp.hide = false;
					}
				});
				setSections([...sectionsData]);
			}
		});
	};

	return (
		<Box className="flex items-start md:flex-row flex-col">
			<SectionWithHeading
				bodyClass={'!p-0'}
				actionButtons={
					<Box
						className="md:!hidden !flex cursor-pointer"
						onClick={() => {
							setIsExpand(!isExpand);
						}}>
						{isExpand ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
					</Box>
				}
				heading={
					<Box
						className="flex justify-center items-center md:pointer-events-none"
						onClick={() => {
							setIsExpand(!isExpand);
						}}>
						<Box className="flex items-center">
							<span className="mx-2">{t('profileAndSettings')}</span>
						</Box>
					</Box>
				}
				classList={`md:!w-[320px] !w-full !mb-4 ${
					isAr() ? 'md:!ml-4' : 'md:!mr-4'
				}`}
				children={
					<Box
						className={`flex flex-col profile-wrapper md:!max-h-[600px] ${
							!isExpand ? 'expand-profile' : ''
						}`}>
						{sections.map((section, index) => {
							return (
								<Box
									key={index}
									className="flex flex-col space-y-2 !p-4">
									<Box className="font-[600] text-[15px]">
										{t(section.mainSection)}
									</Box>
									{section.subSection.map((subSec, index) => {
										return (
											<Box key={index}>
												{!subSec?.hide ? (
													<Section
														isShadow={false}
														classList={`!cursor-pointer !p-2 profile-section ${
															isActiveSection(subSec)
																? 'active-profile-section'
																: ''
														}`}
														onClick={() =>
															subSec?.onClick
																? subSec?.onClick()
																: openLink(subSec?.linkTo)
														}
														key={subSec?.name}
														children={
															<Box className="flex justify-between items-center">
																<Box className="flex items-center">
																	<ButtonWrapper
																		classList="!icon-bg-color !text-primary"
																		icon={subSec.icon}
																		iconButton></ButtonWrapper>
																	<Box className="font-[500] text-sm mx-2">
																		{t(subSec?.name)}
																	</Box>
																</Box>
																<Box>
																	<ArrowIcons
																		isKeyboardArrow
																		classList={'!text-primary'}></ArrowIcons>
																</Box>
															</Box>
														}></Section>
												) : null}
											</Box>
										);
									})}
								</Box>
							);
						})}
					</Box>
				}></SectionWithHeading>
			<Box className="md:!w-[calc(100%-335px)] !w-full">
				<Outlet></Outlet>
			</Box>
		</Box>
	);
};
