import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import {
	Box,
	Divider,
	Typography,
	Stack,
	MenuItem,
	IconButton,
} from '@mui/material';
import MenuPopover from '../../../shared/components/menuPopover';
import { useNavigate } from 'react-router-dom';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import {
	Logout,
	getFirstName,
	getUser,
	userName,
} from '../../../../../../../services/authService';
import { buildLocalizeRoute } from '../../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../../constants/appRoutes';
import { WEBSITE_URL } from '../../../../../../../config/appConfig';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function AccountPopover() {
	const firstNameLetter = userName().charAt(0) || 'A';
	const { t } = useTranslation();
	const MENU_OPTIONS = [
		{
			label: t('myProfile'),
			icon: <PortraitOutlinedIcon />,
			linkTo: `${AppRoutes.BProfile}`,
		},
		{
			label: t('faqs'),
			icon: <QuizOutlinedIcon />,
			linkTo: `${WEBSITE_URL}${i18next.language}/faq`,
		},
		{
			label: t('contactUs'),
			icon: <ContactPhoneOutlinedIcon />,
			linkTo: `${WEBSITE_URL}${i18next.language}/contact-us`,
		},
	];
	const navigate = useNavigate();
	const anchorRef = useRef(null);

	const [open, setOpen] = useState(null);

	const user = getUser();

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const logout = () => {
		handleClose();
		Logout();
		navigate(buildLocalizeRoute(AppRoutes.Login));
	};

	return (
		<>
			<Box className="relative p-1 bg-[#ffffff69] rounded-full">
				<IconButton
					className="!bg-white !text-primary !font-bold !text-lg"
					ref={anchorRef}
					color={open ? 'primary' : 'default'}
					onClick={handleOpen}
					sx={{
						width: 40,
						height: 40,
						p: 0,
						...(open && {
							'&:before': {
								zIndex: 1,
								content: "''",
								width: '100%',
								height: '100%',
								borderRadius: '50%',
								position: 'absolute',
							},
						}),
					}}>
					{firstNameLetter}
				</IconButton>
			</Box>

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
					p: 0,
					mt: 1.5,
					ml: 0.75,
					'& .MuiMenuItem-root': {
						typography: 'body2',
						borderRadius: 0.75,
					},
				}}>
				<Box sx={{ my: 1, px: 2.5 }}>
					<Typography
						variant="subtitle2"
						noWrap>
						{getFirstName()}
					</Typography>
					<Typography
						variant="body2"
						sx={{ color: 'text.secondary' }}
						noWrap>
						{user.email}
					</Typography>
				</Box>

				<Divider
					className="!my-0"
					sx={{ borderStyle: 'dashed' }}
				/>

				<Stack sx={{ p: 1 }}>
					{MENU_OPTIONS.map((option, index) => (
						<Box key={index}>
							<MenuItem
								sx={{ px: 1 }}
								to={option.linkTo}
								component={RouterLink}
								onClick={handleClose}>
								{option.icon ? option.icon : null}
								<Box sx={{ mx: 1.2 }}>{option.label}</Box>
							</MenuItem>
							<Divider
								className="!my-0.5"
								sx={{ borderStyle: 'dashed' }}
							/>
						</Box>
					))}
				</Stack>
				<MenuItem
					onClick={() => logout()}
					sx={{ m: 1, px: 1, marginTop: 0 }}>
					<LoginOutlinedIcon className="text-red-500" />
					<Box
						className="text-red-500"
						sx={{ mx: 1.2 }}>
						Logout
					</Box>
				</MenuItem>
			</MenuPopover>
		</>
	);
}
