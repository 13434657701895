import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextWrapper } from '../../../../../components/formUtilities/useTextWrapper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LanguageService } from '../../../../../../services/languageService';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import { useTranslation } from 'react-i18next';
import { RegionService } from '../../../../../../services/regionService';

export const SubNavbar = () => {
	const navigate = useNavigate();
	const languageService = new LanguageService();
	const langRoute = languageService.getCurrentLanguage();
	const regionService = new RegionService();
	const { t } = useTranslation();
	const region = regionService.currentRegion();
	const subNavLinks = [
		{ name: t('summary'), linkTo: '', classList: '', path: AppRoutes.Borrower },
		{
			name: t('paymentSchedule'),
			linkTo: AppRoutes.BPaymentSchedule,
			classList: '!mx-4',
			path: `${AppRoutes.Borrower}/${AppRoutes.BPaymentSchedule}`,
		},
		{
			name: t('transfers'),
			linkTo: AppRoutes.BTransfers,
			classList: '',
			path: `${AppRoutes.Borrower}/${AppRoutes.BTransfers}`,
		},
	];

	const isMainPagesActive = () => {
		if (
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Borrower}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Borrower}/${AppRoutes.BPaymentSchedule}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Borrower}/${AppRoutes.BTransfers}`
		) {
			return true;
		}
		return false;
	};

	const checkIsActive = (relativePath) => {
		return (
			`/${region}/${langRoute}/${relativePath}` === window.location.pathname
		);
	};

	return (
		<Box className="h-[51px] items-center w-full !hidden md:!flex dark-bg justify-center md:mt-[80px]">
			<Box className="xl:w-[1140px] w-full xl:p-0 px-2">
				{isMainPagesActive() ? (
					<Box className="flex items-center">
						{subNavLinks.map((path, index) => {
							return (
								<Link
									key={index}
									to={path.linkTo}
									className={`nav-non-selected font-[600] hover:text-white text-[16px] sub-nav-items ${
										checkIsActive(path.path) ? '!text-white' : ''
									} ${path.classList ? path.classList : ''}`}>
									{path.name}
								</Link>
							);
						})}
					</Box>
				) : (
					<Box
						className="flex items-center space-x-2 text-white cursor-pointer nav-back-btn"
						onClick={() => {
							if (window.location.pathname.includes(`/${AppRoutes.Profile}/`)) {
								navigate(-2);
							} else {
								navigate(-1);
							}
						}}>
						{languageService.isArabic() ? (
							<>
								<ArrowForwardIcon className="nav-back-icon-ar" />
							</>
						) : (
							<>
								<ArrowBackIcon className="nav-back-icon-en" />
							</>
						)}

						<TextWrapper text="Back"></TextWrapper>
					</Box>
				)}
			</Box>
		</Box>
	);
};
