import { axiosGet, axiosPatch, axiosPost, axiosPut } from './axiosService';

const usersURL = 'users';
const registerInvestorURI = 'Authentication/Register/Lender';
const registerBorrowerURI = 'Authentication/Register/Borrower';
const Borrowers = 'Borrowers';

export class UserService {
	uploadUserDocumentsFromAdmin = (id, file, type, additionalFile = null) => {
		const formData = new FormData();
		formData.append('file', file, file.name);
		if (additionalFile) {
			formData.append('additionalFile', additionalFile, additionalFile?.name);
		}
		formData.append('type', type.toString());

		return axiosPatch(`${usersURL}/${id}/document`, formData, {
			'Content-Type': 'multipart/form-data',
		});
	};

	multiUploadDocuments = (files, type) => {
		const formData = new FormData();
		formData.append('files', files, files.name);
		formData.append('type', type.toString());

		return axiosPatch(
			`${usersURL}/me/document/multiple`,
			formData,
			{
				'Content-Type': 'multipart/form-data',
			},
			null
		);
	};

	uploadUserDocuments = (
		file,
		type,
		additionalFile = null,
		onUploadProgress
	) => {
		const formData = new FormData();
		formData.append('file', file, file.name);
		if (additionalFile) {
			formData.append('additionalFile', additionalFile, additionalFile?.name);
		}
		formData.append('type', type.toString());

		return axiosPatch(
			`${usersURL}/me/document`,
			formData,
			{
				'Content-Type': 'multipart/form-data',
			},
			null,
			onUploadProgress
		);
	};

	getInvestorBasicInformationById = (id) => {
		return axiosGet(null, `${usersURL}/${id}/BasicInformation`);
	};

	updateInvestorBasicInformationById = (id, form) => {
		return axiosPut(form, `${usersURL}/${id}/BasicInformation`);
	};

	getStatusHistory = (id) => {
		return axiosGet(null, `${usersURL}/${id}/statushistory`);
	};

	changeStatus(id, status) {
		return axiosPatch(`${usersURL}/${id}`, { status });
	}

	getOverallRiskAssesment(id) {
		return axiosGet(null, `${usersURL}/${id}/overallriskassesment`);
	}

	editOverallRiskAssesment(id, payload) {
		return axiosPatch(`${usersURL}/${id}/overallriskassesment`, payload);
	}

	editCustomerRiskFactors(id, value) {
		return axiosPatch(`${usersURL}/${id}/customerriskfactors`, value);
	}

	getCustomerRiskFactors(id) {
		return axiosGet(null, `${usersURL}/${id}/customerriskfactors`);
	}

	getServiceRiskFactors(id) {
		return axiosGet(null, `${usersURL}/${id}/serviceRiskfactors`);
	}

	editServiceRiskFactors(id, value) {
		return axiosPatch(`${usersURL}/${id}/serviceRiskfactors`, value);
	}

	getGeographicalRiskFactors(id) {
		return axiosGet(null, `${usersURL}/${id}/goegraphicalriskfactors`);
	}

	editGeographicalRiskFactors(id, value) {
		return axiosPatch(`${usersURL}/${id}/goegraphicalriskfactors`, value);
	}

	getTaxCrimeRiskFactors(id) {
		return axiosGet(null, `${usersURL}/${id}/taxcrimeriskfactors`);
	}

	editTaxCrimeRiskFactors(id, value) {
		return axiosPatch(`${usersURL}/${id}/taxcrimeriskfactors`, value);
	}

	getDocumentsReviewed(id) {
		return axiosGet(null, `${usersURL}/${id}/documentsreviewed`);
	}

	editDocumentsReviewed(id, value) {
		return axiosPatch(`${usersURL}/${id}/documentsreviewed`, value);
	}

	getOtherRiskFactors(id) {
		return axiosGet(null, `users/${id}/otherfactors`);
	}

	editOtherRiskFactors(id, value) {
		return axiosPatch(`users/${id}/otherfactors`, value);
	}

	getDocumentByType(id, type) {
		return axiosGet({ type: type }, `users/${id}/document`);
	}

	getShareholders(id) {
		return axiosGet(null, `${usersURL}/${id}/shareholders`);
	}

	saveShareholders(id, shareholders) {
		return axiosPatch(`${usersURL}/${id}/shareholders`, shareholders);
	}

	getKYC() {
		return axiosGet(null, `${usersURL}/samlkyc`, null, null, {
			hide_toaster: true,
		});
	}

	getAllDocuments() {
		return axiosGet(null, `${usersURL}/me/documents`);
	}

	registerInvestor(form) {
		return axiosPost(form, registerInvestorURI, {}, 'json');
	}

	registerBorrower(form) {
		return axiosPost(form, registerBorrowerURI, {}, 'json');
	}

	deleteFile(id) {
		return axiosPatch(`${usersURL}/me/document/${id}/expired`);
	}

	getFinancialStatementsRatios() {
		return axiosGet(null, `${Borrowers}/Me/FinancialStatement`, null, null);
	}

	addFinancialStatementRatios(form) {
		return axiosPost(form, `${Borrowers}/FinancialStatement`);
	}
}
