import { useState } from 'react';
import DataTableGrid from '../../../../shared/components/formUtilities/useTable';
import { initializeColumns } from './paymentScheduleColumn';
import { useEffect } from 'react';
import { PaymentService } from '../../../../services/paymentService';
import { SectionWithHeading } from '../../../../shared/components/common/sectionWithHeading';
import { useTranslation } from 'react-i18next';

export const BorrowerPaymentSchedule = () => {
	const paymentService = new PaymentService();
	const [paymentSchedule, setPaymentSchedule] = useState([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const columns = initializeColumns(t);

	useEffect(() => {
		setLoading(true);
		paymentService
			.getPaymentSchedule()
			.then((resp) => {
				setPaymentSchedule(resp.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<SectionWithHeading
				heading={t('paymentSchedule')}
				children={
					<DataTableGrid
						hideSerialNumbers
						name={'paymentScheduleTbl'}
						columns={columns}
						records={paymentSchedule}
						showEditColumnsButton={false}
						isPagination={false}
						loading={loading}></DataTableGrid>
				}></SectionWithHeading>
		</>
	);
};
