import { Box } from '@mui/material';
import { IS_SA } from '../../../../../config/appConfig';
import { PersonalInfoKSA } from './ksa';
import { PersonalInfoGlobalIndex } from './global';

export const InvestorPersonalInfo = () => {
	return (
		<Box>{!IS_SA ? <PersonalInfoGlobalIndex /> : <PersonalInfoKSA />}</Box>
	);
};
