import {
	Box,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import Logo from '../../../../../components/common/logo';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { WEBSITE_URL } from '../../../../../../config/appConfig';
import { ButtonWrapper } from '../../../../../components/formUtilities/useButton';
import {
	Logout,
	getUser,
	userName,
} from '../../../../../../services/authService';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import i18next from 'i18next';
import { Section } from '../../../../../components/common/section';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useTranslation } from 'react-i18next';
import { LanguageService } from '../../../../../../services/languageService';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { RegionService } from '../../../../../../services/regionService';
const ListItemStyle = styled((props) => <ListItemButton {...props} />)(
	({ theme }) => ({
		// borderBottom: '1px solid #ccc',
		...theme.typography.body2,
		height: 48,
		position: 'relative',
		textTransform: 'capitalize',
	})
);

const AccountStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
}));

export const MobileSideBar = ({ isOpenSidebar, onCloseSidebar }) => {
	const languageService = new LanguageService();
	const regionService = new RegionService();
	const region = regionService.currentRegion();
	const firstNameLetter = userName().charAt(0) || 'A';
	const user = getUser();
	const { t } = useTranslation();
	const langRoute = languageService.getCurrentLanguage();

	const checkIsActive = (relativePath) => {
		if (relativePath === `${AppRoutes.Investor}/${AppRoutes.ITransfer}`) {
			return (
				window.location.pathname.includes(AppRoutes.IDeposit) ||
				window.location.pathname.includes(AppRoutes.IWithdraw) ||
				window.location.pathname.includes(AppRoutes.ITransactionHistory)
			);
		}
		return `/${region}/${langRoute}/${relativePath}` === window.location.pathname;
	};

	const subNavLinks = [
		{
			name: 'summary',
			linkTo: '',
			classList: '',
			path: AppRoutes.Borrower,
			icon: <DashboardOutlinedIcon />,
		},
		{
			name: 'paymentSchedule',
			linkTo: AppRoutes.BPaymentSchedule,
			classList: '!mx-4',
			path: `${AppRoutes.Borrower}/${AppRoutes.BPaymentSchedule}`,
			icon: <PaidOutlinedIcon />,
		},
		{
			name: 'transfers',
			linkTo: AppRoutes.BTransfers,
			classList: '',
			path: `${AppRoutes.Borrower}/${AppRoutes.BTransfers}`,
			icon: <AccountBalanceOutlinedIcon />,
		},
		{
			name: 'myProfile',
			linkTo: AppRoutes.BProfile,
			classList: '',
			path: `${AppRoutes.Borrower}/${AppRoutes.BProfile}`,
			icon: <AccountBoxOutlinedIcon />,
		},
		{
			name: 'faq',
			linkTo: `${WEBSITE_URL}${i18next.language}/faq`,
			classList: '',
			path: '',
			icon: <LiveHelpOutlinedIcon />,
		},
		{
			name: 'contactUs',
			linkTo: `${WEBSITE_URL}${i18next.language}/contact-us`,
			classList: '',
			path: '',
			icon: <SupportAgentOutlinedIcon />,
		},
	];
	const navigate = useNavigate();

	const logout = () => {
		onCloseSidebar();
		Logout();
		navigate(buildLocalizeRoute(AppRoutes.Login));
	};

	return (
		<Box>
			<Drawer
				SlideProps={{
					direction: languageService.isArabic() ? 'left' : 'right',
				}}
				open={isOpenSidebar}
				onClose={onCloseSidebar}
				PaperProps={{
					sx: { width: '80%' },
				}}>
				{
					<Box className="flex flex-col flex-0">
						<header className="primary-bg h-[78px] flex justify-between items-center p-[6px]">
							<Logo
								url="/"
								logoWhite
								sx={{ width: '100px', height: 'auto' }}></Logo>
							<IconButton
								onClick={() => {
									onCloseSidebar();
								}}>
								<CloseIcon className="text-white !text-3xl"></CloseIcon>
							</IconButton>
						</header>
						<Section
							isShadow={false}
							classList="!block md:!hidden !rounded-none"
							children={
								<Box>
									<AccountStyle>
										<IconButton
											className="!primary-bg !text-white  !font-bold !text-lg"
											sx={{
												width: 40,
												height: 40,
												p: 0,
											}}>
											{firstNameLetter}
										</IconButton>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												flexDirection: 'column',
											}}>
											<Typography
												className="!text-lg"
												sx={{
													color: 'text.primary',
													fontWeight: 'bold',
												}}>
												{userName()}
											</Typography>
											<Typography
												variant="caption"
												className="!font-bold"
												sx={{ color: 'text.secondary' }}>
												<EmailOutlinedIcon className="!text-sm text-primary mx-[2px]"></EmailOutlinedIcon>
												{user?.email}
											</Typography>
										</Box>
									</AccountStyle>
								</Box>
							}></Section>
						<Stack gap={2}>
							<List disablePadding>
								{subNavLinks.map((resp, index) => {
									return (
										<Box key={index}>
											<ListItemStyle
												className={`${
													checkIsActive(resp.path) ? 'nav-active' : ''
												}`}
												onClick={() => {
													onCloseSidebar();
												}}
												component={RouterLink}
												to={resp.linkTo}>
												<Box className="mobile-nav-icon">{resp.icon}</Box>
												<ListItemText
													className={`font-[500] !text-[14px] !text-start !mx-3`}
													disableTypography
													primary={t(resp.name)}
												/>
											</ListItemStyle>
										</Box>
									);
								})}
							</List>
							<Box className="text-center !mb-[30px]">
								<ButtonWrapper
									onClick={() => {
										logout();
									}}
									classList="!w-[150px] !bg-red-600 !shadow-none"
									label={t('signOut')}
									icon={
										<LogoutOutlinedIcon></LogoutOutlinedIcon>
									}></ButtonWrapper>
							</Box>
						</Stack>
					</Box>
				}
			</Drawer>
		</Box>
	);
};
