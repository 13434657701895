import { Box, CircularProgress } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Stack } from '@mui/system';
import { lazy, useEffect, useState } from 'react';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PDFImage from '../../../assets/images/pdf.png';
import { Toaster } from './toaster';
import { TextWrapper } from '../formUtilities/useTextWrapper';
import { ButtonWrapper } from '../formUtilities/useButton';
import { FileHelper } from '../../../helpers/fileHelper';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useTranslation } from 'react-i18next';
import { LabelBadge } from '../common/labelBadge';
import { useConfirm } from 'material-ui-confirm';

const Dropzone = lazy(() => import('react-dropzone'));

export const FileUploadWrapper = ({
	imageURL = null,
	loading = false,
	classList = '',
	disable = false,
	showPreview = false,
	multipleIndex = 0,
	fileExtension = '',
	fileName = '',
	uploadFileType = ['.jpeg', '.png'],
	viewFile = false,
	titleText = 'Drag file to upload',
	showStaticImage = false,
	maxFileSize = null,
	id = '',
	onClick = () => {},
	onUpload = (image) => {},
	onRemoveFile = () => {},
}) => {
	const [imageURLValue, setImageUrlValue] = useState('');
	const [extension, setExtension] = useState('');
	const [types, setTypes] = useState(uploadFileType);
	const { t } = useTranslation();
	const [filesName, setFilesName] = useState('');
	const confirm = useConfirm();

	const onDrop = (files) => {
		if (files.length > 0) {
			onConvertFile(files[0]);
		} else {
			Toaster({ msg: 'Invalid file' });
		}
	};

	useEffect(() => {
		setImageUrlValue(imageURL);
		setFilesName(fileName);
		setExtension(fileExtension);
		if (uploadFileType?.length) {
			setTypes(
				uploadFileType.map((resp, index) => {
					return resp.replace(/\./g, `${index === 0 ? '' : ', '}`);
				})
			);
		}
		// setFilesName(fileName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageURL]);

	const loadingTemplate = () => {
		return (
			<Box
				className={`h-[251px] min-w-[251px] flex flex-col justify-center items-center ${classList}`}>
				<Stack
					gap={1}
					alignItems={'center'}>
					<CircularProgress
						className="!text-6xl"
						color="primary"
					/>
					<Box className="flex flex-col items-center justify-center">
						<TextWrapper
							classList="caption"
							type="h6"
							text={t('loadingPleaseWait')}></TextWrapper>
					</Box>
				</Stack>
			</Box>
		);
	};

	const onConvertFile = (file) => {
		const fileSizeInMB = file?.size * 1e-6;
		if (maxFileSize && fileSizeInMB >= maxFileSize) {
			confirm({
				dialogProps: { className: 'file-size-warning' },
				description: `File shouldn't be greater than ${maxFileSize}MB, Please reduce the file size and upload again.`,
				title: 'File Size Warning!',
				titleProps: { className: '!text-center' },
				contentProps: { className: '!text-center' },
				confirmationButtonProps: {
					className: '!primary-bg !text-white !capitalize',
				},
				hideCancelButton: 'true',
				confirmationText: 'Ok',
				cancellationButtonProps: { className: '!hidden' },
				dialogActionsProps: { className: '!justify-center' },
			})
				.then(() => {})
				.catch(() => console.log('cancelled.'));
		} else {
			setFileLocally(file);
		}
	};

	const setFileLocally = (file) => {
		setExtension(file?.type);
		setFilesName(file?.name);
		if (showPreview) {
			convertToBase64(file, (url) => {
				setImageUrlValue(url);
			});
		}
		onUpload && onUpload(file);
	};

	const convertToBase64 = (file, callBack) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			callBack(reader.result);
		};
	};

	return (
		<>
			{!imageURLValue ? (
				<Dropzone
					onDrop={onDrop}
					multiple={false}
					disabled={disable}
					accept={{ 'image/*': uploadFileType }}>
					{({ getRootProps, getInputProps }) => (
						<Box
							{...getRootProps({ className: 'dropzone' })}
							htmlFor={`${id ? id : `fileID${multipleIndex}`}`}
							className={`flex flex-col cursor-pointer flex-auto justify-center items-center border-4 border-dashed p-6 rounded-xl ${classList}`}
							sx={{ backgroundColor: '#f2fcf7 !important' }}>
							{!loading ? (
								<>
									<input
										onChange={(e) => {
											const uploadedFile = e.target.files[0];
											onConvertFile(uploadedFile);
										}}
										{...getInputProps()}
										type="file"
										hidden
										id={`${id ? id : `fileID${multipleIndex}`}`}
										style={{ display: 'none' }}></input>
									<Stack
										gap={1}
										alignItems={'center'}>
										<CloudUploadOutlinedIcon className="!text-6xl !text-primary"></CloudUploadOutlinedIcon>
										<Box className="flex flex-col items-center justify-center">
											<TextWrapper
												classList="caption !text-center"
												type="h6"
												text={titleText}></TextWrapper>
											<TextWrapper
												classList="!mb-2"
												type="caption"
												text={t('or')}></TextWrapper>
											<ButtonWrapper
												classList="!rounded-full"
												variant={'outlined'}
												label={t('browseFile')}></ButtonWrapper>
											<Box className="flex flex-0 items-center mt-3">
												<TextWrapper
													type="caption"
													text={t('supportFiles')}></TextWrapper>
												<strong className="text-xs caption uppercase mx-1">
													{types}
												</strong>
											</Box>
										</Box>
									</Stack>
								</>
							) : (
								loadingTemplate()
							)}
						</Box>
					)}
				</Dropzone>
			) : (
				<Box
					sx={{ padding: '15px', border: '1px solid rgba(0, 0, 0, 0.12)' }}
					htmlFor="fileID"
					className={`flex bg-[#f2fcf7] cursor-pointer flex-0  justify-center items-center border-1 max-h-[251px] h-auto min-w-[251px] rounded-xl ${classList} ${
						!disable ? 'image-wrapper' : ''
					}`}>
					<Box className="flex flex-col flex-0 items-center">
						<Box className="text-center w-full flex justify-center items-center">
							{showStaticImage ? (
								<Box>
									<InsertDriveFileOutlinedIcon
										color="primary"
										className="!text-[150px]"></InsertDriveFileOutlinedIcon>
								</Box>
							) : FileHelper.isImage(extension) ? (
								<img
									className="max-h-[210px]"
									src={imageURLValue}
									alt="Logo"></img>
							) : (
								<Box className="w-[100px]">
									<img
										className="w-full h-full"
										src={PDFImage}
										alt="PDF"></img>
								</Box>
							)}
						</Box>
						{filesName ? (
							<Box className="!mt-[5px]">
								<LabelBadge label={filesName}></LabelBadge>
							</Box>
						) : null}
					</Box>
					<Box className="hidden image-delete bg-overlay-btn-wrapper">
						<ButtonWrapper
							hideBtn={!imageURLValue}
							onClick={(e) => {
								if (imageURLValue.includes('base64')) {
									var image = new Image();
									image.src = imageURLValue;
									var w = window.open('');
									w.document.write(image.outerHTML);
								} else {
									window.open(imageURLValue, '_blank');
								}
							}}
							classList="!mx-2"
							icon={<PreviewOutlinedIcon></PreviewOutlinedIcon>}
							label={t('viewFile')}></ButtonWrapper>
						<ButtonWrapper
							onClick={(e) => {
								onRemoveFile && onRemoveFile('');
								setImageUrlValue('');
							}}
							classList="!bg-red-600"
							icon={<DeleteOutlineIcon></DeleteOutlineIcon>}
							label={t('deleteFile')}></ButtonWrapper>
					</Box>
				</Box>
			)}
		</>
	);
};
