import { format, isAfter, parseISO } from 'date-fns';

export class DateHelper {
	static getYearsDropdown(startYear) {
		const endYear = new Date().getFullYear();
		return Array.from(
			{ length: endYear - startYear + 1 },
			(_, i) => i + startYear
		);
	}

	static formatDate(date, dateFormat = 'MMM dd, yyyy') {
		if (!date || date === '0001-01-01T00:00:00') {
			return '';
		}

		if (typeof date === 'string') {
			return format(new Date(date), dateFormat);
		}

		return format(date, dateFormat);
	}

	static secondsToHm(seconds) {
		const sec = Number(seconds);
		const d = Math.floor(sec / 86400);
		const h = Math.floor((sec % 86400) / 3600);
		const m = Math.floor((sec % 3600) / 60);
		const dDisplay = d > 0 ? d + 'd ' : '';
		const hDisplay = h > 0 ? h + 'h ' : '';
		const mDisplay = m > 0 ? m + 'min' : '';
		return dDisplay + hDisplay + mDisplay;
	}

	static dateIsAfter(date) {
		let dateFormat = 'yyyy-MM-dd';
		if (date) {
			let setDate = this.formatDate(date, dateFormat) || null;
			let currentDate = this.formatDate(new Date(), dateFormat) || null;
			return isAfter(parseISO(currentDate), parseISO(setDate));
		}
		return true;
	}
}
