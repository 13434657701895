import { IS_SA } from '../config/appConfig';
import { AppConstants } from '../config/appConstants';

export class RegistrationHelper {
	static ifSaudiArabia(nationality, addressCountry) {
		return (
			(nationality === AppConstants.saudiArabia ||
				addressCountry === AppConstants.saudiArabia) &&
			IS_SA
		);
	}
}
