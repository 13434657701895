import { Box } from '@mui/material';
import { ButtonWrapper } from '../../shared/components/formUtilities/useButton';
import { TextWrapper } from '../../shared/components/formUtilities/useTextWrapper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
	return (
		<Box className="flex flex-col light-bg flex-auto justify-center items-center h-full">
			<TextWrapper
				type="h1"
				text="404" color='error'></TextWrapper>
			<TextWrapper
				type="h4"
				classList="caption"
				text="Not Found"></TextWrapper>
			<TextWrapper
				type="caption"
				classList="caption !mb-5"
				text="Sorry, we were unable to find that page"></TextWrapper>
			<Link to={'/'}>
				<ButtonWrapper
					label="Back to Home"
					endIcon={<ArrowForwardIcon></ArrowForwardIcon>}></ButtonWrapper>
			</Link>
		</Box>
	);
};
