import { Box } from '@mui/material';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { useTranslation } from 'react-i18next';

export const SubStepper = ({
	stepName = '',
	currentStep = 0,
	totalSteps = 0,
}) => {
	const { t } = useTranslation();
	return (
		<Box
			data-testid="subStepper"
			className="flex items-center !caption mb-6 relative">
			<TextWrapper
				classList="!font-bold !text-[14px]"
				text={t(stepName)}
			/>
			<Box className="flex items-center">
				<TextWrapper
					classList="!text-[14px]"
					text={':'}
				/>
				<Box className="w-1"></Box>
				<TextWrapper
					testID={currentStep}
					classList="!font-bold !text-[14px]"
					text={currentStep}
				/>
				<Box className="w-1"></Box>
				<TextWrapper
					classList="!text-[14px]"
					text={t('of')}
				/>
				<Box className="w-1"></Box>
				<TextWrapper
					classList="!font-bold !text-[14px]"
					text={totalSteps}
				/>
			</Box>
		</Box>
	);
};
