import {
	Box,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import Logo from '../../../../../components/common/logo';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import LogoutIcon from '@mui/icons-material/Logout';
import {
	NavLink as RouterLink,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { CURRENCY, WEBSITE_URL } from '../../../../../../config/appConfig';
import { ButtonWrapper } from '../../../../../components/formUtilities/useButton';
import AddIcon from '@mui/icons-material/Add';
import {
	Logout,
	getUser,
	userName,
} from '../../../../../../services/authService';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { useTranslation } from 'react-i18next';
import { LanguageService } from '../../../../../../services/languageService';
import { TextWrapper } from '../../../../../components/formUtilities/useTextWrapper';
import { currencyFormatterHelper } from '../../../../../../helpers/currencyFormatterHelper';
import { Section } from '../../../../../components/common/section';
import { InvestorService } from '../../../../../../pages/investor/investorService';
import { useEffect } from 'react';
import { useState } from 'react';
import { SystemSettingsService } from '../../../../../../services/systemSettingsService';
import { InvestorGuidelineService } from '../../../../../../services/guidelineService';
import { InvestorGuidelineSteps } from '../../../../../components/investors/constants/guidlineSteps';
import { InvestorGuidelinePopover } from '../../../../../components/investors/investorGuidlinePopover';
import EmailIcon from '@mui/icons-material/Email';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import i18next from 'i18next';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { Observables } from '../../../../../../observables';
import { RegionService } from '../../../../../../services/regionService';

const AccountStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
}));

const ListItemStyle = styled((props) => <ListItemButton {...props} />)(
	({ theme }) => ({
		// borderBottom: '1px solid #ccc',
		...theme.typography.body2,
		height: 48,
		position: 'relative',
		textTransform: 'capitalize',
	})
);

export const MobileSideBar = ({ isOpenSidebar, onCloseSidebar }) => {
	const { t } = useTranslation();
	const languageService = new LanguageService();
	const investorService = new InvestorService();
	const systemSettingsService = new SystemSettingsService();
	const guideService = new InvestorGuidelineService();
	const regionService = new RegionService();
	const langRoute = languageService.getCurrentLanguage();
	const guideSteps = InvestorGuidelineSteps;
	const user = getUser();
	const location = useLocation();
	const [marketplaceGuide, setMarketplaceGuide] = useState(false);
	const [investmentGuide, setInvestmentGuide] = useState(false);
	const [investSettingsGuide, setInvestSettingsGuide] = useState(false);
	const [currentStep, setCurrentStep] = useState(guideService.stepCount());
	const firstNameLetter = userName().charAt(0) || 'A';
	const region = regionService.currentRegion();
	const [subNavLinks, setSubNavLinks] = useState([
		{
			name: t('investorNavbarDashboard'),
			linkTo: '',
			classList: `${languageService.isArabic() ? '!pl-2' : '!pr-2'}`,
			path: AppRoutes.Investor,
			icon: <DashboardOutlinedIcon />,
			id: 1,
		},
		{
			name: t('marketplace'),
			linkTo: AppRoutes.Marketplace,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.Marketplace}`,
			icon: <StoreOutlinedIcon />,
			id: 2,
		},
		{
			name: t('myInvestment'),
			linkTo: AppRoutes.MyInvestments,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.MyInvestments}`,
			icon: <PaidOutlinedIcon />,
			id: 3,
		},
		{
			name: t('investSettings'),
			linkTo: AppRoutes.InvestmentSettings,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.InvestmentSettings}`,
			icon: <SettingsApplicationsOutlinedIcon />,
			id: 4,
		},
		{
			name: t('transfers'),
			linkTo: AppRoutes.ITransfer,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.ITransfer}`,
			icon: <AccountBalanceOutlinedIcon />,
			id: 5,
		},
		{
			name: t('myProfile'),
			linkTo: AppRoutes.Profile,
			classList: '',
			path: `${AppRoutes.Investor}/${AppRoutes.Profile}`,
			icon: <ContactPageOutlinedIcon />,
			id: 6,
		},
		{
			name: t('faqs'),
			linkTo: `${WEBSITE_URL}${i18next.language}/faq`,
			classList: '',
			path: '',
			icon: <LiveHelpOutlinedIcon />,
			id: 7,
		},
		{
			name: t('contactUs'),
			linkTo: `${WEBSITE_URL}${i18next.language}/contact-us`,
			classList: '',
			path: '',
			icon: <SupportAgentOutlinedIcon />,
			id: 9,
		},
	]);

	const navigate = useNavigate();
	const [summary, setSummary] = useState(null);
	const logout = () => {
		onCloseSidebar();
		Logout();
		navigate(buildLocalizeRoute(AppRoutes.Login));
	};

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		let subNav = subNavLinks;
		if (settings()) {
			subNav.forEach((resp, index) => {
				if (resp.id === 2 && !settings()?.enableMarketplace) {
					subNav.splice(index, 1);
				}
			});
		}
		setSubNavLinks([...subNav]);
		if (
			location.pathname !==
			`/${region}/${languageService.getCurrentLanguage()}/${AppRoutes.Investor}`
		) {
			getInvestorsSummary();
		}
		Observables.investorSummaryRefresh.subscribe((resp) => {
			if (resp === 1) {
				getInvestorsSummary();
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInvestorsSummary = () => {
		investorService
			.getInvestorsSummary()
			.then((resp) => {
				setSummary(resp.data);
			})
			.catch((err) => {});
	};

	const checkIsActive = (relativePath) => {
		if (relativePath === `${AppRoutes.Investor}/${AppRoutes.ITransfer}`) {
			return (
				window.location.pathname.includes(AppRoutes.IDeposit) ||
				window.location.pathname.includes(AppRoutes.IWithdraw) ||
				window.location.pathname.includes(AppRoutes.ITransactionHistory)
			);
		}
		return `/${region}/${langRoute}/${relativePath}` === window.location.pathname;
	};

	useEffect(() => {
		if (guideService.stepCount() === guideSteps.NavMarketPlace) {
			setMarketplaceGuide(true);
			setInvestmentGuide(false);
			setInvestSettingsGuide(false);
		} else if (guideService.stepCount() === guideSteps.NavPortfolio) {
			setMarketplaceGuide(false);
			setInvestmentGuide(true);
			setInvestSettingsGuide(false);
		} else if (guideService.stepCount() === guideSteps.NavInvestSettings) {
			setMarketplaceGuide(false);
			setInvestmentGuide(false);
			setInvestSettingsGuide(true);
		} else if (guideService.stepCount() === guideSteps.AddFund) {
			setMarketplaceGuide(false);
			setInvestmentGuide(false);
			setInvestSettingsGuide(false);
		} else {
			setMarketplaceGuide(false);
			setInvestmentGuide(false);
			setInvestSettingsGuide(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep]);

	return (
		<Box>
			<Drawer
				SlideProps={{
					direction: languageService.isArabic() ? 'left' : 'right',
				}}
				className="z-[9999]"
				open={isOpenSidebar}
				onClose={onCloseSidebar}
				PaperProps={{
					sx: { width: '80%' },
				}}>
				{
					<Box className="flex flex-col flex-0">
						<header className="primary-bg h-[78px] flex justify-between items-center p-[6px]">
							<Logo
								url="/"
								logoWhite
								sx={{ width: '100px', height: 'auto' }}></Logo>
							<IconButton
								onClick={() => {
									onCloseSidebar();
								}}>
								<CloseIcon className="text-white !text-3xl"></CloseIcon>
							</IconButton>
						</header>
						<Section
							isShadow={false}
							classList="!block md:!hidden !rounded-none mb-2"
							children={
								<Box>
									<AccountStyle>
										<IconButton
											className="!primary-bg !text-white  !font-bold !text-lg"
											sx={{
												width: 40,
												height: 40,
												p: 0,
											}}>
											{firstNameLetter}
										</IconButton>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												flexDirection: 'column',
											}}>
											<Typography
												className="!text-lg"
												sx={{
													color: 'text.primary',
													fontWeight: 'bold',
												}}>
												{userName()}
											</Typography>
											<Typography
												variant="caption"
												className="!font-bold"
												sx={{ color: 'text.secondary' }}>
												<EmailIcon className="!text-sm text-primary mx-[2px]"></EmailIcon>
												{user?.email}
											</Typography>
										</Box>
									</AccountStyle>
									<Section
										classList="!mt-4 !rounded-xl !shadow-none border border-dashed"
										children={
											<Box
												className="flex flex-row items-center justify-between cursor-pointer"
												onClick={() => {
													onCloseSidebar();
													navigate(AppRoutes.ITransfer);
												}}>
												<Box className="flex flex-col">
													<TextWrapper
														type="caption"
														text={t('availableCash')}></TextWrapper>
													<TextWrapper
														classList="!sub-heading !text-primary"
														text={`${CURRENCY} ${currencyFormatterHelper(
															summary?.availableCash
														)}`}></TextWrapper>
												</Box>
												<ButtonWrapper
													iconButton
													icon={<AddIcon className="!text-white"></AddIcon>}
													classList="!primary-bg"></ButtonWrapper>
											</Box>
										}></Section>
								</Box>
							}></Section>
						<Stack gap={2}>
							<List disablePadding>
								{subNavLinks.map((resp, index) => {
									return (
										<Box key={index}>
											<ListItemStyle
												className={`${
													checkIsActive(resp.path) ? 'nav-active' : ''
												}`}
												onClick={() => {
													onCloseSidebar();
												}}
												component={RouterLink}
												to={resp.linkTo}>
												{/* <Box className="mobile-nav-icon">{resp.icon}</Box> */}
												<ListItemText
													className={`font-[500] !text-[14px] !text-start`}
													disableTypography
													primary={resp.name}
												/>
											</ListItemStyle>
											{marketplaceGuide && resp.id === 2 ? (
												<InvestorGuidelinePopover
													onNext={(count) => {
														setCurrentStep(count);
													}}
													state={marketplaceGuide}
													message={
														'guideNavMarketPlace'
													}></InvestorGuidelinePopover>
											) : null}
											{investmentGuide && resp.id === 3 ? (
												<InvestorGuidelinePopover
													onNext={(count) => {
														setCurrentStep(count);
													}}
													state={investmentGuide}
													message={
														'guideNavPortfolio'
													}></InvestorGuidelinePopover>
											) : null}
											{investSettingsGuide && resp.id === 4 ? (
												<InvestorGuidelinePopover
													redirect={`${AppRoutes.Investor}/${AppRoutes.InvestmentSettings}`}
													onNext={(count) => {
														onCloseSidebar();
														setCurrentStep(count);
													}}
													state={investSettingsGuide}
													message={
														'guideAutoInvestNav'
													}></InvestorGuidelinePopover>
											) : null}
										</Box>
									);
								})}
							</List>
							<Box className="text-center !mb-[30px]">
								<ButtonWrapper
									onClick={() => {
										logout();
									}}
									classList="!w-[150px] !bg-red-600 !shadow-none"
									label={t('signOut')}
									icon={<LogoutIcon></LogoutIcon>}></ButtonWrapper>
							</Box>
						</Stack>
					</Box>
				}
			</Drawer>
		</Box>
	);
};
