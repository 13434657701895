import { Box } from '@mui/material';
import { RegistrationNavBar } from '../registration/components/registrationNavBar';
import { useTranslation } from 'react-i18next';
import { LanguageService } from '../../services/languageService';
import { InvestorDocuments } from '../investor/views/profile/documents';
import { getRegistrationInfo } from '../../services/authService';

export const Winddown = () => {
	const languageService = new LanguageService();
	const { t } = useTranslation();

	const isArabic = () => {
		return languageService.isArabic();
	};

	return (
		<Box>
			<RegistrationNavBar></RegistrationNavBar>
			<Box className="flex justify-center items-center">
				<Box className="xl:w-[1140px] w-full md:my-8 m-4 justify-center items-center flex flex-col md:min-h-[calc(100vh-310px)]">
					<Box className="flex flex-col items-center">
						<h1 className="md:text-5xl font-[600] my-4">
							{t('accountUnderWinddown')}
						</h1>
						<Box className="flex flex-col">
							<Box className="!mb-4">
								<InvestorDocuments
									investorType={
										getRegistrationInfo()?.investorType
									}></InvestorDocuments>
							</Box>
							{!isArabic() ? (
								<p className='text-center'>
									{t('accountUnderWinddownText')}
									<a
										className="underline-link mx-1"
										href="mailto:support@fundingsouq.com">
										support@fundingsouq.com
									</a>
								</p>
							) : (
								<p className='text-center'>
									{t('accountUnderWinddownText')}
									<a
										className="underline-link mx-1"
										href="mailto:support@fundingsouq.com">
										support@fundingsouq.com
									</a>
									{t('accountUnderWinddownText2')}
								</p>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
