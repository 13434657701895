/* eslint-disable no-unused-vars */
import { FormikProvider, getIn, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormHelper } from '../../../../helpers/formHelper';
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	IconButton,
	InputAdornment,
} from '@mui/material';
import { InputWrapper } from '../../../../shared/components/formUtilities/useInput';
import { InternationalPhone } from '../../../../shared/components/formUtilities/useInternationalPhone';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../shared/components/common/arrowIcons';
import { AppRoutes } from '../../../../constants/appRoutes';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { RegistrationSchema } from '../../../../shared/validations/schema';
import { SystemSettingsService } from '../../../../services/systemSettingsService';
import { IS_SA } from '../../../../config/appConfig';
import { useCallback, useEffect, useState } from 'react';
import { checkDuplicateEmail } from '../../../../services/authService';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { AppConstants } from '../../../../config/appConstants';
import { useLocation, useSearchParams } from 'react-router-dom';
import { PasswordValidationPopover } from '../../../../shared/components/common/passwordValidationPopover';

export const BaseRegistration = ({
	clientType = 'investor' | 'borrower',
	defaultEmail = '',
	loading = false,
	onSubmitForm = (values) => {},
	isSA = IS_SA,
}) => {
	const systemSettingsService = new SystemSettingsService();
	const { t } = useTranslation();
	const [duplicateEmailError, setDuplicateEmailError] = useState('');
	const [passwordType, setPasswordType] = useState('password');
	const [loadingEmail, setLoadingEmail] = useState(false);
	const [showErrorPopover, setShowErrorPopover] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const form = useFormik({
		initialValues: {
			email: defaultEmail ? defaultEmail : '',
			contactNumber: '',
			passwordConfirmation: '',
			password: '',
			termsConditionsForInvestmentsAccepted: false,
			nationalId: '',
			utm: {
				utmSource:
					searchParams.get(AppConstants.UTMSource)?.toLowerCase() || '',
				utmMedium:
					searchParams.get(AppConstants.UTMMedium)?.toLowerCase() || '',
				utmCampaign:
					searchParams.get(AppConstants.UTMCampaign)?.toLowerCase() || '',
				utmTerm: searchParams.get(AppConstants.UTMTerm)?.toLowerCase() || '',
				utmContent:
					searchParams.get(AppConstants.UTMContent)?.toLowerCase() || '',
			},
		},
		validationSchema: RegistrationSchema,
		onSubmit: (values) => {
			let formData = {
				...values,
				passwordConfirmation: values?.password,
			};
			if (!duplicateEmailError) {
				if (!isSA) {
					delete formData.nationalId;
				}
				onSubmitForm(formData);
			}
		},
		...FormHelper.formikProps(),
	});

	const emailField = getIn(form.values, 'email');

	const getIsDisableForm = () => {
		return !settings()?.registrationEnabled && clientType === 'investor';
	};

	const debounce = (func) => {
		let timer;
		return function (...args) {
			const context = this;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args);
			}, 500);
		};
	};

	useEffect(() => {
		if (emailField?.length > 6) {
			checkForValidEmail(emailField);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailField]);

	const checkIfEmailValid = (value) => {
		setLoadingEmail(true);
		checkDuplicateEmail(value)
			.then((resp) => {
				setLoadingEmail(false);
				if (resp.data) {
					setDuplicateEmailError('emailTaken');
					form.getFieldHelpers('email').setTouched(true);
				} else {
					setDuplicateEmailError('');
				}
			})
			.catch((err) => {
				setLoadingEmail(false);
			});
		return;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const checkForValidEmail = useCallback(debounce(checkIfEmailValid), []);

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const getIsInvestor = () => {
		return clientType === 'investor';
	};

	const getIsBorrower = () => {
		return clientType === 'borrower';
	};

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	return (
		<FormikProvider value={form}>
			<Box className="flex flex-col space-y-4">
				<form>
					<fieldset
						data-testid="baseRegFieldset"
						disabled={getIsDisableForm()}>
						<Box className="grid grid-flow-row gap-4">
							<Box className="flex flex-col">
								<InputWrapper
									dataTestID="email"
									errorStateTestID="emailError"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{loadingEmail ? (
													<CircularProgress className="!h-[18px] !w-[18px]" />
												) : null}
											</InputAdornment>
										),
									}}
									externalErrorMessage={duplicateEmailError}
									name="email"
									type="email"
									key={'email'}
									label={t('email')}></InputWrapper>
							</Box>
							{isSA ? (
								<InputWrapper
									errorStateTestID="nationalIdError"
									dataTestID="nationalIDTest"
									maxLength={10}
									name="nationalId"
									key={'nationalId'}
									label={t('nationalityId')}></InputWrapper>
							) : null}
							<Box>
								<InternationalPhone
									dataTestID="contactNumber"
									errorStateID="contactNumberError"
									name={'contactNumber'}
									key={'contactNumber'}
									label={'mobileNumber'}></InternationalPhone>
								{isSA && getIsInvestor() ? (
									<Box
										data-testid="ksaContactInfoId"
										className="caption !text-[11px] mt-1">
										{t('KSAcontactNumberInfo')}
									</Box>
								) : null}
							</Box>
							<Box className="relative">
								<PasswordValidationPopover
									controllerValue={form.values.password}
									showErrorPopover={showErrorPopover}
								/>
								<InputWrapper
									errorStateTestID="passwordError"
									onFocus={() => setShowErrorPopover(true)}
									onBlur={() => setShowErrorPopover(false)}
									hideErrorMessage
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													className="!bg-transparent text-primary"
													onClick={togglePassword}>
													{passwordType === 'password' ? (
														<VisibilityOffOutlinedIcon></VisibilityOffOutlinedIcon>
													) : (
														<RemoveRedEyeOutlinedIcon></RemoveRedEyeOutlinedIcon>
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
									name="password"
									dataTestID="password"
									type={passwordType}
									key={'password'}
									label={t('password')}></InputWrapper>
							</Box>
							<FormControlLabel
								className="flex !items-start"
								label={
									<span>
										{getIsBorrower() && !isSA ? '' : t('iAcceptThe')}{' '}
										{getIsBorrower() ? (
											isSA ? (
												<>
													<a
														className="underline-link"
														href={settings()?.generalTermsConditions}
														rel="noreferrer"
														target="_blank">
														{t('termsConditionsShort')}
													</a>
													<span>&nbsp;{t('iAuthFundingSouqBorrower')}</span>
												</>
											) : (
												<span>{t('iAuthFundingSouqBorrowerGlobal')}</span>
											)
										) : (
											<>
												{getIsInvestor() ? (
													<span>
														<a
															rel="noreferrer"
															className="underline-link"
															href={settings()?.investorTermsAndConditions}
															target="_blank">
															{t('termsConditionsInvestors')}
														</a>
														,
														<a
															className="mx-1 underline-link"
															href={settings()?.investorsShariahTAndCs}
															rel="noreferrer"
															target="_blank">
															{t('termsConditionsShariaInvestors')}
														</a>
													</span>
												) : (
													<span>
														<a
															className="underline-link"
															href={settings()?.borrowerTermsAndConditions}
															rel="noreferrer"
															target="_blank">
															{t('termsConditionsBorrower')}
														</a>
														,
														<a
															className="mx-1 underline-link"
															href={settings()?.borrowersShariahTAndCs}
															rel="noreferrer"
															target="_blank">
															{t('termsConditionsShariahBorrower')}
														</a>
													</span>
												)}
												&{' '}
												<a
													className="underline-link"
													href={settings()?.privacyPolicy}
													rel="noreferrer"
													target="_blank">
													{t('privacyPolicy')}
												</a>
											</>
										)}
									</span>
								}
								control={
									<Checkbox
										data-testid="termsConditionsForInvestmentsAccepted"
										onChange={(e) => {
											form.setFieldValue(
												'termsConditionsForInvestmentsAccepted',
												e.target.checked
											);
										}}
										aria-label='termsConditionsForInvestmentsAccepted'
										className="!px-[3px] !pb-[3px] !pt-0"
										name={'termsConditionsForInvestmentsAccepted'}
										checked={form.values.termsConditionsForInvestmentsAccepted}
										value={form.values.termsConditionsForInvestmentsAccepted}
									/>
								}
							/>
							<Box className="flex justify-center my-2">
								<ButtonWrapper
									dataTestID="submitBtn"
									disabled={loadingEmail || getIsDisableForm()}
									largeBtn
									loading={loading}
									onClick={() => {
										FormHelper.validateForm(form);
									}}
									size="large"
									label={t('continue')}
									endIcon={<ArrowIcons></ArrowIcons>}></ButtonWrapper>
							</Box>
						</Box>
					</fieldset>
				</form>
				<Box className="text-sm text-center">
					{t('alreadyAmember')}{' '}
					<a
						href={buildLocalizeRoute(AppRoutes.Login)}
						className="underline-link">
						{t('signIn')}
					</a>
				</Box>
			</Box>
		</FormikProvider>
	);
};
