/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Roles } from '../../../constants/roles';
import { UserDocumentTypes } from '../../../constants/userDocuments';
import { useEffect } from 'react';
import { UserService } from '../../../services/userService';
import { SystemSettingsService } from '../../../services/systemSettingsService';
import { OTPAuthenticationDialog } from '../otpAuthenticationDialog';
import { OTPTypes } from '../../../constants/otpTypes';
import { Box, Button, Card } from '@mui/material';
import { ButtonWrapper } from '../formUtilities/useButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useTranslation } from 'react-i18next';
import { ArrowIcons } from '../../../shared/components/common/arrowIcons';
import { IS_SA } from '../../../config/appConfig';

export const UploadPassportUploaderSection = ({
	passportFile = null,
	identifierFile = null,
	userType = Roles.Investor,
	isLoading = false,
	onFileUploaded = (e) => { },
	testId = '',
}) => {
	const userService = new UserService();
	const systemSettingsService = new SystemSettingsService();
	const [isPassport, setIsPassport] = useState(true);
	const [isShowAuthOtp, setIsShowAuthOtp] = useState(false);
	const [passportFileValue, setPassportFileValue] = useState(passportFile);
	const [identifierFileValue, setIdentifierFileValue] =
		useState(identifierFile);
	const [tempPassport, setTempPassport] = useState(null);
	const [tempIdentifier, setTempIdentifier] = useState(null);
	const [loadingFile, setLoadingFile] = useState({});
	const { t } = useTranslation();

	const isOTPEnabled = () => {
		return systemSettingsService.systemSettings()?.enabledOtp && isInvestor();
	};

	const isInvestor = () => {
		return userType === Roles.Investor;
	};

	const isDisablePassportChange = () => {
		if (loadingFile[userIdentifier()] || loadingFile[userPassport()]) {
			return true;
		}

		return (
			passportFileValue?.file?.url ||
			identifierFileValue?.file?.url ||
			identifierFileValue?.additionalFile?.url
		);
	};

	useEffect(() => {
		setPassportFileValue(passportFile);
		checkIsPassport();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passportFile]);

	useEffect(() => {
		setIdentifierFileValue(identifierFile);
		checkIsPassport();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifierFile]);

	useEffect(() => {
		setLoaderValue(isLoading, null, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (!isPassport) {
			if (tempIdentifier && tempPassport) {
				checkAuthBeforeUpload();
			}
		} else if (tempPassport) {
			checkAuthBeforeUpload();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempIdentifier, tempPassport]);

	const checkIsPassport = () => {
		if (passportFile?.file?.url) {
			setIsPassport(true);
		} else if (identifierFile?.file?.url) {
			setIsPassport(false);
		}
	};

	const userIdentifier = () => {
		return isInvestor()
			? UserDocumentTypes.InvestorIdentifier
			: UserDocumentTypes.BorrowerIdentifier;
	};

	const userPassport = () => {
		return isInvestor()
			? UserDocumentTypes.InvestorPassport
			: UserDocumentTypes.BorrowerPassport;
	};

	const setLoaderValue = (value, type, isAll = false) => {
		let loading = {};
		if (isAll) {
			loading = {
				[userIdentifier()]: value,
				[userPassport()]: value,
			};
		} else {
			loading = {
				...loadingFile,
				[type]: value,
			};
		}
		setLoadingFile(loading);
	};

	const onUploadClick = (fileTypes) => {
		if (isPassport) {
			if (fileTypes === userPassport()) {
				if (passportFileValue?.file?.url) {
					return;
				}
			}
		} else {
			if (fileTypes === userPassport()) {
				if (identifierFileValue?.file?.url) {
					return;
				}
			} else {
				if (identifierFileValue?.additionalFile?.url) {
					return;
				}
			}
		}

		document.getElementById(`fileUploader${fileTypes}`).click();
	};

	const checkIsPassportUpload = () => {
		if (isPassport) {
			return passportFileValue?.file?.url;
		} else {
			return identifierFileValue?.file?.url;
		}
	};

	const onFileUpload = (file, type) => {
		if (isPassport) {
			setTempPassport(file);
			setLoaderValue(true, userPassport());
		} else {
			if (type === userPassport()) {
				setLoaderValue(true, userPassport());
				setTempPassport(file);
			}
			if (type === userIdentifier()) {
				setLoaderValue(true, userIdentifier());
				setTempIdentifier(file);
			}
		}
	};

	const checkAuthBeforeUpload = () => {
		if (isOTPEnabled()) {
			setIsShowAuthOtp(true);
		} else {
			uploadDocs();
		}
	};

	const uploadDocs = () => {
		let type = isPassport ? userPassport() : userIdentifier();
		let additionalFile = !isPassport ? tempIdentifier : null;
		userService
			.uploadUserDocuments(
				tempPassport,
				type,
				additionalFile ? additionalFile : null
			)
			.then((resp) => {
				setTempIdentifier(null);
				setTempPassport(null);
				setLoaderValue(false, null, true);
				let form = {
					identifierFile: null,
					passportFile: null,
				};
				if (type === userIdentifier()) {
					form.identifierFile = resp.data;
					setIdentifierFileValue(resp.data);
				} else {
					form.passportFile = resp.data;
					setPassportFileValue(resp.data);
				}

				onFileUploaded(form);
			});
	};

	const onViewFile = (url) => {
		if (!url) {
			return;
		}
		window.open(url, '_blank');
	};

	const onDeleteFile = () => {
		let id = passportFileValue?.id || identifierFileValue?.id;
		if (id) {
			setLoaderValue(true, null, true);
			userService
				.deleteFile(id)
				.then((resp) => {
					setPassportFileValue(null);
					setIdentifierFileValue(null);
					let form = {
						identifierFile: null,
						passportFile: null,
					};
					onFileUploaded(form);
					setLoaderValue(true, null, false);
				})
				.catch((err) => {
					setLoaderValue(true, null, false);
				});
		}
	};

	const passportChange = (value) => {
		setLoaderValue(false, null, true);
		setIsPassport(value);
	};

	const isShowDocumentChangeBtn = () => {
		return IS_SA || identifierFile?.file;
	};
	const actionSection = (file, fileType) => {
		return (
			<Box
				data-testid={testId}
				className="flex flex-row items-center justify-end">
				<input
					accept="image/*,.pdf"
					type="file"
					id={`fileUploader${fileType}`}
					hidden
					onChange={(e) => {
						e.stopPropagation();
						onFileUpload(e.target.files[0], fileType);
					}}
				/>
				<ButtonWrapper
					classList="!bg-[#f7f7f7] !text-primary"
					iconButton
					loading={loadingFile[fileType]}
					hideBtn={file?.url}
					icon={<CloudUploadOutlinedIcon />}></ButtonWrapper>
				{file?.url ? (
					<Box className="flex flex-0 items-center">
						<a onClick={(e) => {
							e.stopPropagation();
							onViewFile(file?.url);
						}} className='underline-link'>{t('preview')}</a>
						<a onClick={(e) => {
							onDeleteFile();
						}} className='underline-link !mx-2 !text-red-400'>{t('delete')}</a>
					</Box>
				) : null}
			</Box>
		);
	};

	return (
		<>
			<Box
				className={`flex flex-col ${isShowDocumentChangeBtn()
					? 'space-y-4 border border-dashed p-3 rounded-md'
					: ''
					}`}>
				<Box className="flex justify-end items-center cursor-pointer">
					{isShowDocumentChangeBtn() ? (
						<Button
							disabled={isDisablePassportChange()}
							onClick={() => {
								passportChange(!isPassport);
							}}
							className="p-2 flex justify-center items-center rounded-full border passport-change-btn">
							<Box className="flex items-center !text-[13px]">
								<span className="mx-1 !normal-case">
									{!isPassport
										? t('changeToPassport')
										: t('changeToNationalId')}
								</span>
								<ArrowIcons classList={'!text-[16px]'}></ArrowIcons>
							</Box>
						</Button>
					) : null}
				</Box>
				<Box className='p-2' component={Card}>
					<Box
						onClick={() => {
							onUploadClick(userPassport());
						}}
						className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${passportFileValue?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
						<Box className="flex flex-0 items-center">
							<Box>
								<ButtonWrapper
									disabled
									hideBtn={checkIsPassportUpload()}
									classList="!bg-[#f7f7f7] !text-primary"
									iconButton
									icon={
										<SummarizeOutlinedIcon />
									}></ButtonWrapper>
							</Box>
							<Box className="mx-2 font-[500] !text-sm">
								{!isPassport
									? t('uploadNationIDFront')
									: t('UploadPassportPhoto')}
							</Box>
						</Box>
						<Box>
							{actionSection(
								isPassport ? passportFileValue?.file : identifierFileValue?.file,
								userPassport()
							)}
						</Box>
					</Box>
				</Box>
				{!isPassport ? (
					<Box className='p-2' component={Card}>
						<Box
							onClick={() => {
								onUploadClick(userIdentifier());
							}}
							className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${identifierFileValue?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
							<Box className="flex flex-0 items-center">
								<Box>
									<ButtonWrapper
										disabled
										hideBtn={identifierFileValue?.additionalFile?.url}
										classList="!bg-[#f7f7f7] !text-primary"
										iconButton
										icon={
											<SummarizeOutlinedIcon />
										}></ButtonWrapper>
								</Box>
								<Box className="mx-2 font-[500] !text-sm">
									{t('UploadPassportPhotoBack')}
								</Box>
							</Box>
							<Box>
								{actionSection(
									identifierFileValue?.additionalFile,
									userIdentifier()
								)}
							</Box>
						</Box>
					</Box>
				) : null}
			</Box>

			{isShowAuthOtp ? (
				<OTPAuthenticationDialog
					type={OTPTypes.Other}
					state={isShowAuthOtp}
					onClose={(resp) => {
						setIsShowAuthOtp(false);
						if (resp) {
							uploadDocs();
						}
					}}></OTPAuthenticationDialog>
			) : null}
		</>
	);
};
