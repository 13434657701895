import { Box } from '@mui/material';
import { IS_SA } from '../../../../../config/appConfig';
import { InvestorDocumentVerificationGlobal } from './global';
import { InvestorDocumentVerificationKSA } from './ksa';

export const InvestorDocumentVerification = () => {
	return (
		<Box>
			{IS_SA ? (
				<InvestorDocumentVerificationKSA />
			) : (
				<InvestorDocumentVerificationGlobal />
			)}
		</Box>
	);
};
