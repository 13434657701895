import { Box } from '@mui/material';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import DataTableGrid from '../../../../shared/components/formUtilities/useTable';
import { initializeColumns } from './depositColumn';
import { useState } from 'react';
import { TransferService } from '../../../../services/transferService';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const DepositHistory = () => {
	const transferService = new TransferService();
	const [depositHistory, setDepositHistory] = useState([]);
	const [loading, setLoading] = useState(false);
	const columns = initializeColumns();
	const { t } = useTranslation();

	useEffect(() => {
		setLoading(true);
		transferService
			.getDepositHistory()
			.then((resp) => {
				setLoading(false);
				setDepositHistory(resp.data);
			})
			.catch((err) => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box>
			<TextWrapper
				classList="!text-[22px] !font-[700] !text-start"
				text={t('depositHistory')}></TextWrapper>
			<Box className="mt-4">
				<DataTableGrid
					hideSerialNumbers
					name={'depositHistoryTbl'}
					columns={columns}
					records={depositHistory}
					showEditColumnsButton={false}
					isPagination={false}
					loading={loading}></DataTableGrid>
			</Box>
		</Box>
	);
};
