import { Box } from '@mui/material';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateInvestorSteps,
} from '../../../../../../services/authService';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { FormikProvider, getIn, useFormik } from 'formik';
import { FormHelper } from '../../../../../../helpers/formHelper';
import SelectWrapper from '../../../../../../shared/components/formUtilities/useSelect';
import { useEffect, useState } from 'react';
import { InputWrapper } from '../../../../../../shared/components/formUtilities/useInput';
import { DatePickerWrapper } from '../../../../../../shared/components/formUtilities/useDatePicker';
import { useTranslation } from 'react-i18next';
import { ArrowIcons } from '../../../../../../shared/components/common/arrowIcons';
import { InvestorType } from '../../../../../../constants/investorType';
import { InvestorRegService } from '../../../investorRegService';
import { investorPersonalInfoRegSchema } from '../../../../../../shared/validations/schema';
import { useSelector } from 'react-redux';
import { SubStepper } from '../../../../components/subStepper';

export const PersonalInfoGlobal = ({
	onSubmit = () => {},
	onBack = () => {},
	investorType = {},
}) => {
	const investorRegService = new InvestorRegService();
	const { t } = useTranslation();

	const { investorCollections } = useSelector(
		(state) => state.investorCollectionReducer
	);
	const counties = investorCollections?.countryCollection;
	const publicFunctionList = investorCollections?.publicFunctionCollection;

	const [loading, setLoading] = useState(false);
	const [isCorporate, setIsCorporate] = useState(false);

	const form = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			publicFunction: FormHelper.buildOtherFormGroup(),
			birthDate: '',
			placeOfBirth: '',
			nationalityCountryId: '',
			address: FormHelper.buildAddressFormGroup(),
		},
		validationSchema: investorPersonalInfoRegSchema,
		onSubmit: (values) => {
			onSumbitPersonalInfo(values);
		},
		...FormHelper.formikProps(),
	});

	const nationalityControl = getIn(form?.values, 'nationalityCountryId');
	const countryControl = getIn(form?.values, 'address.countryId');

	useEffect(() => {
		setIsCorporate(investorType?.name === InvestorType.Corporate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investorType]);

	useEffect(() => {
		getInvestorPersonalDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let name = counties?.filter((resp) => resp?.id === countryControl)[0]?.name;
		let data = {
			...getRegistrationData(),
			addressCountry: name,
		};
		setRegistrationData(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryControl]);

	useEffect(() => {
		let name = counties?.filter((resp) => resp?.id === nationalityControl)[0]
			?.name;
		let data = {
			...getRegistrationData(),
			nationality: name,
		};
		setRegistrationData(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nationalityControl]);

	const getInvestorPersonalDetails = () => {
		investorRegService
			.getInvestorPersonalInformation()
			.then((resp) => {
				let data = resp.data;
				let personalInfo = {
					...data,
					firstName: data?.firstName || '',
					lastName: data?.lastName || '',
					nationalityCountryId: data?.nationalityCountry?.id,
					publicFunction: FormHelper.getComplexOther(data.publicFunction),
					birthDate:
						data?.birthDate === '0001-01-01T00:00:00' ? '' : data?.birthDate,
					address: {
						addressLine: data?.address?.addressLine,
						postalCode: data?.address?.postalCode,
						countryId: data?.address?.country?.id,
					},
				};
				setTimeout(() => {
					form.setValues(personalInfo);
				});
			})
			.catch((err) => {});
	};

	const onSumbitPersonalInfo = (values) => {
		setLoading(true);
		investorRegService
			.submitPersonalInformation(values)
			.then((response) => {
				let resp = response.data;
				let registrationModel = resp?.registrationModel;
				saveTokenFromRegistration(resp?.loginModel?.token);
				let regData = {
					...getRegistrationData(),
					step: registrationModel.step,
					investorType:
						registrationModel?.personalInformation?.investorType?.name,
					requestedStep: 2,
				};
				setRegistrationData(regData);
				updateInvestorSteps(registrationModel.step);
				setLoading(false);
				onSubmit();
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<FormikProvider value={form}>
			<Box>
				<SubStepper
					stepName={
						isCorporate ? 'personalInformation' : 'personalInformation1'
					}
					currentStep={1}
					totalSteps={2}
				/>
				<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
					{/* <SelectWrapper
						onChanges={(value) => {
							onInvestorTypeChange(value);
						}}
						name={'investorTypeId'}
						key={'investorTypeId'}
						items={investorTypes}
						label={'investorType'}
					/> */}
					<InputWrapper
						dataTestID='firstName'
						errorStateTestID="firstNameError"
						name={'firstName'}
						key={'firstName'}
						label={
							isCorporate ? 'firstNameSignatory' : 'firstName'
						}></InputWrapper>
					<InputWrapper
						dataTestID='lastName'
						errorStateTestID="lastNameError"
						name={'lastName'}
						key={'lastName'}
						label={
							isCorporate ? 'lastNameSignatory' : 'lastName'
						}></InputWrapper>
					<DatePickerWrapper
						dataTestID='birthDate'
						errorStateTestID="birthDateError"
						defaultMaxDate={true}
						name={'birthDate'}
						key={'birthDate'}
						label={'dateOfBirth'}></DatePickerWrapper>
					<InputWrapper
						dataTestID='placeOfBirth'
						errorStateTestID="placeOfBirthError"
						name={'placeOfBirth'}
						key={'placeOfBirth'}
						label={'placeOfBirth'}></InputWrapper>
					<SelectWrapper
						dataTestID='nationalityCountryId'
						errorStateTestID="nationalityCountryIdError"
						name={'nationalityCountryId'}
						key={'nationalityCountryId'}
						items={counties}
						label={'nationality'}
					/>
					<InputWrapper
						dataTestID='addressLine'
						errorStateTestID="addressLineError"
						name={'address.addressLine'}
						key={'address.addressLine'}
						label={'address'}></InputWrapper>
					<SelectWrapper
						dataTestID='countryId'
						errorStateTestID="countryIdError"
						name={'address.countryId'}
						key={'address.countryId'}
						items={counties}
						label={'countryOfResidence'}
					/>
					<SelectWrapper
						dataTestID='publicFunction'
						name={'publicFunction.valueId'}
						key={'publicFunction.valueId'}
						controlOtherValueName={'publicFunction.valueSpecified'}
						items={publicFunctionList}
						label={'importantPublicFunction'}
					/>
				</Box>
				<Box className="mt-4 flex justify-center md:justify-between">
					<ButtonWrapper
						dataTestID="backBtn"
						classList="!secondary-btn md:!mx-0 !mx-2"
						disabled={loading}
						largeBtn
						icon={<ArrowIcons isInvert></ArrowIcons>}
						label={t('back')}
						onClick={() => {
							onBack && onBack();
						}}
					/>
					<ButtonWrapper
						dataTestID="nextBtn"
						loading={loading}
						largeBtn
						endIcon={<ArrowIcons></ArrowIcons>}
						label={t('next')}
						onClick={() => {
							FormHelper.validateForm(form);
						}}
					/>
				</Box>
			</Box>
		</FormikProvider>
	);
};
