export const taxResidencyHelper = (form = {}, fieldName = 'taxResidency') => {
	let taxResidency = form[fieldName];
	if (taxResidency && Object.keys(taxResidency).length) {
		let countryId =
			taxResidency?.countryId && typeof taxResidency?.countryId === 'number'
				? taxResidency?.countryId
				: taxResidency?.countryId === ''
				? ''
				: null;
		return {
			...form,
			[fieldName]: {
				...taxResidency,
				countryId: countryId,
				areYouTaxedElsewhere: !!countryId,
				taxIdElsewhere: countryId ? taxResidency?.taxIdElsewhere : null,
			},
		};
	}

	return form;
};
