import { FormikProvider, useFormik } from 'formik';
import { BorrowerRegService } from '../../borrowerRegService';
import { FormHelper } from '../../../../../helpers/formHelper';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateBorrowerSteps,
} from '../../../../../services/authService';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { useTranslation } from 'react-i18next';
import { BorrowerRegBusinessSchema } from '../../../../../shared/validations/schema';
import { useSelector } from 'react-redux';

export const BorrowerBusinessInformation = ({
	isLoading = false,
	response = null,
}) => {
	const borrowerRegService = new BorrowerRegService();
	const { borrowerCollections } = useSelector(
		(state) => state.borrowerCollectionReducer
	);

	const navigate = useNavigate();
	const [loading, setLoading] = useState(isLoading);
	const { t } = useTranslation();

	const companyTypes = borrowerCollections?.companyTypesCollection;
	const businessSectors = borrowerCollections?.industriesCollection;
	const registrationPlaces =
		borrowerCollections?.borrowerRegistrationPlacesCollection;
	const cities = borrowerCollections?.citiesCollection;

	const form = useFormik({
		initialValues: {
			businessName: '',
			tradeLicenseNumber: '',
			industryId: '',
			companyTypeId: '',
			businessAddress: FormHelper.buildAddressFormGroup(),
			needFundsFor: '',
			registrationPlaceId: '',
		},
		validationSchema: BorrowerRegBusinessSchema,
		onSubmit: () => {
			submit();
		},
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		getBorrowerProfileBusinessInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBorrowerProfileBusinessInfo = () => {
		borrowerRegService
			.getBorrowerProfileBusinessInfo()
			.then((resp) => {
				const businessInfo = resp.data;
				let businessData = {
					...businessInfo,
					businessName: businessInfo?.businessTradingName || null,
					businessAddress: {
						...businessInfo?.address,
						addressLine: businessInfo.address?.addressLine || null,
						city: businessInfo.address?.city || null,
					},
					industryId: businessInfo?.industry?.id,
					companyTypeId: businessInfo?.companyType?.id,
					registrationPlaceId: businessInfo?.registrationPlace?.id,
				};
				setTimeout(() => form.setValues(businessData));
			})
			.catch((err) => {});
	};

	const submit = () => {
		setLoading(true);
		borrowerRegService
			.registerBorrowerBusinessInfo(form.values)
			.then((resp) => {
				setLoading(false);
				let registrationModel = resp.data?.registrationModel;
				saveTokenFromRegistration(resp.data?.loginModel?.token);
				let regData = {
					...getRegistrationData(),
					step: registrationModel.step,
					requestedStep: 2,
				};
				setRegistrationData(regData);
				updateBorrowerSteps(registrationModel.step);
				onNavigate();
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onBack = () => {
		let data = {
			...getRegistrationData(),
			requestedStep: 0,
		};
		setRegistrationData(data);
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.BorrowerRegistartion}/${AppRoutes.BRegPersonalInfo}`
			)
		);
	};

	const onNavigate = () => {
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.BorrowerRegistartion}/${AppRoutes.BRegDocumentVarification}`
			)
		);
	};

	return (
		<FormikProvider value={form}>
			<Box className="flex flex-col space-y-4">
				<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<InputWrapper
						dataTestID="businessName"
						errorStateTestID="businessNameError"
						name={'businessName'}
						key={'businessName'}
						label={'businessName'}></InputWrapper>
					<InputWrapper
						dataTestID="tradeLicenseNumber"
						errorStateTestID="tradeLicenseNumberError"
						name={'tradeLicenseNumber'}
						key={'tradeLicenseNumber'}
						label={'tradeLicenseNumber'}></InputWrapper>
					<SelectWrapper
						dataTestID="industryId"
						errorStateTestID="industryIdError"
						name={'industryId'}
						key={'industryId'}
						items={businessSectors}
						label={'businessSector'}
					/>
					<SelectWrapper
						dataTestID="companyTypeId"
						errorStateTestID="companyTypeIdError"
						name={'companyTypeId'}
						key={'companyTypeId'}
						items={companyTypes}
						label={'companyType'}
					/>
					<InputWrapper
						dataTestID="addressLine"
						errorStateTestID="addressLineError"
						name={'businessAddress.addressLine'}
						key={'businessAddress.addressLine'}
						label={'businessAddress'}></InputWrapper>
					<SelectWrapper
						dataTestID="city"
						errorStateTestID="cityError"
						name={'businessAddress.city'}
						key={'businessAddress.city'}
						items={cities}
						label={'emirates'}
					/>
					<SelectWrapper
						dataTestID="registrationPlaceId"
						errorStateTestID="registrationPlaceIdError"
						name={'registrationPlaceId'}
						key={'registrationPlaceId'}
						items={registrationPlaces}
						label={'registrationPlace'}
					/>
					<InputWrapper
						dataTestID="needFundsFor"
						errorStateTestID="needFundsForError"
						isTextArea
						rows={1}
						name={'needFundsFor'}
						key={'needFundsFor'}
						label={'WhatDoYouNeedFundsFor'}></InputWrapper>
				</Box>
				<Box className="flex md:justify-between justify-center items-center">
					<ButtonWrapper
						dataTestID="backBtn"
						classList="!secondary-btn md:!mx-0 !mx-2"
						disabled={loading}
						largeBtn
						icon={<ArrowIcons isInvert></ArrowIcons>}
						label={t('back')}
						onClick={() => {
							onBack();
						}}
					/>
					<ButtonWrapper
						dataTestID="nextBtn"
						loading={loading}
						largeBtn
						endIcon={<ArrowIcons></ArrowIcons>}
						label={t('next')}
						onClick={() => {
							if (response) {
								onNavigate();
							} else {
								FormHelper.validateForm(form);
							}
						}}
					/>
				</Box>
			</Box>
		</FormikProvider>
	);
};
