export const InvestorGuidelineSteps = {
	NotStarted: 0,
	NavMarketPlace: 1,
	NavPortfolio: 2,
	NavInvestSettings: 3,
	ActivatedBalance: 4,
	PauseInvest: 5,
	// BusinessA : 6,
	// ActivateBtn : 7,
	AddFund: 6,
};
