import { SET_BORROWER_COLLECTIONS, SET_INVESTOR_COLLECTIONS } from './action';

const investorCollectionReducer = (state = { investorCollections: {} }, action) => {
	switch (action.type) {
		case SET_INVESTOR_COLLECTIONS: {
			return { ...state, investorCollections: action.payload };
		}
		default:
			return state;
	}
};

const borrowerCollectionReducer = (state = { borrowerCollections: {} }, action) => {
	switch (action.type) {
		case SET_BORROWER_COLLECTIONS: {
			return { ...state, borrowerCollections: action.payload };
		}
		default:
			return state;
	}
};

export { investorCollectionReducer, borrowerCollectionReducer };
