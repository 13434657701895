import { axiosGet, axiosPatch, axiosPost } from './axiosService';

const baseTransferURL = '/transfers';

export class TransferService {
	getUserBankDetails() {
		return axiosGet(null, baseTransferURL + '/personalData');
	}

	addTransferIn(amount) {
		return axiosPost({ amount }, baseTransferURL + '/deposit');
	}

	addTransferOut(amount) {
		return axiosPost({ amount }, baseTransferURL + '/withdrawal');
	}

	cancelTransfer(transferId) {
		return axiosPatch(baseTransferURL + '/cancel', {
			correlationId: transferId,
		});
	}

	getDepositHistory() {
		return axiosGet(null, baseTransferURL + '/deposit');
	}

	getWithdrawHistory() {
		return axiosGet(null, baseTransferURL + '/Withdrawal');
	}

	updateUserBankDetails(userBankDetails) {
		return axiosPatch(baseTransferURL + '/personalData', userBankDetails);
	}

	getTransferHistory() {
		return axiosGet(null, baseTransferURL + `/History`);
	}
}
