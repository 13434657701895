import { LanguageService } from '../../../services/languageService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const ArrowIcons = ({
	isInvert = false,
	classList,
	isKeyboardArrow = false,
}) => {
	const languageService = new LanguageService();

	const isArabic = () => {
		return languageService.isArabic();
	};

	return (
		<>
			{!isKeyboardArrow ? (
				<>
					{!isInvert ? (
						!isArabic() ? (
							<ArrowForwardIcon className={classList}></ArrowForwardIcon>
						) : (
							<ArrowBackIcon className={classList}></ArrowBackIcon>
						)
					) : isArabic() ? (
						<ArrowForwardIcon className={classList}></ArrowForwardIcon>
					) : (
						<ArrowBackIcon className={classList}></ArrowBackIcon>
					)}
				</>
			) : (
				<>
					{!isInvert ? (
						!isArabic() ? (
							<KeyboardArrowRightIcon
								className={classList}></KeyboardArrowRightIcon>
						) : (
							<KeyboardArrowLeftIcon
								className={classList}></KeyboardArrowLeftIcon>
						)
					) : isArabic() ? (
						<KeyboardArrowRightIcon
							className={classList}></KeyboardArrowRightIcon>
					) : (
						<KeyboardArrowLeftIcon
							className={classList}></KeyboardArrowLeftIcon>
					)}
				</>
			)}
		</>
	);
};
