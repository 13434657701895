import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../../helpers/formHelper';
import { useEffect, useState } from 'react';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import { DatePickerWrapper } from '../../../../../shared/components/formUtilities/useDatePicker';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { InvestorService } from '../../../investorService';
import { ConfirmPasswordDialog } from '../../../../../shared/components/confirmPasswordDialog';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { InvestorProfileUpdateSchema } from '../../../../../shared/validations/schema';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { useSelector } from 'react-redux';
import { taxResidencyHelper } from '../../../../../helpers/taxResidencyHelper';
import { IS_SA } from '../../../../../config/appConfig';
import { InternationalPhone } from '../../../../../shared/components/formUtilities/useInternationalPhone';

export const InvestorPersonalInfo = ({
	investorDetails,
	onSavePersonalInfo = () => {},
}) => {
	const systemSettingsService = new SystemSettingsService();
	const investorService = new InvestorService();
	const { investorCollections } = useSelector(
		(state) => state.investorCollectionReducer
	);
	const { t } = useTranslation();
	const [isEditInfo, setIsEditInfo] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	const [showOTPAuth, setShowOTPAuth] = useState(false);

	const countryList = investorCollections?.countryCollection || [];
	const employmentStatusList =
		investorCollections?.employmentStatusCollection || [];
	const industryList = investorCollections?.industriesCollection || [];
	const investmentSourceList =
		investorCollections?.incomeSourceCollection || [];
	const annualIncomeList = investorCollections?.annualIncomeCollection || [];
	const publicFunctionList =
		investorCollections?.publicFunctionCollection || [];
	const netWorthList = investorCollections?.netWorthCollection || [];
	const investorTypeList = investorCollections?.investorTypeCollection || [];
	const estimatedYearlyInvestments =
		investorCollections?.estimateAnnualInvestmentCollection || [];

	const form = useFormik({
		initialValues: {
			investorTypeId: '',
			firstName: '',
			lastName: '',
			birthDate: '',
			netWorthId: '',
			nationalityId: '',
			contactNumber: '',
			nationalId: '',
			bankCountryId: '',
			placeOfBirth: '', // TODO: add validation if needed
			employmentStatusId: '',
			companyName: '',
			annualIncomeId: '',
			estimatedAnnualInvestmentId: '',
			address: FormHelper.buildAddressFormGroup(),
			industry: FormHelper.buildOtherFormGroup(),
			incomeSource: FormHelper.buildOtherFormGroup(),
			publicFunction: FormHelper.buildOtherFormGroup(),
			jobTitle: '',
			taxResidency: FormHelper.getTaxResidencies(null),
		},
		validationSchema: InvestorProfileUpdateSchema,
		onSubmit: () => {
			if (settings()?.enabledOtp) {
				setShowOTPAuth(true);
			} else {
				setShowPasswordDialog(true);
			}
		},
		...FormHelper.formikProps(),
	});

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		let formValues = {
			...investorDetails,
			investorTypeId: investorDetails?.investorType?.id,
			nationalityId: investorDetails?.nationality?.id,
			employmentStatusId: investorDetails?.employmentStatus?.id,
			annualIncomeId: investorDetails?.annualIncome?.id,
			jobTitle: investorDetails?.jobTitle,
			netWorthId: investorDetails?.netWorth?.id,
			bankCountryId: investorDetails?.bankCountry?.id,
			estimatedAnnualInvestmentId:
				investorDetails?.estimatedAnnualInvestment?.id,
			industry: FormHelper.getComplexOther(investorDetails?.industry),
			incomeSource: FormHelper.getComplexOther(investorDetails?.incomeSource),
			publicFunction: FormHelper.getComplexOther(
				investorDetails?.publicFunction
			),
			address: {
				...investorDetails?.address,
				addressLine: investorDetails.address?.addressLine || null,
				additionalAddressLine:
					investorDetails.address?.additionalAddressLine || null,
				city: investorDetails.address?.city || null,
				postalCode: investorDetails.address?.postalCode || null,
				countryId: investorDetails.address?.country?.id || null,
			},
			taxResidency: FormHelper.getTaxResidencies(investorDetails?.taxResidency),
		};
		form.setValues(formValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investorDetails]);

	const onEdit = (e) => {
		e.preventDefault();
		if (isEditInfo) {
			FormHelper.validateForm(form);
		} else {
			setIsEditInfo(!isEditInfo);
		}
	};

	const onSaveInfo = () => {
		setLoading(true);
		const personalInfo = form.values;
		delete personalInfo.occupation;
		delete personalInfo.status;
		delete personalInfo.employmentStatus;
		investorService
			.updateInvestorProfile(taxResidencyHelper(personalInfo))
			.then((resp) => {
				onSavePersonalInfo();
				setLoading(false);
				setIsEditInfo(!isEditInfo);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onCloseOTPAuth = (e) => {
		setShowOTPAuth(false);
		if (e) {
			onSaveInfo();
		}
	};

	const onPasswordDialogClose = (e) => {
		setShowPasswordDialog(false);
		if (e) {
			onSaveInfo();
		}
	};

	return (
		<SectionWithHeading
			loading={loading}
			isEditButton={true}
			onEditInfo={(e) => {
				onEdit(e);
			}}
			isEditToggle={isEditInfo}
			heading={
				<Box className="flex items-center">
					<span className="mx-2">{t('information')}</span>
				</Box>
			}
			children={
				<Box>
					<fieldset disabled={!isEditInfo}>
						<FormikProvider value={form}>
							<Box className="grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-2">
								<SelectWrapper
									disabled={true}
									name={'investorTypeId'}
									key={'investorTypeId'}
									items={investorTypeList}
									label={t('investorType')}
								/>
								<InputWrapper
									name={'firstName'}
									key={'firstName'}
									label={t('firstName')}></InputWrapper>
								<InputWrapper
									name={'lastName'}
									key={'lastName'}
									label={t('lastName')}></InputWrapper>
								<DatePickerWrapper
									disabled={true}
									defaultMaxDate={true}
									name={'birthDate'}
									key={'birthDate'}
									label={t('dateOfBirth')}></DatePickerWrapper>
								<SelectWrapper
									disabled={true}
									name={'nationalityId'}
									key={'nationalityId'}
									items={countryList}
									label={t('nationality')}
								/>
								<InputWrapper
									name={'placeOfBirth'}
									key={'placeOfBirth'}
									label={t('placeOfBirth')}></InputWrapper>
								<InternationalPhone
									phoneWithCode={investorDetails?.contactNumber}
									name={'contactNumber'}
									key={'contactNumber'}
									label={'contactNumber'}></InternationalPhone>
								<InputWrapper
									name={'nationalId'}
									key={'nationalId'}
									label={t('nationalIdPassportNo')}></InputWrapper>
								<SelectWrapper
									name={'employmentStatusId'}
									key={'employmentStatusId'}
									items={employmentStatusList}
									label={t('employmentStatus')}
								/>
								<InputWrapper
									name={'companyName'}
									key={'companyName'}
									label={t('companyName')}></InputWrapper>
								<SelectWrapper
									name={'annualIncomeId'}
									key={'annualIncomeId'}
									items={annualIncomeList}
									label={t('annualIncome')}
								/>
								<InputWrapper
									name={'jobTitle'}
									key={'jobTitle'}
									label={t('jobTitle')}></InputWrapper>
								<SelectWrapper
									tooltipId={'networthProfile'}
									tooltipText={'netWorthTooltipText'}
									name={'netWorthId'}
									key={'netWorthId'}
									items={netWorthList}
									label={t('netWorth')}
								/>
								<SelectWrapper
									name={'estimatedAnnualInvestmentId'}
									key={'estimatedAnnualInvestmentId'}
									items={estimatedYearlyInvestments}
									label={'estimatedYearlyInvestment'}
								/>
								{!IS_SA ? (
									<>
										<SelectWrapper
											name={'bankCountryId'}
											key={'bankCountryId'}
											items={countryList}
											label={'bankCountry'}
										/>
									</>
								) : null}
								<SelectWrapper
									name={'industry.valueId'}
									key={'industry.valueId'}
									controlOtherValueName={'industry.valueSpecified'}
									items={industryList}
									label={t('industry')}
								/>
								<SelectWrapper
									name={'incomeSource.valueId'}
									key={'incomeSource.valueId'}
									controlOtherValueName={'incomeSource.valueSpecified'}
									items={investmentSourceList}
									label={t('fundingSourceOfInvestment')}
								/>
								<SelectWrapper
									name={'publicFunction.valueId'}
									key={'publicFunction.valueId'}
									controlOtherValueName={'publicFunction.valueSpecified'}
									items={publicFunctionList}
									label={t('publicFunction')}
								/>
								{!IS_SA ? (
									<>
										<InputWrapper
											name={'address.addressLine'}
											key={'address.addressLine'}
											label={t('address')}></InputWrapper>
										<SelectWrapper
											name={'address.countryId'}
											key={'address.countryId'}
											items={countryList}
											label={t('countryOfResidence')}
										/>
									</>
								) : null}

								{!IS_SA ? (
									<>
										<InputWrapper
											name={'taxResidency.taxId'}
											key={'taxResidency.taxId'}
											label={'taxId'}></InputWrapper>
										<SelectWrapper
											defaultOptionText="no"
											defaultOptionValue={'no'}
											showDefaultOption
											items={countryList}
											name={`taxResidency.countryId`}
											key={'taxResidency.countryId'}
											label={'areYouTaxedElsewhere'}
										/>
										{form.values?.taxResidency?.countryId &&
										typeof form.values?.taxResidency?.countryId === 'number' ? (
											<InputWrapper
												name={'taxResidency.taxIdElsewhere'}
												key={'taxResidency.taxIdElsewhere'}
												label={'taxId2'}></InputWrapper>
										) : null}
									</>
								) : null}
							</Box>
						</FormikProvider>
					</fieldset>
					{showOTPAuth ? (
						<OTPAuthenticationDialog
							type={OTPTypes.Other}
							state={showOTPAuth}
							onClose={(e) => {
								onCloseOTPAuth(e);
							}}></OTPAuthenticationDialog>
					) : null}
					{showPasswordDialog ? (
						<ConfirmPasswordDialog
							state={showPasswordDialog}
							onClose={(e) => {
								onPasswordDialogClose(e);
							}}></ConfirmPasswordDialog>
					) : null}
				</Box>
			}></SectionWithHeading>
	);
};
