import { Box } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { FormHelper } from '../../../../../helpers/formHelper';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { ConfirmPasswordDialog } from '../../../../../shared/components/confirmPasswordDialog';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { InvestorCorporateSchema } from '../../../../../shared/validations/schema';
import { InvestorService } from '../../../investorService';
import { taxResidencyHelper } from '../../../../../helpers/taxResidencyHelper';
import { IS_SA } from '../../../../../config/appConfig';

export const CorporateInformations = ({ id }) => {
	const systemSettingsService = new SystemSettingsService();
	const investorService = new InvestorService();
	const { investorCollections } = useSelector(
		(state) => state.investorCollectionReducer
	);
	const { t } = useTranslation();
	const [isEditInfo, setIsEditInfo] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	const [showOTPAuth, setShowOTPAuth] = useState(false);

	const countryList = investorCollections?.countryCollection || [];

	const form = useFormik({
		initialValues: {
			businessName: '',
			tradeLicenseNumber: '',
			registrationCountryId: '',
			corporateTaxResidency: FormHelper.getTaxResidencies(null),
		},
		validationSchema: InvestorCorporateSchema,
		onSubmit: () => {
			if (settings()?.enabledOtp) {
				setShowOTPAuth(true);
			} else {
				setShowPasswordDialog(true);
			}
		},
		...FormHelper.formikProps(),
	});

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const fetchData = () => {
		investorService.getCorporateInformation().then((resp) => {
			const corporateValue = resp.data;
			let corporateData = {
				...corporateValue,
				registrationCountryId: corporateValue?.registrationCountry?.id,
				corporateTaxResidency: FormHelper.getTaxResidencies(
					corporateValue?.taxResidency
				),
			};
			form.setValues(corporateData);
		});
	};

	const onEdit = (e) => {
		e.preventDefault();
		if (isEditInfo) {
			FormHelper.validateForm(form);
		} else {
			setIsEditInfo(!isEditInfo);
		}
	};

	const onSaveInfo = () => {
		setLoading(true);
		let formValues = taxResidencyHelper(form.values, 'corporateTaxResidency');
		delete formValues.status;
		delete formValues?.taxResidency;
		console.log(form.values['corporateTaxResidency']);
		investorService
			.updateCorporateInformation(formValues)
			.then((resp) => {
				fetchData();
				setLoading(false);
				setIsEditInfo(!isEditInfo);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onCloseOTPAuth = (e) => {
		setShowOTPAuth(false);
		if (e) {
			onSaveInfo();
		}
	};

	const onPasswordDialogClose = (e) => {
		setShowPasswordDialog(false);
		if (e) {
			onSaveInfo();
		}
	};

	return (
		<SectionWithHeading
			loading={loading}
			isEditButton={true}
			onEditInfo={(e) => {
				onEdit(e);
			}}
			isEditToggle={isEditInfo}
			heading={
				<Box className="flex items-center">
					<span className="mx-2">{t('corporateInformation')}</span>
				</Box>
			}
			children={
				<Box>
					<fieldset disabled={!isEditInfo}>
						<FormikProvider value={form}>
							<Box className="grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-2">
								{!IS_SA ? (
									<>
										<InputWrapper
											name={'businessName'}
											key={'businessName'}
											label={t('businessName')}></InputWrapper>{' '}
										<SelectWrapper
											name={'registrationCountryId'}
											key={'registrationCountryId'}
											items={countryList}
											label={t('countryOfRegistration')}
										/>
									</>
								) : null}
								<InputWrapper
									name={'tradeLicenseNumber'}
									key={'tradeLicenseNumber'}
									label={t('tradeLicenseNumber')}></InputWrapper>

								{!IS_SA ? (
									<>
										<InputWrapper
											name={'corporateTaxResidency.taxId'}
											key={'corporateTaxResidency.taxId'}
											label={'taxId'}></InputWrapper>
										<SelectWrapper
											defaultOptionText="no"
											defaultOptionValue={'no'}
											showDefaultOption
											items={countryList}
											name={`corporateTaxResidency.countryId`}
											key={'corporateTaxResidency.countryId'}
											label={'areYouTaxedElsewhere'}
										/>
										{form.values?.corporateTaxResidency?.countryId &&
										typeof form.values?.corporateTaxResidency?.countryId ===
											'number' ? (
											<InputWrapper
												name={'corporateTaxResidency.taxIdElsewhere'}
												key={'corporateTaxResidency.taxIdElsewhere'}
												label={'taxId2'}></InputWrapper>
										) : null}
									</>
								) : null}
							</Box>
						</FormikProvider>
					</fieldset>
					{showOTPAuth ? (
						<OTPAuthenticationDialog
							type={OTPTypes.Other}
							state={showOTPAuth}
							onClose={(e) => {
								onCloseOTPAuth(e);
							}}></OTPAuthenticationDialog>
					) : null}
					{showPasswordDialog ? (
						<ConfirmPasswordDialog
							state={showPasswordDialog}
							onClose={(e) => {
								onPasswordDialogClose(e);
							}}></ConfirmPasswordDialog>
					) : null}
				</Box>
			}></SectionWithHeading>
	);
};
