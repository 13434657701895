import { Avatar, Box, Divider } from '@mui/material';
import { DialogWrapper } from '../../../../shared/components/common/dialogWrapper';
import { useState } from 'react';
import { IS_SA } from '../../../../config/appConfig';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SAflag from '../../../../assets/images/flag-sa.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import CheckIcon from '@mui/icons-material/Check';
import { RegionTypes } from '../../../../config/regions';
import { RegionService } from '../../../../services/regionService';
import i18next from 'i18next';

export const Platform = ({ isShowNames = true, shortName = false }) => {
	const regionService = new RegionService();
	const [state, setState] = useState(false);
	const { t } = useTranslation();

	const onClose = (e) => {
		setState(false);
	};

	const checkCurrentRegionActive = (lang, region) => {
		return (
			i18next.language === lang && regionService.currentRegion() === region
		);
	};

	const LanguageSection = ({
		type = RegionTypes.Global,
		activeArabic = false,
		activeEnglish = false,
	}) => {
		return (
			<Box className={`grid grid-flow-row gap-4 cursor-pointer mt-4`}>
				<Box
					onClick={(e) => {
						setState(false);
						regionService.onChangeRegion(type, 'en');
					}}
					className={`py-2 px-3 bg-[#f7f7f7] flex items-center justify-between rounded-md platform-item ${
						activeEnglish ? 'active' : ''
					} `}>
					<TextWrapper
						classList="!text-[13px] platform-text"
						text="English"
					/>
					{activeEnglish ? (
						<CheckIcon className="!text-white !text-[18px]" />
					) : null}
				</Box>
				<Box
					onClick={(e) => {
						setState(false);
						regionService.onChangeRegion(type, 'ar');
					}}
					className={`py-2 px-3 bg-[#f7f7f7] flex items-center justify-between rounded-md platform-item ${
						activeArabic ? 'active' : ''
					} `}>
					<TextWrapper
						classList="!text-[13px] platform-text"
						text="العربية"
					/>
					{activeArabic ? (
						<CheckIcon className="!text-white !text-[18px]" />
					) : null}
				</Box>
			</Box>
		);
	};

	return (
		<Box data-testid="platform">
			<Box
				className="cursor-pointer flex items-center"
				onClick={(e) => {
					setState(true);
				}}>
				<Box className="mx-1">
					{IS_SA ? (
						<Avatar
							src={SAflag}
							sx={{ width: 18, height: 18 }}></Avatar>
					) : (
						<Avatar
							className="!bg-white"
							sx={{ width: 18, height: 18 }}>
							<LanguageOutlinedIcon className="!text-[#757575] !text-[22px] opacity-95" />
						</Avatar>
					)}
				</Box>

				<Box className="flex flex-0 items-center font-[500] cursor-pointer !text-[#757575] !text-[14px]">
					{isShowNames
						? IS_SA
							? shortName
								? 'SA'
								: 'Saudi Arabia'
							: shortName
							? 'GL'
							: 'Global'
						: null}
					<KeyboardArrowDownIcon
						className={!isShowNames ? '!text-[15px] -mx-[5px]' : ''}
					/>
				</Box>
			</Box>

			{state ? (
				<DialogWrapper
					titleTextClass="md:text-lg !text-[16px]"
					title={t('countryLanguage')}
					maxWidth="xs"
					state={state}
					onClose={() => {
						onClose(false);
					}}
					content={
						<>
							<Box className={`grid gap-3 grid-cols-2`}>
								<Box>
									<Box className="flex items-center">
										<Avatar
											src={SAflag}
											sx={{ width: 18, height: 18 }}></Avatar>
										<Box className="mt-1 mx-2 text-[14px] !font-semibold">
											{'Saudi Arabia'}
										</Box>
									</Box>
									<Divider className="!py-1" />
									<LanguageSection
										activeArabic={checkCurrentRegionActive(
											'ar',
											RegionTypes.SaudiArabia
										)}
										activeEnglish={checkCurrentRegionActive(
											'en',
											RegionTypes.SaudiArabia
										)}
										type={RegionTypes.SaudiArabia}
									/>
								</Box>
								<Box>
									<Box className="flex items-center">
										<Avatar
											className="!bg-white"
											sx={{ width: 18, height: 18 }}>
											<LanguageOutlinedIcon className="!text-[#757575] opacity-95" />
										</Avatar>
										<Box className="mt-1 mx-2 text-[14px] !font-semibold">
											{'Global'}
										</Box>
									</Box>
									<Divider className="py-1" />
									<LanguageSection
										activeArabic={checkCurrentRegionActive(
											'ar',
											RegionTypes.Global
										)}
										activeEnglish={checkCurrentRegionActive(
											'en',
											RegionTypes.Global
										)}
										type={RegionTypes.Global}
									/>
								</Box>
							</Box>
						</>
					}></DialogWrapper>
			) : null}
		</Box>
	);
};
