import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Skeleton,
} from '@mui/material';
import { TextWrapper } from '../../../shared/components/formUtilities/useTextWrapper';
import CloseIcon from '@mui/icons-material/Close';
import { LanguageService } from '../../../services/languageService';
import Logo from './logo';

export const DialogWrapper = ({
	state,
	title,
	content,
	actions,
	subTitle = '',
	maxWidth = 'lg',
	width = null,
	showLogo = false,
	loading = false,
	titleClass = '',
	titleTextClass = '',
	dialogClass = '',
	disableLogo = false,
	centerHeading = true,
	actionFooterClass = '',
	hideCloseBtn = false,
	onClose,
	testId = '',
}) => {
	const languageService = new LanguageService();

	const getIsArabic = () => {
		return languageService.isArabic();
	};

	return (
		<Dialog
			data-testid={testId}
			className={`z-[99999999] ${dialogClass ? dialogClass : ''}`}
			PaperProps={{ style: { width: width ? width : '' } }}
			maxWidth={maxWidth}
			fullWidth={true}
			open={state}
			onClose={(e) => {
				onClose && onClose(false);
			}}>
			{
				<Box>
					<>
						{showLogo ? (
							<Box className="pb-0 px-4 py-3">
								<Logo
									disabledLink={disableLogo}
									sx={{ height: 'auto' }}></Logo>
							</Box>
						) : null}
						{title || !hideCloseBtn ? (
							<DialogTitle className="text-center">
								<Box
									className={`flex  items-center ${centerHeading ? '!justify-center' : 'justify-between'
										}`}>
									<Box
										className={`flex flex-col flex-0  ${centerHeading
											? 'justify-center items-center'
											: 'items-start justify-start'
											} ${titleClass ? titleClass : ''}`}>
										<TextWrapper
											type="h6"
											classList={`!font-bold ${titleTextClass}`}
											text={title}></TextWrapper>
										{subTitle ? (
											<TextWrapper
												type="caption"
												classList="!font-bold !text-[13px]"
												text={subTitle}></TextWrapper>
										) : null}
									</Box>
									{!hideCloseBtn ? (
										<CloseIcon
											onClick={() => {
												onClose();
											}}
											className={`cursor-pointer !text-[18px] ${centerHeading
												? `absolute !top-[20px]  ${getIsArabic() ? 'left-[15px]' : 'right-[15px]'
												} ${showLogo ? '!top-[20px]' : ''}`
												: ''
												}`}></CloseIcon>
									) : null}
								</Box>
							</DialogTitle>
						) : null}

						<DialogContent>
							<Box>
								{!loading ? (
									<>{content}</>
								) : (
									<Box className="grid grid-flow-row gap-4">
										<Skeleton
											variant="text"
											sx={{ fontSize: '2rem', width: '100%' }}
										/>
										<Skeleton
											variant="text"
											sx={{ fontSize: '2rem', width: '100%' }}
										/>
										<Skeleton
											variant="text"
											sx={{ fontSize: '2rem', width: '100%' }}
										/>
									</Box>
								)}
							</Box>
							{actions ? (
								<>
									<DialogActions
										className={`!justify-center !mt-4 ${actionFooterClass ? actionFooterClass : ''
											}`}>
										{!loading ? (
											<>{actions}</>
										) : (
											<Box className="flex flex-auto justify-center items-center">
												<Skeleton
													variant="text"
													sx={{ fontSize: '2rem', width: '150px' }}
												/>
												<Skeleton
													className="!ml-3"
													variant="text"
													sx={{ fontSize: '2rem', width: '150px' }}
												/>
											</Box>
										)}
									</DialogActions>
								</>
							) : null}
						</DialogContent>
					</>
				</Box>
			}
		</Dialog>
	);
};
