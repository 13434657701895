import { Box } from '@mui/material';
import { RegistrationNavBar } from '../registration/components/registrationNavBar';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {
	Logout,
	getRegistrationData,
	getRegistrationInfo,
	getUser,
} from '../../services/authService';
import { Roles } from '../../constants/roles';
import { SystemSettingsService } from '../../services/systemSettingsService';
import { InvestorStatus } from '../../constants/investorStatus';
import { InvestorService } from '../investor/investorService';
// import { useEffect } from 'react';
import { useState } from 'react';
// import { UserService } from '../../services/userService';
// import { UserDocumentTypes } from '../../constants/userDocuments';
import { BorrowerService } from '../borrower/borrowerService';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../helpers/languageHelper';
import { AppRoutes } from '../../constants/appRoutes';
import { BorrowerStatus } from '../../constants/borrowerStatus';
import { ButtonWrapper } from '../../shared/components/formUtilities/useButton';
import { InvestorDocuments } from '../investor/views/profile/documents';
import { OTPTypes } from '../../constants/otpTypes';
import { ConfirmPasswordDialog } from '../../shared/components/confirmPasswordDialog';
import { OTPAuthenticationDialog } from '../../shared/components/otpAuthenticationDialog';
import { InvestorType } from '../../constants/investorType';
import { IS_SA } from '../../config/appConfig';

export const ThankYouPage = () => {
	const systemSettingsService = new SystemSettingsService();
	const investorService = new InvestorService();
	const borrowerService = new BorrowerService();
	// const userService = new UserService();

	// const [allDocuments, setAllDocuments] = useState([]);
	const [showOtpDialog, setShowOtpDialog] = useState(false);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	// const [loading, setLoading] = useState(false);
	const [loadingActivate, setLoadingActivate] = useState(false);

	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = getUser();

	const isBorrower = () => {
		return user?.role === Roles.Borrower;
	};

	const isInvestor = () => {
		return user?.role === Roles.Investor;
	};

	const userStatus = () => {
		return user?.status;
	};

	const isOTPEnabled = () => {
		return systemSettingsService.systemSettings()?.enabledOtp;
	};

	const getInvestorType = () => {
		let investorType =
			getRegistrationData()?.investorType ||
			getRegistrationInfo()?.investorType;
		if (investorType) {
			if (typeof investorType === 'object') {
				return investorType?.name;
			}

			return investorType;
		}

		return null;
	};

	// const isShowDocuments = () => {
	// 	if (user && isInvestor() && user?.status === InvestorStatus.Pending) {
	// 		if (
	// 			(getFilteredDocuments(UserDocumentTypes.InvestorIdentifier) ||
	// 				getFilteredDocuments(UserDocumentTypes.InvestorPassport)) &&
	// 			getFilteredDocuments(UserDocumentTypes.InvestorAddressProof)
	// 		) {
	// 			return false;
	// 		}
	// 		return true;
	// 	}

	// 	return false;
	// };

	// useEffect(() => {
	// 	if (user && isInvestor() && user?.status === InvestorStatus.Pending) {
	// 		getAllDocuments();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const getAllDocuments = () => {
	// 	setLoading(true);
	// 	userService
	// 		.getAllDocuments()
	// 		.then((resp) => {
	// 			// setAllDocuments(resp.data);
	// 			setLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			setLoading(false);
	// 		});
	// };

	// const getFilteredDocuments = (type) => {
	// 	return (
	// 		!!allDocuments.filter((resp) => resp?.type === type)?.length || false
	// 	);
	// };

	const activateAccountWithOTP = () => {
		if (isOTPEnabled()) {
			setShowOtpDialog(true);
		} else {
			setShowPasswordDialog(true);
		}
	};

	const logOut = () => {
		Logout();
		navigate(buildLocalizeRoute(AppRoutes.Login));
	};

	const onActivateAccount = () => {
		setLoadingActivate(true);
		if (isBorrower()) {
			borrowerService
				.activateAccount()
				.then((resp) => {
					setLoadingActivate(false);
					logOut();
				})
				.catch((err) => {
					setLoadingActivate(false);
				});
		} else if (isInvestor()) {
			investorService
				.activateAccount()
				.then((resp) => {
					setLoadingActivate(false);
					logOut();
				})
				.catch((err) => {
					setLoadingActivate(false);
				});
		}
	};

	const borrowerOnBoarding = () => {
		return (
			<Box className="flex flex-col space-y-4 items-center">
				<p className="md:w-[70%] w-full thank-you-text">
					{t('borrowerThankYouText')}
				</p>
				{getHelperEmailSection()}
				<ButtonWrapper
					classList="!max-w-[250px]"
					disabled={true}
					label={
						<span>
							{t('connectToBank')} ({t('comingSoon')})
						</span>
					}></ButtonWrapper>
			</Box>
		);
	};
	const borrowerArchived = () => {
		return (
			<Box className="flex flex-col space-y-4 items-center">
				<p className="md:w-[70%] w-full thank-you-text">
					{t('borrowerAccountArchivedText')}
				</p>
				{getHelperEmailSection()}
				{activateBtnSection()}
			</Box>
		);
	};

	const investorOnBoarding = () => {
		return (
			<Box>
				<Box className="flex flex-col space-y-4 items-center">
					<p className="md:w-[70%] w-full thank-you-text">
						{getInvestorType() === InvestorType.Retail && IS_SA
							? t('yourApplicationUnderReview')
							: t('yourApplicationIsUnderReview')}
					</p>
					<InvestorDocuments
						investorType={getInvestorType()}></InvestorDocuments>
					{getHelperEmailSection()}
				</Box>
			</Box>
		);
	};
	const investorArchived = () => {
		return (
			<Box className="flex flex-col space-y-4 items-center">
				<p className="md:w-[70%] w-full thank-you-text">
					{t('borrowerAccountArchivedText')}
				</p>
				{getHelperEmailSection()}
				{activateBtnSection()}
			</Box>
		);
	};

	const commonThankYou = () => {
		return (
			<Box className="flex flex-col space-y-4 items-center">
				<p className="md:w-[70%] w-full thank-you-text">
					{t('yourApplicationUnderReview')}
				</p>
				{getHelperEmailSection()}
			</Box>
		);
	};
	const getHelperEmailSection = () => {
		return (
			<Box className="text-center">
				<p>
					{t('ifHaveQuestionsPleaseContact')}{' '}
					<a
						className="underline-link !text-[#333] !text-[16px]"
						href="mailto:support@fundingsouq.com">
						{'support@fundingsouq.com'}
					</a>
				</p>
			</Box>
		);
	};

	const activateBtnSection = () => {
		return (
			<ButtonWrapper
				loading={loadingActivate}
				icon={<CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>}
				classList="!max-w-[250px]"
				label={t('activateAccount')}
				onClick={() => {
					activateAccountWithOTP();
				}}></ButtonWrapper>
		);
	};

	return (
		<Box>
			<RegistrationNavBar isShowProfile={isInvestor()}></RegistrationNavBar>
			<Box className="flex justify-center items-center">
				<Box className="xl:w-[1140px] w-full md:my-8 m-4 justify-center items-center flex flex-col md:min-h-[calc(100vh-310px)]">
					<Box className="flex flex-col items-center">
						<svg
							class="checkmark"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 52 52">
							<circle
								class="checkmark__circle"
								cx="26"
								cy="26"
								r="25"
								fill="none"
							/>
							<path
								class="checkmark__check"
								fill="none"
								d="M14.1 27.2l7.1 7.2 16.7-16.8"
							/>
						</svg>
						<h1 className="md:!text-5xl !text-3xl font-[600] my-4">
							{t('thankYou')}
						</h1>
						<Box>
							{isBorrower() ? (
								<>
									{BorrowerStatus.Pending === userStatus()
										? borrowerOnBoarding()
										: null}
									{BorrowerStatus.Archived === userStatus()
										? borrowerArchived()
										: null}
									{BorrowerStatus.Pending !== userStatus() &&
									BorrowerStatus.Archived !== userStatus()
										? commonThankYou()
										: null}
								</>
							) : null}
							{isInvestor() ? (
								<>
									{InvestorStatus.Pending === userStatus()
										? investorOnBoarding()
										: null}
									{InvestorStatus.Archived === userStatus()
										? investorArchived()
										: null}
									{InvestorStatus.Pending !== userStatus() &&
									InvestorStatus.Archived !== userStatus()
										? commonThankYou()
										: null}
								</>
							) : null}
							{!isInvestor() && !isBorrower() ? commonThankYou() : null}
						</Box>
						{showOtpDialog ? (
							<OTPAuthenticationDialog
								type={OTPTypes.Other}
								state={showOtpDialog}
								onClose={(e) => {
									setShowOtpDialog(false);
									if (e) {
										onActivateAccount();
									}
								}}></OTPAuthenticationDialog>
						) : null}
						{showPasswordDialog ? (
							<ConfirmPasswordDialog
								state={showPasswordDialog}
								onClose={(e) => {
									setShowPasswordDialog(false);
									if (e) {
										onActivateAccount();
									}
								}}></ConfirmPasswordDialog>
						) : null}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
