import { DateHelper } from './dateHelper';

export class ConfirmationDialogHelpers {
	static getBucketOptions(t, bucket) {
		return {
			data: {
				title: t('headsUp'),
				message: t('autoInvestmentConfirmationText', {
					date: DateHelper.formatDate(bucket.expectedLunch),
				}),
				negativeText: t('cancel'),
				positiveText: t('activate'),
			},
		};
	}

	static getManualBucketOptions(t) {
		return {
			data: {
				title: t('headsUp'),
				message: t('manualInvestmentConfirmationText'),
				negativeText: t('cancel'),
				positiveText: t('pause'),
			},
		};
	}
}
