import { axiosGet, axiosPatch, axiosPost } from './axiosService';
import { isExpired, decodeToken } from 'react-jwt';
import { LocalStorageKey } from '../constants/localStorageKeys';
import { UtilitiesService } from './utilitiesService';
import { Roles } from '../constants/roles';
import { Observables } from '../observables';
import { Toaster } from '../shared/components/common/toaster';
import { BASE_URLS, IS_SA } from '../config/appConfig';
import { signOut } from '../auth/authUtilities';

const authURL = 'authentication/';
const loginURL = authURL + 'login/';
const forgotPasswordURL = authURL + 'forgotpassword/';
const resetPasswordURL = authURL + 'resetpassword/';
const adminAuthURL = authURL + 'EnableAuthenticator';
const validatePasswordURL = authURL + 'passwordvalidate';
const changePasswordUrl = authURL + 'changepassword';
const refreshURL = authURL + 'refreshtoken/';
const utilitiesService = new UtilitiesService();

export const login = async (credentials) => {
	try {
		const resp = await axiosPost(credentials, loginURL, {
			hide_success_toaster: true,
		});
		if (resp.data && resp.data.token) {
			if (
				resp?.data.roles?.includes(Roles.Borrower) ||
				resp?.data.roles?.includes(Roles.Investor)
			) {
				setRegistrationInfo(resp.data);
				saveToken(resp.data.token);
				const user = getExtractedUserFromToken();
				setUser(user);
				return resp;
			} else {
				Toaster({
					msg: `If you are a Admin, Please use ${
						IS_SA ? BASE_URLS.sa : BASE_URLS.en
					} site.`,
				});
				return null;
			}
		}
		return null;
	} catch (e) {
		throw e;
	}
};

export const forgotPassword = (form) => {
	return axiosPost(form, forgotPasswordURL, { hide_toaster: 'true' });
};

export const resetPassword = (form) => {
	return axiosPatch(resetPasswordURL, form, { hide_success_toaster: true });
};

export const checkDuplicateEmail = (email) => {
	return axiosGet(null, `authentication/emailused?email=${email}`);
};

export const getAdminAuthQRCode = () => {
	return axiosGet(null, adminAuthURL);
};

export const submitAdminOTPVerificationCode = (form) => {
	return axiosPost(form, adminAuthURL);
};

export const verifyAdminAuth = (form) => {
	return axiosPost(form, 'otp/Verify/mfa', {}, 'text');
};

const mapTokenPayloadToUser = (tokenPayload) => {
	const isApproved = tokenPayload.approved === 'True';
	let OtpVerified = true;
	if (tokenPayload.hasOwnProperty('OtpVerified')) {
		OtpVerified = tokenPayload.OtpVerified === 'True';
	}

	return {
		nameid: tokenPayload.nameid,
		email: tokenPayload.email,
		unique_name: tokenPayload.unique_name,
		role: tokenPayload.role,
		isApproved: isApproved,
		status: tokenPayload.status,
		OtpVerified: OtpVerified,
		registrationstep: parseInt(tokenPayload.registrationstep),
		investorGuideStep: tokenPayload.investorGuideStep,
	};
};

export const saveTokenFromRegistration = (token) => {
	if (token) {
		saveToken(token);
		const user = getExtractedUserFromToken();
		setUser(user);
	}
};

export const refreshTokenIfNecessary = (token) => {
	if (!token) {
		return;
	}

	const expire = decodeToken(token)?.exp;
	const diff = expire * 1000 - Date.now();
	if (diff / 60000 > 2) {
		// If diff > then 2 minute do nothing
		return;
	}

	if (localStorage.getItem(LocalStorageKey.tokenRefresh) === '1') {
		return;
	}

	localStorage.setItem(LocalStorageKey.tokenRefresh, '1');

	axiosPost(null, refreshURL, { hide_success_toaster: true }, 'json')
		.then((resp) => {
			localStorage.setItem(LocalStorageKey.tokenRefresh, '0');
			if (resp.data) {
				let token = resp.data;
				saveToken(token);
				const user = getExtractedUserFromToken();
				setUser(user);
			}
		})
		.catch((err) => {});
};

export const isAdmin = (user) => {
	if (user) {
		return user.roles.find(
			(role) =>
				role === Roles.AccountManager ||
				role === Roles.SuperAdmin ||
				role === Roles.CreditAnalyst
		);
	}
	return false;
};

export const setUser = (user) => {
	utilitiesService.setLocalStorage({
		item: LocalStorageKey.users,
		value: JSON.stringify(user),
	});
};

export const loginUsingToken = (token) => {
	const isTokenExpired = isExpired(token);
	if (isTokenExpired) {
		return null;
	}

	saveToken(token);
	const user = getExtractedUserFromToken();
	setUser(user);
};

export const getExtractedUserFromToken = () => {
	const token = getToken();
	const isTokenExpired = isExpired(token);
	if (isTokenExpired) {
		return null;
	}

	const tokenPayload = decodeToken(token);
	const user = mapTokenPayloadToUser(tokenPayload);
	return user;
};

export const getUser = () => {
	return utilitiesService.getLocalStorage({ item: LocalStorageKey.users })
		? JSON.parse(
				utilitiesService.getLocalStorage({ item: LocalStorageKey.users })
		  )
		: null;
};

export const getFirstName = () => {
	if (userName()) {
		return userName()?.split(' ')[0] || '';
	}
	return '';
};

export const userName = () => {
	if (getUser()) {
		let name = getUser()?.unique_name;
		return name && name?.replace(/ /g, '') && name?.length <= 15
			? name
			: getUser()?.email;
	}
};

export const saveToken = (token) => {
	utilitiesService.setLocalStorage({
		item: LocalStorageKey.token,
		value: token,
	});
};

export const getToken = () => {
	return utilitiesService.getLocalStorage({ item: LocalStorageKey.token });
};

export const Logout = () => {
	signOut();
};

export const validatePassword = (form) => {
	return axiosPost(form, validatePasswordURL, {
		hide_success_toaster: true,
	});
};

export const changePassword = (form) => {
	return axiosPatch(changePasswordUrl, form, {
		hide_success_toaster: true,
	});
};

export const setRegistrationData = (data) => {
	let regData = data;
	if (typeof data?.investorType == 'object') {
		regData.investorType = data?.investorType?.name;
	}
	if (typeof data?.nationality == 'object') {
		regData.nationality = data?.nationality?.name;
	}
	if (typeof data?.addressCountry == 'object') {
		regData.addressCountry = data?.addressCountry?.name;
	}
	localStorage.setItem(
		LocalStorageKey.registrationData,
		JSON.stringify(regData)
	);
	Observables.registrationData$.next(regData);
};

export const getRegistrationData = () => {
	let regData = localStorage.getItem(LocalStorageKey.registrationData)
		? JSON.parse(localStorage.getItem(LocalStorageKey.registrationData))
		: null;
	if (typeof regData?.investorType == 'object') {
		regData.investorType = regData?.investorType?.name;
	}
	if (typeof regData?.nationality == 'object') {
		regData.nationality = regData?.nationality?.name;
	}
	if (typeof regData?.addressCountry == 'object') {
		regData.addressCountry = regData?.addressCountry?.name;
	}
	return regData;
};

export const getRegistrationInfo = () => {
	return localStorage.getItem(LocalStorageKey.registrationInfo)
		? JSON.parse(localStorage.getItem(LocalStorageKey.registrationInfo))
		: null;
};

export const setRegistrationInfo = (response) => {
	let registrationInfo = {
		borrowerStep: null,
		investorStep: null,
		investorType: null,
	};
	if (response) {
		registrationInfo = {
			borrowerStep: response?.borrowerStep,
			investorStep: response?.investorStep,
			investorType: response?.investorType,
		};
		localStorage.setItem(
			LocalStorageKey.registrationInfo,
			JSON.stringify(registrationInfo)
		);
	}
};

export const updateInvestorSteps = (step) => {
	let registrationInfo = {
		borrowerStep: null,
		investorStep: null,
		investorType: null,
	};
	if (getRegistrationInfo()) {
		registrationInfo = getRegistrationInfo();
		registrationInfo.investorStep = step;
	}
	setRegistrationInfo(registrationInfo);
};

export const updateBorrowerSteps = (step) => {
	let registrationInfo = {
		borrowerStep: null,
		investorStep: null,
		investorType: null,
	};
	if (getRegistrationInfo()) {
		registrationInfo = getRegistrationInfo();
		registrationInfo.borrowerStep = step;
	}
	setRegistrationInfo(registrationInfo);
};
