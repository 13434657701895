import { CURRENCY } from '../../../../config/appConfig';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';
import { DateHelper } from '../../../../helpers/dateHelper';

export const initializeColumns = () => {
	return [
		{
			header: 'Date',
			accessorKey: 'date',
			Cell: ({ cell }) => {
				return DateHelper.formatDate(cell?.getValue(), 'MMM dd, yyyy hh:mm');
			},
		},

		{
			header: `Planned Amount ( ${CURRENCY} )`,
			accessorKey: 'plannedAmount',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
		},
		{
			header: `Received Amount ( ${CURRENCY} )`,
			accessorKey: 'receivedAmount',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
		},
		{
			header: 'Status',
			accessorKey: 'status',
		},
	];
};
