import { Box } from '@mui/material';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { useEffect } from 'react';
import { UserService } from '../../../../../services/userService';
import { useState } from 'react';
import { UserDocumentTypes } from '../../../../../constants/userDocuments';
import { useTranslation } from 'react-i18next';
import { InvestorType } from '../../../../../constants/investorType';
import { CorporateDocumentUploader } from '../../../../../shared/components/corporateDocumentUploader';
import { DocumentUploadSection } from '../../../../../shared/components/documentUploadSection';
import { UploadPassportUploaderSection } from '../../../../../shared/components/documentPassportUploader';
import { IS_SA } from '../../../../../config/appConfig';

export const InvestorDocuments = ({ investorType = null }) => {
	const userService = new UserService();
	const [identifierDeatils, setIdentifierDeatils] = useState({});
	const [passportDeatils, setPassportDeatils] = useState({});
	const [addressProofDeatils, setAddressProofDeatils] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isCorporate, setIsCorporate] = useState(false);
	const [isProfessional, setIsProfessional] = useState(false);
	const [isRetailer, setIsRetailer] = useState(false);
	const [netWorthProofDeatils, setNetWorthProofDeatils] = useState({});
	const [proofEligibilityDetails, setProofEligibility] = useState({});
	const userDocumentTypes = UserDocumentTypes;

	const { t } = useTranslation();

	useEffect(() => {
		getAllDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsCorporate(investorType === InvestorType.Corporate);
		setIsProfessional(investorType === InvestorType.Professional);
		setIsRetailer(investorType === InvestorType.Retail);
	}, [investorType]);

	const getAllDocuments = () => {
		setIsLoading(true);
		userService
			.getAllDocuments()
			.then((resp) => {
				setIsLoading(false);
				setFiles(resp.data);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	const getFilteredDocuments = (fileDetails = [], type) => {
		return fileDetails.filter((data) => data.type === type)[0] || null;
	};

	const setFiles = (fileDetails) => {
		setPassportDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorPassport)
		);

		setIdentifierDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorIdentifier)
		);
		setAddressProofDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorAddressProof)
		);
		setNetWorthProofDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorNetWorth)
		);

		setProofEligibility(
			getFilteredDocuments(
				fileDetails,
				userDocumentTypes.InvestorProofOfEligibility
			)
		);
	};

	return isRetailer && IS_SA ? (
		<></>
	) : (
		<>
			<SectionWithHeading
				classList="!w-full"
				heading={
					<Box className="flex items-center">
						<span className="mx-2">{t('myDocuments')}</span>
					</Box>
				}
				children={
					<Box className={`flex flex-col space-y-4`}>
						{isCorporate ? (
							<CorporateDocumentUploader></CorporateDocumentUploader>
						) : (
							<Box className="flex flex-col space-y-4">
								{!IS_SA ? (
									<>
										<UploadPassportUploaderSection
											identifierFile={identifierDeatils}
											passportFile={passportDeatils}
											isLoading={isLoading}></UploadPassportUploaderSection>
										<DocumentUploadSection
											onRemoveFile={() => {
												setAddressProofDeatils(null);
											}}
											subPoints={'dragProofofAddresstouploadPoint'}
											showOTPAuth={true}
											fileName="dragProofofAddresstoupload"
											file={addressProofDeatils}
											isLoading={isLoading}
											fileType={
												userDocumentTypes.InvestorAddressProof
											}></DocumentUploadSection>
										{isProfessional ? (
											<DocumentUploadSection
												subPoints={'dragProofofProofNetWorthPoint'}
												onRemoveFile={() => {
													setNetWorthProofDeatils(null);
												}}
												showOTPAuth={true}
												fileName="dragProofofProofNetWorth"
												file={netWorthProofDeatils}
												isLoading={isLoading}
												fileType={
													userDocumentTypes.InvestorNetWorth
												}></DocumentUploadSection>
										) : null}
									</>
								) : (
									<Box>
										<DocumentUploadSection
											onRemoveFile={() => {
												setProofEligibility(null);
											}}
											onUploadedFiles={(e) => {
												setProofEligibility(e?.file);
											}}
											subPoints={[
												'dragProofofProofOfEligibilityPoint1',
												'dragProofofProofOfEligibilityPoint2',
												'dragProofofProofOfEligibilityPoint3',
												'dragProofofProofOfEligibilityPoint4',
											]}
											showOTPAuth={true}
											fileName="dragProofofProofOfEligibility"
											file={proofEligibilityDetails}
											isLoading={isLoading}
											fileType={
												UserDocumentTypes.InvestorProofOfEligibility
											}></DocumentUploadSection>
									</Box>
								)}
							</Box>
						)}
					</Box>
				}></SectionWithHeading>
		</>
	);
};
