import { BehaviorSubject } from 'rxjs';
import { InvestorGuidelineSteps } from '../shared/components/investors/constants/guidlineSteps';
import { getUser } from './authService';
import { isMobile } from 'react-device-detect';
import { axiosPut } from './axiosService';

export class InvestorGuidelineService {
	guideStepCount$ = new BehaviorSubject(-1);
	guideEnum = InvestorGuidelineSteps;
	user = getUser();

	constructor() {
		this.setCountFromLocalStorage();
	}

	isUserApproved() {
		return this.user && this.user?.isApproved;
	}

	checkCurrentUserStepCount() {
		let count = isNaN(this.user?.investorGuideStep)
			? 0
			: this.user?.investorGuideStep;
		count = parseInt(count.toString()) + 1;
		return count;
	}

	setStepCountStorage(count) {
		localStorage.setItem('guideStepCount', count.toString());
	}

	resetGuideStep() {
		this.setStepCountStorage(this.guideEnum.AddFund + 2);
		this.guideStepCount$.next(this.guideEnum.AddFund + 2);
	}

	getIsMobile() {
		return isMobile;
	}

	resetCount(skipAPICall = true) {
		if (skipAPICall) {
			this.resetGuideStep();
		} else {
			this.updateSetpCount(this.guideEnum.AddFund)
				.then((resp) => {
					this.resetGuideStep();
				})
				.catch((err) => {});
		}
	}

	updateSetpCount(count) {
		let stepCount = {
			step: count,
		};
		return axiosPut(stepCount, '/Lenders/me/GuidStep', {
			hide_success_toaster: true,
		});
	}
	setCountFromLocalStorage() {
		if (this.isUserApproved()) {
			let count = localStorage.getItem('guideStepCount')
				? +localStorage.getItem('guideStepCount')
				: this.checkCurrentUserStepCount();

			if (count <= this.guideEnum.AddFund) {
				this.guideStepCount$.next(count);
				this.setStepCountStorage(count);
			}
			if (this.getIsMobile() && count === this.guideEnum.AddFund) {
				// skip deposit step in mobile
				this.setStepCountStorage(this.guideEnum.AddFund + 2);
				this.guideStepCount$.next(this.guideEnum.AddFund + 2);
			}
		} else {
			this.setStepCountStorage(-1);
		}
	}

	setShowThanksPopup(value = 0) {
		localStorage.setItem('showThanksPopup', value.toString());
	}

	removeThanksPopupValue() {
		localStorage.removeItem('showThanksPopup');
	}

	stepCount() {
		let count = localStorage.getItem('guideStepCount')
			? +localStorage.getItem('guideStepCount')
			: this.checkCurrentUserStepCount();
		return count;
	}
}
