import { Box, Stack } from '@mui/material';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { Section } from '../../../../shared/components/common/section';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../helpers/formHelper';
import { InputWrapper } from '../../../../shared/components/formUtilities/useInput';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import CheckIcon from '@mui/icons-material/Check';
import { BorrowerChangePasswordSchema } from '../../../../shared/validations/schema';
import { Logout, changePassword } from '../../../../services/authService';
import { Toaster } from '../../../../shared/components/common/toaster';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../constants/appRoutes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionWithHeading } from '../../../../shared/components/common/sectionWithHeading';

export const BorrowerChangePassword = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const form = useFormik({
		initialValues: {
			newPassword: '',
			oldPassword: '',
			confirmPassword: '',
		},
		onSubmit: (values) => {
			setLoading(true);
			changePassword(values)
				.then((resp) => {
					setLoading(false);
					Toaster({ msg: 'Password changed successfully', isError: false });
					Logout();
					navigate(buildLocalizeRoute(AppRoutes.Login));
				})
				.catch((err) => {
					setLoading(false);
				});
		},
		validationSchema: BorrowerChangePasswordSchema,
		...FormHelper.formikProps(),
	});
	return (
		<SectionWithHeading
			heading={
				<Box className="flex items-center">
					<span className="mx-2">{t('changeYourPassword')}</span>
				</Box>
			}
			children={
				<Box className="flex flex-row justify-center items-center mt-4 h-full">
					<Section
						classList="md:!max-w-[340px]"
						children={
							<Stack
								className="text-center"
								gap={2}>
								<TextWrapper
									classList="!sub-title-text"
									text={t('changeYourPasswordText')}></TextWrapper>
								<hr className="border-color-primary"></hr>
								<FormikProvider value={form}>
									<Stack gap={2}>
										<InputWrapper
											type="password"
											label={t('existingPassword')}
											name="oldPassword"
											key={'oldPassword'}></InputWrapper>
										<InputWrapper
											type="password"
											label={t('enterNewPassword')}
											name="newPassword"
											key={'newPassword'}></InputWrapper>
										<InputWrapper
											type="password"
											label={t('confirmNewPassword')}
											name="confirmPassword"
											key={'confirmPassword'}></InputWrapper>
										<ButtonWrapper
											loading={loading}
											onClick={() => {
												FormHelper.validateForm(form);
											}}
											disabled={!form.isValid}
											icon={<CheckIcon></CheckIcon>}
											classList="!w-full"
											label={t('confirmPassword')}></ButtonWrapper>
									</Stack>
								</FormikProvider>
							</Stack>
						}></Section>
				</Box>
			}></SectionWithHeading>
	);
};
