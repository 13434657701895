import { useEffect, useState } from 'react';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { BorrowerService } from '../../../borrowerService';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../../helpers/formHelper';
import { ConfirmPasswordDialog } from '../../../../../shared/components/confirmPasswordDialog';
import { Box } from '@mui/material';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import { InternationalPhone } from '../../../../../shared/components/formUtilities/useInternationalPhone';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { BorrowerBusinessSchema } from '../../../../../shared/validations/schema';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const BorrowerBusinessInfo = () => {
	const borrowerService = new BorrowerService();
	const { borrowerCollections } = useSelector(
		(state) => state.borrowerCollectionReducer
	);

	const [isEditInfo, setIsEditInfo] = useState(false);
	const [borrowerBusinessInfo, setBorrowerBusinessInfo] = useState({});
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	const { t } = useTranslation();

	const companyTypes = borrowerCollections?.companyTypesCollection;
	const businessSectors = borrowerCollections?.industriesCollection;
	const registrationPlaces =
		borrowerCollections?.borrowerRegistrationPlacesCollection;
	const cities = borrowerCollections?.citiesCollection;

	const [loading, setLoading] = useState(false);

	const form = useFormik({
		initialValues: {
			businessName: '',
			tradeLicenseNumber: '',
			industryId: '',
			companyTypeId: '',
			address: FormHelper.buildAddressFormGroup(),
			needFundsFor: '',
			registrationPlaceId: '',
		},
		onSubmit: () => {
			if (form.isValid) {
				onShowPasswordConfirm();
			}
		},
		validationSchema: BorrowerBusinessSchema,
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		getBorrowerProfileBusinessInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onShowPasswordConfirm = () => {
		setShowPasswordDialog(true);
	};

	const onClosePasswordDialog = (value) => {
		setShowPasswordDialog(false);
		if (value) {
			onSaveInfo();
		}
	};

	const onSaveInfo = () => {
		setLoading(true);
		const businessInfo = form.values;
		borrowerService
			.updateBorrowerProfileBusinessInfo(businessInfo)
			.then((resp) => {
				setIsEditInfo(!isEditInfo);
				getBorrowerProfileBusinessInfo();
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const getBorrowerProfileBusinessInfo = () => {
		setLoading(true);
		borrowerService
			.getBorrowerProfileBusinessInfo()
			.then((resp) => {
				setLoading(false);
				setBorrowerBusinessInfo(resp.data);
				let businessInfo = resp.data;
				let businessData = {
					...businessInfo,
					businessName: businessInfo?.businessTradingName,
					address: {
						...businessInfo?.address,
						addressLine: businessInfo.address?.addressLine || null,
						city: businessInfo.address?.city || null,
					},
					industryId: businessInfo?.industry?.id,
					companyTypeId: businessInfo?.companyType?.id,
					registrationPlaceId: businessInfo?.registrationPlace?.id,
				};
				form.setValues(businessData);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onEdit = (e) => {
		e.preventDefault();
		if (isEditInfo) {
			FormHelper.validateForm(form);
		} else {
			setIsEditInfo(!isEditInfo);
		}
	};

	return (
		<SectionWithHeading
			heading={t('businessInformation')}
			loading={loading}
			isEditButton={true}
			onEditInfo={(e) => {
				onEdit(e);
			}}
			isEditToggle={isEditInfo}
			children={
				<FormikProvider value={form}>
					<fieldset disabled={!isEditInfo}>
						<Box className="grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-2">
							<InputWrapper
								name={'businessName'}
								key={'businessName'}
								label={'businessName'}></InputWrapper>
							<InputWrapper
								name={'tradeLicenseNumber'}
								key={'tradeLicenseNumber'}
								label={'tradeLicenseNumber'}></InputWrapper>
							<SelectWrapper
								name={'industryId'}
								key={'industryId'}
								items={businessSectors}
								label={'businessSector'}
							/>
							<InternationalPhone
								phoneWithCode={borrowerBusinessInfo?.businessMobile}
								name={'businessMobile'}
								key={'businessMobile'}
								label={'contactNumber'}></InternationalPhone>
							<SelectWrapper
								name={'companyTypeId'}
								key={'companyTypeId'}
								items={companyTypes}
								label={'companyType'}
							/>
							<InputWrapper
								name={'address.addressLine'}
								key={'address.addressLine'}
								label={'businessAddress'}></InputWrapper>
							<SelectWrapper
								name={'address.city'}
								key={'address.city'}
								items={cities}
								label={'emirates'}
							/>
							<SelectWrapper
								name={'registrationPlaceId'}
								key={'registrationPlaceId'}
								items={registrationPlaces}
								label={'registrationPlace'}
							/>
							<InputWrapper
								isTextArea
								rows={1}
								name={'needFundsFor'}
								key={'needFundsFor'}
								label={'WhatDoYouNeedFundsFor'}></InputWrapper>
						</Box>
						{showPasswordDialog ? (
							<ConfirmPasswordDialog
								state={showPasswordDialog}
								onClose={(e) => {
									onClosePasswordDialog(e);
								}}></ConfirmPasswordDialog>
						) : null}
					</fieldset>
				</FormikProvider>
			}></SectionWithHeading>
	);
};
