import { RiskRating } from '../constants/riskRating';

export const getRiskRatingKey = (rate) => {
	if (rate === RiskRating.A) {
		return 'A';
	}
	if (rate === RiskRating.B) {
		return 'B';
	}
	if (rate === RiskRating.C) {
		return 'C';
	}

	return '';
};
