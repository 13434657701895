import { FormikProvider, getIn, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { InvestorRegService } from '../../investorRegService';
import { FormHelper } from '../../../../../helpers/formHelper';
import { taxResidencyHelper } from '../../../../../helpers/taxResidencyHelper';
import { InvestorRegPersonalInfoExtraSchema } from '../../../../../shared/validations/schema';
import {
	setRegistrationData,
	getRegistrationData,
	saveTokenFromRegistration,
	updateInvestorSteps,
} from '../../../../../services/authService';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { Box } from '@mui/material';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import { useTranslation } from 'react-i18next';
import { InvestorDeclarationDialog } from '../declarationDialog';
import { useSelector } from 'react-redux';
import { SubStepper } from '../../../components/subStepper';
import { IS_SA } from '../../../../../config/appConfig';

const EmploymentStatus = {
	directorOfCompany: 'Director of a company',
};

export const PersonalInformationExtra = ({
	onGoBack = () => {},
	onKSASubmit = () => {},
	onGoBackKSA = () => {},
	isRetailer = false,
	isSa = IS_SA,
}) => {
	const investorRegService = new InvestorRegService();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { investorCollections } = useSelector(
		(state) => state.investorCollectionReducer
	);
	const annualIncomes = investorCollections?.annualIncomeCollection || [];
	const investmentSources = investorCollections?.incomeSourceCollection || [];
	const employmentStatuses =
		investorCollections?.employmentStatusCollection || [];
	const estimatedYearlyInvestments =
		investorCollections?.estimateAnnualInvestmentCollection || [];
	const industries = investorCollections?.industriesCollection || [];
	const countries = investorCollections?.countryCollection || [];
	const netWorthCollection = investorCollections?.netWorthCollection || [];

	const [isShowIndustry, setIsShowIndustry] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showDeclarationPopup, setShowDeclarationPopup] = useState(false);

	const form = useFormik({
		initialValues: {
			employmentStatusId: '',
			companyName: '',
			bankCountryId: '',
			industry: '',
			annualIncomeId: '',
			netWorthId: '',
			estimatedAnnualInvestmentId: '',
			jobTitle: '',
			incomeSource: '',
			termsConditionsForInvestmentsAccepted: isSa ? false : true,
			investmentContract: isSa ? false : true,
			taxResidency: FormHelper.getTaxResidencies(null),
		},
		onSubmit: (values) => {
			submit(values);
		},
		validationSchema: InvestorRegPersonalInfoExtraSchema,
		...FormHelper.formikProps(),
	});

	const employmentStatusId = getIn(form.values, 'employmentStatusId');

	useEffect(() => {
		if (
			getEmploymentName(employmentStatusId) ===
			EmploymentStatus.directorOfCompany
		) {
			setIsShowIndustry(true);
		} else {
			setIsShowIndustry(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employmentStatusId]);

	useEffect(() => {
		investorRegService
			.getInvestorPersonalInformationExtra()
			.then((resp) => {
				let data = resp.data;
				let personalInfo = {
					...data,
					bankCountryId: data?.bankCountry?.id,
					employmentStatusId: data?.employmentStatus?.id,
					annualIncomeId: data?.annualIncome?.id,
					netWorthId: data?.netWorth?.id,
					estimatedAnnualInvestmentId: data?.estimatedAnnualInvestment?.id,
					industry: FormHelper.getComplexOther(data?.industry),
					jobTitle: data?.jobTitle,
					hasExterienceInFinancialIndustry:
						data?.hasExterienceInFinancialIndustry || false,
					incomeSource: FormHelper.getComplexOther(data?.incomeSource),
					taxResidency: FormHelper.getTaxResidencies(data?.taxResidency),
				};
				form.setValues(personalInfo);
			})
			.catch((err) => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getEmploymentName = (id) => {
		return employmentStatuses?.find((resp) => resp.id === id)?.name;
	};

	const onBack = () => {
		let data = {
			...getRegistrationData(),
			requestedStep: 1,
		};
		setRegistrationData(data);
		onGoBack();
	};

	const submit = (values) => {
		let form = values;
		setLoading(true);
		investorRegService
			.submitPersonalInformationExtra(taxResidencyHelper(form))
			.then((resp) => {
				setLoading(false);
				let registrationModel = resp.data?.registrationModel;
				saveTokenFromRegistration(resp.data?.loginModel?.token);
				let regData = {
					...getRegistrationData(),
					step: registrationModel.step,
					requestedStep: 2,
				};
				setRegistrationData(regData);
				updateInvestorSteps(registrationModel.step);
				if (isSa) {
					onKSASubmit();
				} else {
					navigate(
						buildLocalizeRoute(
							`${AppRoutes.InvestorRegistration}/${AppRoutes.iRegDocumentVarification}`
						)
					);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const showTaxId2 = () =>
		form.values?.taxResidency?.countryId &&
		typeof form.values?.taxResidency?.countryId === 'number';

	return (
		<Box>
			{!isSa ? (
				<SubStepper
					stepName="personalInformation2"
					currentStep={2}
					totalSteps={2}
				/>
			) : null}
			<FormikProvider value={form}>
				<Box className="flex flex-col space-y-4">
					<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<SelectWrapper
							errorStateTestID="employmentStatusIdError"
							name={'employmentStatusId'}
							key={'employmentStatusId'}
							items={employmentStatuses}
							label={'employmentDetails'}
						/>
						{isShowIndustry ? (
							<SelectWrapper
								name={'industry.valueId'}
								key={'industry.valueId'}
								items={industries}
								label={'industry'}
							/>
						) : null}
						<InputWrapper
							name={'companyName'}
							key={'companyName'}
							label={'companyName'}></InputWrapper>
						<InputWrapper
							name={'jobTitle'}
							key={'jobTitle'}
							label={'jobTitle'}></InputWrapper>
						<SelectWrapper
							name={'incomeSource.valueId'}
							key={'incomeSource.valueId'}
							controlOtherValueName={'incomeSource.valueSpecified'}
							items={investmentSources}
							label={'fundingSourceOfInvestment'}
						/>
						<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
							<SelectWrapper
								errorStateTestID="annualIncomeIdError"
								name={'annualIncomeId'}
								key={'annualIncomeId'}
								items={annualIncomes}
								label={'annualIncome'}
							/>
							<SelectWrapper
								errorStateTestID="netWorthIdError"
								tooltipId={'networthRegPersonalExtra'}
								tooltipText={'netWorthTooltipText'}
								name={'netWorthId'}
								key={'netWorthId'}
								items={netWorthCollection}
								label={'netWorth'}
							/>
						</Box>
						<Box
							className={`grid grid-cols-1 gap-4 ${
								!isSa ? 'md:grid-cols-2' : ''
							}`}>
							<SelectWrapper
								errorStateTestID="estimatedAnnualInvestmentIdError"
								name={'estimatedAnnualInvestmentId'}
								key={'estimatedAnnualInvestmentId'}
								items={estimatedYearlyInvestments}
								label={'estimatedYearlyInvestment'}
							/>
							{!isSa ? (
								<>
									<SelectWrapper
										name={'bankCountryId'}
										key={'bankCountryId'}
										items={countries}
										label={'bankCountry'}
									/>
								</>
							) : null}
						</Box>

						{!isSa ? (
							<>
								<InputWrapper
									name={'taxResidency.taxId'}
									key={'taxResidency.taxId'}
									label={'taxId'}></InputWrapper>
								<Box
									className={`grid grid-cols-1 gap-4 ${
										showTaxId2() ? 'md:grid-cols-2' : ''
									}`}>
									<SelectWrapper
										defaultOptionText="no"
										defaultOptionValue={'no'}
										showDefaultOption
										items={countries}
										name={`taxResidency.countryId`}
										key={'taxResidency.countryId'}
										label={'areYouTaxedElsewhere'}
									/>
									{showTaxId2() ? (
										<InputWrapper
											name={'taxResidency.taxIdElsewhere'}
											key={'taxResidency.taxIdElsewhere'}
											label={'taxId2'}></InputWrapper>
									) : null}
								</Box>
							</>
						) : null}
					</Box>
					<Box className={`flex justify-center md:justify-between`}>
						<ButtonWrapper
							dataTestID="backBtn"
							classList="!secondary-btn md:!mx-0 !mx-2"
							disabled={loading}
							largeBtn
							icon={<ArrowIcons isInvert></ArrowIcons>}
							label={t('back')}
							onClick={() => {
								if (isSa) {
									onGoBackKSA && onGoBackKSA();
								} else {
									onBack();
								}
							}}
						/>
						<ButtonWrapper
							dataTestID="nextBtn"
							loading={loading}
							largeBtn
							endIcon={<ArrowIcons></ArrowIcons>}
							label={t('next')}
							onClick={() => {
								FormHelper.validateForm(form);
							}}
						/>
					</Box>
					{showDeclarationPopup ? (
						<InvestorDeclarationDialog
							state={showDeclarationPopup}
							onClose={(e) => {
								setShowDeclarationPopup(false);
							}}></InvestorDeclarationDialog>
					) : null}
				</Box>
			</FormikProvider>
		</Box>
	);
};
