import { useEffect, useState } from 'react';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { Box, Stack } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../../helpers/formHelper';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { ConfirmPasswordDialog } from '../../../../../shared/components/confirmPasswordDialog';
import { BorrowerService } from '../../../borrowerService';
import { BorrowerPersonalSchema } from '../../../../../shared/validations/schema';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IS_SA } from '../../../../../config/appConfig';

export const BorrowerPersonalInfo = ({
	borrowerPersonalInfo,
	onSavePersonalInfo = () => {},
}) => {
	const borrowerService = new BorrowerService();
	const { borrowerCollections } = useSelector(
		(state) => state.borrowerCollectionReducer
	);
	const [isEditInfo, setIsEditInfo] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const countryList = borrowerCollections?.countryCollection || [];
	const hearAboutUsList = borrowerCollections?.hearUsItemCollection || [];
	const propertyOwnershipList =
		borrowerCollections?.propertyOwnershipCollection || [];
	const martialStatuseList = borrowerCollections?.maritalStatusCollection || [];
	const numberOfChildrenList =
		borrowerCollections?.numberOfChildrenCollection || [];
	const publicFunctions = borrowerCollections?.publicFunctionCollection;

	const [showPasswordDialog, setShowPasswordDialog] = useState(false);

	const form = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			nationalityId: '',
			howDidHearAboutUs: FormHelper.buildOtherFormGroup(),
			address: FormHelper.buildAddressFormGroup(),
			publicFunction: FormHelper.buildOtherFormGroup(),
			propertyOwnershipId: '',
			maritalStatusId: '',
			numberOfChildrenId: '',
			nationalId: '',
		},
		onSubmit: () => {
			if (form.isValid) {
				onShowPasswordConfirm();
			}
		},
		validationSchema: BorrowerPersonalSchema,
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		let formValues = {
			...borrowerPersonalInfo,
			nationalityId: borrowerPersonalInfo?.nationality?.id,
			propertyOwnershipId: borrowerPersonalInfo?.propertyOwnership?.id,
			numberOfChildrenId: borrowerPersonalInfo?.numberOfChildren?.id,
			maritalStatusId: borrowerPersonalInfo?.maritalStatus?.id,
			howDidHearAboutUs: FormHelper.getComplexOther(
				borrowerPersonalInfo?.howDidHearAboutUs
			),
			address: {
				...borrowerPersonalInfo?.address,
				countryId: borrowerPersonalInfo?.address?.country?.id,
			},
			publicFunction: FormHelper.getComplexOther(
				borrowerPersonalInfo?.publicFunction
			),
		};
		form.setValues(formValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [borrowerPersonalInfo]);

	const onEdit = (e) => {
		e.preventDefault();
		if (isEditInfo) {
			FormHelper.validateForm(form);
		} else {
			setIsEditInfo(!isEditInfo);
		}
	};

	const onShowPasswordConfirm = () => {
		setShowPasswordDialog(true);
	};

	const onClosePasswordDialog = (value) => {
		setShowPasswordDialog(false);
		if (value) {
			onSaveInfo();
		}
	};

	const onSaveInfo = () => {
		setLoading(true);
		const personalInfo = form.values;
		delete personalInfo.maritalStatus;
		borrowerService
			.updateBorrowerProfilePersonalInfo(personalInfo)
			.then((resp) => {
				onSavePersonalInfo();
				setLoading(false);
				setIsEditInfo(!isEditInfo);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<SectionWithHeading
			heading={t('personalInformation')}
			loading={loading}
			isEditButton={true}
			onEditInfo={(e) => {
				onEdit(e);
			}}
			isEditToggle={isEditInfo}
			children={
				<FormikProvider value={form}>
					<fieldset disabled={!isEditInfo}>
						<Stack gap={2}>
							<Box className="grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-2">
								<InputWrapper
									name={'firstName'}
									key={'firstName'}
									label={'firstName'}></InputWrapper>
								<InputWrapper
									name={'lastName'}
									key={'lastName'}
									label={'lastName'}></InputWrapper>
								{IS_SA ? (
									<InputWrapper
										disabled
										name={'nationalId'}
										key={'nationalId'}
										label={'nationalId'}></InputWrapper>
								) : null}
								<SelectWrapper
									name={'propertyOwnershipId'}
									key={'propertyOwnershipId'}
									items={propertyOwnershipList}
									label={'propertyOwnership'}
								/>
								<SelectWrapper
									name={'address.countryId'}
									key={'address.countryId'}
									items={countryList}
									label={'countryOfResidence'}
								/>
								<SelectWrapper
									name={'nationalityId'}
									key={'nationalityId'}
									items={countryList}
									label={'nationality'}
								/>
								<InputWrapper
									name={'address.addressLine'}
									key={'address.addressLine'}
									label={'residentialAddress'}></InputWrapper>

								<SelectWrapper
									name={'maritalStatusId'}
									key={'maritalStatusId'}
									items={martialStatuseList}
									label={'martialStatus'}
								/>
								<SelectWrapper
									name={'numberOfChildrenId'}
									key={'numberOfChildrenId'}
									items={numberOfChildrenList}
									label={'numberOfChildren'}
								/>
								<SelectWrapper
									name={'howDidHearAboutUs.valueId'}
									key={'howDidHearAboutUs.valueId'}
									controlOtherValueName={'howDidHearAboutUs.valueSpecified'}
									items={hearAboutUsList}
									label={'howHearAboutUs'}
								/>
								<Box className={`${form.values.publicFunction?.valueId === 6 ? 'col-span-2' :''}`}>
									<SelectWrapper
										name={'publicFunction.valueId'}
										key={'publicFunction.valueId'}
										controlOtherValueName={'publicFunction.valueSpecified'}
										items={publicFunctions}
										label={'importantPublicFunction'}
									/>
								</Box>
							</Box>
						</Stack>
					</fieldset>
					{showPasswordDialog ? (
						<ConfirmPasswordDialog
							state={showPasswordDialog}
							onClose={(e) => {
								onClosePasswordDialog(e);
							}}></ConfirmPasswordDialog>
					) : null}
				</FormikProvider>
			}></SectionWithHeading>
	);
};
