import { useTranslation } from 'react-i18next';
import { DialogWrapper } from '../../../../../shared/components/common/dialogWrapper';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateInvestorSteps,
} from '../../../../../services/authService';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { useState } from 'react';
import { useEffect } from 'react';
import { InvestorType } from '../../../../../constants/investorType';
import { FormikProvider, getIn, useFormik } from 'formik';
import { FormHelper } from '../../../../../helpers/formHelper';
import { InvestorRegService } from '../../investorRegService';
import { IS_SA } from '../../../../../config/appConfig';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import CRSSADoc from '../../../../../assets/docs/CRS_Self_Certification_Individuals_investors_KSA.pdf';
import CRSglobalDoc from '../../../../../assets/docs/CRS_Self_Certification_Individuals_investors.pdf';
import CorporateDoc from '../../../../../assets/docs/CRS_Self_Certification_Corporate_investors.pdf';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import {
	InvestorDeclarationCorporateSchema,
	InvestorDeclarationSchema,
} from '../../../../../shared/validations/schema';

export const InvestorDeclarationDialog = ({
	state,
	onClose = (e) => {},
	isSA = IS_SA,
}) => {
	const systemSettingsService = new SystemSettingsService();
	const investorRegService = new InvestorRegService();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const ksaForm = useFormik({
		initialValues: {
			NotBoardMemberAtListedCompanyOrGovernment: false,
			DoNotHoldImportantPublicFunction: false,
			// bySubmittingIAgreeToInvestmentContract: false,
			// selectAll: false,
		},
		validationSchema: InvestorDeclarationSchema,
		onSubmit: (values) => {
			submitKSAForm(values);
		},
		...FormHelper.formikProps(),
	});

	const form = useFormik({
		initialValues: {
			capitalAtRiskAccepted: false,
			investableAssetsAccepted: false,
			informationProvidedAccurateAccepted: false,
			termsConditionsForInvestmentsAccepted: false,
			notUsResident: false,
			longTermResidentialAddressСonfirmed: false,
			selectAll: false,
		},
		validationSchema: InvestorDeclarationSchema,
		onSubmit: (values) => {
			submitForm(values);
		},
		...FormHelper.formikProps(),
	});

	const corporateForm = useFormik({
		initialValues: {
			corporateDulyAuthorisedConfirmed: false,
			corporateMayLoseMoneyConfirmed: false,
			corporateReadRiskDisclosureConfirmed: false,
			corporateProvidedInformationIsAccurateConfirmed: false,
			corporateNotUsResidentConfirmed: false,
			corporateDocumentsAreMineConfirmed: false,
			corporateTermsConditionsConfirmed: false,
			selectAll: false,
		},
		validationSchema: InvestorDeclarationCorporateSchema,
		onSubmit: (values) => {
			submitCorporateForm(values);
		},
		...FormHelper.formikProps(),
	});

	const selectAll = getIn(form.values, 'selectAll');
	const selectAllCorporate = getIn(corporateForm.values, 'selectAll');
	// const selectAllKSA = getIn(ksaForm.values, 'selectAll');

	const { t } = useTranslation();
	const [isCorporate, setIsCorporate] = useState(false);

	const systemSettings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		checkAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, selectAllCorporate]);

	const checkAll = () => {
		let formData = isSA ? ksaForm : isCorporate ? corporateForm : form;
		Object.keys(formData.initialValues).forEach((key) => {
			if (key !== 'selectAll') {
				formData.setFieldValue(key, formData.values.selectAll, true);
			}
		});
	};

	const checkAllData = () => {
		let allChecked = true;
		let formData = isSA ? ksaForm : isCorporate ? corporateForm : form;
		Object.keys(formData.initialValues).forEach((key) => {
			if (key !== 'selectAll') {
				if (!formData.values[key]) {
					allChecked = false;
				}
			}
		});
		return allChecked;
	};

	useEffect(() => {
		setIsCorporate(
			getRegistrationData()?.investorType === InvestorType.Corporate
		);
	}, [state]);

	const onBack = (closeIwillDoLaterPopup = false) => {
		onClose(closeIwillDoLaterPopup);
	};

	const submitForm = (formValue) => {
		submit(formValue);
	};

	const submitCorporateForm = (formValue) => {
		submit(formValue);
	};

	const submitKSAForm = (formValue) => {
		submit(formValue);
	};

	const submit = (formValue) => {
		setLoading(true);
		let declarationData = [];
		Object.keys(formValue).forEach((key) => {
			if (formValue[key] && key !== 'selectAll') {
				declarationData.push(key);
			}
		});

		investorRegService
			.submitDeclarations(declarationData)
			.then((resp) => {
				setLoading(false);
				let registrationModel = resp.data?.registrationModel;
				saveTokenFromRegistration(resp.data?.loginModel?.token);
				let regData = {
					...getRegistrationData(),
					step: registrationModel.step,
					requestedStep: 3,
				};
				setRegistrationData(regData);
				updateInvestorSteps(registrationModel.step);
				onBack(true);
				setTimeout(() => {
					navigate(
						buildLocalizeRoute(`${AppRoutes.Investor}/${AppRoutes.ThankYou}`)
					);
				});
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const getAllChecked = (form) => {
		let formValue = form.initialValues;
		return Object.keys(formValue).every((key) => formValue[key]);
	};

	const getSomeChecked = (form) => {
		if (Object.keys(form.values).every((key) => form.values[key])) {
			return false;
		}
		if (Object.keys(form.values).some((key) => form.values[key])) {
			return true;
		}
	};

	const getKSAForm = () => {
		return (
			<FormikProvider value={ksaForm}>
				<Box className="flex flex-col justify-center items-start space-y-1p-2 md:px-4 md:py-3">
					{getCheckBox(
						ksaForm,
						'NotBoardMemberAtListedCompanyOrGovernment',
						'imNotBoardMember'
					)}
					{getCheckBox(
						ksaForm,
						'DoNotHoldImportantPublicFunction',
						'iHoldImportantPublicFunction'
					)}
					{/* <FormControlLabel
						label={
							<span>
								{t('bySubmittingContract')}{' '}
								<span>
									<a
										rel="noreferrer"
										className="underline-link"
										href={systemSettings()?.investorTermsAndConditions}
										target="_blank">
										{t('investmentContract')}
									</a>
								</span>
							</span>
						}
						control={
							<Checkbox
								onChange={(e) => {
									ksaForm.setFieldValue('bySubmittingIAgreeToInvestmentContract', e.target.checked);
								}}
								name={'investmentContract'}
								checked={ksaForm.values.bySubmittingIAgreeToInvestmentContract}
								value={ksaForm.values.bySubmittingIAgreeToInvestmentContract}
							/>
						}
					/> */}
					<Box className="flex justify-center w-full !mt-[20px]">
						<ButtonWrapper
							dataTestID="ksaBackBtn"
							disabled={loading}
							icon={<ArrowIcons isInvert></ArrowIcons>}
							classList="!secondary-btn !mx-2"
							label={t('cancel')}
							onClick={() => {
								onBack();
							}}></ButtonWrapper>
						<ButtonWrapper
							dataTestID="ksaSubmitBtn"
							loading={loading}
							disabled={!checkAllData()}
							icon={<SaveOutlinedIcon></SaveOutlinedIcon>}
							label={t('submit')}
							onClick={() => {
								FormHelper.validateForm(ksaForm);
							}}></ButtonWrapper>
					</Box>
				</Box>
			</FormikProvider>
		);
	};

	const getCorporateForm = () => {
		return (
			<FormikProvider value={corporateForm}>
				<Box className="border-b border-[#ccc] md:py-1 md:px-4 p-2 flex items-center">
					<FormControlLabel
						label={<span>{t('pleaseConfirmFollowingTickingBoxes')} </span>}
						control={
							<Checkbox
								data-testid="corporateSelectAll"
								className="!px-[3px] !pb-[3px] !pt-0"
								onChange={(e) => {
									corporateForm.setFieldValue('selectAll', e.target.checked);
								}}
								name={'selectAll'}
								indeterminate={getSomeChecked(corporateForm)}
								checked={corporateForm.values.selectAll}
								value={getAllChecked(corporateForm)}
							/>
						}
					/>
				</Box>
				<Box className="flex flex-col justify-center items-start space-y-4 p-2 md:px-4 md:py-3">
					{getCheckBox(
						corporateForm,
						'corporateDulyAuthorisedConfirmed',
						'corporateDulyAuthorisedConfirmed'
					)}
					{getCheckBox(
						corporateForm,
						'corporateMayLoseMoneyConfirmed',
						'corporateMayLoseMoneyConfirmed'
					)}
					{getCheckBox(
						corporateForm,
						'corporateReadRiskDisclosureConfirmed',
						'corporateReadRiskDisclosureConfirmed'
					)}
					{getCheckBox(
						corporateForm,
						'corporateProvidedInformationIsAccurateConfirmed',
						'corporateProvidedInformationIsAccurateConfirmed'
					)}
					{getCheckBox(
						corporateForm,
						'corporateNotUsResidentConfirmed',
						'corporateNotUsResidentConfirmed'
					)}
					{getCheckBox(
						corporateForm,
						'corporateDocumentsAreMineConfirmed',
						'corporateDocumentsAreMineConfirmed'
					)}
					<FormControlLabel
						label={
							<span>
								{t('declarationUnderstandSignedInvestorAgreement')}
								&nbsp;
								{t('iAcceptThe')} &nbsp;
								<a
									className="underline-link"
									href={systemSettings()?.generalTermsConditions}
									target="_blank"
									rel="noreferrer">
									{t('generalTermsOfUse')}
								</a>
								,
								<a
									className="underline-link mx-1"
									href={systemSettings()?.privacyPolicy}
									target="_blank"
									rel="noreferrer">
									{t('privacyPolicy')}
								</a>
								{t('and')}
								<a
									className="underline-link mx-1"
									href={CorporateDoc}
									target="_blank"
									rel="noreferrer">
									{t('crsSelfDeclarationForm')}
								</a>
							</span>
						}
						control={
							<Checkbox
								data-testid="corporateTermsConditionsConfirmed"
								className="!px-[3px] !pb-[3px] !pt-0"
								onChange={(e) => {
									corporateForm.setFieldValue(
										'corporateTermsConditionsConfirmed',
										e.target.checked
									);
								}}
								name={'corporateTermsConditionsConfirmed'}
								checked={corporateForm.values.corporateTermsConditionsConfirmed}
								value={corporateForm.values.corporateTermsConditionsConfirmed}
							/>
						}
					/>
					<Box className="flex justify-center w-full">
						<ButtonWrapper
							dataTestID="corporateBackbtn"
							disabled={loading}
							icon={<ArrowIcons isInvert></ArrowIcons>}
							classList="!secondary-btn !mx-2"
							label={t('cancel')}
							onClick={() => {
								onBack();
							}}></ButtonWrapper>
						<ButtonWrapper
							dataTestID="corporateSubmitbtn"
							loading={loading}
							disabled={!checkAllData()}
							icon={<SaveOutlinedIcon></SaveOutlinedIcon>}
							label={t('submit')}
							onClick={() => {
								FormHelper.validateForm(corporateForm);
							}}></ButtonWrapper>
					</Box>
				</Box>
			</FormikProvider>
		);
	};

	const getForm = () => {
		return (
			<FormikProvider value={form}>
				<Box className="md:py-1 border-b border-[#ccc] md:px-4 p-2 flex items-center ">
					<FormControlLabel
						label={<span>{t('pleaseConfirmFollowingTickingBoxes')} </span>}
						control={
							<Checkbox
								data-testid="formSelectAll"
								className="!px-[3px] !pb-[3px] !pt-0"
								onChange={(e) => {
									form.setFieldValue('selectAll', e.target.checked);
								}}
								name={'selectAll'}
								indeterminate={getSomeChecked(form)}
								checked={form.values.selectAll}
								value={getAllChecked(form)}
							/>
						}
					/>
				</Box>
				<Box className="flex flex-col justify-center items-start space-y-2 p-2 md:px-4 md:py-3">
					{getCheckBox(
						form,
						'capitalAtRiskAccepted',
						'understandMyCapitalIsAtRiskAndMyReturn'
					)}
					{getCheckBox(
						form,
						'investableAssetsAccepted',
						'confirmThatIWillOnlyBeInvestingUpTo'
					)}
					{getCheckBox(
						form,
						'informationProvidedAccurateAccepted',
						'informationProvidedAccurateAccepted'
					)}
					{getCheckBox(form, 'notUsResident', 'confirmNotUsCitizenResident')}
					{getCheckBox(
						form,
						'longTermResidentialAddressСonfirmed',
						'herebyCertifyThatTheProvidedProofOfAddress'
					)}
					<FormControlLabel
						label={
							<span>
								{t('iAcceptThe')}{' '}
								<a
									className="underline-link"
									href={isSA ? CRSSADoc : CRSglobalDoc}
									target="_blank"
									rel="noreferrer">
									{t('crsSelfDeclarationForm')}
								</a>
							</span>
						}
						control={
							<Checkbox
								data-testid={'termsConditionsForInvestmentsAccepted'}
								className="!px-[3px] !pb-[3px] !pt-0"
								onChange={(e) => {
									form.setFieldValue(
										'termsConditionsForInvestmentsAccepted',
										e.target.checked
									);
								}}
								name={'termsConditionsForInvestmentsAccepted'}
								checked={form.values.termsConditionsForInvestmentsAccepted}
								value={form.values.termsConditionsForInvestmentsAccepted}
							/>
						}
					/>
					<Box className="flex justify-center w-full">
						<ButtonWrapper
							dataTestID="formBackBtn"
							disabled={loading}
							icon={<ArrowIcons isInvert></ArrowIcons>}
							classList="!secondary-btn !mx-2"
							label={t('cancel')}
							onClick={() => {
								onBack();
							}}></ButtonWrapper>
						<ButtonWrapper
							dataTestID="formSubmitBtn"
							loading={loading}
							disabled={!checkAllData()}
							icon={<SaveOutlinedIcon></SaveOutlinedIcon>}
							label={t('submit')}
							onClick={() => {
								FormHelper.validateForm(form);
							}}></ButtonWrapper>
					</Box>
				</Box>
			</FormikProvider>
		);
	};

	const getCheckBox = (form, formControl, label) => {
		return (
			<FormControlLabel
				className="!items-start"
				label={<span>{t(label)} </span>}
				control={
					<Checkbox
						data-testid={label}
						onChange={(e) => {
							form.setFieldValue(formControl, e.target.checked);
						}}
						className="!px-[3px] !pb-[3px] !pt-0"
						name={formControl}
						checked={form.values[formControl]}
						value={form.values[formControl]}
					/>
				}
			/>
		);
	};

	return (
		<>
			<DialogWrapper
				maxWidth={isSA ? 'sm' : 'md'}
				state={state}
				onClose={() => {
					onClose();
				}}
				title={t('declaration')}
				content={
					<Box className="flex flex-col">
						<Box>
							{isSA
								? getKSAForm()
								: isCorporate
								? getCorporateForm()
								: getForm()}
						</Box>
					</Box>
				}></DialogWrapper>
		</>
	);
};
