import { DialogWrapper } from '../../../../shared/components/common/dialogWrapper';
import CheckIcon from '@mui/icons-material/Check';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import { Box } from '@mui/material';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { AppRoutes } from '../../../../constants/appRoutes';
import { useTranslation } from 'react-i18next';

export const UserLockOutDialog = ({ state, onClose = (e) => {} }) => {
	const { t } = useTranslation();
	return (
		<>
			<DialogWrapper
				titleClass="!w-full !items-center"
				maxWidth="xs"
				state={state}
				onClose={() => {
					onClose(false);
				}}
				title={t('accountTemporarilyLocked')}
				content={
					<Box className="!text-sm text-center">
						<TextWrapper
							type="span"
							classList="caption"
							text={t('accountTemporarilyLockedText')}></TextWrapper>
						<a
							onClick={() => {
								onClose();
							}}
							className="!text-sm"
							href={`/${AppRoutes.ForgotPassword}`}>
							{t('resetMyPassowrd')}
						</a>
						<TextWrapper
							type="span"
							classList="caption"
							text={t('enterCorrectPassword')}></TextWrapper>
					</Box>
				}
				actions={
					<Box className="flex items-center">
						<ButtonWrapper
							icon={<CheckIcon></CheckIcon>}
							type="button"
							onClick={() => {
								onClose();
							}}
							label={t('okGotIt')}></ButtonWrapper>
					</Box>
				}></DialogWrapper>
		</>
	);
};
