import { useTranslation } from 'react-i18next';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { BorrowerService } from '../../../borrowerService';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toaster } from '../../../../../shared/components/common/toaster';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { Box } from '@mui/material';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { ConfirmPasswordDialog } from '../../../../../shared/components/confirmPasswordDialog';
import { Logout } from '../../../../../services/authService';

export const BorrowerDeactivateAccount = () => {
	const systemSettingsService = new SystemSettingsService();
	const borrowerService = new BorrowerService();
	const { t } = useTranslation();
	const [showOTPAuth, setShowOTPAuth] = useState(false);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);

	const navigate = useNavigate();

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	const deactivateAccountWithOTP = () => {
		if (settings()?.enabledOtp) {
			setShowOTPAuth(true);
		} else {
			setShowPasswordDialog(true);
		}
	};

	const onPasswordDialogClose = (value) => {
		setShowPasswordDialog(false);
		if (value) {
			onDeactivate();
		}
	};

	const onCloseOTPAuth = (value) => {
		setShowOTPAuth(false);
		if (value) {
			onDeactivate();
		}
	};

	const onDeactivate = () => {
		borrowerService
			.deactivateAccount()
			.then((resp) => {
				Toaster({ msg: t('accountActivated') });
				onAfterActiveDeactive();
			})
			.catch((err) => {});
	};

	const onAfterActiveDeactive = () => {
		Logout();
		navigate(`${buildLocalizeRoute(AppRoutes.Login)}`);
	};

	return (
		<>
			<SectionWithHeading
				heading={
					<Box className="flex items-center">
						<span className="mx-2">{t('deactivateAccount')}</span>
					</Box>
				}
				children={
					<Box className="flex flex-col items-center justify-center space-y-4">
						<p className="!text-[14px]">{t('deactivateAccountText')}</p>
						<ButtonWrapper
							onClick={() => {
								deactivateAccountWithOTP();
							}}
							icon={<HighlightOffOutlinedIcon />}
							label={t('deactivate')}
							classList="!danger-bg"></ButtonWrapper>
					</Box>
				}></SectionWithHeading>
			{showOTPAuth ? (
				<OTPAuthenticationDialog
					type={OTPTypes.Other}
					state={showOTPAuth}
					onClose={(e) => {
						onCloseOTPAuth(e);
					}}></OTPAuthenticationDialog>
			) : null}
			{showPasswordDialog ? (
				<ConfirmPasswordDialog
					state={showPasswordDialog}
					onClose={(e) => {
						onPasswordDialogClose(e);
					}}></ConfirmPasswordDialog>
			) : null}
		</>
	);
};
