import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const CustomTooltip = ({
	text = '',
	iconClassList = '',
	tooltipClass = '',
	position = 'bottom',
	children = '',
	iconSizeClass = '',
	id = '',
}) => {
	const { t } = useTranslation();
	//id is required
	return !children ? (
		<>
			<InfoOutlinedIcon
				className={`${
					iconSizeClass ? iconSizeClass : '!text-[16px]'
				} relative -top-1 mx-0.5 cursor-pointer text-[#90ab9f] ${iconClassList}`}
				id={id}></InfoOutlinedIcon>
			<ReactTooltip
				style={{ lineHeight: '14px' }}
				noArrow={false}
				className={`!text-[10px] !px-1 !py-1 !max-w-[200px] ${tooltipClass}`}
				anchorSelect={`#${id}`}
				place={position}
				content={t(text)}
			/>
		</>
	) : (
		<span>
			<span id={id}>{children}</span>
			<ReactTooltip
				style={{ lineHeight: '14px' }}
				noArrow={false}
				className={`!text-[10px] !text-center !px-1 !py-1 !max-w-[200px] ${tooltipClass}`}
				anchorSelect={`#${id}`}
				place={position}
				content={t(text)}
			/>
		</span>
	);
};
