/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Card } from '@mui/material';
import { ButtonWrapper } from '../formUtilities/useButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemSettingsService } from '../../../services/systemSettingsService';
import { UserService } from '../../../services/userService';
import { OTPAuthenticationDialog } from '../otpAuthenticationDialog';
import { OTPTypes } from '../../../constants/otpTypes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalStorageKey } from '../../../constants/localStorageKeys';

export const DocumentUploadSection = ({
	file,
	fileName = '',
	subFileNameKey = '',
	subFileNameValue = '',
	fileType = '',
	isLoading = false,
	showOTPAuth = false,
	subPoints = null,
	isExternalFileOpen = false,
	isExternalFileRemove = false,
	actionSubElements = null,
	isMultiple = false,
	onUploadedFiles = (e) => { },
	onRemoveFile = (e) => { },
	onOpenFile = () => { }
}) => {
	const systemSettingsService = new SystemSettingsService();
	const userService = new UserService();
	const [uploadedFile, setUploadedFile] = useState(file);
	const [multipleFiles, setMultipleFiles] = useState([]);
	const [isShowAuthOtp, setIsShowAuthOtp] = useState(false);
	const [loadingFile, setLoadingFile] = useState([]);
	const [userUploadedfile, setUserUploadedfile] = useState(null);
	const { t } = useTranslation();

	const isOTPEnabled = () => {
		return systemSettingsService.systemSettings()?.enabledOtp;
	};

	useEffect(() => {
		if (!file) {
			setUploadedFile(null);
		}

		if (!isMultiple && file) {
			setUploadedFile(file);
		} else if (file && file?.length) {
			let combinedFiles = [...multipleFiles, ...file];
			let allFiles = removeDuplicate(combinedFiles);
			setMultipleFiles(allFiles);
		}
		console.log(isMultiple);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file, isMultiple]);

	const onFileUpload = (file) => {
		if (!file) {
			return;
		}
		setUserUploadedfile(file);
		setTimeout(() => {
			let userAlreadyConfirmed = isMultiple ? sessionStorage.getItem(LocalStorageKey.showOTPUpload) === '1' : false;
			if (showOTPAuth && isOTPEnabled() && !userAlreadyConfirmed) {
				setIsShowAuthOtp(true);
			} else {
				uploadDocs(file);
			}
		});
	};

	const removeDuplicate = (files) => {
		let allFiles = [
			...new Map(files.map(item => [item.id, item])).values()
		];

		return allFiles;
	};

	const setLoaders = (value) => {
		const loadingTypes = {
			...loadingFile,
			[fileType]: value,
		};
		setLoadingFile(loadingTypes);
	};

	const uploadDocs = (file) => {
		setLoaders(true);
		if (isMultiple) {
			userService
				.multiUploadDocuments(file, fileType)
				.then((resp) => {
					setLoaders(false);
					let files = [];
					if (resp.data && resp.data?.length) {
						resp.data.forEach(respFiles => {
							files.push(respFiles);
						});
					}
					const allFiles = removeDuplicate([...multipleFiles, ...files]);
					setMultipleFiles(allFiles);
					onUploadedFiles &&
						onUploadedFiles({ fileType: fileType, file: allFiles });
				})
				.catch((err) => {
					setLoaders(false);
				});
		} else {
			userService
				.uploadUserDocuments(file, fileType, null, (event) => {
					console.log(Math.round((100 * event.loaded) / event.total));
				})
				.then((resp) => {
					setLoaders(false);
					setUploadedFile(resp.data);
					onUploadedFiles &&
						onUploadedFiles({ fileType: fileType, file: resp.data });
				})
				.catch((err) => {
					setLoaders(false);
				});
		}
	};

	const onDeleteFile = (type, idFromMultiUpload) => {
		let id = uploadedFile?.id;
		if (idFromMultiUpload) {
			id = idFromMultiUpload;
		}

		if (id) {
			if (!idFromMultiUpload) {
				setLoaders(true);
			}
			userService
				.deleteFile(id)
				.then((resp) => {
					setLoaders(false);
					if (isMultiple) {
						let files = multipleFiles.filter(resp => resp?.id !== idFromMultiUpload);
						setMultipleFiles([...files]);
						onRemoveFile && onRemoveFile(files);
					} else {
						setUploadedFile(null);
						onRemoveFile && onRemoveFile(type);
					}
				})
				.catch((err) => {
					setLoaders(false);
				});
		}

		if (isExternalFileRemove) {
			onRemoveFile();
		}
	};

	const onViewFile = (url = '') => {
		if (!url || url?.includes('mockURL')) {
			return;
		}
		window.open(url, '_blank');
	};

	const checkLoading = () => {
		return isLoading ? isLoading : loadingFile[fileType];
	};

	const onUploadClick = () => {
		if (isMultiple && !loadingFile[fileType]) {
			document.getElementById(`fileUploader${fileType}`).click();

		} else if (!uploadedFile?.file?.url && !loadingFile[fileType]) {
			document.getElementById(`fileUploader${fileType}`).click();
		}
	};

	const actionSection = () => {
		return (
			<Box
				data-testid={fileType}
				className="flex flex-row items-center justify-end">
				<input
					accept="image/*,.pdf"
					type="file"
					id={`fileUploader${fileType}`}
					hidden
					onChange={(e) => {
						e.stopPropagation();
						onFileUpload(e.target.files[0]);
					}}
				/>
				<ButtonWrapper
					classList="!bg-[#f7f7f7] !text-primary"
					iconButton
					loading={checkLoading()}
					hideBtn={uploadedFile?.file?.url}
					icon={<CloudUploadOutlinedIcon />}></ButtonWrapper>
				{!isMultiple ? uploadedFile?.file?.url ? (
					getMultipleUploadActionButtons(uploadedFile?.file)
				) : null : null}
			</Box>
		);
	};

	const getMultipleUploadActionButtons = (file, idFromMultiUpload) => {
		return <Box
			className="flex flex-0 items-center"
			data-testid={`action-btn-${fileType}`}>
			<a onClick={(e) => {
				e.stopPropagation();
				onViewFile(file?.url);
				if (isExternalFileOpen) {
					onOpenFile && onOpenFile();
					return;
				}
			}} className='underline-link'>{t('preview')}</a>
			<a onClick={(e) => {
				onDeleteFile(fileType, idFromMultiUpload);
			}} className='underline-link !mx-2 !text-red-400'>{t('delete')}</a>
		</Box>;
	};

	// const getActionButtons = (file, idFromMultiUpload) => {
	// 	return <Box
	// 		className="flex flex-0 items-center"
	// 		data-testid={`action-btn-${fileType}`}>
	// 		<ButtonWrapper
	// 			iconButton
	// 			onClick={(e) => {
	// 				e.stopPropagation();
	// 				onViewFile(file?.url);
	// 				if (isExternalFileOpen) {
	// 					onOpenFile && onOpenFile();
	// 					return;
	// 				}
	// 			}}
	// 			classList="!bg-[#f7f7f7] !text-secondary !mx-2"
	// 			icon={<RemoveRedEyeIcon />}></ButtonWrapper>
	// 		<ButtonWrapper
	// 			iconButton
	// 			onClick={() => {
	// 				onDeleteFile(fileType, idFromMultiUpload);
	// 			}}
	// 			classList="!bg-[#f7f7f7] !text-red-400"
	// 			icon={<DeleteOutlineIcon />}></ButtonWrapper>
	// 	</Box>;
	// };

	return (
		<>
			<Box className='p-2' component={Card}>
				<Box
					onClick={() => {
						onUploadClick();
					}}
					className={`border border-dashed relative flex justify-between ${subPoints?.length && typeof subPoints !== 'string'
						? 'items-start'
						: 'items-center'
						} p-2 rounded-md cursor-pointer hover:shadow-md ${uploadedFile?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
					<Box
						className={`flex flex-0 ${subPoints?.length && typeof subPoints !== 'string'
							? 'items-start'
							: 'items-center'
							}`}>
						<Box>
							<ButtonWrapper
								disabled
								hideBtn={uploadedFile?.file?.url}
								classList="!bg-[#f7f7f7] !text-primary"
								iconButton
								icon={
									<SummarizeOutlinedIcon />
								}></ButtonWrapper>
						</Box>
						<Box className={`mx-2 font-[500] !text-sm`}>
							<Box>
								{t(fileName, {
									[subFileNameKey]: subFileNameValue,
								})}
							</Box>
							{subPoints ? (
								<>
									{typeof subPoints === 'string' ? (
										<small className="caption">{t(subPoints)}</small>
									) : subPoints?.length ? (
										<Box className="mt-2">
											{subPoints?.map((resp, index) => {
												return (
													<Box
														key={index}
														className="flex items-start">
														<CheckCircleIcon
															color="primary"
															className="!text-sm mt-1"
														/>
														<small className="caption mx-1">{t(resp)}</small>
													</Box>
												);
											})}
										</Box>
									) : null}
								</>
							) : null}
							{actionSubElements ? <>{actionSubElements}</> : null}
						</Box>
					</Box>
					<Box>{actionSection()}</Box>
				</Box>
				{multipleFiles && multipleFiles.length ? <Box className='flex md:max-h-[280px] md:overflow-auto flex-col space-y-1 pt-2'>
					{/* <TextWrapper classList='!text-[12px] !font-[500] !caption' text={t('uploadedFiles')} /> */}
					{multipleFiles.map((resp, index) => {
						return <Box key={index} className='flex justify-between items-center'>
							<Box className='flex items-center file-name-wrapper'>
								<Box className="mx-2 font-[500] file-name-text !text-sm">
									{resp?.file?.name}
								</Box>
							</Box>
							{getMultipleUploadActionButtons(resp?.file, resp?.id)}
						</Box>;
					})}
				</Box> : null}
			</Box>
			{isShowAuthOtp ? (
				<OTPAuthenticationDialog
					type={OTPTypes.Other}
					state={isShowAuthOtp}
					onClose={(resp) => {
						setIsShowAuthOtp(resp);
						if (resp) {
							sessionStorage.setItem(LocalStorageKey.showOTPUpload, '1');
							uploadDocs(userUploadedfile);
						}
					}}></OTPAuthenticationDialog>
			) : null}
		</>
	);
};
