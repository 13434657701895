import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import logoImage from '../../../assets/images/logo.webp';
import logoImageAr from '../../../assets/images/logo-ar.webp';
import { WEBSITE_URL } from '../../../config/appConfig';
import i18next from 'i18next';

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({
	sx,
	url = `${WEBSITE_URL}${i18next.language}/`,
	target = '',
	logoWhite = false,
	disabledLink = false,
	classList = '',
}) {

	const isAr = () => i18next.language === 'ar';

	const logo = (
		<a
			data-testid="regMainLogoWrapper"
			className={disabledLink ? '!pointer-events-none !cursor-default' : ''}
			href={url}
			target={target}>
			<Box
				sx={{ width: 80, height: 80, ...sx }}
				className={`${classList ? classList : ''}`}>
				<img
					style={{ filter: logoWhite ? 'brightness(0) invert(1)' : '' }}
					alt="logo"
					src={isAr() ? logoImageAr : logoImage}></img>
			</Box>
		</a>
	);

	return <>{logo}</>;
}
