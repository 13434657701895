import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const FinancialStatementDateHelper = () => {
	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();
	const june30 = new Date(currentYear, 5, 30);
	const currentDate = new Date();
	return isAfter(currentDate, june30)
		? {
				year1: t('yearToDateFS'),
				year2: currentYear - 1,
				isAfter: true,
				year1Key: 'yearToDate',
				year2Key: currentYear - 1,
		  }
		: {
				year1: currentYear - 1,
				year2: currentYear - 2,
				isAfter: false,
				year1Key: currentYear - 1,
				year2Key: currentYear - 2,
		  };
};
