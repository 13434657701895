import { Box } from '@mui/material';
import { DialogWrapper } from '../../../../../../shared/components/common/dialogWrapper';
import { TextWrapper } from '../../../../../../shared/components/formUtilities/useTextWrapper';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { useTranslation } from 'react-i18next';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../constants/appRoutes';

export const InvestErrorDialog = ({
	state,
	errorMessage = '',
	amount,
	onClose,
}) => {
	const { t } = useTranslation();
	const [isDeposit, setIsDeposit] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (errorMessage) {
			setIsDeposit(
				errorMessage
					.toLocaleLowerCase()
					.replace(/ /g, '')
					.includes('sufficientfundstocomplete')
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDepost = () => {
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}?amount=${amount}`
			)
		);
	};

	return (
		<DialogWrapper
			hideCloseBtn={true}
			maxWidth="xs"
			width={'350px'}
			state={state}
			onClose={() => {
				onClose(false);
			}}
			content={
				<>
					<Box className="grid grid-flow-row gap-4 justify-center">
						<Box className="flex flex-col items-center justify-center">
							<HighlightOffOutlinedIcon
								color="error"
								className="!text-[70px]"></HighlightOffOutlinedIcon>
							<Box className="flex justify-center items-center">
								<TextWrapper
									classList="!font-bold !sub-heading !text-center"
									text={t('investmentFailed')}></TextWrapper>
							</Box>
						</Box>

						<Box className="text-center">
							<p
								dangerouslySetInnerHTML={{
									__html: `${errorMessage}`,
								}}></p>
						</Box>
					</Box>
				</>
			}
			actions={
				<Box className="flex items-center">
					{isDeposit ? (
						<ButtonWrapper

							label={t('depositFunds')}
							onClick={() => {
								onDepost();
							}}></ButtonWrapper>
					) : (
						<ButtonWrapper

							variant={'outlined'}
							label={t('close')}
							onClick={() => {
								onClose(false);
							}}></ButtonWrapper>
					)}
				</Box>
			}></DialogWrapper>
	);
};
