import { useTranslation } from 'react-i18next';
import { SectionWithHeading } from '../../../../shared/components/common/sectionWithHeading';
import {
	Box,
	Button,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useEffect, useState } from 'react';
import { InvestorService } from '../../investorService';
import { DateHelper } from '../../../../helpers/dateHelper';
import DataTableGrid from '../../../../shared/components/formUtilities/useTable';
import { InitializeColumns } from './column';
import { saveAs } from 'file-saver';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';

export const TransactionStatements = () => {
	const investorService = new InvestorService();
	const [anchorEl, setAnchorEl] = useState(null);
	const [transactionStatements, setTransactionStatements] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingDowload, setLoadingDowload] = useState(false);
	const yearList = DateHelper.getYearsDropdown(2020);
	const open = Boolean(anchorEl);
	const { t } = useTranslation();
	const [year, setYear] = useState(new Date().getFullYear());
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const [columns, setColumns] = useState(
		InitializeColumns(0, 0, 0, 0, 0, 0, 0, 0, t)
	);

	const handleClose = (year) => {
		if (year) {
			setYear(year);
			getTransactionStatements(year);
		}
		setAnchorEl(null);
	};

	useEffect(() => {
		getTransactionStatements();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTransactionStatements = (year = new Date().getFullYear()) => {
		setLoading(true);
		investorService
			.getTransactionStatements(year)
			.then((resp) => {
				setLoading(false);
				let statements = resp.data || [];
				setTransactionStatements(statements);
				let totalDeposits = 0;
				let totalInvestments = 0;
				let totalReserved = 0;
				let totalRepayment = 0;
				let totalEarnings = 0;
				let totalFees = 0;
				let totalLoses = 0;
				let totalWithdrawals = 0;

				statements.forEach((statement) => {
					totalDeposits += statement.deposits;
					totalInvestments += statement.investments;
					totalReserved += statement.reserved;
					totalRepayment += statement.repayment;
					totalEarnings += statement.earnings;
					totalFees += statement.fees;
					totalLoses += statement.loses;
					totalWithdrawals += statement.withdrawals;
				});

				setColumns(
					InitializeColumns(
						totalDeposits,
						totalInvestments,
						totalReserved,
						totalRepayment,
						totalEarnings,
						totalFees,
						totalLoses,
						totalWithdrawals,
						t
					)
				);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onDownloadCSV = () => {
		setLoadingDowload(true);
		investorService
			.exportMyTransactionStatements(year)
			.then((file) => {
				setLoadingDowload(false);
				const date = DateHelper.formatDate(new Date());
				const fileName = `Transaction-statements-${date}.csv`;
				saveAs(file.data, fileName);
			})
			.catch((err) => {
				setLoadingDowload(false);
			});
	};

	const getDetailsTotals = (details = []) => {
		let investments = 0;
		let repayments = 0;
		let earnings = 0;
		let loses = 0;
		for (const iterator of details) {
			investments += iterator.investments;
			repayments += iterator.repayment;
			earnings += iterator.earnings;
			loses += iterator.loses;
		}
		return [investments, repayments, earnings, loses];
	};

	const getStatementDetailsTable = (details) => {
		let childRows = details?.original?.details || [];
		return childRows && childRows?.length ? (
			<Table style={{ border: '1px solid #ccc' }}>
				<TableHead>
					<TableRow>
						<TableCell className="!font-bold">{t('businessName')}</TableCell>
						<TableCell className="!font-bold">{t('investments')}</TableCell>
						<TableCell className="!font-bold">{t('repayments')}</TableCell>
						<TableCell className="!font-bold">{t('earnings')}</TableCell>
						<TableCell className="!font-bold">{t('losses')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{childRows?.map((detail, index) => {
						return (
							<TableRow key={index}>
								<TableCell>{detail?.businessName}</TableCell>
								<TableCell>
									{currencyFormatterHelper(detail?.investments)}
								</TableCell>
								<TableCell>
									{currencyFormatterHelper(detail?.repayment)}
								</TableCell>
								<TableCell>
									{currencyFormatterHelper(detail?.earnings)}
								</TableCell>
								<TableCell>{currencyFormatterHelper(detail?.loses)}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell className="!font-bold">{t('total')}</TableCell>
						{getDetailsTotals(childRows).map((total) => {
							return <TableCell>{currencyFormatterHelper(total)}</TableCell>;
						})}
					</TableRow>
				</TableFooter>
			</Table>
		) : (
			<Box className="text-center">{t('nothingToDisplay')}</Box>
		);
	};

	return (
		<SectionWithHeading
			actionButtons={
				<Box className="flex flex-0">
					<Button
						className="md:w-auto w-full"
						variant="outlined"
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}>
						<Box className="flex items-center">
							<FilterAltOutlinedIcon
								sx={{
									fontSize: '18px',
									marginTop: '-3px',
								}}></FilterAltOutlinedIcon>
							<Box className="mx-1">{year}</Box>
						</Box>
					</Button>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={() => {
							handleClose(null);
						}}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}>
						{yearList.map((resp) => {
							return (
								<MenuItem
									className={year === resp ? '!highlight-bg' : ''}
									value={year}
									onClick={() => {
										handleClose(resp);
									}}>
									{resp}
								</MenuItem>
							);
						})}
					</Menu>
				</Box>
			}
			heading={t('statementSummary')}
			downloadCSV={true}
			loadingDownload={loadingDowload}
			onDownload={() => {
				onDownloadCSV();
			}}
			children={
				<DataTableGrid
					isShowDetailTab={true}
					DetailTabContent={(row) => {
						return getStatementDetailsTable(row?.row?.row);
					}}
					columnPinningLeft={['date']}
					name={'TransactionTable'}
					columns={columns}
					records={transactionStatements}
					isPagination={false}
					showEditColumnsButton={false}
					loading={loading}></DataTableGrid>
			}></SectionWithHeading>
	);
};
