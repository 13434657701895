import ProgressBar from '@ramonak/react-progress-bar';

export const CustomProgressBar = ({ value = 0 }) => {
	return (
		<ProgressBar width='100%' labelClassName={value <= 20 ? 'custom-progress-label' : ''}
			customLabel={`${value}%`}
			bgColor="#33CD87"
			completed={value}></ProgressBar>
	);
};
