import {
	Button,
	CircularProgress,
	IconButton,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { SpinnerWrapper } from './useSpinner';
import { useRef } from 'react';
import { LanguageService } from '../../../services/languageService';
import { useTranslation } from 'react-i18next';

export const ButtonWrapper = ({
	label = '',
	id = '',
	disabled = false,
	classList = '',
	variant,
	icon,
	endIcon,
	type = 'button',
	loading = false,
	loaderSpinnerClass = '',
	iconButton = false,
	hideBtn = false,
	linkTo = null,
	target = '_blank',
	labelClass = '',
	uploadButton = false,
	fileUploadTypes = '.jpg, .jpeg, .pdf, .svg, .png',
	tooltip = '',
	isErrorButton = false,
	size = 'medium',
	dataTestID = '',
	largeBtn = false,
	spinnerSize = 20,

	onClick = (e) => {},
	onFileUpload = (file) => {},
}) => {
	const { t } = useTranslation();
	const fileInput = useRef();
	const languageService = new LanguageService();

	if (variant !== 'outlined' && !iconButton) {
		classList = classList + ` !text-white`;
	}

	if (variant === 'error') {
		classList = classList + ` !bg-red-600`;
	}

	if (variant === 'neutral') {
		classList = classList + ` !secondary-btn`;
	}

	if (variant === 'outlined') {
		loaderSpinnerClass = '!text-gray-300';
	}

	const uploadFile = (event) => {
		onFileUpload && onFileUpload(event.target.files[0]);
		fileInput.current.value = null;
	};

	const getButton = () => {
		return (
			<>
				<Button
					data-testid={dataTestID}
					sx={{ height: 'fit-content' }}
					title={tooltip}
					size={size}
					key={id}
					LinkComponent={linkTo ? 'a' : 'button'}
					href={linkTo}
					target={target}
					disabled={disabled || loading}
					className={`${classList} ${
						isErrorButton ? '!bg-red-500 !text-white' : ''
					} ${largeBtn ? 'md:!w-[150px]' : ''}`}
					// startIcon={!loading ? icon : null}
					onClick={(e) => {
						onClick(e);
						if (uploadButton) {
							fileInput.current.click();
						}
					}}
					type={type}
					variant={variant ? variant : 'contained'}
					// endIcon={loading ? null : endIcon}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{loading ? (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box>{t('loading')}</Box>
								<SpinnerWrapper
									classList={`mx-2 ${
										loaderSpinnerClass ? loaderSpinnerClass : '!text-white'
									}`}></SpinnerWrapper>
							</Box>
						) : (
							<>
								<Typography
									variant="subtitle2"
									sx={{
										lineHeight: '24px',
										textTransform: 'none',
										whiteSpace: 'nowrap',
									}}
									className={`${labelClass}`}>
									<span
										className={`${languageService.isArabic() ? '!mx-3' : ''}`}>
										{label}
									</span>
								</Typography>
							</>
						)}
					</Box>
				</Button>
				<input
					onChange={uploadFile}
					ref={fileInput}
					accept={fileUploadTypes}
					type="file"
					style={{ display: 'none' }}
				/>
			</>
		);
	};

	return (
		<>
			{hideBtn ? null : (
				<>
					{!iconButton ? (
						getButton()
					) : (
						<IconButton
							size={size}
							title={tooltip}
							disabled={disabled}
							className={`!icon-bg-color ${classList} !relative`}
							onClick={(e) => {
								onClick(e);
							}}>
							{loading ? <CircularProgress size={spinnerSize} /> : icon}
						</IconButton>
					)}
				</>
			)}
		</>
	);
};
