import { Box, FormGroup } from '@mui/material';
import { TextWrapper } from './useTextWrapper';
import { styled } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
	'& .MuiInputBase-root': {
		'& .MuiInputBase-input': {
			padding: '8.5px 14px',
		},
	},
}));

export const DatePickerWrapper = ({
	minDate = null,
	maxDate = null,
	defaultMinDate = false,
	defaultMaxDate = false,
	setCurrentDateAsDefault = false,
	disabled = false,
	errorStateTestID = '',
	dataTestID = '',
	...props
}) => {
	const { t } = useTranslation();
	const [field, meta] = useField(props);
	const [defaultMinDateValue, setDefaultMinDateValue] = useState(new Date());
	const [defaultMaxDateValue, setDefaultMaxDateValue] = useState(new Date());
	let value = field.value;
	if (value && typeof value == 'string') {
		value = Date.parse(value);
	}
	const { setFieldValue } = useFormikContext();

	useEffect(() => {
		setDefaultMaxDateValue(new Date());
		setDefaultMinDateValue(new Date());
		if (setCurrentDateAsDefault) {
			setFieldValue(field.name, new Date());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultMinDate, defaultMaxDate, setCurrentDateAsDefault]);
	return (
		<FormGroup>
			<Box className="flex flex-col items-start w-full custom-date-picker">
				<Box className="flex flex-0 items-center !relative">
					<TextWrapper
						type="caption"
						text={t(props?.label)}></TextWrapper>
					{meta.error ? (
						<span className="!text-red-500 !absolute !right-[-6px] !top-[-3px]">
							*
						</span>
					) : null}
				</Box>
			</Box>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<StyledDatePicker
					disabled={disabled}
					minDate={
						minDate ? minDate : defaultMinDate ? defaultMinDateValue : null
					}
					maxDate={
						maxDate ? maxDate : defaultMaxDate ? defaultMaxDateValue : null
					}
					format="dd/MM/yyyy"
					className={meta.error && meta.touched ? 'date-picker-error' : ''}
					onChange={(val) => {
						setFieldValue(field.name, format(new Date(val), 'yyyy-MM-dd'));
					}}
					slotProps={{
						textField: {
							helperText: (
								<span className="text-[#d32f2f]">
									{meta.error && meta.touched ? t(meta.error) : ''}
								</span>
							),
							FormHelperTextProps: {
								'data-testid': errorStateTestID,
							},
							inputProps: { 'data-testid': dataTestID },
						},
					}}
					value={value || ''}></StyledDatePicker>
			</LocalizationProvider>
		</FormGroup>
	);
};
