import { Box, CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { setRegistrationData } from '../../../../../services/authService';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { BorrowerRegistrationStep } from '../../../../../constants/borrowerRegistrationSteps';
import { IS_SA } from '../../../../../config/appConfig';
import { BorrowerRegService } from '../../borrowerRegService';
import { BorrowerStepperContext } from '../../../../../context/borrowerStepperContext';

export const BorrowerStepper = () => {
	const borrowerRegService = new BorrowerRegService();
	const [loading, setLoading] = useState(true);
	const { steps } = useContext(BorrowerStepperContext);
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const [selectedStep, setSelectedStep] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		getBorrowerRegistrationData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let path = pathname.split('/');
		let lastPathName = path[path.length - 1];
		if (lastPathName && steps && steps?.length) {
			setSelectedStep(steps.findIndex((resp) => lastPathName === resp.path));
		}
	}, [pathname, steps]);

	const getBorrowerRegistrationData = () => {
		setLoading(true);
		borrowerRegService
			.getBorrowerRegistrationData()
			.then((resp) => {
				setRegistrationData(resp.data);
				setLoading(false);
				onHandleStepsRefresh(resp.data?.step);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onHandleStepsRefresh = (step) => {
		let route = null;
		if (step === BorrowerRegistrationStep.NotStarted) {
			route = IS_SA
				? AppRoutes.IRegNafathVerification
				: AppRoutes.BRegPersonalInfo;
		} else if (step === BorrowerRegistrationStep.PersonalInformation) {
			route = IS_SA
				? AppRoutes.CommercialRegistration
				: AppRoutes.BRegPersonalInfo;
		} else if (step === BorrowerRegistrationStep.BusinessInformation) {
			route = IS_SA
				? AppRoutes.CommercialRegistration
				: AppRoutes.BRegBusinessInfo;
		} else if (step === BorrowerRegistrationStep.DocumentVerification) {
			route = AppRoutes.BRegDocumentVarification;
		}
		if (route && !window.location.pathname.includes(route)) {
			navigate(
				buildLocalizeRoute(`${AppRoutes.BorrowerRegistartion}/${route}`)
			);
		}
	};

	return (
		<Box className="flex justify-center items-center">
			<Box className="md:w-[850px] w-full">
				<Stepper
					data-testid="stepperTestId"
					className="my-4 mb-8"
					orientation="horizontal"
					activeStep={selectedStep}
					alternativeLabel>
					{steps.map((resp) => (
						<Step key={resp?.name}>
							<StepLabel>
								<span
									data-testid={`borrower-${resp?.name}`}
									className="font-bold">
									{t(`borrower-${resp?.name}`)}
								</span>
							</StepLabel>
						</Step>
					))}
				</Stepper>
				{!loading ? (
					<Outlet></Outlet>
				) : (
					<Box className="flex justify-center items-center reg-section-height">
						<CircularProgress data-testid="loader" />
					</Box>
				)}
			</Box>
		</Box>
	);
};
