import { Box, Paper, Stack } from '@mui/material';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../constants/appRoutes';
import { CURRENCY } from '../../../../config/appConfig';
import { DateHelper } from '../../../../helpers/dateHelper';
import { Toaster } from '../../../../shared/components/common/toaster';
import { TransferService } from '../../../../services/transferService';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { useTranslation } from 'react-i18next';
import { FSBankDetails } from '../../../../shared/components/fsBankDetails';

export default function DepositSuccess({
	userBankDetails,
	amount = 0,
	transferId,
	onCancel = () => {},
}) {
	const transferService = new TransferService();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onCancelTransaction = () => {
		if (transferId?.length === 0) {
			Toaster({
				msg: t('depositErrorText'),
			});
			return;
		}

		transferService
			.cancelTransfer(transferId)
			.then((resp) => {
				onCancel && onCancel();
			})
			.catch((er) => {});
	};

	const onDoneTransaction = () => {
		navigate(`/${AppRoutes.Borrower}`);
	};

	return (
		<Box className="flex flex-0 justify-center">
			<Box
				component={Paper}
				className="flex flex-col items-center p-10 md:w-[670px] w-full">
				<Stack gap={3}>
					<Box className="flex flex-col flex-0">
						<TextWrapper
							classList="caption !text-sm "
							text={t('youHaveMadeFollowingTransferIntoAccount')}></TextWrapper>
						<TextWrapper
							classList="caption !text-sm !mt-2"
							text={t('completeItViaInternetBankingOrTelephone')}></TextWrapper>
					</Box>
					<Stack gap={2}>
						<Box className="grid grid-cols-2 gap-4">
							<TextWrapper
								classList="!text-sm !font-bold !text-end"
								text={t('amount')}></TextWrapper>
							<TextWrapper
								classList="!text-sm border-b"
								text={`${CURRENCY} ${amount}`}></TextWrapper>
						</Box>
						<Box className="grid grid-cols-2 gap-4">
							<TextWrapper
								classList="!text-sm !font-bold !text-end"
								text={t('status')}></TextWrapper>
							<TextWrapper
								classList="!text-sm border-b"
								text={'Pending'}></TextWrapper>
						</Box>
						<Box className="grid grid-cols-2 gap-4">
							<TextWrapper
								classList="!text-sm !font-bold !text-end"
								text={t('date')}></TextWrapper>
							<TextWrapper
								classList="!text-sm border-b"
								text={DateHelper.formatDate(new Date())}></TextWrapper>
						</Box>
						<Box className="grid grid-cols-2 gap-4">
							<TextWrapper
								classList="!text-sm !font-bold !text-end"
								text={`${t('reference')} #`}></TextWrapper>
							<TextWrapper
								classList="!text-sm border-b"
								text={userBankDetails?.reference}></TextWrapper>
						</Box>
						<FSBankDetails></FSBankDetails>
					</Stack>

					<Box className="flex-col flex justify-center items-center">
						<Stack
							gap={1}
							direction={'row'}
							className="justify-center">
							<ButtonWrapper
								onClick={() => {
									onCancelTransaction();
								}}
								variant={'outlined'}
								label={t('cancel')}
								icon={<ClearIcon></ClearIcon>}></ButtonWrapper>
							<ButtonWrapper
								onClick={() => {
									onDoneTransaction();
								}}
								label={t('done')}
								icon={<CheckIcon></CheckIcon>}></ButtonWrapper>
						</Stack>
						<Link
							className="underline-link mt-3"
							to={buildLocalizeRoute(
								`${AppRoutes.Borrower}/${AppRoutes.BDepositHistory}`
							)}>
							{t('depositHistory')}
						</Link>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
}
