import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';
import { DateHelper } from '../../../../helpers/dateHelper';
import { getRiskRatingKey } from '../../../../helpers/riskRatingHelper';

export const InitializeMyordersColumns = (t) => {
	return [
		{
			header: t('businessName'),
			accessorKey: 'businessName',
			maxSize: 100,
		},
		{
			header: t('industry'),
			accessorKey: 'businessIndustry',
			maxSize: 100,
		},
		{
			header: t('amount'),
			accessorKey: 'amount',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
			maxSize: 100,
		},
		{
			header: t('riskRating'),
			accessorKey: 'rating',
			Cell: ({ cell }) => {
				return getRiskRatingKey(cell?.getValue());
			},
			maxSize: 100,
		},
		{
			header: t('type'),
			accessorKey: 'paymentFrequency',
			Cell: ({ cell }) => {
				let value = cell?.getValue();
				if (value === 0) {
					return 'Monthly';
				}
				if (value === 1) {
					return 'OneOff';
				}
			},
			maxSize: 100,
		},
		{
			header: t('profitRate'),
			accessorKey: 'investorProfit',
			Cell: ({ cell }) => {
				return `${cell?.getValue()}%`;
			},
			maxSize: 100,
		},
	];
};

export const InitializeRatiosColumns = (t) => {
	return [
		{
			header: t('businessName'),
			accessorKey: 'title',
			Header: ({ column }) => (
				<div style={{ maxWidth: '160px !important' }}>
					{column.columnDef.header}
				</div>
			),
			maxSize: 160,
		},
		{
			header: t('industry'),
			accessorKey: 'businessIndustry',
			Header: ({ column }) => (
				<div className="data-table-header">{column.columnDef.header}</div>
			),
			size: 50,
		},
		{
			header: t('risk'),
			accessorKey: 'risk',
			Header: ({ column }) => (
				<div className="data-table-header">{column.columnDef.header}</div>
			),
			size: 50,
		},
		{
			header: t('tenure'),
			accessorKey: 'tenure',
			Header: ({ column }) => <div>{column.columnDef.header}</div>,
			size: 30,
		},
		{
			header: t('repaymentsLeft'),
			accessorKey: 'repaymentsLeft',
			Header: ({ column }) => (
				<div className="data-table-header">{column.columnDef.header}</div>
			),
			size: 50,
		},
		{
			header: t('amountOutstanding'),
			accessorKey: 'principalRemaining',
			Header: ({ column }) => (
				<div className="data-table-header">{column.columnDef.header}</div>
			),
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
			maxSize: 100,
		},
		{
			header: t('nextPayment'),
			accessorKey: 'nextPaymentAmount',
			Header: ({ column }) => (
				<div
					className="data-table-header"
					style={{ maxWidth: '50px !important' }}>
					{column.columnDef.header}
				</div>
			),
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
			maxSize: 50,
		},
		{
			header: t('nextPaymentDue'),
			accessorKey: 'nextPayment',
			Header: ({ column }) => (
				<div
					className="data-table-header"
					style={{ maxWidth: '120px !important' }}>
					{column.columnDef.header}
				</div>
			),
			Cell: ({ cell }) => {
				return DateHelper.formatDate(cell?.getValue(), 'dd/MM/yyyy');
			},
			maxSize: 120,
		},
		{
			header: t('profitRate'),
			accessorKey: 'investorProfit',
			Header: ({ column }) => (
				<div className="data-table-header">{column.columnDef.header}</div>
			),
			Cell: ({ cell }) => {
				return `${cell?.getValue()}%`;
			},
			size: 50,
		},
		{
			header: t('status'),
			accessorKey: 'loanStatus',
			Header: ({ column }) => (
				<div className="data-table-header">{column.columnDef.header}</div>
			),
			Cell: ({ cell }) => {
				return `${
					cell?.getValue()?.toLocaleLowerCase() !== 'paidlate'
						? cell?.getValue()
						: 'Paid'
				}`;
			},
			size: 50,
		},
	];
};
