export const LocalStorageKey = {
	users: 'user',
	token: 'token',
	proInvestorCount: 'proCount',
	notificationCount: 'notifyCount',
	systemSettings: 'systemSettings',
	tokenRefresh: 'token-refreshing',
	registrationData: 'registration_data',
	registrationInfo: 'registrationInfo',
	loadAllCollection: 'loadAllCollection',
	investorsCollections: 'investorsCollections',
	borrowersCollections: 'borrowersCollections',
	notificationType: 'notificationType',
	nafathVerified: 'nafathVerified',
	isEligibilityCompleted: 'isEligibilityCompleted',
	showOTPUpload: 'showOTPUpload'
};
