import { Box } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { DialogWrapper } from '../../../../../../shared/components/common/dialogWrapper';
import { TextWrapper } from '../../../../../../shared/components/formUtilities/useTextWrapper';
import { InputWrapper } from '../../../../../../shared/components/formUtilities/useInput';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { IS_SA, MIN_INVESTMENT } from '../../../../../../config/appConfig';
import { currencyFormatterHelper } from '../../../../../../helpers/currencyFormatterHelper';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormHelper } from '../../../../../../helpers/formHelper';

export const InvestBehalfDialog = ({
	state,
	onClose,
	onInvestment,
	remainingAmount = 0,
	minInvestmentAmount = MIN_INVESTMENT,
	loadingInvest = false,
}) => {
	if (minInvestmentAmount === 0) {
		minInvestmentAmount = MIN_INVESTMENT;
	}
	const { t } = useTranslation();
	const amounts = [
		{ name: '5,000', value: 5000 },
		{ name: '10,000', value: 10000 },
		{ name: !IS_SA ? '20,000' : '15,000', value: !IS_SA ? 20000 : 15000 },
		{ name: !IS_SA ? '36,000' : '18,000', value: !IS_SA ? 36000 : 18000 },
	];

	const getMinInvestAmount = () => {
		return currencyFormatterHelper(minInvestmentAmount);
	};

	const getRemainingAmount = () => {
		return currencyFormatterHelper(remainingAmount);
	};

	const InvestAmountSchema = yup.object().shape({
		amount: yup
			.number()
			.required(t('required'))
			.min(
				minInvestmentAmount,
				`${t('minimumCollectionInvestAmount')} ${getMinInvestAmount()}`
			)
			.max(
				remainingAmount,
				`${t('amountGreaterThanRemainingAmount')} ${getRemainingAmount()}`
			)
			.test('', t('enteredMustMultipleOf10'), (value) => {
				if (value % 10 > 0) {
					return false;
				}
				return true;
			})
			.nullable(),
	});

	const form = useFormik({
		initialValues: {
			amount: null,
		},
		validationSchema: InvestAmountSchema,
		onSubmit: () => {
			onInvestment(form.values.amount);
		},
		...FormHelper.formikProps(),
	});

	const onInvest = () => {
		form.submitForm();
	};

	const getTandCUrls = () => {
		return IS_SA
			? process.env.PUBLIC_URL + '/docs/TCs_Investors_KSA.docx'
			: process.env.PUBLIC_URL + '/docs/TC_Investors.pdf';
	};

	return (
		<DialogWrapper
			centerHeading={true}
			maxWidth="xs"
			width={'380px'}
			state={state}
			onClose={() => {
				onClose(false);
			}}
			title={t('confirmation')}
			content={
				<>
					<Box className="grid grid-flow-row gap-3">
						<TextWrapper
							classList="!text-center"
							text="Amount you wish to invest"
							type="h6"></TextWrapper>
						<Box className="grid grid-cols-4 gap-4">
							{amounts.map((resp, index) => {
								return (
									<Box
										key={index}
										onClick={() => {
											form.setFieldValue('amount', resp.value);
										}}
										className="border p-1 flex justify-center items-center cursor-pointer hover:primary-bg hover:text-white rounded-sm hover:border-0">
										{resp.name}
									</Box>
								);
							})}
						</Box>
						<FormikProvider value={form}>
							<form>
								<InputWrapper
									type="number"
									name="amount"
									key={'amount'}
									label={t('amount')}></InputWrapper>
							</form>
						</FormikProvider>
						<Box>
							<span className="!text-[11px]">
								<p className="caption">
									<span className="caption">As per the</span>
									<a
										target="_blank"
										rel="noreferrer"
										className="!text-[11px] underline-link"
										href={getTandCUrls()}>
										&nbsp;T&amp;Cs for Investors,&nbsp;
									</a>
									the provided risk band classification is only an indication
									and you need to form your own opinion regarding the
									creditworthiness of the Business. The financing contract will
									be directly between you (and other investors) and the
									Business. Placing this order is final so please seek
									independent advice if in doubt.
								</p>
							</span>
						</Box>
					</Box>
				</>
			}
			actions={
				<Box className="flex items-center">
					<ButtonWrapper
						variant={'outlined'}
						label={t('cancel')}
						disabled={loadingInvest}
						onClick={() => {
							onClose(false);
						}}></ButtonWrapper>
					<ButtonWrapper
						loading={loadingInvest}
						classList="!ml-2"
						label={t('invest')}
						onClick={() => {
							onInvest();
						}}></ButtonWrapper>
				</Box>
			}></DialogWrapper>
	);
};
