import { axiosGet, axiosPatch, axiosPut } from '../../services/axiosService';

const baseInvestorsSummaryURI = 'LenderSummary';
const baseInvestorsURI = 'Lenders';

export class InvestorService {
	getInvestorsSummary() {
		return axiosGet(null, baseInvestorsSummaryURI);
	}

	activateInvestments() {
		return axiosGet(null, baseInvestorsSummaryURI + `/ActiveInvestments`);
	}

	getInvestorById(id) {
		return axiosGet(null, baseInvestorsURI + `/${id}`);
	}

	getInvestor() {
		return axiosGet(null, baseInvestorsURI + `/me`);
	}

	getNetEarnings(term) {
		return axiosGet(null, baseInvestorsSummaryURI + `/Earnings?term=${term}`);
	}

	getAutoInvestingMode() {
		return axiosGet(
			null,
			baseInvestorsSummaryURI + `/AutoInvestingMode`,
			null,
			'json'
		);
	}

	getTransactionStatements(year) {
		return axiosGet(null, `${baseInvestorsSummaryURI}/Statements?year=${year}`);
	}

	getInvestorSummaryShort() {
		return axiosGet(null, baseInvestorsSummaryURI + '/Short');
	}

	activateAccount() {
		return axiosPatch(baseInvestorsURI + '/me/activate', null, {
			hide_success_toaster: true,
		});
	}

	deactivateAccount() {
		return axiosPatch(baseInvestorsURI + '/me/deactivate', null, {
			hide_success_toaster: true,
		});
	}

	updateInvestorProfile(investorDetails) {
		return axiosPut(investorDetails, baseInvestorsURI + '/me');
	}

	exportMyTransactionStatements(year) {
		return axiosGet(
			null,
			baseInvestorsSummaryURI + `/Statements/Export?year=${year}`,
			null,
			'blob'
		);
	}

	getCorporateInformation() {
		return axiosGet(null, baseInvestorsURI + `/Me/CorporateInformation`);
	}

	updateCorporateInformation(investorDetails) {
		return axiosPut(investorDetails, baseInvestorsURI + '/me/CorporateInformation');
	}
}
