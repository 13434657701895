import { Box, Divider, Skeleton } from '@mui/material';

export const NextPaymentLoader = () => {
	return (
		<Box className="flex flex-col flex-auto justify-between md:!min-h-[200px]">
			<Box className="flex flex-col flex-0">
				<Box className="flex justify-between items-center">
					<Skeleton
						variant="text"
						className="w-[100px]"
					/>
					<Skeleton
						variant="text"
						className="w-[80px]"
					/>
				</Box>
				<Box className="my-3">
					<Divider></Divider>
				</Box>
				<Box className="flex justify-between items-center">
					<Skeleton
						variant="text"
						className="w-[100px]"
					/>
					<Skeleton
						variant="text"
						className="w-[80px]"
					/>
				</Box>
				<Box className="my-3">
					<Divider></Divider>
				</Box>
				<Box className="flex justify-between items-center">
					<Skeleton
						variant="text"
						className="w-[100px]"
					/>
					<Skeleton
						variant="text"
						className="w-[80px]"
					/>
				</Box>
			</Box>
			<Box className="flex justify-center mt-4">
				<Skeleton
					variant="text"
					className="button-loader"
				/>
			</Box>
		</Box>
	);
};
