import { Box, Paper, Skeleton, Stack } from '@mui/material';
import { LanguageService } from '../../../../../services/languageService';
import { Section } from '../../../common/section';

export const MarketplaceDetailLoader = () => {
	const languageService = new LanguageService();
	const subItems = (isLast) => {
		return (
			<Section
				isShadow={false}
				classList={`!rounded-none !p-0 !flex !justify-center !items-center ${
					!isLast
						? languageService.isArabic()
							? 'md:border-l-2'
							: 'md:border-r-2'
						: ''
				}  `}
				children={
					<Stack className="flex justify-center items-center">
						<Skeleton
							variant="text"
							sx={{ fontSize: '10px', width: '60px' }}
						/>
						<Skeleton
							variant="text"
							sx={{ fontSize: '10px', width: '100px' }}
						/>
					</Stack>
				}></Section>
		);
	};

	return (
		<Box className="grid grid-flow-row gap-4">
			<Section
				classList="!p-0"
				children={
					<Box className="grid grid-cols-1 md:grid-cols-3">
						<Box className="h-full w-full">
							<Skeleton
								height={'310px'}
								variant="rectangular"
								sx={{ fontSize: '10px', width: '100%' }}
							/>
						</Box>
						<Box className="md:col-span-2 p-4">
							<Stack
								gap={1}
								direction={'column'}>
								<Skeleton
									variant="text"
									sx={{ fontSize: '15px', width: '300px' }}
								/>
								<Box
									component={Paper}
									className="grid grid-cols-2 md:grid-cols-3 gap-4 p-3">
									{subItems()}
									{subItems()}
									{subItems(true)}
									{subItems()}
									{subItems()}
									<Section
										isShadow={false}
										classList="!rounded-md !p-2 !flex !justify-center !items-center"
										children={
											<Stack className="text-center">
												<Skeleton
													variant="text"
													sx={{ fontSize: '10px', width: '100px' }}
												/>
												<Skeleton
													variant="text"
													sx={{ fontSize: '10px', width: '100px' }}
												/>
											</Stack>
										}></Section>
								</Box>
								<Stack className="mt-6 marketplace-progress-container relative">
									<Box className="mb-1 marketplace-progress-funded absolute w-full -top-[22px]">
										<Box className="flex w-full items-center justify-between">
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '100px' }}
											/>
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '100px' }}
											/>
										</Box>
									</Box>
									<Box
										className="card flex flex-0 flex-col items-center relative "
										sx={{ width: '100%' }}>
										<Skeleton
											height={'15px'}
											variant="rounded"
											sx={{ fontSize: '10px', width: '100%' }}
										/>
									</Box>
								</Stack>
								<Box className="flex justify-center mt-3">
									<Skeleton
										variant="text"
										sx={{ fontSize: '2rem', width: '100px' }}
									/>
								</Box>
							</Stack>
						</Box>
					</Box>
				}></Section>
			<Section
				children={
					<Box className="grid grid-cols-1 md:grid-cols-4 gap-3">
						{subItems()}
						{subItems()}
						{subItems()}
						{subItems(true)}
					</Box>
				}></Section>
		</Box>
	);
};
