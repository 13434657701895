import { LocalStorageKey } from '../constants/localStorageKeys';
import { axiosGet } from './axiosService';

const riskAssessmentOptions = ['High', 'Medium', 'Low'];
const CollectionsURL = 'Collections';
const getInvestmentTypes = 'buckets/collection';

export class DictionaryService {
	getRiskRatingOptions() {
		return riskAssessmentOptions;
	}

	getRiskRatingAdditionalOptions() {
		return [...riskAssessmentOptions, 'Not Applicable'];
	}

	getRiskRatingAddionalLow() {
		return [...riskAssessmentOptions, 'Low (Lenders)'];
	}

	getInvestorTypes = () => {
		return axiosGet(null, `${CollectionsURL}/InvestorType`);
	};

	getEmploymentStatus = () => {
		return axiosGet(null, `${CollectionsURL}/EmploymentStatus`);
	};

	getOccupations = () => {
		return axiosGet(null, `${CollectionsURL}/Occupation`);
	};

	getAnnualIncomes = () => {
		return axiosGet(null, `${CollectionsURL}/AnnualIncome`);
	};

	getPublicFunctions = () => {
		return axiosGet(null, `${CollectionsURL}/PublicFunction`);
	};

	getCountries = () => {
		return axiosGet(null, `${CollectionsURL}/Country`);
	};

	getIndustries = () => {
		return axiosGet(null, `${CollectionsURL}/Industry`);
	};

	getInvestmentSources = () => {
		return axiosGet(null, `${CollectionsURL}/SourceOfInvestment`);
	};

	getHearAboutUses = () => {
		return axiosGet(null, `${CollectionsURL}/HearUsItem`);
	};

	getInvestmentTypes = () => {
		return axiosGet(null, getInvestmentTypes);
	};

	getCountryRegistrations = () => {
		return axiosGet(null, `${CollectionsURL}/CountryRegistration`);
	};

	getWhyNotQuestions = () => {
		return axiosGet(null, `${CollectionsURL}/CrsWhyNotQuestion`);
	};

	getFinanceTerms = () => {
		return axiosGet(null, `${CollectionsURL}/FinancingTerm`);
	};

	getRevenue() {
		return axiosGet(null, `${CollectionsURL}/BorrowerRevenues`);
	}

	getYearOfOperation = () => {
		return axiosGet(null, `${CollectionsURL}/YearsOperation`);
	};

	getNumberOfBanks = () => {
		return axiosGet(null, `${CollectionsURL}/NumberOfBanks`);
	};

	getPropertyOwnership = () => {
		return axiosGet(null, `${CollectionsURL}/PropertyOwnership`);
	};

	getNumberOfChildren = () => {
		return axiosGet(null, `${CollectionsURL}/NumberOfChildren`);
	};

	getMaritalStatus = () => {
		return axiosGet(null, `${CollectionsURL}/MaritalStatus`);
	};

	getBorrowerTypes = () => {
		return axiosGet(null, `${CollectionsURL}/BorrowerType`);
	};

	getBorrowerIndustries = () => {
		return axiosGet(null, `${CollectionsURL}/Industry`);
	};
	getHeadCounts = () => {
		return axiosGet(null, `${CollectionsURL}/HeadCount`);
	};
	getCustomerProfiles = () => {
		return axiosGet(null, `${CollectionsURL}/CustomerProfile`);
	};
	getCompanyTypes = () => {
		return axiosGet(null, `${CollectionsURL}/companyTypes`);
	};
	getBorrowerRegistrationPlaces = () => {
		return axiosGet(null, `${CollectionsURL}/borrowerRegistrationPlaces`);
	};
	getCities = () => {
		return axiosGet(null, `${CollectionsURL}/Cities`);
	};

	getLoanCollaterals() {
		return axiosGet(null, `${CollectionsURL}/loanCollaterals`);
	}
	getAccountTypes() {
		return axiosGet(null, `${CollectionsURL}/AccountType`);
	}

	getAdmins() {
		return axiosGet(null, 'Authentication/AdminsCollection');
	}

	getAllInvestorCollection() {
		return axiosGet(null, `${CollectionsURL}/AllForInvestor`);
	}

	getAllBorrowerCollection() {
		return axiosGet(null, `${CollectionsURL}/AllForBorrower`);
	}

	getInvestorCollectionLocally() {
		let collection =
			localStorage.getItem(LocalStorageKey.investorsCollections) || null;
		if (collection) {
			return JSON.parse(collection);
		}
		return collection;
	}

	getBorrowerCollectionLocally() {
		let collection =
			localStorage.getItem(LocalStorageKey.borrowersCollections) || null;
		if (collection) {
			return JSON.parse(collection);
		}
		return collection;
	}
}
