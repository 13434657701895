import { RegionService } from '../services/regionService';
import { RegionTypes } from './regions';

const regionService = new RegionService();
const IS_SA = regionService.currentRegion() === RegionTypes.SaudiArabia;

const CYNOPSIS_URL = process.env.REACT_APP_CYNOPSIS_URL;
const WEBSITE_URL = `${process.env.REACT_APP_WEBSITE_URL}${
	IS_SA ? 'sa' : 'ae'
}/`;
const IS_PROD = process.env.REACT_APP_ENV === 'prod' ? true : false;
const OTP_TIMEOUT = 60;
const MIN_INVESTMENT = 10;

const API_KEY = IS_SA
	? process.env.REACT_APP_API_URL_SA
	: process.env.REACT_APP_API_URL;
const CURRENCY = IS_SA
	? process.env.REACT_APP_CURRENCY_SA
	: process.env.REACT_APP_CURRENCY;
const SOUQ_BANK_DETAILS = {
	souqBankName: IS_SA
		? process.env.REACT_APP_SOUQ_BANK_NAME_SA
		: process.env.REACT_APP_SOUQ_BANK_NAME,
	souqBankAddress: IS_SA
		? process.env.REACT_APP_SOUQ_BANK_ADDRESS_SA
		: process.env.REACT_APP_SOUQ_BANK_ADDRESS,
	souqAccountName: IS_SA
		? process.env.REACT_APP_SOUQ_ACCOUNT_NAME_SA
		: process.env.REACT_APP_SOUQ_ACCOUNT_NAME,
	souqIBAN: IS_SA
		? process.env.REACT_APP_SOUQ_IBAN_SA
		: process.env.REACT_APP_SOUQ_IBAN,
	souqSwiftCode: IS_SA
		? process.env.REACT_APP_SOUQ_SWIFT_CODE_SA
		: process.env.REACT_APP_SOUQ_SWIFT_CODE,
	souqAccountNumber: IS_SA
		? process.env.REACT_APP_SOUQ_ACCOUNT_NUMBER_SA
		: process.env.REACT_APP_SOUQ_ACCOUNT_NUMBER,
};

const BASE_URLS = {
	en: process.env.REACT_APP_BASE_URL_EN,
	sa: process.env.REACT_APP_BASE_URL_SA,
};

const OLD_APP_URL = IS_SA
	? process.env.REACT_APP_OLD_APP_URL_SA
	: process.env.REACT_APP_OLD_APP_URL_EN;

export {
	API_KEY,
	CYNOPSIS_URL,
	WEBSITE_URL,
	CURRENCY,
	IS_SA,
	IS_PROD,
	SOUQ_BANK_DETAILS,
	OTP_TIMEOUT,
	MIN_INVESTMENT,
	BASE_URLS,
	OLD_APP_URL,
};
