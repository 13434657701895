import { FormikProvider, useFormik } from 'formik';
import { ResetPasswordSchema } from '../../../shared/validations/schema';
import { Box, IconButton, Stack } from '@mui/material';
import { RegistrationNavBar } from '../../../pages/registration/components/registrationNavBar';
import { TextWrapper } from '../../../shared/components/formUtilities/useTextWrapper';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../../shared/components/formUtilities/useInput';
import { ButtonWrapper } from '../../../shared/components/formUtilities/useButton';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FormHelper } from '../../../helpers/formHelper';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Toaster } from '../../../shared/components/common/toaster';
import { resetPassword } from '../../../services/authService';
import { buildLocalizeRoute } from '../../../helpers/languageHelper';
import { AppRoutes } from '../../../constants/appRoutes';
import { PasswordValidationPopover } from '../../../shared/components/common/passwordValidationPopover';

export const ResetPassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const [showErrorPopover, setShowErrorPopover] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);

	const form = useFormik({
		initialValues: {
			userId: null,
			password: null,
			passwordConfirmation: null,
			code: null,
		},
		onSubmit: () => {
			onResetPassword();
		},
		validationSchema: ResetPasswordSchema,
		enableReinitialize: true,
		validateOnChange: true,
	});

	useEffect(() => {
		form.setFieldValue('userId', searchParams.get('userId') ?? null);
		let code = decodeURIComponent(searchParams.get('code'))
			.split(' ')
			.join('+');
		form.setFieldValue('code', code);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onResetPassword = () => {
		let formValues = form.values;
		if (formValues?.code && formValues?.userId) {
			setLoading(true);
			resetPassword(formValues)
				.then((resp) => {
					Toaster({
						msg: t('resetPasswordSuccessMsg'),
						isError: false,
					});
					setTimeout(() => {
						setLoading(false);
						navigate(buildLocalizeRoute(AppRoutes.Login));
					}, 2000);
				})
				.catch((err) => {
					setLoading(false);
				});
		} else {
			Toaster({ msg: 'Unauthorized Activity Detected' });
		}
	};

	return (
		<>
			<RegistrationNavBar isLogin={true}></RegistrationNavBar>
			<div className="flex  flex-col flex-auto items-center justify-center p-4 min-w-0 md:h-[calc(100%-130px)] auth-card-wrapper bg-white">
				<div className="w-full relative flex sm:w-auto rounded-2xl shadow-md bg-card bg-white">
					<div className="w-full md:p-12 p-8 pt-12 max-w-[400px] sm:w-[400px] mx-auto sm:mx-0">
						<div className="w-full flex flex-col flex-0 relative">
							<TextWrapper
								classList={`!text-center !text-center`}
								type="h6"
								text={t('resetYourPassword')}
							/>
						</div>
						<FormikProvider value={form}>
							<Stack
								className="text-center mt-6"
								gap={2}>
								<FormikProvider value={form}>
									<Stack gap={2}>
										<Box className="relative">
											<PasswordValidationPopover
												controllerValue={form.values.password}
												showErrorPopover={showErrorPopover}
											/>
											<InputWrapper
												onFocus={() => setShowErrorPopover(true)}
												onBlur={() => setShowErrorPopover(false)}
												endIcon={
													<IconButton
														onClick={() => {
															setShowPassword(!showPassword);
														}}
														className="!bg-transparent text-primary">
														{!showPassword ? (
															<VisibilityOffOutlinedIcon />
														) : (
															<VisibilityOutlinedIcon />
														)}
													</IconButton>
												}
												type={showPassword ? 'text' : 'password'}
												label={t('createAPassword')}
												name="password"
												key={'password'}></InputWrapper>
										</Box>

										<InputWrapper
											endIcon={
												<IconButton
													onClick={() => {
														setShowPasswordConfirm(!showPasswordConfirm);
													}}
													className="!bg-transparent text-primary">
													{!showPasswordConfirm ? (
														<VisibilityOffOutlinedIcon />
													) : (
														<VisibilityOutlinedIcon />
													)}
												</IconButton>
											}
											type={showPasswordConfirm ? 'text' : 'password'}
											label={t('confirmPassword')}
											name="passwordConfirmation"
											key={'passwordConfirmation'}></InputWrapper>
										<ButtonWrapper
											loading={loading}
											onClick={() => {
												FormHelper.validateForm(form);
											}}
											disabled={!form.isValid}
											icon={<CheckIcon></CheckIcon>}
											classList="!w-full"
											label={t('resetPassword')}></ButtonWrapper>
										<Box className="text-center mt-1 text-[14px]">
											<span>{t('backTo')}</span>
											<span
												onClick={() =>
													navigate(buildLocalizeRoute(AppRoutes.Login))
												}
												className="underline-link text-primary mx-1">
												{t('signIn')}
											</span>
										</Box>
									</Stack>
								</FormikProvider>
							</Stack>
						</FormikProvider>
					</div>
				</div>
			</div>
		</>
	);
};
