import { Box, Card } from '@mui/material';

export const Section = ({
	isShadow = true,
	children = null,
	classList = '',
	hide = false,
	sx = {},
	onClick = () => {},
}) => {
	return (
		<>
			{!hide ? (
				<Box
					sx={{ ...sx }}
					onClick={() => onClick && onClick()}
					className={`p-4 ${classList ? classList : ''}`}
					component={isShadow ? Card : 'div'}>
					{children}
				</Box>
			) : null}
		</>
	);
};
