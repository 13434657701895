
import { currencyFormatterHelper } from '../../../../../../helpers/currencyFormatterHelper';
import { DateHelper } from '../../../../../../helpers/dateHelper';

export const initializeColumns = (t) => {
	return [
		{
			header: t('period'),
			accessorKey: 'number',
		},
		{
			header: t('dueDate'),
			accessorKey: 'dueDate',
			Cell: ({ cell }) => {
				return DateHelper.formatDate(cell?.getValue(), 'MMM dd, yyyy hh:mm');
			},
		},

		{
			header: t('amount'),
			accessorKey: 'amount',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
		},
		{
			header: t('total'),
			accessorKey: 'total',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
        },
        {
			header: t('principal'),
			accessorKey: 'principal',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
        },
        {
			header: t('investorProfit'),
			accessorKey: 'investorProfit',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
		},
		{
			header: t('status'),
			accessorKey: 'status',
		},
	];
};
