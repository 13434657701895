/* eslint-disable no-unused-vars */
import { Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseRegistration } from '../../../core/baseRegistration';
import { useState } from 'react';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { Logout, loginUsingToken } from '../../../../../services/authService';
import { Toaster } from '../../../../../shared/components/common/toaster';
import { UserService } from '../../../../../services/userService';
import { useEffect } from 'react';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { EligibilityChecker } from '../eligibilityChecker';
import { LocalStorageKey } from '../../../../../constants/localStorageKeys';
import { IS_SA } from '../../../../../config/appConfig';

export const BorrowerBaseRegistration = ({
	regCountryList = [],
	isSA = IS_SA,
}) => {
	const userService = new UserService();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [showOTPAuth, setShowOTPAuth] = useState(false);

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [email, setEmail] = useState(null);
	const [showEligibility, setShowEligibility] = useState(
		sessionStorage.getItem(LocalStorageKey.isEligibilityCompleted) !== '1'
	);

	useEffect(() => {
		setEmail(searchParams.get('email'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams.get('email')]);

	const onNavigateToNextScreen = () => {
		let route = IS_SA
			? AppRoutes.IRegNafathVerification
			: AppRoutes.BRegPersonalInfo;
		navigate(buildLocalizeRoute(`${AppRoutes.BorrowerRegistartion}/${route}`));
	};

	const onSubmit = (values) => {
		if (!values.termsConditionsForInvestmentsAccepted) {
			Toaster({ msg: t('termsAndConditionsRequired') });
			return;
		}

		setLoading(true);
		userService
			.registerBorrower(values)
			.then((resp) => {
				sessionStorage.setItem(LocalStorageKey.isEligibilityCompleted, '0');
				let tokenModel = resp.data;
				if (tokenModel) {
					setLoading(false);
					loginUsingToken(tokenModel.token);
					if (tokenModel.otpRequested) {
						setShowOTPAuth(true);
					} else {
						onNavigateToNextScreen();
					}
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<Box className="justify-center items-center flex">
			{!showEligibility ? (
				<Box className="reregistration-wrapper m-auto flex-col overflow-auto md:p-2">
					<Box
						component={Card}
						className="registration-card !rounded-lg md:!px-10 md:!py-8 md:w-[430px] w-full">
						<h1 className="text-[24px] font-[500] mb-6 !text-center">
							{t('borrowerForRegistration')}
						</h1>
						<BaseRegistration
							defaultEmail={email}
							loading={loading}
							clientType={'borrower'}
							onSubmitForm={(values) => {
								onSubmit(values);
							}}></BaseRegistration>

						{showOTPAuth ? (
							<OTPAuthenticationDialog
								type={OTPTypes.Authentication}
								onClose={(isVerified) => {
									setShowOTPAuth(false);
									if (isVerified) {
										onNavigateToNextScreen();
									} else {
										Logout();
										navigate(buildLocalizeRoute(AppRoutes.Login));
									}
								}}
								state={showOTPAuth}></OTPAuthenticationDialog>
						) : null}
					</Box>
				</Box>
			) : (
				<EligibilityChecker
					isSA={isSA}
					regCountryList={regCountryList}
					onSubmit={() => {
						setShowEligibility(false);
					}}
				/>
			)}
		</Box>
	);
};
