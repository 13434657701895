/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { InvestorTypeHeader } from '../../../../components/investorTypeHeader';
import { useEffect, useState } from 'react';
import { InvestorType } from '../../../../../../constants/investorType';
import { PersonalInfoGlobal } from './personalInfoGlobal';
import { PersonalInformationExtra } from '../../personalInformationExtra';
import { InvestorBusinessInformation } from '../../businessInformation/global';
import { useSearchParams } from 'react-router-dom';
import { getRegistrationData } from '../../../../../../services/authService';

const FormSubSteps = {
	personalInfo: 1,
	businessOrExtra: 2,
};

export const PersonalInfoGlobalIndex = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeType, setActiveType] = useState(null);
	const [currentStep, setCurrentStep] = useState(FormSubSteps.personalInfo);
	const [isBack, setIsBack] = useState(false);

	const isCorporate = () => {
		return activeType?.name === InvestorType.Corporate;
	};

	const onTop = () => {
		window.scrollTo({
			behavior: 'smooth',
			top: 0,
			left: 0,
		});
	};

	useEffect(() => {
		let step = searchParams.get('step');
		const regStep = getRegistrationData()?.step;
		if (
			step &&
			!isNaN(parseInt(step)) &&
			parseInt(step) <= 2 &&
			regStep === 2
		) {
			setCurrentStep(parseInt(step));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const removeParams = () => {
		if (searchParams.has('step')) {
			searchParams.delete('step');
			setSearchParams(searchParams);
		}
	};

	return (
		<Box>
			<InvestorTypeHeader
				isBack={isBack}
				onSelectInvestorType={(type) => {
					setIsBack(false);
					setActiveType(type);
				}}>
				{currentStep === FormSubSteps.personalInfo ? (
					<PersonalInfoGlobal
						onBack={() => {
							setIsBack(true);
						}}
						investorType={activeType}
						onSubmit={() => {
							onTop();
							setCurrentStep(FormSubSteps.businessOrExtra);
						}}
					/>
				) : currentStep === FormSubSteps.businessOrExtra ? (
					!isCorporate() ? (
						<PersonalInformationExtra
							onGoBackKSA={() => {
								setIsBack(true);
							}}
							onGoBack={() => {
								removeParams();
								onTop();
								setCurrentStep(FormSubSteps.personalInfo);
							}}
						/>
					) : (
						<InvestorBusinessInformation
							onGoBack={() => {
								removeParams();
								onTop();
								setCurrentStep(FormSubSteps.personalInfo);
							}}
						/>
					)
				) : null}
			</InvestorTypeHeader>
		</Box>
	);
};
