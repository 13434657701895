/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { UserService } from '../../../../../../services/userService';
import { UserDocumentTypes } from '../../../../../../constants/userDocuments';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateInvestorSteps,
} from '../../../../../../services/authService';
import { InvestorType } from '../../../../../../constants/investorType';
import { Box } from '@mui/material';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../../shared/components/common/arrowIcons';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import { InvestorRegService } from '../../../investorRegService';
import { IwillDoItLaterDialog } from '../../../../components/iwillDoItLaterPopup';
import { InvestorDeclarationDialog } from '../../declarationDialog';
import { CorporateDocumentUploader } from '../../../../../../shared/components/corporateDocumentUploader';
import { UploadPassportUploaderSection } from '../../../../../../shared/components/documentPassportUploader';
import { DocumentUploadSection } from '../../../../../../shared/components/documentUploadSection';
import { DocumentUploadIssueInfo } from '../../../../components/documentUploadIssueInfo';
import { TextWrapper } from '../../../../../../shared/components/formUtilities/useTextWrapper';
// import { DocumentChecklist } from '../../../../../../shared/components/documentChecklist';

export const InvestorDocumentVerificationGlobal = ({
	professional = false,
	corporate = false,
	isLoadingSubmit = false,
}) => {
	const userService = new UserService();
	const investorRegService = new InvestorRegService();

	const navigate = useNavigate();

	const [identifierDeatils, setIdentifierDeatils] = useState({});
	const [passportDeatils, setPassportDeatils] = useState({});
	const [addressProofDeatils, setAddressProofDeatils] = useState({});
	const [netWorthProofDeatils, setNetWorthProofDeatils] = useState({});
	const [allDocuments, setAllDocuments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(isLoadingSubmit);
	const [showIwilldoitlater, setShowIwilldoitlater] = useState(false);
	const [showDeclartionPopup, setDeclartionPopup] = useState(false);
	const [isCorporate, setIsCorporate] = useState(corporate);
	const [isProfessional, setIsProfessional] = useState(professional);
	const [isAllFileUploaded, setIsAllFileUploaded] = useState(false);
	const userDocumentTypes = UserDocumentTypes;

	const { t } = useTranslation();

	const addressProofURL = () => {
		return addressProofDeatils ? addressProofDeatils?.file : null;
	};

	const netWorthUrl = () => {
		return netWorthProofDeatils ? netWorthProofDeatils?.file : null;
	};

	const passportUrl = () => {
		return passportDeatils ? passportDeatils?.file : null;
	};

	const identifierBackUrl = () => {
		return identifierDeatils ? identifierDeatils?.additionalFile : null;
	};

	const isDocumentUploaded = () => {
		if (isProfessional) {
			return (
				addressProofURL() &&
				netWorthUrl() &&
				(passportUrl() || identifierBackUrl())
			);
		}

		if (isCorporate) {
			return isAllFileUploaded;
		}

		return addressProofURL() && (passportUrl() || identifierBackUrl());
	};

	useEffect(() => {
		getAllDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInvestorType = () => {
		return typeof getRegistrationData()?.investorType === 'object'
			? getRegistrationData()?.investorType?.name
			: getRegistrationData()?.investorType;
	};

	const getAllDocuments = () => {
		setIsLoading(true);
		userService
			.getAllDocuments()
			.then((resp) => {
				setAllDocuments(resp.data);
				setIsCorporate(getInvestorType() === InvestorType.Corporate);
				setIsProfessional(getInvestorType() === InvestorType.Professional);
				setFiles(resp.data);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const setFiles = (fileDetails) => {
		setPassportDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorPassport)
		);

		setIdentifierDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorIdentifier)
		);
		setAddressProofDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorAddressProof)
		);
		setNetWorthProofDeatils(
			getFilteredDocuments(fileDetails, userDocumentTypes.InvestorNetWorth)
		);
	};

	const getFilteredDocuments = (fileDetails = [], type) => {
		return fileDetails.filter((data) => data.type === type)[0] || null;
	};

	const onBack = () => {
		let regData = {
			...getRegistrationData(),
			requestedStep: 1,
		};
		setRegistrationData(regData);
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.InvestorRegistration}/${AppRoutes.IRegPersonalInfo}`
			)
		);
	};

	const onSubmit = () => {
		if (!isDocumentUploaded()) {
			setShowIwilldoitlater(true);
		} else {
			setLoadingSubmit(true);
			investorRegService
				.submitDocuments()
				.then((resp) => {
					setLoadingSubmit(false);
					let registrationModel = resp.data?.registrationModel;
					saveTokenFromRegistration(resp.data?.loginModel?.token);
					let regData = {
						...getRegistrationData(),
						step: registrationModel.step,
						requestedStep: 2,
					};
					setRegistrationData(regData);
					updateInvestorSteps(registrationModel?.step);
					redirectToNextStep();
				})
				.catch(() => {
					setLoadingSubmit(false);
				});
		}
	};

	const redirectToNextStep = () => {
		setDeclartionPopup(true);
	};

	const onCloseIwillDoIt = (action) => {
		setShowIwilldoitlater(false);
		if (action === 'skip') {
			let regData = {
				...getRegistrationData(),
				requestedStep: 2,
			};
			setRegistrationData(regData);
			redirectToNextStep();
		}
	};

	return (
		<Box className="flex flex-col space-y-8">
			<Box className={'flex flex-col space-y-4'}>
				{/* <DocumentChecklist investorType={getInvestorType()} /> */}
				{isCorporate ? (
					<CorporateDocumentUploader
						onAllUploaded={(value) => {
							setIsAllFileUploaded(value);
						}}></CorporateDocumentUploader>
				) : (
					<>
						<UploadPassportUploaderSection
							testId={userDocumentTypes.InvestorPassport}
							onFileUploaded={(e) => {
								setIdentifierDeatils(e?.identifierFile);
								setPassportDeatils(e?.passportFile);
							}}
							identifierFile={identifierDeatils}
							passportFile={passportDeatils}
							isLoading={isLoading}></UploadPassportUploaderSection>
						<DocumentUploadSection
							subPoints={'dragProofofAddresstouploadPoint'}
							onRemoveFile={() => {
								setAddressProofDeatils(null);
							}}
							onUploadedFiles={(e) => {
								setAddressProofDeatils(e?.file);
							}}
							showOTPAuth={true}
							fileName="dragProofofAddresstoupload"
							file={addressProofDeatils}
							isLoading={isLoading}
							fileType={
								userDocumentTypes.InvestorAddressProof
							}></DocumentUploadSection>
						{isProfessional ? (
							<DocumentUploadSection
								onRemoveFile={() => {
									setNetWorthProofDeatils(null);
								}}
								onUploadedFiles={(e) => {
									setNetWorthProofDeatils(e?.file);
								}}
								subPoints={'dragProofofProofNetWorthPoint'}
								showOTPAuth={true}
								fileName="dragProofofProofNetWorth"
								file={netWorthProofDeatils}
								isLoading={isLoading}
								fileType={
									userDocumentTypes.InvestorNetWorth
								}></DocumentUploadSection>
						) : null}
					</>
				)}
			</Box>

			<Box className="flex md:justify-between justify-center">
				<ButtonWrapper
					dataTestID="backBtn"
					classList="!secondary-btn md:!mx-0 !mx-2"
					disabled={loadingSubmit}
					largeBtn
					icon={<ArrowIcons isInvert></ArrowIcons>}
					label={t('back')}
					onClick={() => {
						onBack();
					}}
				/>
				<ButtonWrapper
					dataTestID="nextBtn"
					classList={'primary-bg'}
					loading={loadingSubmit}
					largeBtn
					endIcon={<ArrowIcons></ArrowIcons>}
					label={t('next')}
					onClick={() => {
						onSubmit();
					}}
				/>
				{showIwilldoitlater ? (
					<IwillDoItLaterDialog
						testId="iwilldoitlaterId"
						isBorrower={false}
						isProfessional={isProfessional}
						isCorporate={isCorporate}
						state={showIwilldoitlater}
						onClose={(e) => {
							onCloseIwillDoIt(e);
						}}></IwillDoItLaterDialog>
				) : null}
			</Box>
			<DocumentUploadIssueInfo />
			{showDeclartionPopup ? (
				<InvestorDeclarationDialog
					state={showDeclartionPopup}
					onClose={() => {
						setDeclartionPopup(false);
					}}></InvestorDeclarationDialog>
			) : null}
		</Box>
	);
};
