import { Box, FormGroup } from '@mui/material';
import { useField } from 'formik';
import { TextWrapper } from './useTextWrapper';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { LanguageService } from '../../../services/languageService';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

export const InternationalPhone = ({
	phoneWithCode,
	preferredCountries = ['sa', 'ae'],
	defaultCountry = 'sa',
	dataTestID = '',
	errorStateID = '',
	...props
}) => {
	const { t } = useTranslation();
	const languageService = new LanguageService();
	const inputRef = useRef(null);
	const [field, meta, helper] = useField(props);
	let phoneNumber = {
		dialCode: phoneWithCode ? phoneWithCode.code : '',
		number: phoneWithCode
			? `${phoneWithCode.code} ${phoneWithCode.number}`
			: '',
	};

	return (
		<FormGroup>
			<Box className="flex flex-col items-start">
				<Box className="flex flex-0 items-center relative">
					<TextWrapper
						type="caption"
						text={t(props?.label)}></TextWrapper>
					{meta.error ? (
						<span
							className={`!text-red-500 absolute right-[-8px] ${
								languageService.isArabic() ? '!left-[-8px] !right-auto' : ''
							}`}>
							*
						</span>
					) : null}
				</Box>
				<PhoneInput
					onClick={() => {
						if (!field.value?.number && languageService.isArabic()) {
							let codeLength = inputRef.current.value + 1;
							inputRef.current.setSelectionRange(codeLength, codeLength);
						}
					}}
					containerClass={meta.error && meta.touched ? 'error-phone-input' : ''}
					country={defaultCountry}
					preferredCountries={preferredCountries}
					inputClass={`!w-full !h-[40px] !rounded-[5px]  ${
						meta.error && meta.touched ? '!border-red-500' : ''
					} ${languageService.isArabic() ? '!text-end' : ''}`}
					inputProps={{
						name: field.name,
						required: true,
						'data-testid': dataTestID,
						ref: inputRef,
					}}
					dropdownClass="!text-start "
					enableSearch={true}
					// autoFormat={true}
					disableCountryGuess={false}
					countryCodeEditable={false}
					onChange={(phone, data, e, formattedValue) => {
						let formattedPhone = phone.substring(data.dialCode?.length);
						let phoneWithCode = {
							code: data.dialCode,
							number: formattedPhone,
						};
						helper.setValue(phoneWithCode);
					}}
					className={`${props.classList} ${
						languageService.isArabic() ? 'arabic-phone-input' : ''
					}`}
					value={phoneNumber.number}
				/>
				{meta.error && meta.touched ? (
					<p
						data-testid={errorStateID}
						className="text-[#d32f2f] !text-[0.75rem] my-[4px] mx-[14px] !mb-0">
						{typeof meta.error === 'object'
							? t(meta.error?.number)
							: t(meta.error)}
					</p>
				) : null}
			</Box>
		</FormGroup>
	);
};
