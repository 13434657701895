import { Box } from '@mui/material';
import { DialogWrapper } from '../../../../shared/components/common/dialogWrapper';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import { useEffect } from 'react';
import { useState } from 'react';
import { ConfirmationDialogHelpers } from '../../../../helpers/confirmationDialogHelper';
import { useTranslation } from 'react-i18next';

export const ConfirmAutoInvestDialog = ({
	state,
	bucket,
	onClose = (e) => {},
}) => {
	const [options, setOptions] = useState({});
	const { t } = useTranslation();
	useEffect(() => {
		const tempOptions = !bucket
			? ConfirmationDialogHelpers.getManualBucketOptions(t)
			: ConfirmationDialogHelpers.getBucketOptions(t, bucket);
		setOptions(tempOptions.data);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bucket]);

	const getMessage = () => {
		return options?.message;
	};

	return (
		<DialogWrapper
			hideCloseBtn={true}
			maxWidth="xs"
			width={'400px'}
			state={state}
			centerHeading={true}
			title={options?.title}
			onClose={() => {
				onClose(false);
			}}
			content={
				<>
					<Box className="flex justify-center items-center">
						<p
							dangerouslySetInnerHTML={{
								__html: `${getMessage()}`,
							}}
							className="!sub-title-text text-center"></p>
					</Box>
				</>
			}
			actions={
				<Box className="flex items-center justify-center w-full">
					<ButtonWrapper
						classList="!w-full"
						label={options?.positiveText}
						onClick={() => {
							onClose(true);
						}}></ButtonWrapper>
					<ButtonWrapper
						classList="!mx-2 !w-full"
						variant={'outlined'}
						label={options?.negativeText}
						onClick={() => {
							onClose(false);
						}}></ButtonWrapper>
				</Box>
			}></DialogWrapper>
	);
};
