import { Outlet } from 'react-router-dom';
import { RegistrationNavBar } from '../components/registrationNavBar';
import { Box } from '@mui/material';
import { BorrowerStepperProvider } from '../../../context/borrowerStepperContext';

export const BorrowerRegistration = () => {
	return (
		<>
			<BorrowerStepperProvider>
				<RegistrationNavBar></RegistrationNavBar>
				<Box className="flex justify-center items-center">
					<Box className="xl:w-[1140px] w-full md:my-8 m-4">
						<Outlet />
					</Box>
				</Box>
			</BorrowerStepperProvider>
		</>
	);
};
