import { axiosGet, axiosPost } from '../../../services/axiosService';

const borrowerBaseURI = 'Borrowers';

const borrowerRegistrationDataURI = 'Borrowers/Me/RegistrationData';
const borrowerProfilePersonalInfoURI = 'Borrowers/Me/PersonalInformation';
const borrowerProfileBusinessInfoURI = 'Borrowers/Me/BusinessInformation';
const borrowerProfileFinancialInfoURI = 'Borrowers/Me/FinancialInformation';

export class BorrowerRegService {
	getBorrowerRegistrationData() {
		return axiosGet(null, borrowerRegistrationDataURI);
	}

	getBorrowerProfilePersonalInfo() {
		return axiosGet(null, borrowerProfilePersonalInfoURI);
	}

	registerBorrowerPersonalInfo(form) {
		return axiosPost(form, `${borrowerBaseURI}/personalinformation`);
	}

	getBorrowerProfileBusinessInfo() {
		return axiosGet(null, borrowerProfileBusinessInfoURI);
	}

	registerBorrowerBusinessInfo(form) {
		return axiosPost(form, `${borrowerBaseURI}/businessinformation`);
	}

	getBorrowerProfileFinancialInfo() {
		return axiosGet(null, borrowerProfileFinancialInfoURI);
	}

	registerBorrowerFinancialInfo(form) {
		return axiosPost(form, `${borrowerBaseURI}/financialinformation`);
	}

	registerBorrowerDeclaration(declaration = []) {
		return axiosPost(declaration, `${borrowerBaseURI}/declaration`);
	}

	registerCRNumber(crNumber) {
		return axiosPost(
			crNumber,
			`${borrowerBaseURI}/CreateBusinessInformationFromWathq`
		);
	}

	requestWathq() {
		return axiosGet(
			null,
			`${borrowerBaseURI}/RequestBusinessInformationFromWathq`
		);
	}
}
