import { Box } from '@mui/material';
import { ButtonWrapper } from '../formUtilities/useButton';
import { DialogWrapper } from '../common/dialogWrapper';
import { useTranslation } from 'react-i18next';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { FileUploadWrapper } from '../common/fileUploadWrapper';
import { useState } from 'react';
import { UserService } from '../../../services/userService';
import { UserDocumentTypes } from '../../../constants/userDocuments';

export const UploadIBANDocument = ({ state, onClose = (e) => {} }) => {
	const { t } = useTranslation();
	const userService = new UserService();
	const [iBanDetails, setIBanDetails] = useState({});
	const uploadFileType = ['.jpeg', '.png', '.pdf'];
	const [loading, setLoading] = useState(false);

	const onSubmit = () => {
		if (iBanDetails?.file?.url) {
			onClose(true);
		}
	};

	const iBanUrl = () => {
		if (!iBanDetails) {
			return null;
		}

		return iBanDetails?.file;
	};

	const uploadIban = (file) => {
		setLoading(true);
		userService
			.uploadUserDocuments(file, UserDocumentTypes.InvestorIban)
			.then((resp) => {
				setLoading(false);
				setIBanDetails(resp.data);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<DialogWrapper
				maxWidth="xs"
				centerHeading={true}
				width={'380px'}
				state={state}
				onClose={() => {
					onClose(false);
				}}
				title={t('uploadIbanDocument')}
				actionFooterClass="!px-0"
				content={
					<Box className="grid grid-flow-row gap-3">
						<FileUploadWrapper
							loading={loading}
							titleText={t('DragIbanToUpload')}
							uploadFileType={uploadFileType}
							onRemoveFile={() => {
								setIBanDetails(null);
							}}
							fileName={iBanUrl()?.name}
							fileExtension={iBanUrl()?.extension}
							imageURL={iBanUrl()?.url}
							onUpload={(file) => {
								uploadIban(file);
							}}></FileUploadWrapper>
					</Box>
				}
				actions={
					<Box className="!w-full text-center">
						<ButtonWrapper
							disabled={!iBanDetails?.file?.url}
							icon={<CloudUploadOutlinedIcon></CloudUploadOutlinedIcon>}
							type="button"
							loading={loading}
							onClick={() => {
								onSubmit();
							}}
							label={t('upload&continue')}></ButtonWrapper>
					</Box>
				}></DialogWrapper>
		</>
	);
};
