import { Box } from '@mui/material';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogWrapper } from '../../../../../../shared/components/common/dialogWrapper';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';

export const WithdrawWarningDialog = ({ state, onClose = (e) => {} }) => {
	const { t } = useTranslation();
	return (
		<DialogWrapper
			hideCloseBtn={true}
			maxWidth="xs"
			state={state}
			title={t('withdrawalRequest')}
			onClose={() => {
				onClose(false);
			}}
			content={
				<Box className="flex flex-col justify-center">
					<p className="text-center !text-[14px]">
						<span>{t('withdrawalRequestText1')}</span>
						&nbsp;<strong>$30</strong>.&nbsp;
						<span>{t('withdrawalRequestText2')}</span>
					</p>
				</Box>
			}
			actions={
				<Box className="flex items-center space-x-2">
					<ButtonWrapper
						icon={<CloseIcon />}
						variant={'outlined'}
						label={t('cancel')}
						onClick={() => {
							onClose(false);
						}}></ButtonWrapper>
					<ButtonWrapper
						icon={<CheckIcon />}
						label={t('withdraw')}
						onClick={() => {
							onClose(true);
						}}></ButtonWrapper>
				</Box>
			}></DialogWrapper>
	);
};
