import i18next from 'i18next';
import { signOut } from '../auth/authUtilities';
import { AvailableLanguages } from '../config/languages';
import { RegionTypes, Regions } from '../config/regions';
import { AppRoutes } from '../constants/appRoutes';
import { buildLocalizeRoute } from '../helpers/languageHelper';
import { LocalStorageKey } from '../constants/localStorageKeys';

export class RegionService {
	currentRegion() {
		return localStorage.getItem('region');
	}

	verifyRegion(region) {
		return Regions.find((reg) => reg.code === region)?.code || false;
	}

	onChangeRegion(region, language = null) {
		if (this.currentRegion() === region && !language) {
			return;
		}

		this.onBeforeLangChange();
		let regionName = region;
		if (!this.verifyRegion(region)) {
			localStorage.setItem('region', '');
			regionName = RegionTypes.Global;
		}

		if (regionName !== this.currentRegion()) {
			sessionStorage.setItem(LocalStorageKey.isEligibilityCompleted, '0');
			localStorage.setItem('region', regionName);

			let redirectURL = `${window.location.pathname
				.split('/')
				.slice(3)
				.join('/')}${window.location.search}`;

			if (localStorage.getItem(LocalStorageKey.token)) {
				redirectURL = AppRoutes.Login;
				signOut();
			}

			let loginUrl = buildLocalizeRoute(
				redirectURL,
				regionName,
				false,
				language
			);
			window.location.replace(loginUrl);
		} else {
			this.onChangeLanguage(language);
		}
	}

	onBeforeLangChange() {
		localStorage.setItem(LocalStorageKey.investorsCollections, null);
		localStorage.setItem(LocalStorageKey.borrowersCollections, null);
	}

	onChangeLanguage(lang) {
		let pathArray = window.location.pathname.split('/');
		let isAvailable = AvailableLanguages.find(
			(language) => language.lang === lang
		);
		if (isAvailable) {
			i18next.changeLanguage(lang);
			pathArray[2] = lang;
		} else {
			pathArray[2] = AvailableLanguages[0].lang;
			i18next.changeLanguage(AvailableLanguages[0].lang);
		}
		document.body.dir = i18next.dir(lang);
		let newURL = pathArray.join('/');
		window.location.replace(newURL);
	}
}
