import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Spacing } from './spacing';
import { Link, useLocation } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../helpers/languageHelper';
import { useState } from 'react';
import { useEffect } from 'react';

export default function ActionBarList({
	actionTabs = [],
	active = {},
	loading = false,
	actionButtons = null,
	fullWidthTab = false,
	isAutoFetchActiveTab = false,
}) {
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(active);

	useEffect(() => {
		const pathArray = location.pathname.split('/');
		let activePath = pathArray[pathArray.length - 1];
		if (isAutoFetchActiveTab && activePath) {
			let activeTab = actionTabs.find((resp) =>
				resp.relativePath.includes(activePath)
			);
			setActiveTab(activeTab);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const isSelected = (tabObj) => {
		if (tabObj?.path === activeTab?.path) {
			return true;
		}

		return false;
	};
	return (
		<>
			<Box>
				<Box className="grid grid-flow-row gap-1 md:grid-flow-col md:gap-6">
					{actionTabs.map((res, index) => {
						return (
							<Link
								to={buildLocalizeRoute(res?.path)}
								key={res?.path}
								className={`m-1 !no-underline ${
									!fullWidthTab ? 'w-full' : ''
								}`}>
								<Box
									className={`bg-white font-[500]  px-4 py-4 text-center shadow-sm rounded-md text-sm caption cursor-pointer hover:primary-bg hover:text-white ${
										isSelected(res) ? '!primary-bg !text-white shadow-lg' : ''
									}`}>
									<Box className="flex items-center flex-0 justify-center">
										{isSelected(res) ? (
											<CheckCircleOutlineIcon className="text-white !text-[20px]" />
										) : (
											''
										)}
										<Box className="ml-1 mr-1 whitespace-nowrap !text-[16px] !font-[600]">
											{res?.name}
										</Box>
									</Box>
								</Box>
							</Link>
						);
					})}
					{actionButtons}
				</Box>
			</Box>
			<Spacing />
		</>
	);
}
