import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
export const Toaster = ({ msg = null, isError = true }) => {
	toastr.options = {
		hideDuration: 300,
		timeOut: 6000,
		progressBar: true,
		rtl: localStorage.getItem('i18nextLng') === 'ar',
		positionClass: 'toast-bottom-right',
		toastClass: `${isError ? '!bg-red-500' : '!primary-bg'} !opacity-100`,
		closeOnHover: false,
		messageClass: '!text-[13px] direction-ltr-toast',
	};

	if (isError) {
		toastr.error(msg);
	} else {
		toastr.success(msg);
	}
};
