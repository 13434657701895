import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { InvestorType } from '../../../../../../constants/investorType';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateInvestorSteps,
} from '../../../../../../services/authService';
import { UserService } from '../../../../../../services/userService';
import { UserDocumentTypes } from '../../../../../../constants/userDocuments';
import { DocumentUploadSection } from '../../../../../../shared/components/documentUploadSection';
import { CorporateDocumentUploader } from '../../../../../../shared/components/corporateDocumentUploader';
import { InvestorRegService } from '../../../investorRegService';
import { useTranslation } from 'react-i18next';
import { DocumentUploadIssueInfo } from '../../../../components/documentUploadIssueInfo';
import { IwillDoItLaterDialog } from '../../../../components/iwillDoItLaterPopup';
import { InvestorDeclarationDialog } from '../../declarationDialog';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../../shared/components/common/arrowIcons';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import { useNavigate } from 'react-router-dom';
// import { DocumentChecklist } from '../../../../../../shared/components/documentChecklist';

export const InvestorDocumentVerificationKSA = ({
	corporate = false,
	isLoadingSubmit = false,
}) => {
	const userService = new UserService();
	const investorRegService = new InvestorRegService();

	const [loading, setLoading] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(isLoadingSubmit);
	const [isCorporate, setIsCorporate] = useState(corporate);
	const [proofEligibilityDetails, setProofEligibility] = useState({});
	const [showIwilldoitlater, setShowIwilldoitlater] = useState(false);
	const [showDeclartionPopup, setDeclartionPopup] = useState(false);
	const [isAllFileUploaded, setIsAllFileUploaded] = useState(false);

	const navigate = useNavigate();

	const { t } = useTranslation();

	useEffect(() => {
		getAllDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInvestorType = () => {
		return typeof getRegistrationData()?.investorType === 'object'
			? getRegistrationData()?.investorType?.name
			: getRegistrationData()?.investorType;
	};

	const getAllDocuments = () => {
		setLoading(true);
		userService
			.getAllDocuments()
			.then((resp) => {
				setIsCorporate(getInvestorType() === InvestorType.Corporate);
				setFiles(resp.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const setFiles = (fileDetails) => {
		setProofEligibility(
			getFilteredDocuments(
				fileDetails,
				UserDocumentTypes.InvestorProofOfEligibility
			)
		);
	};

	const getFilteredDocuments = (fileDetails = [], type) => {
		return fileDetails.filter((data) => data.type === type)[0] || null;
	};

	const onBack = () => {
		let regData = {
			...getRegistrationData(),
			requestedStep: 1,
		};
		setRegistrationData(regData);
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.InvestorRegistration}/${AppRoutes.IRegPersonalInfo}`
			)
		);
	};

	const onSubmit = () => {
		if (!isDocumentUploaded()) {
			setShowIwilldoitlater(true);
		} else {
			setLoadingSubmit(true);
			investorRegService
				.submitDocuments()
				.then((resp) => {
					setLoadingSubmit(false);
					let registrationModel = resp.data?.registrationModel;
					saveTokenFromRegistration(resp.data?.loginModel?.token);
					let regData = {
						...getRegistrationData(),
						step: registrationModel.step,
						requestedStep: 2,
					};
					setRegistrationData(regData);
					updateInvestorSteps(registrationModel?.step);
					redirectToNextStep();
				})
				.catch((err) => {
					setLoadingSubmit(false);
				});
		}
	};

	const redirectToNextStep = () => {
		setDeclartionPopup(true);
	};

	const onCloseIwillDoIt = (action) => {
		setShowIwilldoitlater(false);
		if (action === 'skip') {
			let regData = {
				...getRegistrationData(),
				requestedStep: 2,
			};
			setRegistrationData(regData);
			redirectToNextStep();
		}
	};

	const isDocumentUploaded = () => {
		if (isCorporate) {
			return isAllFileUploaded;
		}

		return proofEligibilityDetails ? proofEligibilityDetails?.file : null;
	};

	return (
		<Box className="flex flex-col space-y-8">
			<Box className={'flex flex-col space-y-4'}>
				{/* <DocumentChecklist investorType={getInvestorType()} /> */}
				{isCorporate ? (
					<>
						<CorporateDocumentUploader
							onAllUploaded={(value) => {
								setIsAllFileUploaded(value);
							}}></CorporateDocumentUploader>
					</>
				) : (
					<Box className="flex flex-col space-y-4">
						<DocumentUploadSection
							subPoints={[
								'dragProofofProofOfEligibilityPoint1',
								'dragProofofProofOfEligibilityPoint2',
								'dragProofofProofOfEligibilityPoint3',
								'dragProofofProofOfEligibilityPoint4',
							]}
							onRemoveFile={(e) => {
								setProofEligibility(null);
							}}
							onUploadedFiles={(e) => {
								setProofEligibility(e?.file);
							}}
							showOTPAuth={true}
							fileName="dragProofofProofOfEligibility"
							file={proofEligibilityDetails}
							isLoading={loading}
							fileType={
								UserDocumentTypes.InvestorProofOfEligibility
							}></DocumentUploadSection>
					</Box>
				)}
			</Box>
			<Box className="flex md:justify-between justify-center">
				<ButtonWrapper
					dataTestID="backBtn"
					classList="!secondary-btn md:!mx-0 !mx-2"
					disabled={loadingSubmit}
					largeBtn
					icon={<ArrowIcons isInvert></ArrowIcons>}
					label={t('back')}
					onClick={() => {
						onBack();
					}}
				/>
				<ButtonWrapper
					dataTestID="nextBtn"
					classList={'primary-bg'}
					loading={loadingSubmit}
					largeBtn
					endIcon={<ArrowIcons></ArrowIcons>}
					label={t('next')}
					onClick={() => {
						onSubmit();
					}}
				/>
			</Box>
			<DocumentUploadIssueInfo />
			{showIwilldoitlater ? (
				<IwillDoItLaterDialog
					testId="iwilldoitlaterId"
					isBorrower={false}
					state={showIwilldoitlater}
					onClose={(e) => {
						onCloseIwillDoIt(e);
					}}></IwillDoItLaterDialog>
			) : null}
			{showDeclartionPopup ? (
				<InvestorDeclarationDialog
					state={showDeclartionPopup}
					onClose={(e) => {
						setDeclartionPopup(false);
					}}></InvestorDeclarationDialog>
			) : null}
		</Box>
	);
};
