import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BorrowerRegService } from '../../borrowerRegService';
import { FormHelper } from '../../../../../helpers/formHelper';
import { FormikProvider, getIn, useFormik } from 'formik';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateBorrowerSteps,
} from '../../../../../services/authService';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { Box } from '@mui/material';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import { BorrowerPersonalInfoSchema } from '../../../../../shared/validations/schema';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { useSelector } from 'react-redux';

export const BorrowerPersonalInfo = ({
	isLoading = false,
	response = null,
}) => {
	const borrowerRegService = new BorrowerRegService();
	const { borrowerCollections } = useSelector(
		(state) => state.borrowerCollectionReducer
	);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(isLoading);
	const [showSuccessMsg, setShowSuccessMsg] = useState(false);
	const propertyOwnerships = borrowerCollections?.propertyOwnershipCollection;
	const martialStatuses = borrowerCollections?.maritalStatusCollection;
	const numbersOfChildren = borrowerCollections?.numberOfChildrenCollection;
	const hearAboutUses = borrowerCollections?.hearUsItemCollection;
	const publicFunctions = borrowerCollections?.publicFunctionCollection;
	const countries = borrowerCollections?.countryCollection;
	const nationality = borrowerCollections?.countryCollection;

	const form = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			howDidHearAboutUs: FormHelper.buildOtherFormGroup(),
			nationalityOfOwnerId: '',
			residentialAddress: FormHelper.buildAddressFormGroup(),
			propertyOwnershipId: '',
			maritalStatusId: '',
			numberOfChildrenId: '',
			publicFunction: FormHelper.buildOtherFormGroup(),
		},
		onSubmit: () => {
			submit();
		},
		validationSchema: BorrowerPersonalInfoSchema,
		...FormHelper.formikProps(),
	});

	const nationalityControl = getIn(form?.values, 'nationalityOfOwnerId');
	const countryControl = getIn(form?.values, 'residentialAddress.countryId');

	useEffect(() => {
		let name = countries?.filter((resp) => resp?.id === countryControl)[0]
			?.name;
		let data = {
			...getRegistrationData(),
			addressCountry: name,
		};
		setRegistrationData(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryControl]);

	useEffect(() => {
		let name = countries?.filter((resp) => resp?.id === nationalityControl)[0]
			?.name;
		let data = {
			...getRegistrationData(),
			nationality: name,
		};
		setRegistrationData(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nationalityControl]);

	useEffect(() => {
		getBorrowerProfilePersonalInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setShowSuccessMsg(!!location.state?.isPassed);
	}, [location]);

	const getBorrowerProfilePersonalInfo = () => {
		borrowerRegService
			.getBorrowerProfilePersonalInfo()
			.then((resp) => {
				const personalInfo = resp.data;
				let personData = {
					...personalInfo,
					howDidHearAboutUs: FormHelper.getComplexOther(
						personalInfo?.howDidHearAboutUs
					),
					publicFunction: FormHelper.getComplexOther(
						personalInfo?.publicFunction
					),
					residentialAddress: {
						...personalInfo.address,
						addressLine: personalInfo?.address?.addressLine || null,
						countryId: personalInfo?.address?.country?.id,
					},
					maritalStatusId: personalInfo?.maritalStatus?.id,
					nationalityOfOwnerId: personalInfo?.nationality?.id,
					numberOfChildrenId: personalInfo?.numberOfChildren?.id,
					propertyOwnershipId: personalInfo?.propertyOwnership?.id,
				};
				setTimeout(() => {
					form.setValues(personData);
				});
			})
			.catch((err) => {});
	};

	const submit = () => {
		setLoading(true);
		borrowerRegService
			.registerBorrowerPersonalInfo(form.values)
			.then((resp) => {
				setLoading(false);
				let registrationModel = resp.data?.registrationModel;
				saveTokenFromRegistration(resp.data?.loginModel?.token);
				let regData = {
					...getRegistrationData(),
					step: registrationModel.step,
					requestedStep: 1,
				};
				setRegistrationData(regData);
				updateBorrowerSteps(registrationModel.step);
				onNavigate();
			})
			.catch((err) => setLoading(false));
	};

	const onNavigate = () => {
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.BorrowerRegistartion}/${AppRoutes.BRegBusinessInfo}`
			)
		);
	};

	return (
		<FormikProvider value={form}>
			<Box className="flex flex-col space-y-6">
				{showSuccessMsg ? (
					<Box className="flex items-center text-primary">
						<EmojiEventsOutlinedIcon></EmojiEventsOutlinedIcon>
						<Box className="font-[500] text-[14px]">
							{t('youHavePassedFirstStep')}
						</Box>
					</Box>
				) : null}
				<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<InputWrapper
						dataTestID="firstName"
						errorStateTestID="firstNameError"
						name={'firstName'}
						key={'firstName'}
						label={'firstName'}></InputWrapper>
					<InputWrapper
						dataTestID="lastName"
						errorStateTestID="lastNameError"
						name={'lastName'}
						key={'lastName'}
						label={'lastName'}></InputWrapper>
					<SelectWrapper
						dataTestID="propertyOwnershipId"
						errorStateTestID="propertyOwnershipIdError"
						name={'propertyOwnershipId'}
						key={'propertyOwnershipId'}
						items={propertyOwnerships}
						label={'propertyOwnership'}
					/>
					<SelectWrapper
						dataTestID="countryId"
						errorStateTestID="countryIdError"
						name={'residentialAddress.countryId'}
						key={'residentialAddress.countryId'}
						items={countries}
						label={'countryOfResidence'}
					/>
					<InputWrapper
						dataTestID="addressLine"
						errorStateTestID="addressLineError"
						name={'residentialAddress.addressLine'}
						key={'residentialAddress.addressLine'}
						label={'residentialAddress'}></InputWrapper>
					<SelectWrapper
						dataTestID="nationalityOfOwnerId"
						errorStateTestID="nationalityOfOwnerIdError"
						name={'nationalityOfOwnerId'}
						key={'nationalityOfOwnerId'}
						items={nationality}
						label={'nationality'}
					/>
					<SelectWrapper
						dataTestID="maritalStatusId"
						errorStateTestID="maritalStatusIdError"
						name={'maritalStatusId'}
						key={'maritalStatusId'}
						items={martialStatuses}
						label={'martialStatus'}
					/>
					<SelectWrapper
						dataTestID="numberOfChildrenId"
						errorStateTestID="numberOfChildrenIdError"
						name={'numberOfChildrenId'}
						key={'numberOfChildrenId'}
						items={numbersOfChildren}
						label={'numberOfChildren'}
					/>
					<SelectWrapper
						dataTestID="howDidHearAboutUs"
						errorStateTestID="howDidHearAboutUsValueIdError"
						name={'howDidHearAboutUs.valueId'}
						key={'howDidHearAboutUs.valueId'}
						controlOtherValueName={'howDidHearAboutUs.valueSpecified'}
						items={hearAboutUses}
						label={'howHearAboutUs'}
					/>
					<SelectWrapper
						dataTestID="publicFunction"
						errorStateTestID="publicFunctionValueIdError"
						name={'publicFunction.valueId'}
						key={'publicFunction.valueId'}
						controlOtherValueName={'publicFunction.valueSpecified'}
						items={publicFunctions}
						label={'importantPublicFunction'}
					/>
				</Box>
				<Box className="mt-4 flex md:justify-end justify-center items-center">
					<ButtonWrapper
						dataTestID="nextBtn"
						loading={loading}
						largeBtn
						endIcon={<ArrowIcons></ArrowIcons>}
						label={t('next')}
						onClick={() => {
							if (response) {
								onNavigate();
							} else {
								FormHelper.validateForm(form);
							}
						}}
					/>
				</Box>
			</Box>
		</FormikProvider>
	);
};
