import { Box } from '@mui/material';
import { DateHelper } from '../../../../helpers/dateHelper';
import { CURRENCY } from '../../../../config/appConfig';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';

export const InitializeColumns = (
	totalPayment = 0,
	totalPrincipal = 0,
	totalInterest = 0,
	t
) => {
	return [
		{
			header: t('date'),
			accessorKey: 'date',
			Cell: ({ cell }) => {
				return DateHelper.formatDate(cell?.getValue(), 'MMM dd, yyyy hh:mm');
			},
			Footer: () => <Box>{t('total')}</Box>,
		},
		{
			header: t('payment'),
			accessorKey: 'payment',
			sortable: false,
			hide: false,
			flex: 1,
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
			Footer: () => (
				<Box>
					{totalPayment?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('principal'),
			accessorKey: 'principal',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
			Footer: () => (
				<Box>
					{totalPrincipal?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('interest'),
			accessorKey: 'interest',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
			Footer: () => (
				<Box>
					{totalInterest?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('balance'),
			accessorKey: 'balance',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
		},
	];
};
