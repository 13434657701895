import { Box } from '@mui/material';

export const LabelBadge = ({
	label,
	classList,
	bgColor = 'rgba(51, 205, 135, 0.2)',
	textColor = '#28a56c',
}) => {
	return (
		<Box
			sx={{ backgroundColor: bgColor, color: textColor }}
			className={`px-[5px] py-[3px] min-w-[40px] rounded-sm uppercase font-bold text-[10px] justify-center items-center flex flex-0  ${classList}`}>
			{label}
		</Box>
	);
};
