import { useEffect } from 'react';
import { MarketplaceService } from './marketplaceService';
import { useState } from 'react';
import { Box } from '@mui/material';
import { MarketplaceItems } from './marketplaceItem';
import { MarketplaceStatuses } from '../../../../constants/marketplaceStatuses';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { useTranslation } from 'react-i18next';
import { MarketplaceLoader } from '../../../../shared/components/investors/loaders/marketplaceLoader';

export const Marketplace = () => {
	const marketplaceService = new MarketplaceService();

	const [marketplaceItems, setMarketplaceItems] = useState([]);
	const [collectionItems, setCollectionItems] = useState([]);
	const [pastMarketplaceItems, setPastMarketplaceItems] = useState([]);
	// const [opportunitiesCount, setOpportunitiesCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const { t } = useTranslation();

	useEffect(() => {
		getAllOpportunities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAllOpportunities = async () => {
		setLoading(true);
		try {
			const [
				marketplaceItems,
				collectionItems,
				pastMarketplaceItems,
				// opportunitiesCount,
			] = await Promise.all([
				marketplaceService.getMarketplace(),
				marketplaceService.getMarketplaceCollection(),
				marketplaceService.getMarketplaceHistory(true),
				// marketplaceService.getOpportunitiesCount(),
			]).catch((err) => {
				setLoading(false);
			});
			setMarketplaceItems(marketplaceItems.data);
			setCollectionItems(collectionItems.data);
			setPastMarketplaceItems(pastMarketplaceItems.data);
			// setOpportunitiesCount(opportunitiesCount.data);
			setLoading(false);
		} catch {}
	};

	const checkRatingA = () => {
		let isRatingA = false;

		if (collectionItems.length) {
			isRatingA = collectionItems.find((resp) => resp.rating === 'A');
		}

		if (marketplaceItems.length && !isRatingA) {
			isRatingA = marketplaceItems.find((resp) => resp.rating === 'A');
		}

		return isRatingA;
	};

	return (
		<Box className="grid grid-flow-row gap-4">
			<Box className="grid grid-cols-1 md:grid-cols-3 gap-3">
				{!loading ? (
					<>
						{collectionItems?.length
							? collectionItems?.map((item, index) => {
									return (
										<MarketplaceItems
											key={index}
											isCollection={true}
											item={item}
											status={MarketplaceStatuses.Current}></MarketplaceItems>
									);
							  })
							: null}
						{marketplaceItems?.length
							? marketplaceItems?.map((item, index) => {
									return (
										<MarketplaceItems
											key={index}
											isCollection={false}
											item={item}
											status={MarketplaceStatuses.Current}></MarketplaceItems>
									);
							  })
							: null}
					</>
				) : (
					[1, 2, 3, 4, 5, 6].map((resp) => {
						return <MarketplaceLoader key={resp}></MarketplaceLoader>;
					})
				)}
			</Box>
			<Box className="flex justify-start">
				{!collectionItems?.length && !marketplaceItems?.length && !loading ? (
					<TextWrapper
						classList="!text-[18px] md:!text-[22px]"
						text={t('currentlyNoBusinesses')}></TextWrapper>
				) : null}
			</Box>
			<Box>
				{checkRatingA() ? (
					<TextWrapper
						type="caption"
						text={t('shamsMoreInfo')}></TextWrapper>
				) : (
					''
				)}
			</Box>
			<Box className="mt-7 grid grid-flow-row gap-4">
				<TextWrapper
					classList="!heading"
					text={t('pastOpportunities')}></TextWrapper>
				<Box className="grid grid-cols-1 md:grid-cols-3 gap-3">
					{!loading ? (
						<>
							{pastMarketplaceItems?.length
								? pastMarketplaceItems?.map((item, index) => {
										return (
											<MarketplaceItems
												key={index}
												isCollection={false}
												item={item}
												status={MarketplaceStatuses.Past}></MarketplaceItems>
										);
								  })
								: null}
						</>
					) : (
						[1, 2, 3, 4, 5, 6].map((resp) => {
							return <MarketplaceLoader key={resp}></MarketplaceLoader>;
						})
					)}
				</Box>
				<Box className="flex justify-start">
					{!pastMarketplaceItems?.length && !loading ? (
						<Box className="flex flex-col items-start justify-start space-y-4">
							<TextWrapper
								classList="!text-[18px] md:!text-[22px]"
								text={t('currentlyNoBusinesses')}></TextWrapper>
						</Box>
					) : null}
				</Box>
			</Box>
			{/* <Box>
				{pastMarketplaceItems?.length && !loading ? (
					<>
						{t('moreThanOther')} <strong>{opportunitiesCount}</strong>{' '}
						{t('opportunities')}
					</>
				) : null}
			</Box> */}
		</Box>
	);
};
