import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IS_SA } from '../../../../../config/appConfig';
import { BorrowerGlobalDocuments } from '../../../../../shared/components/borrowerGlobalDocuments';
import { BorrowerKSADocuments } from '../../../../../shared/components/borrowerKSADocuments';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';

export const BorrowerDocuments = () => {
	const { t } = useTranslation();

	return (
		<SectionWithHeading
			heading={t('myDocuments')}
			children={
				<Box className="flex flex-col space-y-3">
					{!IS_SA ? <BorrowerGlobalDocuments /> : <BorrowerKSADocuments />}
				</Box>
			}></SectionWithHeading>
	);
};
