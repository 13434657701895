import { useEffect } from 'react';
import { useState } from 'react';
import { MarketplaceService } from '../../marketplaceService';
import { currencyFormatterHelper } from '../../../../../../helpers/currencyFormatterHelper';
import { Section } from '../../../../../../shared/components/common/section';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import { CURRENCY } from '../../../../../../config/appConfig';
import { CustomTooltip } from '../../../../../../shared/components/common/tooltip';
import { useTranslation } from 'react-i18next';
import { FinancialRatiosType } from '../../../../../../constants/financialRatiosTypes';
import { FormatNumberArray } from '../../../../../../helpers/formatNumberArray';

export const BusinessFinancialRatios = ({ loanId }) => {
	const marketplaceService = new MarketplaceService();
	const [financialRatios, setFinancialRatios] = useState([]);
	const [financialDetails, setFinancialDetails] = useState({});

	const { t } = useTranslation();

	useEffect(() => {
		if (loanId) {
			loadFinancialRatios();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loanId]);

	const formatValue = (value) => {
		let formattedValue = 0;
		if (value) {
			if (!isNaN(Number(value))) {
				formattedValue = !value.includes(',')
					? currencyFormatterHelper(value)
					: value;
			} else {
				formattedValue = value;
			}
		}
		return formattedValue;
	};

	const loadFinancialRatios = () => {
		marketplaceService
			.getFinancialRatios(loanId)
			.then((resp) => {
				let respData = FormatNumberArray(resp.data?.ratios, ['periodEnding']);
				setFinancialDetails(resp.data);
				if (respData?.length) {
					let ratios = respData.length >= 3 ? respData.slice(0, -1) : respData;
					ratios.forEach((ratio) => {
						Object.keys(ratio).forEach((key) => {
							if (key !== 'periodEnding') {
								ratio[key] = formatValue(ratio[key]);
							}
						});
					});
					checkYearToDateEmpty(ratios);
				}
			})
			.catch((err) => { });
	};

	const checkYearToDateEmpty = (ratios = []) => {
		let indexToRemove = [];
		let ratiosValues = ratios;
		let financialRatioValues = ratios;
		financialRatioValues.forEach((ratio, index) => {
			var exists = Object.keys(ratio).some(function (k) {
				return ratio[k] && k !== 'periodEnding';
			});
			if (!exists) {
				indexToRemove.push(index);
			}
		});
		indexToRemove.reverse().forEach((index) => {
			ratiosValues.splice(index, 1);
		});

		setFinancialRatios([...ratiosValues]);
	};

	const showColumns = (periodEnding) => {
		if (!periodEnding) {
			return false;
		}
		return financialRatios.find((resp) => resp?.periodEnding === periodEnding);
	};

	return financialRatios &&
		financialRatios?.length <= 3 &&
		financialRatios?.length >= 1 ? (
		<Box className="relative">
			<Section
				classList="!p-0"
				children={
					<Box>
						<Table>
							<TableHead className="shadow-md">
								<TableRow>
									<TableCell className="!font-bold">
										<Box className="flex flex-col">
											<Box>{t('financialHighlight')}</Box>
											{financialDetails?.type === FinancialRatiosType.Audited ||
												financialDetails?.type ===
												FinancialRatiosType.Internal ? (
												<Box className="!text-[12px] !font-[500] md:!mt-0 mt-1">
													{financialDetails?.type ===
														FinancialRatiosType.Audited
														? t('basedOnAudit')
														: t('basedOnInternal')}
												</Box>
											) : null}
										</Box>
									</TableCell>
									{showColumns(financialRatios[0]?.periodEnding) ? (
										<TableCell className="!font-bold">
											{financialRatios[0]?.periodEnding}
										</TableCell>
									) : null}
									{showColumns(financialRatios[1]?.periodEnding) ? (
										<TableCell className="!font-bold">
											{financialRatios[1]?.periodEnding}
										</TableCell>
									) : null}
									{showColumns(financialRatios[2]?.periodEnding) ? (
										<TableCell className="!font-bold">
											{financialRatios[2]?.periodEnding}
										</TableCell>
									) : null}
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>{`${t('revenueBand')} (${CURRENCY})`}</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.revenueBand)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>{`${t('netProfitBand')} (${CURRENCY})`}</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.netProfitBand)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>{`${t('revenueGrowth')}`}</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.revenueGrowth)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{`${t('netProfitMargin')}`}
										<CustomTooltip
											id="netProfitMargin"
											text={t('netProfitMarginHint')}></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.netProfitMargin)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{`${t('returnOnEquity')}`}
										<CustomTooltip
											id={'returnOnEquity'}
											text={t('returnOnEquityHint')}></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.returnOnEquity)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{`${t('returnOnAssets')}`}
										<CustomTooltip
											id="returnOnAssets"
											text={t('returnOnAssetsHint')}></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.returnOnAssets)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>{t('totalOutstandingDebtFinance')}</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.totalOutstandingDebt)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('inventoryDays')}
										<CustomTooltip
											text={t('inventoryDaysHint')}
											id="inventoryDays"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>{ratio?.inventoryDays}</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('debtorDays')}{' '}
										<CustomTooltip
											text={t('debtorDaysHint')}
											id="debtorDays"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>{ratio?.debtorDays}</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('creditorDays')}{' '}
										<CustomTooltip
											text={t('creditorDaysHint')}
											id="creditorDays"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>{ratio?.creditorDays}</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{`${t('levelofExternalDebt')} (${CURRENCY})`}{' '}
										<CustomTooltip
											text={t('levelofExternalDebtHint')}
											id="levelOfExternalDebt"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.levelOfExternalDebt)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{`${t('debtRatio')}`}{' '}
										<CustomTooltip
											text={t('debtRatioHint')}
											id="debtRatio"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.debtRatio)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('interestCoverRatio')}{' '}
										<CustomTooltip
											text={t('interestCoverRatioHint')}
											id="interestCoverRatio"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.interestCoverRatio)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('debtServiceCoverageRatio')}{' '}
										<CustomTooltip
											text={t('debtServiceCoverageRatioHint')}
											id="debtServiceCoverageRatio"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(
													ratio?.debtServiceCoverageRatio
												)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('debtToEquity')}{' '}
										<CustomTooltip
											text={t('debtToEquityHint')}
											id="debtToEquity"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.debtToEquity)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('currentRatio')}{' '}
										<CustomTooltip
											text={t('currentRatioHint')}
											id="currentRatio"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.currentRatio)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('quickRatio')}{' '}
										<CustomTooltip
											text={t('quickRatioHint')}
											id="quickRatio"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.quickRatio)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('operatingCashflow')}{' '}
										<CustomTooltip
											text={t('operatingCashflowHint')}
											id="operatingCashflow"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{currencyFormatterHelper(ratio?.operatingCashflow)}
											</TableCell>
										);
									})}
								</TableRow>
								<TableRow>
									<TableCell>
										{t('repaymentCoverageStrength')}
										<CustomTooltip
											text={t('repaymentCoverageStrengthHint')}
											id="repaymentCoverageStrength"></CustomTooltip>
									</TableCell>
									{financialRatios.map((ratio, index) => {
										return (
											<TableCell key={index}>
												{ratio?.repaymentCoverageStrength}
											</TableCell>
										);
									})}
								</TableRow>
							</TableBody>
						</Table>
					</Box>
				}></Section>
			<Box className="caption !text-[12px] mt-2 relative !top-[0.5rem]">
				<span>{t('limitsOnDueDiligence')}: </span>
				<span>{financialDetails?.limitsOnDueDiligence || t('notApplicable')}</span>
			</Box>
		</Box>
	) : null;
};
