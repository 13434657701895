/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState } from 'react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
	InputAdornment,
	IconButton,
	Box,
	Card,
	LinearProgress,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { Logout, getUser, login } from '../../../services/authService';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TextWrapper } from '../../../shared/components/formUtilities/useTextWrapper';
import { ButtonWrapper } from '../../../shared/components/formUtilities/useButton';
import { InputWrapper } from '../../../shared/components/formUtilities/useInput';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { AppRoutes } from '../../../constants/appRoutes';
import { getCurrentUserPath } from '../../../helpers/authHelper';
import { buildLocalizeRoute } from '../../../helpers/languageHelper';
import { OTPTypes } from '../../../constants/otpTypes';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../constants/roles';
import { InvestorGuidelineService } from '../../../services/guidelineService';
import { checkIsBorrowerOrInvestor } from '../../../helpers/loginCheckHelper';
import { InvestorStatus } from '../../../constants/investorStatus';
import { RegistrationDialog } from '../components/registrationDialog';
import { UserLockOutDialog } from '../components/userLockoutDialog';
import { OTPAuthenticationDialog } from '../../../shared/components/otpAuthenticationDialog';
import * as yup from 'yup';
import { RegistrationNavBar } from '../../../pages/registration/components/registrationNavBar';
import { useEffect } from 'react';
import { SystemSettingsService } from '../../../services/systemSettingsService';
import { Platform } from '../../../pages/registration/components/platform';
import { LanguageService } from '../../../services/languageService';
import { BASE_URLS, IS_SA } from '../../../config/appConfig';

function Login({ isLoading = false }) {
	const navigate = useNavigate();
	const systemSettingsService = new SystemSettingsService();
	const languageService = new LanguageService();
	const [passwordType, setPasswordType] = useState('password');
	const { t } = useTranslation();

	const [loading, setLoading] = useState(isLoading);
	const [isUserLockOut, setIsUserLockOut] = useState(false);
	const [isShowOtpScreen, setIsShowOtpScreen] = useState(false);
	const [errorCounter, setErrorCounter] = useState(100);
	const [timerId, setTimerId] = useState(null);
	const [isShowEmailNotFoundError, setIsShowEmailNotFoundError] =
		useState(false);
	const [loginResp, setLoginResp] = useState({});
	const [showRegDialog, setShowRegDialog] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		systemSettingsService
			.getAllFront()
			.then((resp) => {})
			.catch((resp) => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isShowEmailNotFoundError) {
			startCountdown();
		}
	}, [isShowEmailNotFoundError]);

	const startCountdown = () => {
		setErrorCounter(100);
		const id = setInterval(() => {
			setErrorCounter((countdown) => countdown - 1);
		}, 100);
		setTimerId(id);
	};

	useEffect(() => {
		if (errorCounter <= 0) {
			setErrorCounter(100);
			setIsShowEmailNotFoundError(false);
			clearInterval(timerId);
		}
	}, [errorCounter, timerId]);

	useEffect(() => {
		return () => clearInterval(timerId);
	}, [timerId]);

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const isAr = () => languageService.isArabic();

	const loginSchema = yup.object({
		email: yup.string().email().required(t('required')),
		password: yup.string().required(t('required')),
	});

	const initialValues = {
		email: '',
		password: '',
	};

	const onSubmit = (values, e) => {
		setLoading(true);
		login(values)
			.then((loginResp) => {
				setLoading(false);
				setLoginResp(loginResp.data);
				if (loginResp.data?.otpRequested) {
					setTimeout(() => {
						showOtpVerification();
					});
				} else {
					setTimeout(() => {
						afterLoginSuccess(loginResp.data);
					});
				}
			})
			.catch((err) => {
				setIsUserLockOut(checkIfUserLockOut(err));
				if (
					err?.response?.data?.loginEmailNotFound &&
					!checkIfUserLockOut(err)
				) {
					setIsShowEmailNotFoundError(true);
				} else {
					setIsShowEmailNotFoundError(false);
				}

				setLoading(false);
			});
	};

	const showOtpVerification = () => {
		setIsShowOtpScreen(true);
	};

	const afterLoginSuccess = (data = null) => {
		let loginData = data ? data : loginResp;
		const user = getUser();
		let route = checkIsBorrowerOrInvestor(loginData, user);
		if (route) {
			navigate(buildLocalizeRoute(route));
			return;
		}

		if (!user?.isApproved) {
			if (user?.status === InvestorStatus.WindDown) {
				navigate(buildLocalizeRoute(AppRoutes.WindDown));
				return;
			}
			redirectToThankYouPage(loginData?.roles);
		} else {
			onNavigateToDashboard();
		}
	};

	const redirectToThankYouPage = (roles = []) => {
		let url = roles.find((resp) => resp === Roles.Investor)
			? AppRoutes.Investor
			: roles.find((resp) => resp === Roles.Borrower)
			? AppRoutes.Borrower
			: null;
		if (url) {
			url = `${url}/${AppRoutes.ThankYou}`;
			navigate(buildLocalizeRoute(url));
		}

		return;
	};

	const onNavigateToDashboard = () => {
		const user = getUser();
		const returnUrl = searchParams.get('returnUrl');
		if (user && user.role === Roles.Investor) {
			const guidelineService = new InvestorGuidelineService();
			let count = guidelineService.checkCurrentUserStepCount();
			localStorage.setItem('guideStepCount', count.toString());
			guidelineService.setCountFromLocalStorage();
		}
		navigate(getCurrentUserPath(returnUrl));
	};

	const checkIfUserLockOut = (err) => {
		let response = err?.response || null;
		if (response && response.status === 400 && response?.data?.UserLockedOut) {
			return true;
		}

		return false;
	};

	const getLoginUrl = () => {
		let baseURL = !IS_SA ? BASE_URLS.sa : BASE_URLS.en;
		return `${baseURL}/${languageService.getCurrentLanguage()}/login`;
	};

	return (
		<>
			<RegistrationNavBar isLogin={true}></RegistrationNavBar>
			<div className="flex flex-auto relative flex-col items-center justify-center min-w-0 md:h-[calc(100%-130px)] auth-card-wrapper bg-white p-3 md:p-0">
				<Box
					component={Card}
					className="flex md:flex-row flex-col !rounded-lg h-auto overflow-auto w-full md:w-auto">
					<Box className="flex relative md:flex-auto md:h-auto h-full flex-col md:py-12 md:px-12 p-6 bg-white md:w-[400px] w-full">
						<Box
							className={`absolute ${
								isAr() ? 'left-[24px]' : 'right-[24px]'
							}   top-[24px]`}>
							<Platform isShowNames={false} />
						</Box>
						<TextWrapper
							classList="!text-[24px] font-bold !text-center !mt-[34px] md:!mt-[15px] !mb-1"
							type="h6"
							text={t('welcomeBack')}
						/>
						<TextWrapper
							classList="!text-[15px] !caption !text-center !mb-2"
							type="h6"
							text={t('signInToYourAccount')}
						/>
						<Formik
							initialValues={initialValues}
							onSubmit={onSubmit}
							validationSchema={loginSchema}
							validateOnMount={true}
							enableReinitialize={true}>
							{(formik) => {
								return (
									<Form className="mt-3">
										<Box className="grid grid-rows-2 gap-6">
											<InputWrapper
												errorStateTestID="emailError"
												key={'email'}
												label={'email'}
												size="small"
												error={formik.errors.email && formik.touched.email}
												helperText={
													formik.errors.email && formik.touched.email
														? t(formik.errors.email)
														: ''
												}
												variant="outlined"
												fullWidth={true}
												type={'email'}
												name="email"></InputWrapper>
											<InputWrapper
												errorStateTestID="passwordError"
												error={
													formik.errors.password && formik.touched.password
												}
												helperText={
													formik.errors.password && formik.touched.password
														? t(formik.errors.password)
														: ''
												}
												fullWidth={true}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																className="!bg-transparent text-primary"
																onClick={togglePassword}>
																{passwordType === 'password' ? (
																	<VisibilityOffOutlinedIcon></VisibilityOffOutlinedIcon>
																) : (
																	<VisibilityOutlinedIcon></VisibilityOutlinedIcon>
																)}
															</IconButton>
														</InputAdornment>
													),
												}}
												key={'password'}
												label={'password'}
												variant="outlined"
												type={passwordType}
												placeholder={t('enterPassword')}
												name="password"
												size="small"
											/>
										</Box>
										{isShowEmailNotFoundError ? (
											<Box className="mt-6">
												<LinearProgress
													color="error"
													variant="determinate"
													value={errorCounter}
												/>
												<Box className="text-[#d32f2f] !text-[0.75rem] mt-1">
													<span>
														<span>{t('loginEmailNotFound')}</span>
														&nbsp;
														<a
															className="!text-[0.75rem] !text-[#d32f2f] underline"
															href={getLoginUrl()}>
															{t('here')}
														</a>
													</span>
												</Box>
											</Box>
										) : null}
										<ButtonWrapper
											dataTestID="signInBtn"
											icon={<LockOpenOutlinedIcon />}
											loading={loading}
											label={t('signIn')}
											classList="w-full !mt-6"
											type="submit"></ButtonWrapper>
										<Box className="text-center mt-6">
											<Link
												data-testid="forgotPasswordLink"
												className="no-underline"
												to={`${buildLocalizeRoute(AppRoutes.ForgotPassword)}`}>
												<span className="underline-link">
													{t('loginForgotPassword')}
												</span>
											</Link>
										</Box>
										<Box className="text-center mt-1 text-[14px]">
											<span>{t('dontHaveAnAccount')}</span>
											<span
												data-testid="signUpLink"
												onClick={() => {
													setShowRegDialog(true);
												}}
												className="underline-link text-primary mx-1">
												{t('signUp')}
											</span>
										</Box>
									</Form>
								);
							}}
						</Formik>
					</Box>
				</Box>
			</div>
			{isUserLockOut ? (
				<UserLockOutDialog
					state={isUserLockOut}
					onClose={() => {
						setIsUserLockOut(false);
					}}></UserLockOutDialog>
			) : null}

			{isShowOtpScreen ? (
				<OTPAuthenticationDialog
					roles={loginResp?.roles}
					type={OTPTypes.Authentication}
					onClose={(isVerified) => {
						setIsShowOtpScreen(false);
						if (isVerified) {
							afterLoginSuccess();
						} else {
							Logout();
						}
					}}
					state={isShowOtpScreen}></OTPAuthenticationDialog>
			) : null}
			{showRegDialog ? (
				<RegistrationDialog
					state={showRegDialog}
					onClose={(e) => {
						setShowRegDialog(false);
					}}
				/>
			) : null}
		</>
	);
}

export default Login;
