import { axiosGet, axiosPost, axiosPut } from '../../../services/axiosService';
const getInvestorPersonalInfoRegistrationUrl = `Lenders/me`;
const investorPersonalInfoRegistrationUrl = 'Lenders';

export class InvestorRegService {
	buildInvestorRegistrationGetterURL(path) {
		return `${getInvestorPersonalInfoRegistrationUrl}/${path}`;
	}

	buildInvestorRegistrationSubmittingURL(path) {
		return `${investorPersonalInfoRegistrationUrl}/${path}`;
	}
	getInvestorRegistrationData() {
		return axiosGet(
			null,
			this.buildInvestorRegistrationGetterURL('RegistrationData')
		);
	}

	getInvestorPersonalInformation() {
		return axiosGet(
			null,
			this.buildInvestorRegistrationGetterURL('PersonalInformation')
		);
	}

	submitPersonalInformation(values) {
		return axiosPost(
			values,
			this.buildInvestorRegistrationSubmittingURL('PersonalInformation')
		);
	}

	getInvestorPersonalInformationExtra() {
		return axiosGet(
			null,
			this.buildInvestorRegistrationGetterURL('PersonalInformationExtra')
		);
	}

	submitPersonalInformationExtra(values) {
		return axiosPost(
			values,
			this.buildInvestorRegistrationSubmittingURL('PersonalInformationExtra')
		);
	}

	getInvestorProfileBusinessInfo() {
		return axiosGet(
			null,
			this.buildInvestorRegistrationGetterURL('BusinessInformation'),
			null,
			'json'
		);
	}

	submitBusinessInformation(values) {
		return axiosPost(
			values,
			this.buildInvestorRegistrationSubmittingURL('BusinessInformation')
		);
	}

	submitDocuments(documents = {}) {
		return axiosPost(
			documents,
			this.buildInvestorRegistrationSubmittingURL('Documents')
		);
	}

	submitDeclarations(declaration = []) {
		return axiosPost(
			declaration,
			this.buildInvestorRegistrationSubmittingURL('Declarations')
		);
	}

	updateInvestorType(type) {
		return axiosPut(
			{},
			this.buildInvestorRegistrationSubmittingURL(`type/${type}`)
		);
	}

	ksaUpdateBusinessDetails(values) {
		return axiosPost(
			values,
			this.buildInvestorRegistrationSubmittingURL('BusinessInformation')
		);
	}
}
