import { Box } from '@mui/material';
import { InvestorTypeHeader } from '../../../../components/investorTypeHeader';
import { useState } from 'react';
import { InvestorType } from '../../../../../../constants/investorType';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import { InvestorDeclarationDialog } from '../../declarationDialog';
import { BusinessInformationKSA } from '../../businessInformation/ksa';
import { PersonalInformationExtra } from '../../personalInformationExtra';

export const PersonalInfoKSA = () => {
	const [activeType, setActiveType] = useState(null);
	const [showDeclartionPopup, setDeclartionPopup] = useState(false);
	const [isBack, setIsBack] = useState(false);

	const navigate = useNavigate();

	const isRetailer = () => {
		return activeType?.name === InvestorType.Retail;
	};

	const isCorporate = () => {
		return activeType?.name === InvestorType.Corporate;
	};

	const onSubmit = () => {
		if (isRetailer()) {
			setDeclartionPopup(true);
		} else {
			navigate(
				buildLocalizeRoute(
					`${AppRoutes.InvestorRegistration}/${AppRoutes.iRegDocumentVarification}`
				)
			);
		}
	};

	return (
		<Box>
			<InvestorTypeHeader
				isBack={isBack}
				onSelectInvestorType={(type) => {
					setIsBack(false);
					setActiveType(type);
				}}>
				{!isCorporate() ? (
					<PersonalInformationExtra
						onGoBackKSA={() => {
							setIsBack(true);
						}}
						isRetailer={isRetailer()}
						onKSASubmit={() => {
							onSubmit();
						}}
					/>
				) : (
					<BusinessInformationKSA onGoBackKSA={() => {
						setIsBack(true);
					}} />
				)}
			</InvestorTypeHeader>

			{showDeclartionPopup ? (
				<InvestorDeclarationDialog
					state={showDeclartionPopup}
					onClose={(e) => {
						setDeclartionPopup(false);
					}}></InvestorDeclarationDialog>
			) : null}
		</Box>
	);
};
