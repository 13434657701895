import { Box, Divider, Skeleton, Stack } from '@mui/material';
import { Section } from '../../../common/section';

export const SummaryLoader = () => {
	return (
		<Stack gap={1.5}>
			<Box className="grid grid-cols-1 md:grid-cols-3 gap-3">
				{[1, 2, 3].map((resp, index) => {
					return (
						<Section
							key={index}
							classList="p-4 !min-h-[260px]"
							children={
								<Box className="flex flex-col h-full justify-between space-y-4">
									<Box>
										<Box className="flex items-center justify-between">
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '160px' }}
											/>
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '80px' }}
											/>
										</Box>
										<Divider className="!mt-2" />
										<Box className="flex items-center justify-between mt-4">
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '160px' }}
											/>
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '80px' }}
											/>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '160px' }}
											/>
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '80px' }}
											/>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '160px' }}
											/>
											<Skeleton
												variant="text"
												sx={{ fontSize: '10px', width: '80px' }}
											/>
										</Box>
										{resp === 3 ? (
											<Box className="flex items-center justify-between mt-4">
												<Skeleton
													variant="text"
													sx={{ fontSize: '10px', width: '160px' }}
												/>
												<Skeleton
													variant="text"
													sx={{ fontSize: '10px', width: '80px' }}
												/>
											</Box>
										) : null}
									</Box>
									<Box>
										{resp !== 2 ? (
											<Skeleton
												variant="text"
												sx={{ fontSize: '2rem', width: '160px' }}
											/>
										) : null}
									</Box>
								</Box>
							}></Section>
					);
				})}
			</Box>
			<Box className="grid grid-cols-1 md:grid-cols-3 gap-3">
				<Section
					classList="md:!col-span-2 p-6"
					children={
						<Stack
							className="w-full"
							gap={1}>
							<Box className="flex justify-start">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '160px' }}
								/>
							</Box>
							<Box className="flex justify-center mt-4">
								<Box className="grid grid-cols-10 gap-6 justify-center">
									{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((resp) => {
										return (
											<Skeleton
												key={resp}
												height={'200px'}
												variant="rectangular"
												sx={{ fontSize: '10px', width: '20px' }}
											/>
										);
									})}
								</Box>
							</Box>
						</Stack>
					}></Section>
				<Section
					classList="p-6"
					children={
						<Stack
							className="w-full"
							gap={1}>
							<Box className="flex justify-start">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '160px' }}
								/>
							</Box>
							<Box className="flex flex-col space-y-4">
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '60%' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '60%' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '60%' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '60%' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '60%' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '60%' }}
								/>
								<Skeleton
									variant="text"
									sx={{ fontSize: '10px', width: '60%' }}
								/>
							</Box>
						</Stack>
					}></Section>
			</Box>
		</Stack>
	);
};
