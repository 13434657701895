import { CircularProgress } from '@mui/material';

export const SpinnerWrapper = ({classList, size = '15px'}) => {
	return (
		<CircularProgress
			className={classList}
			size={size}>
			{' '}
		</CircularProgress>
	);
};
