/* eslint-disable jsx-a11y/anchor-is-valid */
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Skeleton } from '@mui/material';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { useSearchParams } from 'react-router-dom';
import { CURRENCY } from '../../../../config/appConfig';
import { AppRoutes } from '../../../../constants/appRoutes';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';
import { DateHelper } from '../../../../helpers/dateHelper';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { BucketService } from '../../../../services/bucketService';
import { LanguageService } from '../../../../services/languageService';
import { Section } from '../../../../shared/components/common/section';
import { SectionWithHeading } from '../../../../shared/components/common/sectionWithHeading';
import DataTableGrid from '../../../../shared/components/formUtilities/useTable';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { InvestorService } from '../../investorService';
import {
	InitializeMyordersColumns,
	InitializeRatiosColumns,
} from './portfolioColumns';
import { PortfolioService } from './portfolioService';

export const Portfolio = () => {
	const { t } = useTranslation();
	const portfolioService = new PortfolioService();
	const languageService = new LanguageService();
	const investorService = new InvestorService();
	const bucketService = new BucketService();
	const [orders, setOrders] = useState([]);
	const [summary, setSummary] = useState({});
	const [activeInvestmentCount, setActiveInvestmentCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [comments, setComments] = useState([]);
	const [loans, setLoans] = useState([]);
	const [investmentsTabIndex, setInvestmentsTabIndex] = useState(0);
	const [loanPagination, setLoanPagination] = useState({
		currentPage: 1,
		pageCount: 0,
		perPage: 10,
		totalCount: 0,
	});
	const [commentPagination, setCommentPagination] = useState({
		currentPage: 1,
		pageCount: 0,
		perPage: 3,
		totalCount: 0,
	});
	// eslint-disable-next-line no-unused-vars
	const [searchParams, setSearchParams] = useSearchParams();
	const tabs = [
		{ name: t('active'), status: 'active' },
		{ name: t('past'), status: 'past' },
	];

	const orderColumns = InitializeMyordersColumns(t);
	const ratiosColumns = InitializeRatiosColumns(t);
	const [loadingLoan, setLoadingLoan] = useState(false);
	const [loadingComment, setLoadingComment] = useState(false);
	const [loadingOrders, setLoadingOrders] = useState(false);
	const [loadingCSV, setLoadingCSV] = useState(false);
	const [isShowCSVMenu, setShowCSVMenu] = useState(false);

	const [isNotification, setIsNotification] = useState(
		searchParams.get('isNotificationComment')
	);

	useEffect(() => {
		setIsNotification(searchParams.get('isNotificationComment'));
		loadTableData();
		getDashboardData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadTableData = () => {
		getOrders();
		getComments();
	};

	const getDashboardData = async () => {
		try {
			const [activeInvestmentCount, summaryData] = await Promise.all([
				bucketService.activateInvestmentsCount(),
				investorService.getInvestorsSummary(),
			]).catch((err) => {
				setLoading(false);
			});
			setLoading(false);
			setActiveInvestmentCount(activeInvestmentCount.data);
			setSummary(summaryData.data);
		} catch { }
	};

	const isAr = () => {
		return languageService.isArabic();
	};

	const getOrders = () => {
		setLoadingOrders(true);
		portfolioService
			.getOrders()
			.then((resp) => {
				setLoadingOrders(false);
				setOrders(resp.data);
			})
			.catch((err) => {
				setLoadingOrders(false);
			});
	};

	const getComments = (
		currentPage = commentPagination.currentPage,
		perPage = commentPagination.perPage
	) => {
		setLoadingComment(true);
		portfolioService
			.getComments(currentPage, perPage)
			.then((resp) => {
				setLoadingComment(false);
				setCommentPagination({
					pageCount: resp.data.paging?.pageCount,
					currentPage: resp.data.paging?.currentPage,
					perPage: commentPagination.perPage,
					totalCount: resp.data.paging?.totalCount,
				});
				setComments(resp.data?.list);
				rearrangeComments(resp.data?.list);
				if (isNotification === 'true') {
					setTimeout(() => {
						document
							.getElementById('isNotification')
							.scrollIntoView({ behavior: 'smooth' });
					}, [300]);
				}
			})
			.catch((err) => {
				setLoadingComment(false);
			});
	};

	const getPastComments = (
		currentPage = commentPagination.currentPage,
		perPage = commentPagination.perPage
	) => {
		setLoadingComment(true);
		portfolioService
			.getPastComments(currentPage, perPage)
			.then((resp) => {
				setLoadingComment(false);
				setCommentPagination({
					pageCount: resp.data.paging?.pageCount,
					currentPage: resp.data.paging?.currentPage,
					perPage: commentPagination.perPage,
					totalCount: resp.data.paging?.totalCount,
				});
				setComments(resp.data?.list);
				rearrangeComments(resp.data?.list);
			})
			.catch((err) => {
				setLoadingComment(false);
			});
	};

	const getLoans = (
		currentPage = loanPagination.currentPage,
		perPage = loanPagination.perPage
	) => {
		setLoadingLoan(true);
		portfolioService
			.getPortfolioLoans(currentPage, perPage)
			.then((resp) => {
				setLoadingLoan(false);
				setLoans(resp.data?.list);
				setLoanPagination({
					pageCount: resp.data.paging?.pageCount,
					currentPage: resp.data.paging?.currentPage,
					perPage: resp.data.paging?.perPage,
					totalCount: resp.data.paging?.totalCount,
				});
				reArrangeLonsBasedOnStatus(resp.data?.list);
			})
			.catch((err) => {
				setLoadingLoan(false);
			});
	};

	const getPastLoans = (
		currentPage = loanPagination.currentPage,
		perPage = loanPagination.perPage
	) => {
		setLoadingLoan(true);
		portfolioService
			.getPortfolioPastLoans(currentPage, perPage)
			.then((resp) => {
				setLoadingLoan(false);
				setLoans(resp.data?.list);
				setLoanPagination({
					pageCount: resp.data.paging?.pageCount,
					currentPage: resp.data.paging?.currentPage,
					perPage: resp.data.paging?.perPage,
					totalCount: resp.data.paging?.totalCount,
				});
				reArrangeLonsBasedOnStatus(resp.data?.list);
			})
			.catch((err) => {
				setLoadingLoan(false);
			});
	};

	const reArrangeLonsBasedOnStatus = (loans = [], status = 'Late') => {
		if (loans.length) {
			let filteredLoans = [
				...loans.filter(
					(resp) =>
						resp.loanStatus.toLocaleLowerCase() !== status.toLocaleLowerCase()
				),
				...loans.filter(
					(resp) =>
						resp.loanStatus.toLocaleLowerCase() === status.toLocaleLowerCase()
				),
			];
			setLoans(filteredLoans);
		}
	};

	const rearrangeComments = (allComments = []) => {
		let filteredComments = allComments.map((x) => ({
			...x,
			showAllNotes: false,
		}));
		setComments(filteredComments);
	};

	const getCommentNotes = (comment) => {
		if (!comment?.notes) return [];
		return comment.showAllNotes ? comment.notes : comment.notes.slice(0, 1);
	};

	const getComment = (comment) => {
		if (!comment && comment?.length) {
			return '';
		}
		return comment.charAt(comment?.length - 1) !== '.'
			? comment?.trim().concat('.')
			: comment;
	};

	useEffect(() => {
		onInvestmentTabChange(investmentsTabIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investmentsTabIndex]);

	const onInvestmentTabChange = (index) => {
		setLoanPagination({
			currentPage: 1,
			perPage: 10,
			pageCount: 0,
			totalCount: 0,
		});
		setCommentPagination({
			currentPage: 1,
			perPage: 3,
			pageCount: 0,
			totalCount: 0,
		});
		let status = tabs[index].status;
		getLoanByStatus(status, 1, 10);
		getCommentsByStatus(status, 1, 3);
	};

	const getLoanByStatus = (
		status,
		currentPage = loanPagination.currentPage,
		perPage = loanPagination.perPage
	) => {
		if (status === 'past') {
			getPastLoans(currentPage, perPage);
		} else if (status === 'active') {
			getLoans(currentPage, perPage);
		}
	};

	const onLoanPageSizeChange = (e) => {
		let status = tabs[investmentsTabIndex].status;
		getLoanByStatus(status, 1, e);
	};

	const onLoanPageChange = (e) => {
		let status = tabs[investmentsTabIndex].status;
		getLoanByStatus(status, e + 1, loanPagination.perPage);
	};

	const onCommentPageChange = (page) => {
		let status = tabs[investmentsTabIndex].status;
		getCommentsByStatus(status, page, commentPagination.perPage);
	};

	const getCommentsByStatus = (
		status,
		currentPage = commentPagination.currentPage,
		perPage = commentPagination.perPage
	) => {
		if (status === 'past') {
			getPastComments(currentPage, perPage);
		} else if (status === 'active') {
			getComments(currentPage, perPage);
		}
	};

	const toggleSeeMore = (index) => {
		comments[index].showAllNotes = !comments[index].showAllNotes;
		setComments([...comments]);
	};

	const getLoanStatus = () => {
		return tabs[investmentsTabIndex].status;
	};

	const exportCSV = (file) => {
		const statusName = getLoanStatus() === 'past' ? 'Past' : 'Active';
		const date = DateHelper.formatDate(new Date());
		const fileName = `${statusName}-investments-report-${date}.csv`;
		saveAs(file, fileName);
	};

	const onDownloadCSV = () => {
		setLoadingCSV(true);
		if (getLoanStatus() === 'past') {
			portfolioService
				.exportPastLoans()
				.then((resp) => {
					setLoadingCSV(false);
					exportCSV(resp.data);
				})
				.catch((err) => setLoadingCSV(false));
		} else if (getLoanStatus() === 'active') {
			portfolioService
				.exportActiveLoans()
				.then((resp) => {
					setLoadingCSV(false);
					exportCSV(resp.data);
				})
				.catch((err) => setLoadingCSV(false));
		}
	};

	const customDownloadBtn = () => {
		return (
			<Box className="cursor-pointer relative">
				<MenuIcon onClick={() => {
					setShowCSVMenu(!isShowCSVMenu);
				}} className={`!text-[20px] !text-[#6e8192]`} />
				{isShowCSVMenu ? (
					<Box
						className={`${loadingCSV ? 'pointer-events-none' : ''} absolute border border-solid text-center p-1 rounded-[4px] min-w-[120px] right-[6px] z-[999] bg-white ${isAr() ? '!right-auto left-[6px]' : ''
							}`}>
						<p
							onClick={() => {
								setShowCSVMenu(!isShowCSVMenu);
								onDownloadCSV();
							}}
							className="p-1 hover:bg-[#eee] !text-[12px] ">
							{t('downloadCSV')}
						</p>
					</Box>
				) : null}
			</Box>
		);
	};

	return (
		<Box className="flex flex-col">
			{orders && orders?.length ? (
				<SectionWithHeading
					heading={
						<Box className="flex items-center">
							<span className="mx-2">{t('myOrders')}</span>
						</Box>
					}
					children={
						<DataTableGrid
							tableClass="no-space-body"
							loading={loadingOrders}
							columns={orderColumns}
							records={orders}
							showEditColumnsButton={false}
							isPagination={false}></DataTableGrid>
					}></SectionWithHeading>
			) : null}
			<SectionWithHeading
				classList="!mt-3"
				heading={
					<Box className="flex items-start flex-col">
						<span>{t('myInvestments')}</span>
						{!loading ? (
							<TextWrapper
								text={
									<span>
										<span className="!text-sm">
											{t('youHaveCurrentlyInvestedIn')}&nbsp;
											<strong>{activeInvestmentCount}</strong>
											&nbsp;{t('businessWithTotal')}&nbsp;
											<strong>{`${CURRENCY} ${currencyFormatterHelper(
												summary?.outstandingAmount
											)}`}</strong>
										</span>
									</span>
								}></TextWrapper>
						) : null}
					</Box>
				}
				children={
					<Box className="grid grid-flow-row gap-3 w-full">
						<DataTableGrid
							actionButtonContainerClass='!w-full'
							headingClass="!flex-col !items-start"
							tableClass="no-space-body"
							heading={
								investmentsTabIndex === 0
									? t('activeInvestments')
									: t('pastInvestments')
							}
							actionButtons={
								<Box className="flex items-center justify-between relative">
									<a
										disabled={loadingLoan}
										onClick={() => {
											setInvestmentsTabIndex(investmentsTabIndex === 0 ? 1 : 0);
										}}
										className="flex justify-center cursor-pointer items-center passport-change-btn">
										<Box className="flex items-center !text-[13px]">
											<span className="!normal-case">
												{investmentsTabIndex === 0
													? t('showPastInvestments')
													: t('showActiveInvestments')}
											</span>
											{/* <ArrowIcons classList={'!text-[16px]'}></ArrowIcons> */}
										</Box>
									</a>
									{loans && loans.length && !loadingLoan
										? customDownloadBtn()
										: null}
								</Box>
							}
							loading={loadingLoan}
							onPageChange={onLoanPageChange}
							onPageSizeChange={onLoanPageSizeChange}
							pagination={loanPagination}
							columns={ratiosColumns}
							records={loans}
							showEditColumnsButton={false}></DataTableGrid>
						<Box id="isNotification">
							<TextWrapper
								classList="!heading"
								text={t('recentLoanComments')}></TextWrapper>
							<Box className="mt-4">
								{!loadingComment ? (
									<Box className="grid grid-flow-row gap-3">
										{comments && comments?.length ? (
											comments.map((comment, mainIndex) => {
												return (
													<Section
														key={mainIndex}
														classList="!w-full"
														children={
															<Box className="grid grid-flow-row gap-2">
																<a
																	className="underline-link !text-start !font-[600]"
																	href={buildLocalizeRoute(
																		`${AppRoutes.Investor}/${AppRoutes.Marketplace}/${AppRoutes.MarketplaceBusinessHistory}/${comment.loanId}`
																	)}>
																	{comment.businessName}
																</a>
																{comment?.notes && comment?.notes?.length
																	? getCommentNotes(comment).map(
																		(note, index) => {
																			return (
																				<Box
																					key={index}
																					className="bg-light p-2 grid grid-flow-row rounded-md">
																					<TextWrapper
																						classList="!text-[14px] !font-[600]"
																						text={DateHelper.formatDate(
																							note.createdDate
																						)}></TextWrapper>
																					<TextWrapper
																						classList="!text-sm"
																						text={
																							<span>
																								{getComment(note.comment)}
																								{comment.notes?.length > 1 ? (
																									<a
																										className="underline-link mx-1"
																										onClick={() => {
																											toggleSeeMore(
																												mainIndex
																											);
																										}}>
																										{!comment.showAllNotes
																											? t('showComments')
																											: comment.showAllNotes &&
																												index ===
																												comment.notes
																													.length -
																												1
																												? t('hideComments')
																												: null}
																									</a>
																								) : null}
																							</span>
																						}></TextWrapper>
																				</Box>
																			);
																		}
																	)
																	: null}
															</Box>
														}></Section>
												);
											})
										) : (
											<Section
												classList="!p-12 !text-center"
												children={
													<TextWrapper
														classList="!caption !text-sm  !w-full "
														text={t('thereAreNoComments')}></TextWrapper>
												}></Section>
										)}
										{comments && comments.length ? (
											<ResponsivePagination
												nextClassName={`${commentPagination.currentPage *
													commentPagination.perPage >=
													commentPagination.totalCount
													? '!hidden'
													: ''
													}`}
												previousClassName={`${commentPagination.currentPage === 1 ? '!hidden' : ''
													}`}
												activeItemClassName="pointer-events-none active-pagination"
												total={commentPagination.pageCount}
												current={commentPagination.currentPage}
												onPageChange={(page) => onCommentPageChange(page)}
											/>
										) : null}
									</Box>
								) : (
									<Section className="grid grid-flow-row gap-3">
										{[1, 2, 3, 4, 5].map((resp) => {
											return (
												<Skeleton
													key={resp}
													variant="text"
													sx={{ fontSize: '10px', width: '100%' }}
												/>
											);
										})}
									</Section>
								)}
							</Box>
						</Box>
					</Box>
				}></SectionWithHeading>
		</Box>
	);
};
