import { InvestorChartTypes } from '../constants/investorChartTypes';
import { DateHelper } from './dateHelper';

const months = [
	{ short: 'jan', full: 'January' },
	{ short: 'feb', full: 'February' },
	{ short: 'mar', full: 'March' },
	{ short: 'apr', full: 'April' },
	{ short: 'may', full: 'May' },
	{ short: 'jun', full: 'June' },
	{ short: 'jul', full: 'July' },
	{ short: 'aug', full: 'August' },
	{ short: 'sep', full: 'September' },
	{ short: 'sept', full: 'September' },
	{ short: 'oct', full: 'October' },
	{ short: 'nov', full: 'November' },
	{ short: 'dec', full: 'December' },
];

export const getFullMonthName = (month = '') => {
	return months
		.filter((mon) => mon.short === month.toLocaleLowerCase())
		.map((resp) => {
			return resp.full;
		});
};

export function chartData(type, isArabic = false) {
	let chartData = {
		[InvestorChartTypes.RepaymentChart]: {
			series: [
				{
					data: [],
				},
			],

			options: {
				colors: ['#32cd87', '#7850f2'],
				chart: {
					id: 'repaymentChart',
					type: 'bar',
					height: 300,
					stacked: true,
					animations: {
						enabled: false,
						easing: 'easeout',
						speed: 1000,
					},
					toolbar: {
						show: false,
						export: {
							csv: {
								filename: `Repayment-Schedule-${DateHelper.formatDate(
									new Date()
								)}`,
								columnDelimiter: ',',
								headerCategory: 'Month',
								headerValue: 'value',
							},
							svg: {
								filename: `Repayment-Schedule-${DateHelper.formatDate(
									new Date()
								)}`,
							},
							png: {
								filename: `Repayment-Schedule-${DateHelper.formatDate(
									new Date()
								)}`,
							},
						},
					},
				},
				tooltip: {
					x: {
						show: true,
						format: 'MMMM',
						formatter(val) {
							return getFullMonthName(val);
						},
					},
					y: {
						formatter(val) {
							return val.toLocaleString('en-us', { maximumFractionDigits: 0 });
						},
					},
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [1],
					textAnchor: 'middle',
					formatter: function (_val, opt) {
						let series = opt.w.config.series;
						let idx = opt.dataPointIndex;
						const total = series.reduce(
							(total, self) => total + self.data[idx],
							0
						);
						return typeof total === 'number'
							? total?.toLocaleString('en-us', {
								maximumFractionDigits: 0,
							})
							: '';
					},
					offsetY: -16,
					style: {
						textAlign: 'center',
						fontWeight: 600,
						fontSize: '9px',
						colors: ['black'],
					},
				},
				stroke: {
					width: 5,
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: 'top',
							},
							dataLabels: {
								enabled: true,
								enabledOnSeries: [1],
								textAnchor: 'middle',
								formatter: function (_val, opt) {
									let series = opt.w.config.series;
									let idx = opt.dataPointIndex;
									const total = series.reduce(
										(total, self) => total + self.data[idx],
										0
									);
									return typeof total === 'number'
										? total?.toLocaleString('en-us', {
											maximumFractionDigits: 0,
										})
										: '';
								},
								offsetY: -13,
								style: {
									textAlign: 'center',
									fontWeight: 600,
									fontSize: '6px',
									colors: ['black'],
								},
							},
							plotOptions: {
								bar: {
									horizontal: false,
									columnWidth: '60%',
									hideOverflowingLabels: false,
								},
							},
							grid: {
								padding: {
									top: 0,
									right: 0,
									bottom: 0,
									left: 0,
								},
							},
							xaxis: {
								type: 'category',
								labels: {
									style: {
										fontSize: '8px',
									},
								},
								categories: [],
							},
							yaxis: {
								labels: {
									offsetX: -15,
								},
							},
						},
					},
				],
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '60%',
						dataLabels: {
							position: 'top',
							hideOverflowingLabels: false,
						},
					},
				},
				xaxis: {
					type: 'category',
					categories: [],
				},
				yaxis: {
					labels: {
						formatter(val) {
							return val.toLocaleString('en-us', { maximumFractionDigits: 0 });
						},
						offsetX: isArabic ? -20 : 0
					},
				},
				legend: {
					position: 'top',
				},
				fill: {
					opacity: 1,
				},
			},
		},
		[InvestorChartTypes.FundChart]: {
			series: [],
			options: {
				chart: {
					width: 380,
					type: 'pie',
				},
				tooltip: {
					shared: false,
					intersect: true,
					x: {
						formatter(val) {
							return val.toLocaleString('en-us', { maximumFractionDigits: 0 });
						},
					},
					y: {
						formatter(val) {
							return val.toLocaleString('en-us', { maximumFractionDigits: 0 });
						},
					},
				},
				colors: ['#33CD87', '#90ab9f', '#7850f2'],
				dataLabels: {
					enabled: false,
				},
				grid: {
					padding: {
						top: 25,
						right: 0,
						bottom: 0,
						left: 0,
					},
				},
				labels: [],
				legend: {
					position: 'top',
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 350,
							},
							legend: {
								itemMargin: {
									horizontal: 5,
									vertical: 0,
								},
								position: 'top',
								markers: {
									// offsetX: 1,
								},
							},
							dataLabels: {
								// enabled:true,
								// style: {
								//   fontSize: '8px',
								//   fontWeight: 'bold',
								// },
								// formatter(val: number, { seriesIndex, dataPointIndex, w }) {
								//   return w.config.series[seriesIndex].toLocaleString('en-us', {
								//     maximumFractionDigits: 0
								//   });
								// }
							},
						},
					},
				],
			},
		},
	};

	return chartData[type];
}
