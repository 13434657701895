import { Box, Stack } from '@mui/material';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import CheckIcon from '@mui/icons-material/Check';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../../../../config/appConfig';
import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';

export const WithdrawSuccess = ({ amount = 0, onDone = () => {} }) => {
	const { t } = useTranslation();

	return (
		<Box className="flex flex-0 justify-center">
			<Box className="flex flex-col items-center md:w-[670px] w-full">
				<Stack gap={3}>
					<Box className="flex flex-col flex-0" >
						<TextWrapper
							classList="caption !text-sm "
							text={t('youHaveMadeFollowingTransferIntoAccount')}></TextWrapper>
						<TextWrapper
							classList="caption !text-sm !mt-2"
							text={t('withdrawSuccessText')}></TextWrapper>
					</Box>
					<Stack gap={2}>
						<Box className="grid grid-cols-2 gap-4">
							<TextWrapper
								classList="!text-sm !font-bold !text-end"
								text={t('amount')}></TextWrapper>
							<TextWrapper
								classList="!text-sm border-b"
								text={`${CURRENCY} ${currencyFormatterHelper(amount)}`}></TextWrapper>
						</Box>
					</Stack>

					<Box className="flex-col flex justify-center items-center">
						<Stack
							gap={1}
							direction={'row'}
							className="justify-center">
							<ButtonWrapper
								onClick={() => {
									onDone();
								}}
								label={t('done')}
								icon={<CheckIcon></CheckIcon>}></ButtonWrapper>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
};
