import { Box, FormGroup, InputAdornment, TextField } from '@mui/material';
import { useField } from 'formik';
import { CURRENCY } from '../../../config/appConfig';
import { TextWrapper } from './useTextWrapper';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../common/tooltip';

export const InputWrapper = ({
	placeholder,
	isTextArea = false,
	rows = 1,
	type = 'text',
	disabled = false,
	labelClass = '',
	errorClass = '',
	showCurrency = false,
	externalErrorMessage = '',
	hideErrorMessage = false,
	dataTestID = '',
	errorStateTestID = '',
	readOnly = false,
	endIcon = null,
	maxLength = null,
	tooltipText = null,
	onFocus = () => {},
	onBlur = () => {},
	endIconClick = () => {},
	...props
}) => {
	const { t } = useTranslation();
	const [field, meta] = useField(props);
	return (
		<FormGroup className="w-full h-auto">
			<Box className="flex flex-col items-start w-full">
				<Box className="flex flex-0 items-center !relative w-full">
					<TextWrapper
						classList="truncate-word"
						type="caption"
						text={
							<span>
								<span>
									{typeof props?.label === 'string'
										? t(props?.label)
										: props?.label}
								</span>
								{(meta.error || externalErrorMessage) && props?.label ? (
									<span className="!text-red-500 !text-[16px] !absolute !top-[-3px]">
										*
									</span>
								) : null}
							</span>
						}></TextWrapper>
					{tooltipText && (
						<CustomTooltip
							id={props?.label}
							text={tooltipText}></CustomTooltip>
					)}
				</Box>
				<TextField
					autoComplete="on"
					{...field}
					error={
						(meta.error || externalErrorMessage) && meta.touched ? true : false
					}
					helperText={
						<span>
							{meta.touched & !hideErrorMessage
								? t(meta.error || externalErrorMessage)
								: ''}
						</span>
					}
					placeholder={t(props?.label)}
					FormHelperTextProps={{
						className: `${errorClass}`,
						'data-testid':
							(meta.error || externalErrorMessage) && meta.touched
								? errorStateTestID
								: '',
					}}
					multiline={isTextArea}
					maxRows={isTextArea ? 6 : 1}
					InputLabelProps={{ shrink: false, style: { display: 'none' } }}
					variant={'outlined'}
					disabled={disabled}
					size="small"
					onFocus={() => {
						onFocus && onFocus();
					}}
					onBlur={(e) => {
						onBlur && onBlur(e);
					}}
					onClick={(e) => {
						e.stopPropagation();
					}}
					inputProps={{
						maxLength: maxLength,
						'aria-label': field.name,
						'data-testid': dataTestID,
					}}
					InputProps={{
						readOnly: readOnly,
						startAdornment: showCurrency ? (
							<>
								<InputAdornment position="start">{CURRENCY}</InputAdornment>
							</>
						) : null,
						endAdornment: endIcon ? endIcon : null,
						'aria-label': field.name,
					}}
					minRows={rows}
					fullWidth={true}
					className={`mt-1 reusable-input ${
						isTextArea ? 'input-text-area' : ''
					} ${props.classList ? props.classList : props.classlist || ''}`}
					type={type ? type : showCurrency ? 'number' : 'text'}
					name={field?.name}
					value={field?.value}
					{...props}
				/>
			</Box>
		</FormGroup>
	);
};
