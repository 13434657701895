/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from '@mui/material';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import { useTranslation } from 'react-i18next';
import DataTableGrid from '../../../../../shared/components/formUtilities/useTable';
import { initializeColumns } from './historyColumns';
import { useEffect, useState } from 'react';
import { TransferService } from '../../../../../services/transferService';
import { Section } from '../../../../../shared/components/common/section';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { FormikProvider, useFormik } from 'formik';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';

const TransactionTypes = {
	All: 'All',
	Deposit: 'Deposit',
	Withdraw: 'Withdraw',
};

export const InvestorTransferHistory = ({ getTransactions }) => {
	const transferService = new TransferService();
	const { t } = useTranslation();
	const columns = initializeColumns(t);
	const [history, setHistory] = useState([]);
	const [filtertedHistory, setFiltertedHistory] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadMore, setLoadMore] = useState(false);
	const form = useFormik({
		initialValues: { type: TransactionTypes.All },
		enableReinitialize: true,
	});

	useEffect(() => {
		if (getTransactions) {
			getTransactions.current = getAllTransactions;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filterHistory = (selectedType) => {
		setLoadMore(true);
		let isDeposit = false;
		let historyData = filtertedHistory;
		if (selectedType === TransactionTypes.All) {
			setLoadMore(false);
			setHistory(historyData?.slice(0, 5));
			return;
		} else if (selectedType === TransactionTypes.Deposit) {
			isDeposit = true;
		}
		setHistory([...historyData.filter((resp) => resp.isDeposit === isDeposit)]);
	};

	const getAllTransactions = () => {
		setLoading(true);
		transferService
			.getTransferHistory()
			.then((resp) => {
				let history = resp.data || [];
				setFiltertedHistory(history);
				setHistory(history?.slice(0, 5));
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getAllTransactions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box className="flex flex-col">
			<SectionWithHeading
				heading={
					<Box className="flex items-center">
						<span className="mx-2">{t('transactionHistory')}</span>
					</Box>
				}
				actionButtons={
					<Box className="md:min-w-[220px]">
						<FormikProvider value={form}>
							<SelectWrapper
								onChanges={(value) => {
									filterHistory(value);
								}}
								items={[
									{ name: t('all'), id: TransactionTypes.All },
									{ name: t('deposit'), id: TransactionTypes.Deposit },
									{ name: t('withdraw'), id: TransactionTypes.Withdraw },
								]}
								key={'type'}
								name="type"></SelectWrapper>
						</FormikProvider>
					</Box>
				}
				children={
					<Box className="max-h-[400px] overflow-auto flex-col relative">
						<DataTableGrid
							name={'transactionHistoryTbl'}
							columns={columns}
							records={history}
							showEditColumnsButton={false}
							isPagination={false}
							loading={loading}></DataTableGrid>
						{!loadMore ? (
							<Box className="absolute w-full text-center md:bottom-[4px] bottom-[8px] z-[999]">
								<ButtonWrapper
									label={t('seeMore')}
									variant={'outlined'}
									onClick={() => {
										setLoadMore(true);
										setHistory(filtertedHistory);
									}}>
									LoadMore
								</ButtonWrapper>
							</Box>
						) : null}
					</Box>
				}></SectionWithHeading>
			<Section
				classList="!mt-3"
				children={
					<Box className="flex justify-center">
						<TextWrapper
							classList="!text-sm"
							text={t(
								'transferAmountMayVaryFromRequestedAmountDueBankCharges'
							)}></TextWrapper>
					</Box>
				}></Section>
		</Box>
	);
};
