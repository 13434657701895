import { applyMiddleware, combineReducers, createStore } from 'redux';
import {
	borrowerCollectionReducer,
	investorCollectionReducer,
} from './reducers';
import { thunk } from 'redux-thunk';

// add functions inside the combineReducers
const rootReducer = combineReducers({
	investorCollectionReducer,
	borrowerCollectionReducer,
});

export const Store = createStore(rootReducer, applyMiddleware(thunk));
