import { IS_SA } from '../config/appConfig';

const culture = {
	Global: { en: 'en-US', ar: 'ar-AE' },
	Saudi: { en: 'en-US', ar: 'ar-AE' },
};

export class CultureHelper {
	static currentCulture = () => {
		let lang = localStorage.getItem('i18nextLng') || 'en';
		return IS_SA ? culture.Saudi[lang] : culture.Global[lang];
	};
}
