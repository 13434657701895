import { Box, FormGroup, MenuItem, TextField } from '@mui/material';
import { useField } from 'formik';
import { TextWrapper } from './useTextWrapper';
import { InputWrapper } from './useInput';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { CustomTooltip } from '../common/tooltip';

SelectWrapper.propTypes = {
	placeholder: PropTypes.any,
	items: PropTypes.any,
	valueKey: PropTypes.any,
	labelKey: PropTypes.any,
	controlOtherValueName: PropTypes.any,
	delimiter: PropTypes.string,
	classList: PropTypes.string,
	wrapperClass: PropTypes.string,
	preventSetValue: PropTypes.bool,
	isSmallPopup: PropTypes.bool,
	onChanges: PropTypes.func,
};

export default function SelectWrapper({
	placeholder,
	items = [],
	valueKey = 'id',
	labelKey = 'displayName',
	controlOtherValueName = '',
	delimiter = ' ',
	classList = '',
	wrapperClass = '',
	preventSetValue = false,
	isSmallPopup = false,
	isSearchable = true,
	searchableKey = '',
	onChanges = (e) => {},
	tooltipText = null,
	tooltipId = null,
	showDefaultOption = false,
	defaultOptionText = 'default',
	defaultOptionValue = 'default',
	errorStateTestID = '',
	dataTestID = '',
	selectTestId = '',
	...props
}) {
	const [field, meta, helper] = useField(props);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredItems, setFilteredItems] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		setFilteredItems(items);
	}, [items]);

	const getValue = (option) => {
		if (typeof option !== 'object' || !option) {
			return option;
		}

		if (option[valueKey] === 0) {
			option[valueKey] = '0';
		}
		return option[valueKey];
	};

	const displayLabel = (option) => {
		if (typeof option !== 'object' || !option) {
			return option;
		}
		if (typeof labelKey === 'string') {
			return option[labelKey] || option['name'];
		}
		return labelKey.map((key) => option[key]).join(delimiter);
	};

	const hasOtherValue = () => {
		return controlOtherValueName && isOther(field.value);
	};

	const isOther = (value) => {
		if (!value) {
			return false;
		}
		return filteredItems?.find((resp) => resp?.id === value)?.name === 'Other';
	};

	const onSearchValue = (value = '') => {
		if (!isSearchable) {
			return;
		}
		setSearchTerm(value);

		if (!value) {
			setFilteredItems(items);
			return;
		}

		let searchValue = value?.toLocaleLowerCase();
		let filteredValues = items.filter((res) =>
			res[searchableKey ? searchableKey : labelKey]
				?.toLocaleLowerCase()
				?.replace(/\s/g, '')
				?.includes(searchValue?.replace(/\s/g, ''))
		).length
			? items.filter((res) =>
					res[searchableKey ? searchableKey : labelKey]
						?.toLocaleLowerCase()
						?.replace(/\s/g, '')
						?.includes(searchValue?.replace(/\s/g, ''))
			  )
			: items.filter((res) =>
					res['name']
						?.toLocaleLowerCase()
						?.replace(/\s/g, '')
						?.includes(searchValue?.replace(/\s/g, ''))
			  );

		setFilteredItems(filteredValues);
	};

	return (
		<FormGroup
			className="w-full"
			style={{ height: 'fit-content' }}>
			<Box
				className={
					hasOtherValue() ? 'grid grid-cols-2 gap-2 w-full' : 'w-full'
				}>
				<Box
					className={`flex flex-col flex-0 items-start w-full ${wrapperClass}`}>
					<Box className="flex flex-0 items-center w-full relative">
						<TextWrapper
							classList="truncate-word"
							type="caption"
							text={
								<span>
									<span>{t(props?.label)}</span>
									{meta.error ? (
										<span className="!text-red-500 !text-[16px] !absolute !top-[-3px]">
											*
										</span>
									) : null}
								</span>
							}></TextWrapper>
						<Box className="w-1"></Box>
						{tooltipText && (
							<CustomTooltip
								iconSizeClass="!text-[13px]"
								iconClassList={'!-top-1.5'}
								id={tooltipId ? tooltipId : props?.label}
								text={t(tooltipText)}></CustomTooltip>
						)}
					</Box>
					<TextField
						onChange={(e) => {
							let value = e.target.value;
							if (!preventSetValue) {
								helper.setValue(value);
							}
							onChanges(value);
						}}
						error={meta.error && meta.touched ? true : false}
						helperText={<span>{meta.touched ? t(meta.error) : ''}</span>}
						FormHelperTextProps={{
							'data-testid': meta.error ? errorStateTestID : '',
						}}
						defaultValue={null}
						variant={'outlined'}
						inputProps={{
							'aria-label': props?.label,
							'data-testid': dataTestID,
						}}
						SelectProps={{
							'data-testid': selectTestId,
							MenuProps: {
								classes: { paper: 'menu-custom-select' },
							},
							style: { textAlign: 'start' },
							onClose: () => {
								onSearchValue('');
							},
							onOpen: () => {
								setTimeout(() =>
									document.getElementById(`input-${field.name}`)?.focus()
								);
							},
						}}
						InputLabelProps={{
							shrink: false,
							style: { display: 'none' },
						}}
						size="small"
						select
						fullWidth={true}
						required={props?.isRequired ? true : false}
						name={field?.name}
						value={field?.value}
						className={`${props?.classList}`}
						disabled={props?.disabled || false}>
						<Box className="text-end mx-2 mb-2 md:hidden block">
							<CloseIcon
								onClick={(e) => {}}
								className="text-gray-400"
							/>
						</Box>
						{isSearchable ? (
							<Box onKeyDown={(e) => e.stopPropagation()}>
								<TextField
									id={`input-${field.name}`}
									onChange={(e) => {
										onSearchValue(e.target.value);
										setTimeout(() =>
											document.getElementById(`input-${field.name}`).focus()
										);
									}}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
									}}
									value={searchTerm}
									InputProps={{
										endAdornment: searchTerm ? (
											<CloseIcon
												onClick={() => {
													onSearchValue('');
												}}
												className="text-[#5b5b5b] cursor-pointer"></CloseIcon>
										) : null,
									}}
									placeholder={`${t('search')}...`}
									variant="outlined"
									size="small"
									className="w-auto !mx-2 !mb-2 !flex"></TextField>
							</Box>
						) : null}
						<MenuItem
							onKeyDown={(e) => e.stopPropagation()}
							className={`!hidden`}
							value=""
							disabled></MenuItem>
						{showDefaultOption && filteredItems.length ? (
							<MenuItem
								onKeyDown={(e) => e.stopPropagation()}
								className={`custom-menu ${
									isSmallPopup ? '!whitespace-break-spaces' : ''
								}`}
								value={defaultOptionValue}>
								{t(defaultOptionText)}
							</MenuItem>
						) : null}
						{filteredItems && filteredItems.length > 0 ? (
							filteredItems.map((resp, index) => {
								return (
									<MenuItem
										onKeyDown={(e) => e.stopPropagation()}
										className={`custom-menu ${
											isSmallPopup ? '!whitespace-break-spaces' : ''
										}`}
										key={index}
										value={getValue(resp)}>
										{displayLabel(resp)}
									</MenuItem>
								);
							})
						) : (
							<MenuItem
								disabled={true}
								onKeyDown={(e) => e.stopPropagation()}>
								<em>Nothing to display</em>
							</MenuItem>
						)}
					</TextField>
				</Box>
				{hasOtherValue() ? (
					<Box>
						<InputWrapper
							label={'Other'}
							name={controlOtherValueName}
							key={controlOtherValueName}></InputWrapper>
					</Box>
				) : null}
			</Box>
		</FormGroup>
	);
}
