import { Box, Stack } from '@mui/material';
import { TextWrapper } from '../formUtilities/useTextWrapper';
import { SOUQ_BANK_DETAILS } from '../../../config/appConfig';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LanguageService } from '../../../services/languageService';

export const FSBankDetails = () => {
	const { t } = useTranslation();
	const languageService = new LanguageService();
	const copyItem = (text, classList) => {
		let copyText = document.querySelector(`.${classList}`);
		navigator.clipboard.writeText(text);
		copyText.classList.add('active');
		setTimeout(function () {
			copyText.classList.remove('active');
		}, 500);
	};

	return (
		<Stack
			gap={2}
			className={languageService.isArabic() ? 'arabic-bank-details' : ''}>
			<TextWrapper
				classList="!text-lg !font-[700]"
				text={t('fundingSouqBankDetails')}></TextWrapper>
			<Box className="grid grid-cols-3 items-center">
				<TextWrapper
					classList="!caption !text-sm"
					text={t('bankName')}></TextWrapper>
				<TextWrapper
					classList="!font-bold !text-sm !col-span-2 !mx-2"
					text={
						<Box
							className="flex items-center cursor-pointer copy-button bank-name"
							onClick={() => {
								copyItem(SOUQ_BANK_DETAILS.souqBankName, 'bank-name');
							}}>
							<ContentCopyIcon className="!text-[16px]"></ContentCopyIcon>
							<span className="mx-1">{SOUQ_BANK_DETAILS.souqBankName}</span>
						</Box>
					}></TextWrapper>
			</Box>
			<Box className="grid grid-cols-3 items-center">
				<TextWrapper
					classList="!caption !text-sm"
					text={t('bankAddress')}></TextWrapper>
				<TextWrapper
					classList="!font-bold !text-sm !col-span-2 !mx-2"
					text={
						<Box
							className="flex items-center cursor-pointer copy-button bank-address"
							onClick={() => {
								copyItem(SOUQ_BANK_DETAILS.souqBankAddress, 'bank-address');
							}}>
							<ContentCopyIcon className="!text-[16px]"></ContentCopyIcon>
							<span className="mx-1">{SOUQ_BANK_DETAILS.souqBankAddress}</span>
						</Box>
					}></TextWrapper>
			</Box>
			<Box className="grid grid-cols-3 items-center">
				<TextWrapper
					classList="!caption !text-sm"
					text={t('accountName')}></TextWrapper>
				<TextWrapper
					classList="!font-bold !text-sm !col-span-2 !mx-2"
					text={
						<Box
							className="flex items-center cursor-pointer copy-button account-name"
							onClick={() => {
								copyItem(SOUQ_BANK_DETAILS.souqAccountName, 'account-name');
							}}>
							<ContentCopyIcon className="!text-[16px]"></ContentCopyIcon>
							<span className="mx-1">{SOUQ_BANK_DETAILS.souqAccountName}</span>
						</Box>
					}></TextWrapper>
			</Box>
			<Box className="grid grid-cols-3 items-center">
				<TextWrapper
					classList="!caption !text-sm"
					text={t('accountNo')}></TextWrapper>
				<TextWrapper
					classList="!font-bold !text-sm !col-span-2 !mx-2"
					text={
						<Box
							className="flex items-center cursor-pointer copy-button account-number"
							onClick={() => {
								copyItem(SOUQ_BANK_DETAILS.souqAccountNumber, 'account-number');
							}}>
							<ContentCopyIcon className="!text-[16px]"></ContentCopyIcon>
							<span className="mx-1">
								{SOUQ_BANK_DETAILS.souqAccountNumber}
							</span>
						</Box>
					}></TextWrapper>
			</Box>
			<Box className="grid grid-cols-3 items-center">
				<TextWrapper
					classList="!caption !text-sm"
					text={t('iban')}></TextWrapper>
				<TextWrapper
					classList="!font-bold !text-sm !col-span-2 !mx-2"
					text={
						<Box
							className="flex items-center cursor-pointer copy-button iban"
							onClick={() => {
								copyItem(SOUQ_BANK_DETAILS.souqIBAN, 'iban');
							}}>
							<ContentCopyIcon className="!text-[16px]"></ContentCopyIcon>
							<span className="mx-1">{SOUQ_BANK_DETAILS.souqIBAN}</span>
						</Box>
					}></TextWrapper>
			</Box>
			<Box className="grid grid-cols-3 items-center">
				<TextWrapper
					classList="!caption !text-sm"
					text={t('swiftCode')}></TextWrapper>
				<TextWrapper
					classList="!font-bold !text-sm !col-span-2 !mx-2"
					text={
						<Box
							className="flex items-center cursor-pointer copy-button swift-code"
							onClick={() => {
								copyItem(SOUQ_BANK_DETAILS.souqSwiftCode, 'swift-code');
							}}>
							<ContentCopyIcon className="!text-[16px]"></ContentCopyIcon>
							<span className="mx-1">{SOUQ_BANK_DETAILS.souqSwiftCode}</span>
						</Box>
					}></TextWrapper>
			</Box>
		</Stack>
	);
};
