/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper } from '../formUtilities/useButton';
import { useEffect, useState } from 'react';
import { UserDocumentTypes } from '../../../constants/userDocuments';
import ClientClassficationDoc from '../../../assets/docs/230621 FSL - Assessed Professional Client Questionnaire - Corporate.docx';
import { UserService } from '../../../services/userService';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { IS_SA } from '../../../config/appConfig';

export const CorporateDocumentUploader = ({
	onAllUploaded = (value) => { },
}) => {
	const userService = new UserService();

	const [tradeLicenseFile, setTradeLicenseFile] = useState(null);
	const [moaArticle, setMoaArticle] = useState(null);
	const [delegationAuthority, setDelegationAuthority] = useState(null);
	const [passport, setPassport] = useState(null);
	const [evidenceEntityFund, setEvidenceEntityFund] = useState(null);
	const [clientClassification, setClientClassification] = useState(null);
	const [allDocuments, setAllDocuments] = useState(null);
	const [loadingUploadDocs, setLoadingUploadDocs] = useState({
		[UserDocumentTypes.CorporateInvestorTradeLicense]: false,
		[UserDocumentTypes.CorporateInvestorMoaArticle]: false,
		[UserDocumentTypes.CorporateInvestorDelegationAuthority]: false,
		[UserDocumentTypes.CorporateInvestorPassportCopy]: false,
		[UserDocumentTypes.CorporateInvestorEvidenceEntityFund]: false,
		[UserDocumentTypes.CorporateInvestorClientClassificationQn]: false,
	});
	const fileTypes = UserDocumentTypes;

	const { t } = useTranslation();

	useEffect(() => {
		getAllDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!IS_SA) {
			if (
				tradeLicenseFile &&
				moaArticle &&
				delegationAuthority &&
				passport &&
				evidenceEntityFund &&
				clientClassification
			) {
				onAllUploaded(true);
			} else {
				onAllUploaded(false);
			}
		} else {
			if (tradeLicenseFile && moaArticle && delegationAuthority) {
				onAllUploaded(true);
			} else {
				onAllUploaded(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		tradeLicenseFile,
		moaArticle,
		delegationAuthority,
		passport,
		evidenceEntityFund,
		clientClassification,
	]);

	const getAllDocuments = () => {
		startStopLoader(null, null, true, true);
		userService
			.getAllDocuments()
			.then((resp) => {
				setAllDocuments(resp.data);
				setTradeLicenseFile(
					getFilteredDocuments(
						UserDocumentTypes.CorporateInvestorTradeLicense,
						resp.data
					)
				);
				setMoaArticle(
					getFilteredDocuments(
						UserDocumentTypes.CorporateInvestorMoaArticle,
						resp.data
					)
				);
				setDelegationAuthority(
					getFilteredDocuments(
						UserDocumentTypes.CorporateInvestorDelegationAuthority,
						resp.data
					)
				);
				setPassport(
					getFilteredDocuments(
						UserDocumentTypes.CorporateInvestorPassportCopy,
						resp.data
					)
				);
				setEvidenceEntityFund(
					getFilteredDocuments(
						UserDocumentTypes.CorporateInvestorEvidenceEntityFund,
						resp.data
					)
				);
				setClientClassification(
					getFilteredDocuments(
						UserDocumentTypes.CorporateInvestorClientClassificationQn,
						resp.data
					)
				);
				startStopLoader(null, null, true, false);
			})
			.catch((err) => {
				startStopLoader(null, null, true, false);
			});
	};

	const getFilteredDocuments = (type, document = []) => {
		const documents = document && document.length ? document : allDocuments;
		return documents.filter((data) => data.type === type)[0] || null;
	};

	const startStopLoader = (type, value, all = false, allValue = false) => {
		if (all) {
			const loaders = {
				[UserDocumentTypes.CorporateInvestorTradeLicense]: allValue,
				[UserDocumentTypes.CorporateInvestorMoaArticle]: allValue,
				[UserDocumentTypes.CorporateInvestorDelegationAuthority]: allValue,
				[UserDocumentTypes.CorporateInvestorPassportCopy]: allValue,
				[UserDocumentTypes.CorporateInvestorEvidenceEntityFund]: allValue,
				[UserDocumentTypes.CorporateInvestorClientClassificationQn]: allValue,
			};
			setLoadingUploadDocs(loaders);
		} else {
			setLoadingUploadDocs((prevState) => ({
				...prevState,
				[type]: value,
			}));
		}
	};

	const onFileUpload = (files, type) => {
		const file = files[0];
		startStopLoader(type, true);
		userService
			.uploadUserDocuments(file, type)
			.then((resp) => {
				setDocumentValues(resp.data, type);
				startStopLoader(type, false);
			})
			.catch((err) => {
				startStopLoader(type, false);
			});
	};

	const setDocumentValues = (document, type) => {
		switch (type) {
			case UserDocumentTypes.CorporateInvestorTradeLicense: {
				setTradeLicenseFile(document);
				break;
			}
			case UserDocumentTypes.CorporateInvestorMoaArticle: {
				setMoaArticle(document);
				break;
			}
			case UserDocumentTypes.CorporateInvestorDelegationAuthority: {
				setDelegationAuthority(document);
				break;
			}
			case UserDocumentTypes.CorporateInvestorPassportCopy: {
				setPassport(document);
				break;
			}
			case UserDocumentTypes.CorporateInvestorEvidenceEntityFund: {
				setEvidenceEntityFund(document);
				break;
			}
			case UserDocumentTypes.CorporateInvestorClientClassificationQn: {
				setClientClassification(document);
				break;
			}
			default: {
				break;
			}
		}
	};

	const onUploadFileClick = (type, file) => {
		if (!file?.file?.url && !loadingUploadDocs[type]) {
			document.getElementById(`fileUploader${type}`).click();
		}
	};

	const onViewFile = (url) => {
		if (!url) {
			return;
		}
		window.open(url, '_blank');
	};

	const getCurrentDocumentId = (type) => {
		switch (type) {
			case UserDocumentTypes.CorporateInvestorTradeLicense: {
				return tradeLicenseFile?.id;
			}
			case UserDocumentTypes.CorporateInvestorMoaArticle: {
				return moaArticle?.id;
			}
			case UserDocumentTypes.CorporateInvestorDelegationAuthority: {
				return delegationAuthority?.id;
			}
			case UserDocumentTypes.CorporateInvestorPassportCopy: {
				return passport?.id;
			}
			case UserDocumentTypes.CorporateInvestorEvidenceEntityFund: {
				return evidenceEntityFund?.id;
			}
			case UserDocumentTypes.CorporateInvestorClientClassificationQn: {
				return clientClassification?.id;
			}
			default: {
				break;
			}
		}
	};

	const onDeleteFile = (type) => {
		let id = getCurrentDocumentId(type);
		if (id) {
			startStopLoader(type, true);
			userService
				.deleteFile(id)
				.then((resp) => {
					setDocumentValues(null, type);
					startStopLoader(type, false);
				})
				.catch((err) => {
					startStopLoader(type, false);
				});
		}
	};

	const actionSection = (file, type) => {
		return (
			<Box
				data-testid={type}
				className="flex flex-row items-center justify-end">
				<input
					accept="image/*,.pdf"
					type="file"
					id={'fileUploader' + type}
					hidden
					onChange={(e) => {
						onFileUpload(e.target.files, type);
					}}
				/>
				<ButtonWrapper
					classList="!bg-[#f7f7f7] !text-primary"
					iconButton
					loading={loadingUploadDocs[type]}
					hideBtn={file?.file?.url}
					icon={<CloudUploadOutlinedIcon />}></ButtonWrapper>
				{file?.file?.url ? (
					<Box className="flex flex-0 items-center">
						<a onClick={(e) => {
							onViewFile(file?.file?.url);
						}} className='underline-link'>{t('preview')}</a>
						<a onClick={(e) => {
							onDeleteFile(type);
						}} className='underline-link !mx-2 !text-red-400'>{t('delete')}</a>
					</Box>
				) : null}
			</Box>
		);
	};

	return (
		<Box className="flex flex-col space-y-4">
			<Box className='p-2' component={Card}>
				<Box
					onClick={() => {
						onUploadFileClick(
							fileTypes.CorporateInvestorTradeLicense,
							tradeLicenseFile
						);
					}}
					className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${tradeLicenseFile?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
					<Box className="flex flex-0 items-center">
						<Box>
							<ButtonWrapper
								disabled
								hideBtn={tradeLicenseFile?.file?.url}
								classList="!bg-[#f7f7f7] !text-primary"
								iconButton
								icon={
									<SummarizeOutlinedIcon />
								}></ButtonWrapper>
						</Box>
						<Box className="mx-2 font-[500] !text-sm">
							{t('tradeLicenseorCertificationofIncorporation')}
						</Box>
					</Box>
					<Box>
						{actionSection(
							tradeLicenseFile,
							fileTypes.CorporateInvestorTradeLicense
						)}
					</Box>
				</Box>
			</Box>
			<Box className='p-2' component={Card}>
				<Box
					onClick={() => {
						onUploadFileClick(fileTypes.CorporateInvestorMoaArticle, moaArticle);
					}}
					className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${moaArticle?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
					<Box className="flex flex-0 items-center">
						<Box>
							<ButtonWrapper
								disabled
								hideBtn={moaArticle?.file?.url}
								classList="!bg-[#f7f7f7] !text-primary"
								iconButton
								icon={
									<SummarizeOutlinedIcon />
								}></ButtonWrapper>
						</Box>
						<Box className="mx-2 font-[500] !text-sm">
							{t('moAorArticlesofAssociation')}
						</Box>
					</Box>
					<Box>
						{actionSection(moaArticle, fileTypes.CorporateInvestorMoaArticle)}
					</Box>
				</Box>
			</Box>
			<Box className='p-2' component={Card}>
				<Box
					onClick={() => {
						onUploadFileClick(
							fileTypes.CorporateInvestorDelegationAuthority,
							delegationAuthority
						);
					}}
					className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${delegationAuthority?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
					<Box className="flex flex-0 items-center">
						<Box>
							<ButtonWrapper
								disabled
								hideBtn={delegationAuthority?.file?.url}
								classList="!bg-[#f7f7f7] !text-primary"
								iconButton
								icon={
									<SummarizeOutlinedIcon />
								}></ButtonWrapper>
						</Box>
						<Box className="mx-2 font-[500] !text-sm">
							<Box>{t('ifadelegationofauthority')}</Box>
							<small>{t('ifadelegationofauthorityInfo')}</small>
						</Box>
					</Box>
					<Box>
						{actionSection(
							delegationAuthority,
							fileTypes.CorporateInvestorDelegationAuthority
						)}
					</Box>
				</Box>
			</Box>
			{!IS_SA ? (
				<>
					<Box className='p-2' component={Card}>
						<Box
							onClick={() => {
								onUploadFileClick(
									fileTypes.CorporateInvestorPassportCopy,
									passport
								);
							}}
							className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${passport?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
							<Box className="flex flex-0 items-center">
								<Box>
									<ButtonWrapper
										disabled
										hideBtn={passport?.file?.url}
										classList="!bg-[#f7f7f7] !text-primary"
										iconButton
										icon={
											<SummarizeOutlinedIcon />
										}></ButtonWrapper>
								</Box>
								<Box className="mx-2 font-[500] !text-sm">
									{t('passportCopyOftheSignatory')}
								</Box>
							</Box>
							<Box>
								{actionSection(passport, fileTypes.CorporateInvestorPassportCopy)}
							</Box>
						</Box>
					</Box>
					<Box className='p-2' component={Card}>
						<Box
							onClick={() => {
								onUploadFileClick(
									fileTypes.CorporateInvestorEvidenceEntityFund,
									evidenceEntityFund
								);
							}}
							className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${evidenceEntityFund?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
							<Box className="flex flex-0 items-center">
								<Box>
									<ButtonWrapper
										disabled
										hideBtn={evidenceEntityFund?.file?.url}
										classList="!bg-[#f7f7f7] !text-primary"
										iconButton
										icon={
											<SummarizeOutlinedIcon />
										}></ButtonWrapper>
								</Box>
								<Box className="mx-2 font-[500] !text-sm">
									{t('evidenceofYourEntityFunds')}
								</Box>
							</Box>
							<Box>
								{actionSection(
									evidenceEntityFund,
									fileTypes.CorporateInvestorEvidenceEntityFund
								)}
							</Box>
						</Box>
					</Box>
					<Box className='p-2' component={Card}>
						<Box
							onClick={() => {
								onUploadFileClick(
									fileTypes.CorporateInvestorClientClassificationQn,
									clientClassification
								);
							}}
							className={`border border-dashed flex justify-between items-center p-2 rounded-md cursor-pointer hover:shadow-md ${clientClassification?.file?.url ? '!border-none !p-0 hover:!shadow-none' : ''}`}>
							<Box className="flex flex-0 items-center">
								<Box>
									<ButtonWrapper
										disabled
										hideBtn={clientClassification?.file?.url}
										classList="!bg-[#f7f7f7] !text-primary"
										iconButton
										icon={
											<SummarizeOutlinedIcon />
										}></ButtonWrapper>
								</Box>
								<Box className="mx-2 font-[500] !text-sm flex flex-col">
									<Box>{t('clientClassificationQuestionnaire')}</Box>
									<small>
										{t('downloadTheApplicationFormFrom')}
										<a
											onClick={(event) => {
												event.stopPropagation();
											}}
											rel="noreferrer"
											target="_blank"
											className="underline-link mx-1 !text-[11.2px]"
											href={ClientClassficationDoc}>
											{t('here')}
										</a>
									</small>
								</Box>
							</Box>
							<Box>
								{actionSection(
									clientClassification,
									fileTypes.CorporateInvestorClientClassificationQn
								)}
							</Box>
						</Box>
					</Box>
				</>
			) : null}
		</Box>
	);
};
