/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { MarketplaceDetailLoader } from '../../../../../shared/components/investors/loaders/marketplaceDetailLoader';
import { useState } from 'react';
import { Section } from '../../../../../shared/components/common/section';
import LogoPlaceholder from '../../../../../assets/images/logo-placeholder.jpg';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { CURRENCY } from '../../../../../config/appConfig';
import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';
import { LanguageService } from '../../../../../services/languageService';
import { useEffect } from 'react';
import { MarketplaceService } from '../marketplaceService';
import { useNavigate, useParams } from 'react-router-dom';
import { DateHelper } from '../../../../../helpers/dateHelper';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { useTranslation } from 'react-i18next';

export const BusinessDetailsHistory = () => {
	const languageService = new LanguageService();
	const marketplaceService = new MarketplaceService();
	const params = useParams();
	const [loading, setLoading] = useState(false);
	const [businessDetails, setBusinessDetails] = useState({});
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		if (params?.id) {
			setLoading(true);
			marketplaceService
				.getMarketplaceHistoryDetails(params?.id)
				.then((resp) => {
					setBusinessDetails(resp.data);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const openWebsiteInNewTab = () => {
		if (businessDetails && businessDetails?.webSite) {
			const url = businessDetails?.webSite.startsWith('http')
				? businessDetails?.webSite
				: `http://${businessDetails?.webSite}`;

			return url;
		}
		return null;
	};

	const subItems = (name, value, isLast) => {
		return (
			<Section
				isShadow={false}
				classList={`!rounded-none !p-0 !flex !justify-center !items-center ${
					!isLast
						? languageService.isArabic()
							? 'md:border-l-2'
							: 'md:border-r-2'
						: ''
				}  `}
				children={
					<Stack className="text-center">
						<TextWrapper
							classList="!font-bold"
							text={`${value}`}></TextWrapper>
						<TextWrapper
							type="caption"
							text={t(name)}></TextWrapper>
					</Stack>
				}></Section>
		);
	};

	const getPercentage = () => {
		let investPercentage =
			(businessDetails?.paymentsMadeCount /
				businessDetails?.paymentsTotalCount) *
			100;
		investPercentage =
			investPercentage <= 100 ? investPercentage.toFixed(1) : 100;
		return investPercentage;
	};

	const openPaymentSchedule = () => {
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.Investor}/${AppRoutes.Marketplace}/${
					AppRoutes.MarketplaceBusinessHistory
				}/${`${businessDetails.id}`}/${AppRoutes.IPaymentSchedule}`
			)
		);
	};

	return (
		<Box className="grid grid-flow-row gap-4">
			{!loading ? (
				<>
					<Section
						classList="!p-0"
						children={
							<Box className="grid grid-cols-1 md:grid-cols-3">
								<Box className="h-full">
									<img
										className="!h-full object-fill min-h-[310px]"
										src={
											businessDetails?.logo
												? businessDetails?.logo
												: LogoPlaceholder
										}
										alt="Marketplace Logo"></img>
								</Box>
								<Box className="md:col-span-2 p-4">
									<Stack
										gap={1}
										direction={'column'}>
										<TextWrapper
											classList="!heading"
											text={
												<span className="flex items-center">
													<span>{businessDetails?.businessName}</span>
													{openWebsiteInNewTab() ? (
														<a
															href={openWebsiteInNewTab()}
															className="cursor-pointer mx-1">
															<LinkOutlinedIcon color="primary"></LinkOutlinedIcon>
														</a>
													) : null}
												</span>
											}></TextWrapper>
										<Box
											component={Paper}
											className="flex flex-col flex-0 p-3">
											<Box className="flex md:flex-row flex-col  md:justify-between items-center">
												<Box className="grid grid-flow-row">
													<TextWrapper
														classList="!heading !text-primary"
														text={`${CURRENCY} ${currencyFormatterHelper(
															businessDetails?.loanAmount
														)}`}></TextWrapper>
													<TextWrapper
														type="caption"
														text={t('requestedAmount')}></TextWrapper>
												</Box>
												<Box className="flex flex-0 items-center mt-6 md:mt-0">
													<Box className="grid grid-flow-row gap-1 justify-center items-center">
														<Box className="h-20 w-20 items-center flex justify-center rounded-full border-[4px] border-solid">
															<TextWrapper
																type="span"
																classList="!font-bold"
																text={`${
																	businessDetails?.fixed || 0
																}%`}></TextWrapper>
														</Box>
														<TextWrapper
															classList="!text-center"
															type="caption"
															text={t('peryear')}></TextWrapper>
													</Box>
													<Box className="grid grid-flow-row gap-1 justify-center items-center mx-5">
														<Box className="h-20 w-20  items-center flex justify-center rounded-full border-[4px] border-solid">
															<TextWrapper
																type="span"
																classList="!font-bold"
																text={`${
																	businessDetails?.month || 0
																}`}></TextWrapper>
														</Box>
														<TextWrapper
															classList="!text-center"
															type="caption"
															text={t('months')}></TextWrapper>
													</Box>
													<Box className="grid grid-flow-row gap-1 justify-center items-center">
														<Box className="h-20 w-20 items-center flex justify-center rounded-full border-[4px] border-solid">
															<TextWrapper
																type="span"
																classList="!font-bold"
																text={businessDetails?.rating}></TextWrapper>
														</Box>
														<TextWrapper
															classList="!text-center"
															type="caption"
															text={t('rating')}></TextWrapper>
													</Box>
												</Box>
											</Box>
											<hr className="my-2"></hr>
											<Box
												className="card flex flex-0 flex-col items-start relative mt-4 mb-2"
												sx={{ width: '100%' }}>
												<LinearProgress
													className="w-full !h-6 rounded-lg"
													variant="determinate"
													value={getPercentage()}
												/>
												<Box className="w-full absolute">
													<Typography
														style={{
															padding: '0px 10px',
															width: `${
																+getPercentage() >= 10
																	? +getPercentage()
																	: '100'
															}%`,
														}}
														className="absolute !font-bold text-end top-[3px]  !text-white"
														variant="body2"
														color="text.secondary">
														{getPercentage()}%
													</Typography>
												</Box>
												<Box className="flex flex-0 justify-between items-center w-full">
													<TextWrapper
														classList="!mt-2"
														type="caption"
														text={`${t('paymentsMande')} : ${
															businessDetails?.paymentsMadeCount
														} of ${
															businessDetails?.paymentsTotalCount
														}`}></TextWrapper>
												</Box>
											</Box>
											<hr className="my-2"></hr>
											<Box className="grid grid-flow-row gap-1">
												<TextWrapper
													text={`${t(
														'investedAmount'
													)}: ${CURRENCY} ${currencyFormatterHelper(
														businessDetails?.loanAmount
													)}`}></TextWrapper>
												<TextWrapper
													text={`${t(
														'nextPaymentDate'
													)} ${DateHelper.formatDate(
														businessDetails?.nextPaymentDate
													)}`}></TextWrapper>
												<TextWrapper
													text={`${t(
														'nextPaymentAmount'
													)} ${CURRENCY} ${currencyFormatterHelper(
														businessDetails?.nextPaymentAmount
													)}`}></TextWrapper>
											</Box>
											<Box className="md:text-end text-center md:mt-0 mt-3">
												<a
													onClick={() => {
														openPaymentSchedule();
													}}
													className="underline-link">
													{t('repayments')}
													<ArrowIcons classList={'!text-sm'}></ArrowIcons>
												</a>
											</Box>
										</Box>
									</Stack>
								</Box>
							</Box>
						}></Section>
					<TextWrapper
						type="caption"
						text={t('assignedRisk')}></TextWrapper>
					<Section
						children={
							<Box className="grid grid-cols-2 md:grid-cols-4 gap-3">
								{subItems('Industry', `${businessDetails?.industry || '---'}`)}
								{subItems(
									'Business type',
									`${businessDetails?.businessType || '---'}`
								)}
								{subItems('Country', `${businessDetails?.region || '---'}`)}
								{subItems(
									'purpose',
									`${businessDetails?.purpose || '---'}`,
									true
								)}
								{subItems(
									'repaymentType',
									`${businessDetails?.paymentFrequency || '---'}`
								)}
								{subItems(
									'yearsTrading',
									`${businessDetails?.yearsTrading || '---'}`,
									true
								)}
							</Box>
						}></Section>
					<Section
						hide={!businessDetails?.description}
						children={
							<Box>
								<p
									dangerouslySetInnerHTML={{
										__html: `${businessDetails?.description}`,
									}}
									className="!sub-title-text text-center"></p>
							</Box>
						}></Section>
					<TextWrapper
						type="caption"
						text="The information provided herein about the borrower is not
                  checked or approved by the DFSA"></TextWrapper>
				</>
			) : (
				<MarketplaceDetailLoader></MarketplaceDetailLoader>
			)}
		</Box>
	);
};
