import { Alert, Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseRegistration } from '../../../core/baseRegistration';
import { InvestorChannels } from '../../../../../constants/investorChannels';
import { Toaster } from '../../../../../shared/components/common/toaster';
import { useState } from 'react';
import { UserService } from '../../../../../services/userService';
import { Logout, loginUsingToken } from '../../../../../services/authService';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { IS_SA } from '../../../../../config/appConfig';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';

export const InvestorBaseRegistration = () => {
	const systemSettingsService = new SystemSettingsService();
	const userService = new UserService();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [showOTPAuth, setShowOTPAuth] = useState(false);

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	const onSubmit = (values) => {
		const data = {
			...values,
			referral: localStorage.getItem('ref'),
			channel: InvestorChannels.Core,
		};
		if (!data.termsConditionsForInvestmentsAccepted) {
			Toaster({ msg: t('termsAndConditionsRequired') });
			return;
		}

		setLoading(true);
		userService
			.registerInvestor(data)
			.then((resp) => {
				let tokenModel = resp.data;
				if (tokenModel) {
					setLoading(false);
					localStorage.removeItem('ref');
					loginUsingToken(tokenModel.token);
					if (tokenModel.otpRequested) {
						setShowOTPAuth(true);
					} else {
						onNavigateNextStep();
					}
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onNavigateNextStep = () => {
		let route = `${AppRoutes.InvestorRegistration}/${
			IS_SA ? AppRoutes.IRegNafathVerification : AppRoutes.IRegPersonalInfo
		}`;
		navigate(buildLocalizeRoute(route));
	};

	return (
		<Box className="flex flex-col">
			{!settings()?.registrationEnabled ? (
				<Box className="mb-4">
					<Alert
						data-testid="investorKSARegistrationInfo"
						severity="warning">
						{t('investorKSARegistrationInfoMessage')}
					</Alert>
				</Box>
			) : null}
			<Box className="justify-center items-center flex flex-col">
				<Box className="reregistration-wrapper m-auto flex-col overflow-auto md:p-2">
					<Box
						component={Card}
						className="registration-card !rounded-lg md:!px-10 md:!py-8 md:w-[430px] w-full">
						<h1 className="text-[24px] font-[500] mb-6 !text-center">
							{t('investorForRegistration')}
						</h1>
						<BaseRegistration
							loading={loading}
							clientType={'investor'}
							onSubmitForm={(values) => {
								onSubmit(values);
							}}></BaseRegistration>
						{showOTPAuth ? (
							<OTPAuthenticationDialog
								type={OTPTypes.Authentication}
								onClose={(isVerified) => {
									setShowOTPAuth(false);
									if (isVerified) {
										onNavigateNextStep();
									} else {
										Logout();
										navigate(buildLocalizeRoute(AppRoutes.Login));
									}
								}}
								state={showOTPAuth}></OTPAuthenticationDialog>
						) : null}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
