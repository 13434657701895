import { Navigate } from 'react-router-dom';
import {
	getRegistrationData,
	getRegistrationInfo,
	getUser,
} from '../services/authService';
import { AppRoutes } from '../constants/appRoutes';
import { buildLocalizeRoute } from '../helpers/languageHelper';
import { checkIsBorrowerOrInvestor } from '../helpers/loginCheckHelper';
import { Roles } from '../constants/roles';
import { getCurrentUserPath } from '../helpers/authHelper';
import { InvestorStatus } from '../constants/investorStatus';
import { IS_SA } from '../config/appConfig';

export const InvestorRole = ({ children }) => {
	const user = getUser();
	if (user && user?.role === Roles.Investor) {
		if (!user?.isApproved) {
			<Navigate
				to={buildLocalizeRoute(
					`${AppRoutes.Investor}/${AppRoutes.ThankYou}`
				)}></Navigate>;
		}
		return children;
	}

	return <Navigate to={buildLocalizeRoute('')}></Navigate>;
};

export const BorrowerRole = ({ children }) => {
	const user = getUser();
	if (user && user?.role === Roles.Borrower) {
		if (!user?.isApproved) {
			<Navigate
				to={buildLocalizeRoute(
					`${AppRoutes.Borrower}/${AppRoutes.ThankYou}`
				)}></Navigate>;
		}
		return children;
	}

	return <Navigate to={buildLocalizeRoute('')}></Navigate>;
};

export const CheckProfileAuth = ({ children }) => {
	if (getUser()) {
		return children;
	}
	return <Navigate to={buildLocalizeRoute(`${AppRoutes.Login}`)}></Navigate>;
};

export const CheckLogin = ({ children }) => {
	const user = getUser();
	if (!user?.isApproved || !user?.OtpVerified) {
		const requestedHref = `${document.location.pathname}${document.location.search}`;
		const hasSAinUrl = requestedHref.split('/sa/').length;
		const returnLangUrl =
			hasSAinUrl.length > 1 ? requestedHref.split('/sa')[1] : requestedHref;
		const returnUrl = returnLangUrl?.includes('/en/')
			? returnLangUrl.split('/en/')[1]
			: returnLangUrl.includes('/ar/')
			? returnLangUrl.includes('/ar/')[1]
			: returnLangUrl;
		return (
			<Navigate
				to={buildLocalizeRoute(
					`${AppRoutes.Login}?returnUrl=${returnUrl}`
				)}></Navigate>
		);
	}

	return children;
};

export const ThankYouPageGuard = ({ children }) => {
	const user = getUser();
	if (user) {
		return children;
	}
	return <Navigate to={buildLocalizeRoute(AppRoutes.Login)}></Navigate>;
};

export const AlreadyLogin = ({ children }) => {
	const user = getUser();
	const regInfo = getRegistrationInfo();

	if (!user) {
		return children;
	}

	if (!regInfo) {
		if (user?.role === Roles.Investor) {
			return IS_SA ? (
				<Navigate
					to={buildLocalizeRoute(
						`${AppRoutes.InvestorRegistration}/${AppRoutes.IRegNafathVerification}`
					)}></Navigate>
			) : (
				<Navigate
					to={buildLocalizeRoute(
						`${AppRoutes.InvestorRegistration}/${AppRoutes.IRegPersonalInfo}`
					)}></Navigate>
			);
		} else if (user?.role === Roles.Borrower) {
			return (
				<Navigate
					to={buildLocalizeRoute(
						`${AppRoutes.BorrowerRegistartion}/${AppRoutes.BRegPersonalInfo}`
					)}></Navigate>
			);
		}
	}

	if (user && regInfo) {
		let route = checkIsBorrowerOrInvestor(regInfo, user);
		if (route) {
			return <Navigate to={buildLocalizeRoute(route)}></Navigate>;
		}

		if (user?.isApproved) {
			return <Navigate to={getCurrentUserPath()}></Navigate>;
		} else {
			if (user?.status === InvestorStatus.WindDown) {
				return (
					<Navigate to={buildLocalizeRoute(AppRoutes.WindDown)}></Navigate>
				);
			}
			let thankYouUrl =
				user?.role === Roles.Investor
					? AppRoutes.Investor
					: user?.role === Roles.Borrower
					? AppRoutes.Borrower
					: AppRoutes.Investor;
			thankYouUrl = `${thankYouUrl}/${AppRoutes.ThankYou}`;
			return <Navigate to={buildLocalizeRoute(thankYouUrl)}></Navigate>;
		}
	}
	return children;
};

export const RegistrationLoginCheck = ({ children }) => {
	const user = getUser();
	if (!user) {
		return children;
	}

	if ((user?.status && user?.status !== 'InComplete') || user?.isApproved) {
		return <Navigate to={buildLocalizeRoute(AppRoutes.Login)}></Navigate>;
	}

	return children;
};

export const NafathVerificationGuard = ({ children }) => {
	const user = getUser();
	const regInfo = getRegistrationInfo();
	const regData = getRegistrationData();
	if (user) {
		if (user.role === Roles.Investor) {
			if (regInfo?.investorStep > 0 || regData?.step > 0) {
				return (
					<Navigate
						to={buildLocalizeRoute(
							`${AppRoutes.InvestorRegistration}/${AppRoutes.IRegPersonalInfo}`
						)}></Navigate>
				);
			}
			return children;
		} else if (user.role === Roles.Borrower) {
			if (regInfo?.borrowerStep > 0 || regData?.step > 0) {
				let route = IS_SA
					? AppRoutes.CommercialRegistration
					: AppRoutes.BRegPersonalInfo;
				return (
					<Navigate
						to={buildLocalizeRoute(
							`${AppRoutes.BorrowerRegistartion}/${route}`
						)}></Navigate>
				);
			}
			return children;
		}
	}
	return <Navigate to={buildLocalizeRoute(AppRoutes.Login)}></Navigate>;
};
