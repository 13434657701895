import { axiosGet } from './axiosService';
const basePaymentURL = '/payments';

export class PaymentService {
	getPaymentSchedule() {
		return axiosGet(null, basePaymentURL + '/me');
	}

	getPaymentScheduleById(loanId) {
		return axiosGet(null, basePaymentURL + `/${loanId}`);
	}
}
