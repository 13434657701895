import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './components/navbar/index';
import { Box } from '@mui/material';
import { SubNavbar } from './components/subNavbar';
import { MobileSideBar } from './components/mobileSidebar';

const RootStyle = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100%',
	overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
	flexGrow: 1,
	overflow: 'hidden',
	minHeight: '100%',
	paddingTop: theme.spacing(3),
	paddingBottom: theme.spacing(1),
	paddingLeft: theme.spacing(1),
	paddingRight: theme.spacing(1),
	backgroundColor: '#f5f5f5',
	[theme.breakpoints.up('md')]: {
		paddingTop: theme.spacing(0),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingBottom: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
}));

const InvestorLayout = () => {
	const [open, setOpen] = useState(false);

	return (
		<RootStyle>
			<Box>
				<DashboardNavbar onOpenSidebar={() => setOpen(true)} />
				<SubNavbar></SubNavbar>
				<MobileSideBar
					isOpenSidebar={open}
					onCloseSidebar={() => setOpen(false)}></MobileSideBar>
			</Box>
			<MainStyle className="md:!pt-0">
				<Box className="xl:w-[1140px] w-full py-3 sm:py-0 md:py-3 lg:py-3 md:mt-[132px] mt-[55px]">
					<Outlet />
				</Box>
			</MainStyle>
		</RootStyle>
	);
};
export default InvestorLayout;
