import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SAflag from '../assets/images/flag-sa.svg';

export const RegionTypes = {
	Global: 'ae',
	SaudiArabia: 'sa',
};

export const Regions = [
	{ name: 'Global', code: RegionTypes.Global, icon: <LanguageOutlinedIcon /> },
	{ name: 'Saudi Arabia', code: RegionTypes.SaudiArabia, icon: SAflag },
];
