/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextWrapper } from '../../../../../components/formUtilities/useTextWrapper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LanguageService } from '../../../../../../services/languageService';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import { SystemSettingsService } from '../../../../../../services/systemSettingsService';
import { useEffect } from 'react';
import { useState } from 'react';
import { ButtonWrapper } from '../../../../../components/formUtilities/useButton';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { useConfirm } from 'material-ui-confirm';
import { InvestorGuidelinePopover } from '../../../../../components/investors/investorGuidlinePopover';
import { InvestorGuidelineService } from '../../../../../../services/guidelineService';
import { InvestorGuidelineSteps } from '../../../../../components/investors/constants/guidlineSteps';
import { useTranslation } from 'react-i18next';
import { currencyFormatterHelper } from '../../../../../../helpers/currencyFormatterHelper';
import { CURRENCY } from '../../../../../../config/appConfig';
import { InvestorService } from '../../../../../../pages/investor/investorService';
import { Observables } from '../../../../../../observables';
import { RegionService } from '../../../../../../services/regionService';

export const SubNavbar = () => {
	const navigate = useNavigate();
	const systemSettingsService = new SystemSettingsService();
	const languageService = new LanguageService();
	const guideService = new InvestorGuidelineService();
	const investorService = new InvestorService();
	const regionService = new RegionService();
	const guideSteps = InvestorGuidelineSteps;
	const location = useLocation();
	const confirm = useConfirm();
	const langRoute = languageService.getCurrentLanguage();
	const [marketplaceGuide, setMarketplaceGuide] = useState(false);
	const [investmentGuide, setInvestmentGuide] = useState(false);
	const [investSettingsGuide, setInvestSettingsGuide] = useState(false);
	const [depositGuide, setDepositGuide] = useState(false);
	const [availableCash, setAvailableCash] = useState(0);
	const { t } = useTranslation();
	const region = regionService.currentRegion();
	const [currentStep, setCurrentStep] = useState(guideService.stepCount());
	const [subNavLinks, setSubNavLinks] = useState([
		{
			name: t('investorNavbarDashboard'),
			linkTo: '',
			classList: `${languageService.isArabic() ? '!pl-2' : '!pr-2'}`,
			path: AppRoutes.Investor,
			id: 1,
		},
		{
			name: t('marketplace'),
			linkTo: AppRoutes.Marketplace,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.Marketplace}`,
			id: 2,
		},
		{
			name: t('myInvestment'),
			linkTo: AppRoutes.MyInvestments,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.MyInvestments}`,
			id: 3,
		},
		{
			name: t('investSettings'),
			linkTo: AppRoutes.InvestmentSettings,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.InvestmentSettings}`,
			id: 4,
		},
		{
			name: t('transfers'),
			linkTo: AppRoutes.ITransfer,
			classList: 'px-2',
			path: `${AppRoutes.Investor}/${AppRoutes.ITransfer}`,
			id: 5,
		},
	]);

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		if (guideService.stepCount() === guideSteps.AddFund) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
			setDepositGuide(true);
		}
	});

	useEffect(() => {
		getSummaryShort();
	}, [location]);

	useEffect(() => {
		if (guideService.stepCount() === guideSteps.NavMarketPlace) {
			setMarketplaceGuide(true);
			setInvestmentGuide(false);
			setInvestSettingsGuide(false);
			setDepositGuide(false);
		} else if (guideService.stepCount() === guideSteps.NavPortfolio) {
			setMarketplaceGuide(false);
			setInvestmentGuide(true);
			setInvestSettingsGuide(false);
			setDepositGuide(false);
		} else if (guideService.stepCount() === guideSteps.NavInvestSettings) {
			setMarketplaceGuide(false);
			setInvestmentGuide(false);
			setInvestSettingsGuide(true);
			setDepositGuide(false);
		} else if (guideService.stepCount() === guideSteps.AddFund) {
			setMarketplaceGuide(false);
			setInvestmentGuide(false);
			setInvestSettingsGuide(false);
			setDepositGuide(true);
		} else {
			setMarketplaceGuide(false);
			setInvestmentGuide(false);
			setInvestSettingsGuide(false);
			setDepositGuide(false);
		}
	}, [currentStep]);

	useEffect(() => {
		let subNav = subNavLinks;
		if (settings()) {
			subNav.forEach((resp, index) => {
				if (resp.id === 2 && !settings()?.enableMarketplace) {
					subNav.splice(index, 1);
				}
			});
		}
		setSubNavLinks([...subNav]);
		getSummaryShort();
		Observables.investorSummaryRefresh.subscribe((resp) => {
			if (resp === 1) {
				setTimeout(() => {
					getSummaryShort();
				}, 2000);
			}
		});
	}, []);

	const getSummaryShort = () => {
		investorService
			.getInvestorSummaryShort()
			.then((resp) => {
				setAvailableCash(resp.data?.availableCash);
			})
			.catch((err) => {});
	};

	const isMainPagesActive = () => {
		if (
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IWithdraw}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.ITransactionHistory}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.Marketplace}/upcoming` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.Marketplace}/past` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.Marketplace}/current` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.Marketplace}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.MyInvestments}` ||
			window.location.pathname ===
				`/${region}/${langRoute}/${AppRoutes.Investor}/${AppRoutes.InvestmentSettings}`
		) {
			return true;
		}
		return false;
	};

	const checkIsActive = (relativePath) => {
		if (relativePath === `${AppRoutes.Investor}/${AppRoutes.ITransfer}`) {
			return (
				window.location.pathname.includes(AppRoutes.IDeposit) ||
				window.location.pathname.includes(AppRoutes.IWithdraw) ||
				window.location.pathname.includes(AppRoutes.ITransactionHistory)
			);
		}
		return (
			`/${region}/${langRoute}/${relativePath}` === window.location.pathname
		);
	};

	const onAddFunds = () => {
		if (settings()?.enableDeposits) {
			navigate({
				pathname: `${buildLocalizeRoute(
					`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`
				)}`,
			});
		} else {
			confirm({
				description: `Funding Souq has paused accepting any
				deposits in the upcoming months. Once we are back,
				we'll email you accordingly. For more information,
				reach out to support@fundingsouq.com`,
				title: 'We Paused Deposits!',
				confirmationButtonProps: {
					className: '!primary-bg !text-white',
				},
				hideCancelButton: 'true',
				confirmationText: 'Ok',
			})
				.then(() => {})
				.catch(() => console.log('cancelled.'));
		}
	};

	return (
		<Box className="h-[51px] items-center w-full !hidden md:!flex dark-bg justify-center md:mt-[80px] !fixed z-[99]">
			<Box className="xl:w-[1140px] w-full xl:p-0 px-2 flex items-center justify-between">
				{isMainPagesActive() ? (
					<Box className="flex items-center">
						{subNavLinks.map((path, index) => {
							return (
								<Box
									className="relative"
									key={index}>
									<Link
										to={path.linkTo}
										className={`nav-non-selected font-[600] hover:text-white text-[16px] relative sub-nav-items ${
											checkIsActive(path.path) ? '!text-white' : ''
										} ${path.classList ? path.classList : ''}`}>
										{path.name}
									</Link>
									{marketplaceGuide && path.id === 2 ? (
										<InvestorGuidelinePopover
											onNext={(count) => {
												setCurrentStep(count);
											}}
											state={marketplaceGuide}
											message={'guideNavMarketPlace'}
											top={'30px'}
											left={'-90px'}></InvestorGuidelinePopover>
									) : null}
									{investmentGuide && path.id === 3 ? (
										<InvestorGuidelinePopover
											onNext={(count) => {
												setCurrentStep(count);
											}}
											state={investmentGuide}
											message={'guideNavPortfolio'}
											top={'30px'}
											left={'-75px'}></InvestorGuidelinePopover>
									) : null}
									{investSettingsGuide && path.id === 4 ? (
										<InvestorGuidelinePopover
											redirect={`${AppRoutes.Investor}/${AppRoutes.InvestmentSettings}`}
											onNext={(count) => {
												setCurrentStep(count);
											}}
											state={investSettingsGuide}
											message={'guideAutoInvestNav'}
											top={'30px'}
											left={'-75px'}></InvestorGuidelinePopover>
									) : null}
								</Box>
							);
						})}
					</Box>
				) : (
					<Box
						className="flex items-center text-white cursor-pointer nav-back-btn"
						onClick={() => {
							if (window.location.pathname.includes(`/${AppRoutes.Profile}/`)) {
								navigate(-2);
							} else {
								navigate(-1);
							}
						}}>
						{languageService.isArabic() ? (
							<>
								<ArrowForwardIcon className="nav-back-icon-ar" />
							</>
						) : (
							<>
								<ArrowBackIcon className="nav-back-icon-en" />
							</>
						)}

						<TextWrapper
							classList="!mx-2"
							text={t('back')}></TextWrapper>
					</Box>
				)}
				<Stack
					id="depositBtn"
					className="items-center relative"
					gap={2}
					direction={'row'}>
					<Box className={`flex flex-row items-center !text-white !text-sm `}>
						<Box className="available-fund-text">{t('availableFunds')}:</Box>
						<Box className="mx-2 font-bold available-fund">
							{CURRENCY} {currencyFormatterHelper(availableCash)}
						</Box>
					</Box>
					<ButtonWrapper
						onClick={() => {
							onAddFunds();
						}}
						classList="md:!min-w-[130px]"
						label={t('deposit')}
						icon={
							<PaymentsOutlinedIcon></PaymentsOutlinedIcon>
						}></ButtonWrapper>
					{depositGuide ? (
						<InvestorGuidelinePopover
							onNext={(count) => {
								setCurrentStep(count);
							}}
							state={depositGuide}
							message={'guideAddFund'}
							top={'40px'}
							left={'120px'}></InvestorGuidelinePopover>
					) : null}
				</Stack>
			</Box>
		</Box>
	);
};
