/* eslint-disable react/jsx-pascal-case */
import { rowsPerPageOptions } from '../../../constants/rowPerPage';
import Box from '@mui/material/Box';
import { TextWrapper } from './useTextWrapper';
import VerticalSplitOutlinedIcon from '@mui/icons-material/VerticalSplitOutlined';
import { ButtonWrapper } from './useButton';
import { MenuItem, Paper, Switch, TableContainer } from '@mui/material';
import { lazy, useEffect, useRef, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MenuPopover from '../../layouts/mainLayout/borrowerLayout/shared/components/menuPopover';
import { UtilitiesService } from '../../../services/utilitiesService';
import { useTranslation } from 'react-i18next';

const MaterialReactTable = lazy(() => import('material-react-table'));

const serialNumberHeader = {
	header: 'Sl.No ',
	accessorKey: 'serialNumber',
	enableEditing: false,
	maxSize: 100,
};

const utilitiesService = new UtilitiesService();

export default function DataTableGrid({
	name,
	columns = [],
	records = [],
	isPagination = true,
	pagination,
	loading = false,
	heading = null,
	showEditColumnsButton = true,
	showDownloadCSV = false,
	downloadBtnClass = '',
	downloadBtnVariant = 'contained',
	downloadBtnText = '',
	rowHeight = 'comfortable',
	actionButtons = null,
	columnPinning = [],
	columnPinningLeft = [],
	isEditTable = false,
	hideSerialNumbers = false,
	isShowDetailTab = false,
	loadingDownload = false,
	customDownloadBtn = null,
	tableClass = '',
	headingClass = '',
	DetailTabContent = <></>,
	actionButtonContainerClass = '',
	onRowClick = (e) => { },
	onPageChange = (e) => { },
	onPageSizeChange = (e) => { },
	download = (e) => { },
	onCellClick = (e) => { },
	handleSaveCell = (cell, value) => { },
}) {
	const tableInstanceRef = useRef(null);
	const [visibilityModel, setVisibilityModel] = useState({});
	const [open, setOpen] = useState(null);
	const [isExpand, setIsExpand] = useState(false);
	const { t } = useTranslation();

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const getAllVisiblityModel = (tblName, columns = []) => {
		let visibilityModel = {};

		let storedVisibilityModel =
			JSON?.parse(utilitiesService.getLocalStorage({ item: tblName })) || null;

		if (storedVisibilityModel) {
			return storedVisibilityModel;
		}

		columns.forEach((res) => {
			Object.assign(visibilityModel, { [res.accessorKey]: !res?.hide });
		});
		return visibilityModel;
	};

	const onColumnChange = (col) => {
		let oldVisibilityModel = getAllVisiblityModel(name, columns);
		oldVisibilityModel[col.accessorKey] = !col.hide;
		utilitiesService.setLocalStorage({
			item: name,
			value: JSON.stringify(oldVisibilityModel),
		});
		setVisibilityModel(getAllVisiblityModel(name, columns));
	};

	const getActualColumns = (tblName, columns = []) => {
		let visibilityModel = getAllVisiblityModel(tblName, columns);
		columns.forEach((col) => {
			col.hide = !visibilityModel[col.accessorKey];
			col.enableEditing = col?.enableEditing ? true : false;
		});
		if (!hideSerialNumbers) {
			columns = [serialNumberHeader, ...columns];
		}
		return columns;
	};

	const getChildrenRows = () => {
		records.forEach((resp, index) => {
			if (!hideSerialNumbers) {
				resp[serialNumberHeader.accessorKey] =
					((pagination?.currentPage || 1) - 1) * (pagination?.perPage || 0) +
					index +
					1;
			}
		});
		return records;
	};

	useEffect(() => {
		if (showEditColumnsButton) {
			setVisibilityModel(getAllVisiblityModel(name, columns));
		}
	}, [columns, name, showEditColumnsButton]);

	if (showEditColumnsButton) {
		columns = getActualColumns(name, columns);
	}

	records = getChildrenRows();

	useEffect(() => {
		tableInstanceRef?.current.setColumnVisibility(visibilityModel);
	}, [visibilityModel]);

	useEffect(() => {
		if (records && records.length) {
			setIsExpand(!!records.find((resp) => 'children' in resp));
		}
	}, [records]);

	return (
		<TableContainer
			className={tableClass}
			component={Paper}
			sx={{ width: '100%' }}>
			{(heading || showEditColumnsButton || showDownloadCSV) && (
				<Box className={`w-full text-start px-3 pt-5 mb-5 flex flex-col md:flex-row justify-between items-center ${headingClass}`}>
					<TextWrapper
						classList="!mb-2 md:!mb-0 w-full md:w-auto"
						type="h6"
						text={heading}></TextWrapper>
					<Box className={`grid grid-cols-1 md:grid-flow-col gap-2 ${actionButtonContainerClass}`}>
						{showEditColumnsButton ? (
							<>
								<ButtonWrapper
									disabled={loading}
									classList="md:w-[140px] w-full"
									onClick={handleOpen}
									icon={<VerticalSplitOutlinedIcon />}
									label={t('fields')}
									variant="outlined"></ButtonWrapper>
							</>
						) : null}
						{showDownloadCSV ? (
							customDownloadBtn ? (
								customDownloadBtn
							) : (
								<ButtonWrapper
									loading={loadingDownload}
									variant={downloadBtnVariant}
									disabled={loading || !records?.length || loadingDownload}
									classList={`md:min-w-[140px] w-full ${downloadBtnClass}`}
									onClick={download}
									icon={<DownloadIcon />}
									label={t(
										downloadBtnText ? downloadBtnText : 'download'
									)}></ButtonWrapper>
							)
						) : null}
						{actionButtons ? actionButtons : null}
					</Box>

					<MenuPopover
						open={Boolean(open)}
						anchorEl={open}
						onClose={handleClose}
						sx={{
							p: 0,
							mt: 1.5,
							ml: 0.75,
							minWidth: '300px',
							maxHeight: '400px',
							overflow: 'auto',
							'& .MuiMenuItem-root': {
								typography: 'body2',
								borderRadius: 0.75,
							},
						}}>
						{columns.map((col, index) => {
							return (
								<MenuItem
									key={col.accessorKey}
									disabled={col.accessorKey === 'action'}>
									<Box className="flex justify-between items-center w-full">
										<Box className="caption">{col.header}</Box>
										<Switch
											checked={!col.hide}
											onChange={(e) => {
												col.hide = !col.hide;
												onColumnChange(col);
											}}
											size="small"></Switch>
									</Box>
								</MenuItem>
							);
						})}
					</MenuPopover>
				</Box>
			)}
			<MaterialReactTable
				renderDetailPanel={
					!isShowDetailTab
						? null
						: (row) => {
							return <DetailTabContent row={row}></DetailTabContent>;
						}
				}
				paginateExpandedRows={false}
				getSubRows={(originalRow) => originalRow?.children}
				enableEditing={isEditTable}
				enableExpanding={isExpand}
				editingMode="table"
				tableInstanceRef={tableInstanceRef}
				enableColumnOrdering={false}
				enableFullScreenToggle={false}
				enablePagination={isPagination && records?.length ? true : false}
				enableColumnActions={false}
				enableTopToolbar={false}
				enablePinning={true}
				muiTablePaperProps={{ elevation: 0 }}
				muiTableBodyProps={{
					sx: {
						'& tr:nth-of-type(odd)': {
							backgroundColor: '#f5f5f5',
						},
						'& tr:hover': {
							'& td': {
								backgroundColor: 'white',
							},
						},
					},
				}}
				muiTableBodyCellCopyButtonProps={({ cell }) => ({
					onClick: () => {
						// temporary fix, need to find better solution for copy the content from objects.
						if (cell.column.id === 'contactNumber') {
							navigator.clipboard.writeText(
								`${cell.row.original.contactNumber.code} ${cell.row.original.contactNumber.number}`
							);
						} else {
							navigator.clipboard.writeText(
								`${cell.row.original[cell.column.id]}`
							);
						}
					},
					startIcon: <ContentCopyOutlinedIcon />,
				})}
				muiTableBodyCellEditTextFieldProps={({ cell }) => ({
					onChange: (event) => {
						handleSaveCell(cell, event.target.value);
					},
					variant: 'outlined',
					className: 'inline-edit-field',
				})}
				muiTableHeadCellProps={{ sx: { textAlign: 'start' } }}
				muiTableBodyCellProps={({ cell }) => ({
					className: 'hover:!outline-0',
					sx: { textAlign: 'start' },
					onClick: (event) => {
						const values = {
							row: cell.row?.original,
							field: cell.column.id,
							cell: cell,
						};

						onCellClick && onCellClick(values);
					},
				})}
				initialState={{
					columnPinning: { right: columnPinning, left: columnPinningLeft },
					density: 'comfortable',
				}}
				enableDensityToggle={false}
				columns={columns}
				enableFilters={false}
				enableSorting={false}
				data={records}
				manualFiltering={true}
				muiTablePaginationProps={{
					onRowsPerPageChange: (e) => {
						onPageSizeChange && onPageSizeChange(e.target.value);
					},
					onPageChange: (e, page) => {
						onPageChange(page);
					},
					rowsPerPageOptions: rowsPerPageOptions,
					showFirstButton: true,
					showLastButton: true,
				}}
				renderEmptyRowsFallback={() => {
					return (
						<Box className="p-[30px] text-center !text-[#7a7a7a] italic">
							{t('noRecordsToDisplay')}
						</Box>
					);
				}}
				pageCount={pagination?.totalCount || records?.length}
				state={{
					density: rowHeight === 'auto' ? 'comfortable' : 'compact',
					showSkeletons: loading,
					pagination: {
						pageIndex: pagination?.currentPage - 1 || 0,
						pageSize: pagination?.perPage || 10,
					},
				}}
				rowCount={
					pagination?.totalCount || records?.length
				}></MaterialReactTable>
		</TableContainer>
	);
}
