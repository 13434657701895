import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../../../helpers/formHelper';
import { Box } from '@mui/material';
import { InputWrapper } from '../../../../../../shared/components/formUtilities/useInput';
import SelectWrapper from '../../../../../../shared/components/formUtilities/useSelect';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { useEffect } from 'react';
import { InvestorRegService } from '../../../investorRegService';
import {
	getRegistrationData,
	saveToken,
	setRegistrationData,
	updateInvestorSteps,
} from '../../../../../../services/authService';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { useNavigate } from 'react-router-dom';
import { IS_SA } from '../../../../../../config/appConfig';
import { useState } from 'react';
import { ArrowIcons } from '../../../../../../shared/components/common/arrowIcons';
import { useTranslation } from 'react-i18next';
import { InvestorRegistrationBusinesSchema } from '../../../../../../shared/validations/schema';
import { InvestorDeclarationDialog } from '../../declarationDialog';
import { useSelector } from 'react-redux';
import { SubStepper } from '../../../../components/subStepper';
import { taxResidencyHelper } from '../../../../../../helpers/taxResidencyHelper';

export const InvestorBusinessInformation = ({
	onGoBack = () => {},
	isLoading = false,
}) => {
	const investorRegService = new InvestorRegService();
	const { investorCollections } = useSelector(
		(state) => state.investorCollectionReducer
	);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const [showDeclarationPopup, setShowDeclarationPopup] = useState(false);
	const [loading, setLoading] = useState(isLoading);

	const countries = investorCollections?.countryCollection;

	const form = useFormik({
		initialValues: {
			businessName: '',
			tradeLicenseNumber: '',
			registrationCountryId: '',
			taxResidency: FormHelper.getTaxResidencies(null),
		},
		validationSchema: InvestorRegistrationBusinesSchema,
		onSubmit: (values) => {
			submit(values);
		},
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		investorRegService
			.getInvestorProfileBusinessInfo()
			.then((resp) => {
				let businessInfo = resp.data;
				let businessInfoData = {
					...businessInfo,
					tradeLicenseNumber: businessInfo?.tradeLicenseNumber || '',
					businessName: businessInfo?.businessName || '',
					registrationCountryId: businessInfo?.registrationCountry?.id,
					taxResidency: FormHelper.getTaxResidencies(
						businessInfo?.taxResidency
					),
				};
				setTimeout(() => {
					form.setValues(businessInfoData);
				});
			})
			.catch((err) => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submit = (values) => {
		setLoading(true);
		investorRegService
			.submitBusinessInformation(taxResidencyHelper(values))
			.then((resp) => {
				let registrationModel = resp.data?.registrationModel;
				saveToken(resp.data?.loginModel?.token);
				let regData = {
					...getRegistrationData(),
					step: registrationModel.step,
					requestedStep: 1,
				};
				setRegistrationData(regData);
				updateInvestorSteps(registrationModel.step);
				if (IS_SA) {
					setShowDeclarationPopup(true);
				} else {
					navigate(
						buildLocalizeRoute(
							`${AppRoutes.InvestorRegistration}/${AppRoutes.iRegDocumentVarification}`
						)
					);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onBack = () => {
		let data = {
			...getRegistrationData(),
			requestedStep: 0,
		};
		setRegistrationData(data);
		onGoBack();
	};

	const showTaxId2 = () =>
		form.values?.taxResidency?.countryId &&
		typeof form.values?.taxResidency?.countryId === 'number';

	return (
		<Box>
			<SubStepper
				stepName="businessInformation"
				currentStep={2}
				totalSteps={2}
			/>
			<FormikProvider value={form}>
				<Box className="flex flex-col space-y-4">
					<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<InputWrapper
							errorStateTestID="businessNameError"
							name={'businessName'}
							key={'businessName'}
							label={'businessTradingName'}></InputWrapper>
						<InputWrapper
							errorStateTestID="tradeLicenseNumberError"
							name={'tradeLicenseNumber'}
							key={'tradeLicenseNumber'}
							label={'tradeLicenseNumber'}></InputWrapper>
						<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
							<SelectWrapper
								errorStateTestID='registrationCountryIdError'
								name={'registrationCountryId'}
								key={'registrationCountryId'}
								items={countries}
								label={'countryOfRegistration'}
							/>
							<InputWrapper
								name={'taxResidency.taxId'}
								key={'taxResidency.taxId'}
								label={'taxId'}></InputWrapper>
						</Box>
						<Box className={`grid ${showTaxId2() ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1'} gap-4`}>
							<SelectWrapper
								defaultOptionText="no"
								defaultOptionValue={'no'}
								showDefaultOption
								items={countries}
								name={`taxResidency.countryId`}
								key={'taxResidency.countryId'}
								label={'areYouTaxedElsewhere'}
							/>
							{showTaxId2() ? (
								<InputWrapper
									name={'taxResidency.taxIdElsewhere'}
									key={'taxResidency.taxIdElsewhere'}
									label={'taxId2'}></InputWrapper>
							) : null}
						</Box>
					</Box>
					<Box className="flex md:justify-between justify-center">
						<ButtonWrapper
							dataTestID="backBtn"
							classList="!secondary-btn md:!mx-0 !mx-2"
							disabled={loading}
							largeBtn
							icon={<ArrowIcons isInvert></ArrowIcons>}
							label={t('back')}
							onClick={() => {
								onBack();
							}}
						/>
						<ButtonWrapper
							dataTestID="nextBtn"
							loading={loading}
							largeBtn
							endIcon={<ArrowIcons></ArrowIcons>}
							label={t('next')}
							onClick={() => {
								FormHelper.validateForm(form);
							}}
						/>
					</Box>
					{showDeclarationPopup ? (
						<InvestorDeclarationDialog
							state={showDeclarationPopup}
							onClose={(e) => {
								setShowDeclarationPopup(false);
							}}></InvestorDeclarationDialog>
					) : null}
				</Box>
			</FormikProvider>
		</Box>
	);
};
