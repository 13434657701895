/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper, Stack } from '@mui/material';
import { SectionWithHeading } from '../../../../shared/components/common/sectionWithHeading';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { FormikProvider, useFormik } from 'formik';
import { InputWrapper } from '../../../../shared/components/formUtilities/useInput';
import { CURRENCY, IS_SA } from '../../../../config/appConfig';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useEffect } from 'react';
import { BorrowerService } from '../../borrowerService';
import { TransferService } from '../../../../services/transferService';
import { useState } from 'react';
import { BorrowerDepositSchema } from '../../../../shared/validations/schema';
import DepositSuccess from './depositSuccess';
import { useTranslation } from 'react-i18next';
import { FormHelper } from '../../../../helpers/formHelper';
import { FSBankDetails } from '../../../../shared/components/fsBankDetails';

export default function BankTransfer() {
	const borrowerService = new BorrowerService();
	const transferService = new TransferService();
	const [userBankDetails, setUserBankDetails] = useState();
	const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);
	const [transferId, setTransferId] = useState('');
	const [showReviewScreen, setShowReviewScreen] = useState(false);
	const { t } = useTranslation();
	const form = useFormik({
		initialValues: {
			amount: '',
		},
		validationSchema: BorrowerDepositSchema,
		onSubmit: () => {
			setIsLoadingDeposit(true);
			transferService
				.addTransferIn(form.values.amount)
				.then((resp) => {
					setTransferId(resp.data);
					setIsLoadingDeposit(false);
					setShowReviewScreen(true);
				})
				.catch((err) => {
					setIsLoadingDeposit(false);
				});
		},
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		getUserBankDetails();
		getSummary();
	}, []);

	const getUserBankDetails = () => {
		transferService
			.getUserBankDetails()
			.then((resp) => {
				setUserBankDetails(resp.data);
			})
			.catch((err) => {});
	};

	const getSummary = () => {
		borrowerService
			.getSummary()
			.then((resp) => {
				let summary = resp.data;
				const payments = summary?.payments?.filter((s) => !s.isPaid);
				if (payments.length !== 0) {
					let amountToPay = payments[0].amount;
					form.setFieldValue('amount', amountToPay);
				}
			})
			.catch((err) => {});
	};

	return (
		<Box>
			<TextWrapper
				classList="!text-[22px] !font-[700] !text-start"
				text={
					!showReviewScreen ? t('depositMoney') : t('fundsInProgress')
				}></TextWrapper>
			{!showReviewScreen ? (
				<Box className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">
					<SectionWithHeading
						heading={
							<Box className="flex items-center">
								<span className="mx-2">{t('depositNotification')}</span>
							</Box>
						}
						children={
							<Stack gap={4}>
								<Box>
									<TextWrapper
										classList="caption !text-sm"
										text={t('pleaseEnterTransferAmount')}></TextWrapper>
									<FormikProvider value={form}>
										<Box className="mt-3 flex flex-0 md:w-[380px]">
											<InputWrapper
												label={`${t('amount')} ${CURRENCY}`}
												key={'amount'}
												name="amount"></InputWrapper>
											<ButtonWrapper
												onClick={() => {
													form.submitForm();
												}}
												loading={isLoadingDeposit}
												icon={
													<NotificationsOutlinedIcon></NotificationsOutlinedIcon>
												}
												classList="!min-w-[130px] !mt-[22px] !mx-3"
												label={t('notify')}></ButtonWrapper>
										</Box>
									</FormikProvider>
								</Box>
								<Stack gap={2}>
									<TextWrapper
										classList="!text-lg !font-[700]"
										text={t('yourDepositReference')}></TextWrapper>
									<Box className="grid md:grid-cols-3 grid-cols-2 items-center">
										<TextWrapper
											classList="!caption !text-sm"
											text={t('reference')}></TextWrapper>
										<TextWrapper
											classList="!font-bold !text-sm md:!col-span-2"
											text={userBankDetails?.reference}></TextWrapper>
									</Box>
									<Box className="grid md:grid-cols-3 grid-cols-2 items-center">
										<TextWrapper
											classList="!caption !text-sm"
											text={t('bankName')}></TextWrapper>
										<TextWrapper
											classList="!font-bold !text-sm md:!col-span-2"
											text={userBankDetails?.bankName}></TextWrapper>
									</Box>
									<Box className="grid md:grid-cols-3 grid-cols-2 items-center">
										<TextWrapper
											classList="!caption !text-sm"
											text={t('accountName')}></TextWrapper>
										<TextWrapper
											classList="!font-bold !text-sm md:!col-span-2"
											text={userBankDetails?.accountName}></TextWrapper>
									</Box>
								</Stack>
								<FSBankDetails></FSBankDetails>
							</Stack>
						}></SectionWithHeading>
					<SectionWithHeading
						classList="!h-auto"
						bodyClass="!h-full"
						heading={
							<Box className="flex items-center">
								<span className="mx-2">{t('importantInfo')}</span>
							</Box>
						}
						children={
							<Box className="flex flex-col flex-0 justify-between h-full">
								<Stack gap={4}>
									<Box>
										<TextWrapper
											classList="caption !text-sm"
											text={t(
												'depositMoneyIntoFundinSouqAccountText'
											)}></TextWrapper>
									</Box>
									<Stack gap={2}>
										<Box className="flex flex-0 items-start">
											<CheckCircleOutlineOutlinedIcon
												className="!text-[20px]"
												color="primary"></CheckCircleOutlineOutlinedIcon>
											<TextWrapper
												classList="caption !text-sm !mx-1"
												text={t(
													'depositMoneyIntoFundinSouqAccountText1'
												)}></TextWrapper>
										</Box>
										<Box className="flex flex-0 items-start">
											<CheckCircleOutlineOutlinedIcon
												className="!text-[20px]"
												color="primary"></CheckCircleOutlineOutlinedIcon>
											<TextWrapper
												classList="caption !text-sm !mx-1"
												text={t(
													'depositMoneyIntoFundinSouqAccountText2'
												)}></TextWrapper>
										</Box>
										<Box className="flex flex-0 items-start">
											<CheckCircleOutlineOutlinedIcon
												className="!text-[20px]"
												color="primary"></CheckCircleOutlineOutlinedIcon>
											<TextWrapper
												classList="caption !text-sm !mx-1"
												text={
													<span>
														{t('depositMoneyIntoFundinSouqAccountText3')}
														<a
															className="underline-link mx-1"
															href="mailto:support@fundingsouq.com">
															support@fundingsouq.com
														</a>
													</span>
												}></TextWrapper>
										</Box>
									</Stack>
								</Stack>
								<Box
									component={Paper}
									className="p-3 mt-20 !bg-[#f9f9f9] flex flex-col flex-0">
									<TextWrapper
										classList="!text-md !font-bold"
										text={t('investorFundProtection')}></TextWrapper>
									<TextWrapper
										classList="!text-sm !caption"
										text={
											IS_SA
												? t('yourFundsAreSecurelyANB')
												: t('yourFundsAreSecurely')
										}></TextWrapper>
								</Box>
							</Box>
						}></SectionWithHeading>
				</Box>
			) : (
				<DepositSuccess
					onCancel={() => {
						setShowReviewScreen(false);
					}}
					amount={form.values.amount}
					transferId={transferId}
					userBankDetails={userBankDetails}></DepositSuccess>
			)}
		</Box>
	);
}
