import { Box, CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { InvestorRegService } from '../../investorRegService';
import { setRegistrationData } from '../../../../../services/authService';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InvestorStepperContext } from '../../../../../context/investorStepperContext';
import { InvestorRegistrationStep } from '../../../../../constants/investorRegistrationSteps';
import { IS_SA } from '../../../../../config/appConfig';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';

export const InvestorStepper = () => {
	const investorService = new InvestorRegService();
	const [loading, setLoading] = useState(true);
	const { steps, handleStepper } = useContext(InvestorStepperContext);
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const [selectedStep, setSelectedStep] = useState(-1);

	const navigate = useNavigate();

	useEffect(() => {
		getInvestorRegistrationData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let path = pathname.split('/');
		let lastPathName = path[path.length - 1];
		if (lastPathName && steps && steps?.length) {
			setSelectedStep(steps.findIndex((resp) => lastPathName === resp.path));
		}
	}, [pathname, steps]);

	const getInvestorRegistrationData = () => {
		investorService
			.getInvestorRegistrationData()
			.then((resp) => {
				setRegistrationData(resp.data);
				let investorType = resp.data?.investorType;
				onHandleStepsRefresh(resp.data?.step);
				if (investorType) {
					handleStepper(investorType);
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onHandleStepsRefresh = (step) => {
		let route = null;
		if (step === InvestorRegistrationStep.NotStarted || step === -1) {
			route = IS_SA
				? AppRoutes.IRegNafathVerification
				: AppRoutes.IRegPersonalInfo;
		} else if (step === InvestorRegistrationStep.PersonalInformation) {
			route = AppRoutes.IRegPersonalInfo;
		} else if (step === InvestorRegistrationStep.DocumentVerification) {
			route = AppRoutes.iRegDocumentVarification;
		}
		if (route && !window.location.pathname.includes(route)) {
			navigate(
				buildLocalizeRoute(`${AppRoutes.InvestorRegistration}/${route}`)
			);
		}
	};

	return (
		<Box className="flex justify-center items-center">
			<Box className="md:w-[850px] w-full">
				<Stepper
					data-testid="investorStepper"
					className="my-4 mb-8"
					orientation="horizontal"
					activeStep={selectedStep}
					alternativeLabel>
					{steps.map((resp) => (
						<Step key={resp?.name}>
							<StepLabel>
								<span
									className="font-bold"
									data-testid={`investor-${resp?.name}`}>
									{t(`investor-${resp?.name}`)}
								</span>
							</StepLabel>
						</Step>
					))}
				</Stepper>
				{!loading ? (
					<Outlet></Outlet>
				) : (
					<Box className="flex justify-center items-center reg-section-height">
						<CircularProgress data-testid={'loader'} />
					</Box>
				)}
			</Box>
		</Box>
	);
};
