import { useState } from 'react';
import { AppRoutes } from '../../../../constants/appRoutes';
import ActionBarList from '../../../../shared/components/common/actionBarList';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const InvestorTransfer = () => {
	const { t } = useTranslation();
	const tabs = [
		{
			id: 1,
			name: t('deposit'),
			path: `${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`,
			relativePath: AppRoutes.IDeposit,
		},
		{
			id: 2,
			name: t('withdraw'),
			path: `${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IWithdraw}`,
			relativePath: AppRoutes.IWithdraw,
		},
		// {
		// 	id: 3,
		// 	name: t('transactionHistory'),
		// 	path: `${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.ITransactionHistory}`,
		// 	relativePath: AppRoutes.ITransactionHistory,
		// },
	];

	const [selectedTab, setSelectedTab] = useState(tabs[0]);

	useEffect(() => {
		setSelectedTab(tabs[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box className="flex flex-col">
			<ActionBarList
				isAutoFetchActiveTab
				actionTabs={tabs}
				active={selectedTab}></ActionBarList>
			<Box className='mt-3'>
				<Outlet></Outlet>
			</Box>
		</Box>
	);
};
