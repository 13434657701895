import {
	FormControlLabel,
	FormControl,
	Radio,
	RadioGroup,
	FormLabel,
	Box,
} from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

export const RadioWrapper = ({
	options = [],
	defaultValue = null,
	classList = '',
	labelClass = '',
	onChange,
	disable = false,
	errorStateTestID = '',
	dataTestID = '',
	...props
}) => {
	const [field, meta, helper] = useField(props);
	const { t } = useTranslation();
	return (
		<FormControl
			className={`flex !flex-row flex-auto items-start justify-between ${classList}`}>
			<FormLabel className={`mr-2 text-start !text-[14px] ${labelClass}`}>
				<Box className="flex flex-0 items-center">
					<Box data-testid={dataTestID}>
						{t(props.label)}
						{meta.error ? <span className="!text-red-500">*</span> : null}
					</Box>
				</Box>
				{meta.error && meta.touched && (
					<Box
						data-testid={errorStateTestID}
						className="text-[#d32f2f] !text-[0.75rem]">
						{t(meta.error)}
					</Box>
				)}
			</FormLabel>
			<RadioGroup
				defaultValue={defaultValue}
				name={props.name}
				value={field.value}
				className="flex !flex-row !flex-nowrap">
				{options.map((resp, index) => {
					return (
						<FormControlLabel
							className="last:!m-0"
							key={index}
							disabled={disable}
							onChange={(val) => {
								let value = val.target.value;
								if (value === 'true') {
									value = true;
								} else if (value === 'false') {
									value = false;
								}
								onChange && onChange(value);
								helper.setValue(value);
							}}
							value={resp?.value}
							control={<Radio className="!px-[3px] !pb-[3px] !pt-0" />}
							label={resp?.name}
						/>
					);
				})}
			</RadioGroup>
		</FormControl>
	);
};
