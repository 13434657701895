import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Logo from '../../../../../components/common/logo';
import LanguagePopover from './components/languagePopover';
import AccountPopover from './components/accountPopover';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import i18next from 'i18next';

const APPBAR_MOBILE = 80;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(6px)',
	WebkitBackdropFilter: 'blur(6px)',
	backgroundColor: alpha(theme.palette.background.default, 0.72),
	[theme.breakpoints.up('lg')]: {
		width: `100%`,
	},
	// [theme.breakpoints.down('md')]: {
	// 	top: '53px',
	// },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	minHeight: APPBAR_MOBILE,
	[theme.breakpoints.up('lg')]: {
		minHeight: APPBAR_DESKTOP,
		padding: 0,
	},
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
	onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
	const isAr = () => i18next.language === 'ar';
	return (
		<Box>
			<RootStyle>
				<Box className="primary-bg shadow-md flex justify-center">
					<ToolbarStyle className="md:w-[1140px] w-full">
						<Box>
							<Logo
								url={buildLocalizeRoute(`${AppRoutes.Borrower}`)}
								classList={`${isAr() ? 'md:!-ml-[26px] md:!-mr-[26px]' : ''} -ml-[12px] -mr-[12px]  md:!w-[150px] !w-[100px]`}
								sx={{ height: 'auto' }}
								logoWhite="true"></Logo>
						</Box>
						<Stack
							width="100%"
							direction="row"
							alignItems="center"
							justifyContent="flex-end">
							<Box className="mx-2 md:mx-4">
								<LanguagePopover />
							</Box>
							<Box className="!hidden md:!block">
								<AccountPopover></AccountPopover>
							</Box>
							<IconButton
								className="md:!hidden !block !-mt-[5px]"
								onClick={onOpenSidebar}
								sx={{
									color: 'text.primary',
								}}>
								<MenuOutlinedIcon className="!text-white !text-[28px]"></MenuOutlinedIcon>
							</IconButton>
						</Stack>
					</ToolbarStyle>
				</Box>
			</RootStyle>
		</Box>
	);
}
