import { Box } from '@mui/material';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../../shared/components/common/arrowIcons';
import { useEffect, useState } from 'react';
import { TextWrapper } from '../../../../../../shared/components/formUtilities/useTextWrapper';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../../../../../shared/components/formUtilities/useInput';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../../../helpers/formHelper';
import { buildLocalizeRoute } from '../../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../../constants/appRoutes';
import { useNavigate } from 'react-router-dom';
import { InvestorBusinessKSASchema } from '../../../../../../shared/validations/schema';
import { InvestorRegService } from '../../../investorRegService';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateInvestorSteps,
} from '../../../../../../services/authService';

export const BusinessInformationKSA = ({ onGoBackKSA, isLoading = false }) => {
	const [loading, setLoading] = useState(isLoading);
	const investorRegService = new InvestorRegService();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const form = useFormik({
		initialValues: { tradeLicenseNumber: '' },
		validationSchema: InvestorBusinessKSASchema,
		onSubmit: () => {
			onSubmitBusinessInfo();
		},
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		investorRegService
			.getInvestorProfileBusinessInfo()
			.then((resp) => {
				if (resp.data) {
					form.setFieldValue(
						'tradeLicenseNumber',
						resp.data?.tradeLicenseNumber
					);
				}
			})
			.catch((err) => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmitBusinessInfo = () => {
		setLoading(true);
		investorRegService
			.ksaUpdateBusinessDetails(form.values)
			.then((response) => {
				let resp = response.data;
				let registrationModel = resp?.registrationModel;
				saveTokenFromRegistration(resp?.loginModel?.token);
				let regData = {
					...getRegistrationData(),
					step: registrationModel.step,
					investorType:
						registrationModel?.personalInformation?.investorType?.name,
					requestedStep: 1,
				};
				setRegistrationData(regData);
				updateInvestorSteps(registrationModel.step);
				setLoading(false);
				navigate(
					buildLocalizeRoute(
						`${AppRoutes.InvestorRegistration}/${AppRoutes.iRegDocumentVarification}`
					)
				);
			})
			.catch((err) => {
				setLoading(false);
			});
	};
	return (
		<FormikProvider value={form}>
			<Box>
				<Box className="flex items-center justify-center flex-col">
					<Box className="md:w-[500px] w-full flex flex-col justify-center items-center">
						<Box className="!self-start">
							<TextWrapper
								testID="corporateCRNumberTextId"
								classList="sub-heading !caption"
								text={t('corporateCRNumberText')}
							/>
						</Box>
						<Box className="my-4 w-full">
							<InputWrapper
								errorStateTestID="tradeLicenseNumberError"
								name={'tradeLicenseNumber'}
								key={'tradeLicenseNumber'}
								label={t('crNumber')}></InputWrapper>
						</Box>
					</Box>
				</Box>
				<Box className="flex items-center justify-center">
					<ButtonWrapper
						dataTestID="backBtn"
						classList="!secondary-btn md:!mx-0 !mx-2"
						disabled={loading}
						largeBtn
						icon={<ArrowIcons isInvert></ArrowIcons>}
						label={t('back')}
						onClick={() => {
							onGoBackKSA && onGoBackKSA();
						}}
					/>
					<ButtonWrapper
						dataTestID="nextBtn"
						classList="md:!mx-3 !mx-1"
						loading={loading}
						largeBtn
						endIcon={<ArrowIcons></ArrowIcons>}
						label={t('next')}
						onClick={() => {
							form.submitForm();
						}}
					/>
				</Box>
			</Box>
		</FormikProvider>
	);
};
