import { Outlet } from 'react-router-dom';
import { RegistrationNavBar } from '../components/registrationNavBar';
import { Box } from '@mui/material';
import { InvestorStepperProvider } from '../../../context/investorStepperContext';

export const InvestorRegistration = () => {
	return (
		<>
			<InvestorStepperProvider>
				<RegistrationNavBar></RegistrationNavBar>
				<Box className="flex justify-center items-center">
					<Box className="xl:w-[1140px] w-full md:my-8 m-4">
						<Outlet />
					</Box>
				</Box>
			</InvestorStepperProvider>
		</>
	);
};
