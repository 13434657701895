import { Box, Divider, Paper, Skeleton } from '@mui/material';
import { ButtonWrapper } from '../../components/formUtilities/useButton';
import { TextWrapper } from '../../components/formUtilities/useTextWrapper';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useTranslation } from 'react-i18next';

export const SectionWithHeading = ({
	heading = null,
	children,
	actionButtons,
	statusLabel = '',
	downloadCSV = false,
	loadingDownload = false,
	isEditButton = false,
	isEditToggle = false,
	hideEditBtn = false,
	loading = false,
	classList = '',
	bodyClass = '',
	headerClass = '',
	customLoader = '',
	customStyle = '',
	onDownload = () => {},
	onEditInfo = (e) => {},
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Box
				component={Paper}
				className={`!p-0 rounded-md flex flex-col items-start ${
					classList ? classList : ''
				}`}
				style={{ height: 'fit-content' }}>
				<Box
					className={`flex justify-between flex-row w-full p-4 flex-0 h-auto ${
						headerClass ? headerClass : ''
					}`}>
					<Box className="flex flex-0 items-center">
						{!loading ? (
							<>
								<TextWrapper
									type="span"
									classList="!text-xl md:!text-[22px] !font-[700] !mr-3 !text-start"
									text={heading}></TextWrapper>
								{statusLabel ? (
									<Box className="md:mt-0 mt-4">{statusLabel}</Box>
								) : null}
							</>
						) : (
							<>
								<Skeleton
									variant="text"
									sx={{ fontSize: '1.2rem', width: '300px' }}
								/>
							</>
						)}
					</Box>
					<Box className="grid grid-flow-row md:grid-flow-col gap-2">
						{!loading ? (
							<>
								{downloadCSV ? (
									<ButtonWrapper
										loading={loadingDownload}
										onClick={() => onDownload && onDownload()}
										label={t('download')}
										icon={<DownloadOutlinedIcon />}></ButtonWrapper>
								) : (
									''
								)}
								{actionButtons ? (
									<Box className="md:mt-0 mt-1">{actionButtons}</Box>
								) : null}
								{isEditButton ? (
									<>
										<ButtonWrapper
											hideBtn={hideEditBtn}
											classList={`!min-w-[90px] ${
												actionButtons ? '!ml-3' : ''
											}`}
											onClick={(e) => {
												onEditInfo(e);
											}}
											type={!isEditToggle ? 'button' : 'submit'}
											variant={'outlined'}
											label={!isEditToggle ? t('edit') : t('save')}
											icon={
												!isEditToggle ? <EditIcon /> : <SaveOutlinedIcon />
											}></ButtonWrapper>
									</>
								) : null}
							</>
						) : (
							<>
								<Box className="grid grid-cols-3 gap-3 w-full">
									{actionButtons ? (
										<>
											<Skeleton
												variant="text"
												sx={{ fontSize: '2rem', width: '100px' }}
											/>
											<Skeleton
												variant="text"
												sx={{ fontSize: '2rem', width: '100px' }}
											/>
											<Skeleton
												variant="text"
												sx={{ fontSize: '2rem', width: '100px' }}
											/>
										</>
									) : null}
								</Box>
							</>
						)}
					</Box>
				</Box>
				<Divider className="w-full"></Divider>
				<Box className={`p-4 w-full ${bodyClass ? bodyClass : ''}`}>
					{!loading ? (
						<>{children}</>
					) : (
						<>
							{customLoader ? (
								customLoader
							) : (
								<>
									{[1, 2, 3].map((resp) => {
										return (
											<Box
												key={resp}
												className="grid grid-flow-row gap-2">
												<Box className="grid grid-cols-3 gap-6">
													<Skeleton
														variant="text"
														sx={{ fontSize: '2rem', width: '100%' }}
													/>
													<Skeleton
														variant="text"
														sx={{ fontSize: '2rem', width: '100%' }}
													/>
													<Skeleton
														variant="text"
														sx={{ fontSize: '2rem', width: '100%' }}
													/>
												</Box>
											</Box>
										);
									})}
								</>
							)}
						</>
					)}
				</Box>
			</Box>
		</>
	);
};
