export class CsvHelper {
	static serializeToKeyValueCsv(source) {
		let csv = '';
		if (!source) {
			return csv;
		}

		const allProperties = Object.keys(source);

		allProperties.forEach((propertyName) => {
			csv +=
				typeof source[propertyName] === 'object'
					? this.serializeToKeyValueCsv(source[propertyName])
					: `${propertyName},"${source[propertyName]}"\r\n`; // TODO: double check spec chars in the values
		});
		return csv;
	}

	static serializeArrayToCsv(sourceArray) {
		let csv = '';

		if (!sourceArray || sourceArray.length === 0) {
			return csv;
		}

		const allProperties = Object.keys(sourceArray[0]);

		// headers
		allProperties.forEach((propertyName) => {
			// skipping complex object here for now
			if (typeof sourceArray[0][propertyName] !== 'object') {
				csv += `${propertyName},`;
			}
		});
		csv += '\r\n';

		sourceArray.forEach((element) => {
			allProperties.forEach((propertyName) => {
				// skipping complex object here for now
				if (typeof element[propertyName] !== 'object') {
					csv += `${element[propertyName]},`;
				}
			});
			csv += '\r\n';
		});

		return csv;
	}
}
