import { axiosGet, axiosPut } from './axiosService';

const baseBecomeProURL = 'Lenders';
const investorsSummary = 'LenderSummary';

export class BecomeProService {
	getBecomeProStatus() {
		return axiosGet(null, `${investorsSummary}/GetRequestedBecameProStatus`);
	}

	updateQuestionAnswers(questionAnswers) {
		return axiosPut(questionAnswers, baseBecomeProURL + `/me/BecameProInvestor`);
	}
}
