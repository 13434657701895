/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from '@mui/material';
import { DocumentUploadSection } from '../documentUploadSection';
import { useEffect, useState } from 'react';
import { UserDocumentTypes } from '../../../constants/userDocuments';
import { FinancialDocument } from '../financialDocument';
import { UserService } from '../../../services/userService';

export const BorrowerGlobalDocuments = ({ onAllFileUploaded = (e) => { } }) => {
	const userService = new UserService();
	const [documents, setDocuments] = useState([]);
	const [tradeLicense, setTradeLicense] = useState(null);
	const [moaArticle, setMoaArticle] = useState(null);
	const [loading, setLoading] = useState(true);
	const [uboId, setuboId] = useState(null);
	const [monthBankStatement, setMonthBankStatement] = useState(null);
	const [vatReport, setVatReport] = useState(null);
	const [isFSUploaded, setIsFSUploaded] = useState(false);

	const [loadingUploadDocs, setLoadingUploadDocs] = useState({
		[UserDocumentTypes.BorrowerTradeLicense]: false,
		[UserDocumentTypes.BorrowerArticleAssociationOrMomorandum]: false,
		[UserDocumentTypes.BorrowerUBOID]: false,
		[UserDocumentTypes.Borrower12MonthBankStatement]: false,
		[UserDocumentTypes.BorrowerVatReport]: false,
	});

	useEffect(() => {
		getAllDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			tradeLicense &&
			moaArticle?.length &&
			uboId?.length &&
			monthBankStatement?.length &&
			vatReport?.length &&
			isFSUploaded
		) {
			onAllFileUploaded && onAllFileUploaded(true);
		} else {
			console.log("test1");
			onAllFileUploaded && onAllFileUploaded(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		tradeLicense,
		moaArticle,
		uboId,
		monthBankStatement,
		vatReport,
		isFSUploaded,
	]);

	const getAllDocuments = () => {
		startStopLoader(null, null, true, true);
		userService
			.getAllDocuments()
			.then((resp) => {
				setDocuments(resp.data);
				setFiles(resp.data);
				setLoading(false);
				startStopLoader(null, null, true, false);
			})
			.catch((err) => {
				setLoading(false);
				startStopLoader(null, null, true, false);
			});
	};

	const startStopLoader = (type, value, all = false, allValue = false) => {
		if (all) {
			const loaders = {
				[UserDocumentTypes.BorrowerTradeLicense]: allValue,
				[UserDocumentTypes.BorrowerArticleAssociationOrMomorandum]: allValue,
				[UserDocumentTypes.BorrowerUBOID]: allValue,
				[UserDocumentTypes.Borrower12MonthBankStatement]: allValue,
				[UserDocumentTypes.BorrowerVatReport]: allValue,
			};
			setLoadingUploadDocs(loaders);
		} else {
			setLoadingUploadDocs((prevState) => ({
				...prevState,
				[type]: value,
			}));
		}
	};

	const setFiles = (fileDetails) => {
		setTradeLicense(
			getFilteredDocuments(fileDetails, UserDocumentTypes.BorrowerTradeLicense)
		);
		setMoaArticle(
			getFilteredDocuments(
				fileDetails,
				UserDocumentTypes.BorrowerArticleAssociationOrMomorandum,
				true
			)
		);
		setuboId(
			getFilteredDocuments(fileDetails, UserDocumentTypes.BorrowerUBOID, true)
		);
		setMonthBankStatement(
			getFilteredDocuments(
				fileDetails,
				UserDocumentTypes.Borrower12MonthBankStatement, true
			)
		);
		setVatReport(
			getFilteredDocuments(
				fileDetails,
				UserDocumentTypes.BorrowerVatReport, true
			)
		);
	};

	const getFilteredDocuments = (fileDetails = [], type, isMultiple = false) => {
		let files = fileDetails.filter((data) => data.type === type) || null;
		return files ? isMultiple ? files : files[0] : null;
	};

	return (
		<Box className="flex flex-col space-y-8">
			<Box className={`flex flex-col space-y-4`}>
				<DocumentUploadSection
					onRemoveFile={(e) => {
						setTradeLicense(null);
					}}
					onUploadedFiles={(e) => {
						setTradeLicense(e?.file);
					}}
					showOTPAuth={true}
					fileName="tradeLicense"
					file={tradeLicense}
					isLoading={loadingUploadDocs[UserDocumentTypes.BorrowerTradeLicense]}
					fileType={
						UserDocumentTypes.BorrowerTradeLicense
					}></DocumentUploadSection>
				<DocumentUploadSection
					isMultiple
					onRemoveFile={(e) => {
						setMoaArticle(e);
					}}
					onUploadedFiles={(e) => {
						setMoaArticle(e?.file);
					}}
					showOTPAuth={true}
					fileName="moAorArticlesofAssociation"
					file={moaArticle}
					isLoading={
						loadingUploadDocs[
						UserDocumentTypes.BorrowerArticleAssociationOrMomorandum
						]
					}
					fileType={
						UserDocumentTypes.BorrowerArticleAssociationOrMomorandum
					}></DocumentUploadSection>
				<DocumentUploadSection
					isMultiple
					onRemoveFile={(e) => {
						setuboId(e);
					}}
					onUploadedFiles={(e) => {
						setuboId(e?.file);
					}}
					showOTPAuth={true}
					fileName="uboID"
					subPoints={'uboIDInfo'}
					file={uboId}
					isLoading={loadingUploadDocs[UserDocumentTypes.BorrowerUBOID]}
					fileType={UserDocumentTypes.BorrowerUBOID}></DocumentUploadSection>
				<DocumentUploadSection
					isMultiple
					onRemoveFile={(e) => {
						setMonthBankStatement(e);
					}}
					onUploadedFiles={(e) => {
						setMonthBankStatement(e?.file);
					}}
					showOTPAuth={true}
					fileName="12MonthBankStatement"
					file={monthBankStatement}
					isLoading={
						loadingUploadDocs[UserDocumentTypes.Borrower12MonthBankStatement]
					}
					fileType={
						UserDocumentTypes.Borrower12MonthBankStatement
					}></DocumentUploadSection>
				{!loading ? <FinancialDocument
					onFileUploaded={(e) => {
						setIsFSUploaded(e);
					}}
					documents={documents}
				/> : null}
				<DocumentUploadSection
					isMultiple
					onRemoveFile={(e) => {
						setVatReport(e);
					}}
					onUploadedFiles={(e) => {
						setVatReport(e?.file);
					}}
					showOTPAuth={true}
					fileName="vatReportforLast4years"
					file={vatReport}
					isLoading={
						loadingUploadDocs[UserDocumentTypes.BorrowerVatReport]
					}
					fileType={
						UserDocumentTypes.BorrowerVatReport
					}></DocumentUploadSection>
				{/* <DocumentUploadSection
					onRemoveFile={(e) => {
						setSupportingDocument(null);
					}}
					onUploadedFiles={(e) => {
						setSupportingDocument(e?.file);
					}}
					showOTPAuth={true}
					subPoints={'otherSupportingDocumentPoint'}
					fileName="otherSupportingDocument"
					file={supportingDocument}
					isLoading={
						loadingUploadDocs[
						UserDocumentTypes.BorrowerOtherSupportingDocuments
						]
					}
					fileType={
						UserDocumentTypes.BorrowerOtherSupportingDocuments
					}></DocumentUploadSection> */}
			</Box>
		</Box>
	);
};
