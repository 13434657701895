import { Box } from '@mui/material';
import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';
import { Link } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import LogoPlaceholder from '../../../../../assets/images/logo-placeholder.jpg';

export const initializeCollectionLoansColumns = (status, t) => {
	return [
		{
			header: t('logo'),
			accessorKey: 'logo',
			Cell: ({ cell }) => {
				return (
					<Box>
						<img
							className="!w-[80px]"
							src={cell.getValue() ? cell.getValue() : LogoPlaceholder}
							alt="Logo"></img>
					</Box>
				);
			},
		},
		{
			header: t('businessName'),
			accessorKey: 'businessName',
			Cell: ({ cell, row }) => {
				return (
					<Link
						to={buildLocalizeRoute(
							`${AppRoutes.Investor}/${AppRoutes.Marketplace}/business-details/${row?.original?.id}?status=${status}`
						)}
						className="underline-link">
						{cell?.getValue()}
					</Link>
				);
			},
		},
		{
			header: t('amount'),
			accessorKey: 'campaignAmount',
		},
		{
			header: t('funded'),
			accessorKey: 'investedAmount',
			Cell: ({ cell }) => {
				return <Box>{currencyFormatterHelper(cell?.getValue())}</Box>;
			},
		},
		{
			header: t('profitRate'),
			accessorKey: 'fixed',
		},
	];
};
