import { AppRoutes } from '../constants/appRoutes';
import { Roles } from '../constants/roles';
import { getUser } from '../services/authService';
import { buildLocalizeRoute } from './languageHelper';

export const getCurrentUserPath = (returnUrl = null) => {
	const user = getUser();
	if (user) {
		if (user.role === Roles.Investor) {
			return buildLocalizeRoute(returnUrl || AppRoutes.Investor);
		} else if (user.role === Roles.Borrower) {
			return buildLocalizeRoute(returnUrl || AppRoutes.Borrower);
		}
	}
};
