import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IS_SA } from '../../../../../config/appConfig';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateBorrowerSteps,
} from '../../../../../services/authService';
import { BorrowerGlobalDocuments } from '../../../../../shared/components/borrowerGlobalDocuments';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { BorrowerService } from '../../../../borrower/borrowerService';
import { IwillDoItLaterDialog } from '../../../components/iwillDoItLaterPopup';
import { BorrowerDeclarationDailog } from '../declarationDialog';
import { BorrowerKSADocuments } from '../../../../../shared/components/borrowerKSADocuments';
import { DocumentUploadIssueInfo } from '../../../components/documentUploadIssueInfo';

export const BorrowerDocumentVerification = ({
	isSA = IS_SA,
	loading = false,
}) => {
	const borrowerRegService = new BorrowerService();

	const navigate = useNavigate();
	const [loadingSubmit, setLoadingSubmit] = useState(loading);
	const [showIwilldoitlater, setShowIwilldoitlater] = useState(false);
	const [showDeclartionPopup, setDeclartionPopup] = useState(false);
	const [isAllFileUploaded, setIsAllFileUploaded] = useState(false);

	const { t } = useTranslation();

	const onBack = () => {
		let regData = {
			...getRegistrationData(),
			requestedStep: 2,
		};
		setRegistrationData(regData);
		let route = isSA
			? AppRoutes.CommercialRegistration
			: AppRoutes.BRegBusinessInfo;
		navigate(buildLocalizeRoute(`${AppRoutes.BorrowerRegistartion}/${route}`));
	};

	const onSubmit = () => {
		if (!isAllFileUploaded) {
			setShowIwilldoitlater(true);
		} else {
			setLoadingSubmit(true);
			borrowerRegService
				.submitDocuments()
				.then((resp) => {
					setLoadingSubmit(false);
					let registrationModel = resp.data?.registrationModel;
					saveTokenFromRegistration(resp.data?.loginModel?.token);
					let regData = {
						...getRegistrationData(),
						step: registrationModel.step,
						requestedStep: 3,
					};
					setRegistrationData(regData);
					updateBorrowerSteps(registrationModel?.step);
					redirectToNextStep();
				})
				.catch((err) => {
					setLoadingSubmit(false);
				});
		}
	};

	const redirectToNextStep = () => {
		setDeclartionPopup(true);
	};

	const onCloseIwillDoIt = (action) => {
		setShowIwilldoitlater(false);
		if (action === 'skip') {
			let regData = {
				...getRegistrationData(),
				requestedStep: 3,
			};
			setRegistrationData(regData);
			redirectToNextStep();
		}
	};

	return (
		<Box className="flex flex-col space-y-8">
			<Box className={`flex flex-col space-y-4`}>
				{isSA ? (
					<BorrowerKSADocuments
						onAllFileUploaded={(e) => {
							setIsAllFileUploaded(e);
						}}
					/>
				) : (
					<BorrowerGlobalDocuments
						onAllFileUploaded={(e) => {
							setIsAllFileUploaded(e);
						}}
					/>
				)}
			</Box>
			<Box className="flex md:justify-between justify-center items-center">
				<ButtonWrapper
					dataTestID="backBtn"
					classList="!secondary-btn md:!mx-0 !mx-2"
					disabled={loadingSubmit}
					largeBtn
					icon={<ArrowIcons isInvert></ArrowIcons>}
					label={t('back')}
					onClick={() => {
						onBack();
					}}
				/>
				<ButtonWrapper
					dataTestID="nextBtn"
					classList={'primary-bg'}
					loading={loadingSubmit}
					largeBtn
					endIcon={<ArrowIcons></ArrowIcons>}
					label={t('next')}
					onClick={() => {
						onSubmit();
					}}
				/>
			</Box>
			<DocumentUploadIssueInfo />
			{showIwilldoitlater ? (
				<IwillDoItLaterDialog
					isBorrower={true}
					state={showIwilldoitlater}
					onClose={(e) => {
						onCloseIwillDoIt(e);
					}}></IwillDoItLaterDialog>
			) : null}
			{showDeclartionPopup ? (
				<BorrowerDeclarationDailog
					state={showDeclartionPopup}
					onClose={(e) => {
						setDeclartionPopup(false);
					}}></BorrowerDeclarationDailog>
			) : null}
		</Box>
	);
};
