import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';
import { DateHelper } from '../../../../../helpers/dateHelper';
import { LabelBadge } from '../../../../../shared/components/common/labelBadge';

export const initializeColumns = (t) => {
	return [
		{
			header: t('date'),
			accessorKey: 'date',
			Cell: ({ cell }) => {
				return DateHelper.formatDate(cell?.getValue(), 'MMM dd, yyyy hh:mm');
			},
		},

		{
			header: t('amount'),
			accessorKey: 'amount',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
		},
		{
			header: t('action'),
			accessorKey: 'action',
			Cell: ({ cell, row }) => {
				return row?.original?.isDeposit ? t('deposit') : t('withdraw');
			},
		},
		{
			header: t('status'),
			accessorKey: 'status',
			Cell: ({ cell, row }) => {
				let status = row?.original?.status;
				let statusClass = status.includes('Rejected')
					? '!bg-[#fff3dc] !text-[#535151]'
					: status.includes('Received')
					? 'primary-bg'
					: status.includes('Requested')
					? 'bg-warning'
					: '!bg-gray-400';
				return (
					<LabelBadge
						label={status?.includes('Rejected') ? 'Cancelled' : status}
						classList={`${statusClass} !w-[90px]`}></LabelBadge>
				);
			},
		},
	];
};
