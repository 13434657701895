import { Box } from '@mui/material';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import DataTableGrid from '../../../../../shared/components/formUtilities/useTable';
import { initializeColumns } from './depositHistoryColumns';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TransferService } from '../../../../../services/transferService';
import { Section } from '../../../../../shared/components/common/section';

export const InvestorDepositHistory = () => {
	const transferService = new TransferService();
	const [history, setHistory] = useState([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const columns = initializeColumns(t);

	useEffect(() => {
		setLoading(true);
		transferService
			.getDepositHistory()
			.then((resp) => {
				setLoading(false);
				setHistory(resp.data);
			})
			.catch((err) => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box className="flex flex-col space-y-2">
			<SectionWithHeading
				heading={
					<Box className="flex items-center">
						<span className="mx-2">{t('depositHistory')}</span>
					</Box>
				}
				children={
					<DataTableGrid
						name={'depositHistoryTbl'}
						columns={columns}
						records={history}
						showEditColumnsButton={false}
						isPagination={false}
						loading={loading}></DataTableGrid>
				}></SectionWithHeading>
			<Section
				children={
					<Box className="text-center !text-sm">
						{t('depositAmountMayVaryFromReceivedAmount')}
					</Box>
				}></Section>
		</Box>
	);
};
