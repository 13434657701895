import { currencyFormatterHelper } from "./currencyFormatterHelper";

export const FormatNumberArray = (list = [], execeptionalKeys = []) => {
  if (list.length) {
    let formattedList = list.map((obj) => {
      return formatNumbersInObject(obj, execeptionalKeys);
    });
    return formattedList;
  }
  return list;
};

const formatNumbersInObject = (obj, execeptionalKeys = []) => {
  const formattedObj = {};
  for (const key in obj) {
    let value = Number(obj[key]?.replace(/,/g, ''));
    if (!isNaN(value) && !execeptionalKeys?.find(resp => resp === key)) {
      formattedObj[key] = currencyFormatterHelper(value);
    } else {
      formattedObj[key] = obj[key];
    }
  }
  return formattedObj;
};