import { Box } from '@mui/material';
import { DialogWrapper } from '../../../../shared/components/common/dialogWrapper';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { useState } from 'react';
import { InvestorDeclarationDialog } from '../../investor/views/declarationDialog';

export const IwillDoItLaterDialog = ({
	state,
	onClose = (e) => {},
	isProfessional = false,
	isCorporate = false,
	isBorrower = false,
	testId = 'iwilldoitlaterId',
}) => {
	const { t } = useTranslation();
	const [showDeclarationPopup, setShowDeclarationPopup] = useState(false);

	const onIWillDoItLater = () => {
		if (isBorrower) {
			onClose('skip');
		} else {
			setShowDeclarationPopup(true);
		}
	};
	return (
		<>
			<DialogWrapper
				testId={testId}
				maxWidth="sm"
				state={state}
				onClose={() => {
					onClose();
				}}
				title={t('confirmation')}
				actionFooterClass="!px-0"
				content={
					<Box className="text-center">
						<p>{t('areYouSureSkipThisStep')}</p>
						<p>{t('weWillNeedDocumentsToOpenAccountLater')}</p>
					</Box>
				}
				actions={
					<Box className="flex flex-col space-y-6">
						<Box className="flex justify-center">
							<ButtonWrapper
								icon={<WatchLaterOutlinedIcon />}
								onClick={() => {
									onIWillDoItLater();
								}}
								variant={'outlined'}
								label={t('skip')}></ButtonWrapper>
							<Box className="w-2"></Box>
							<ButtonWrapper
								icon={<WatchLaterOutlinedIcon />}
								onClick={() => {
									onClose();
								}}
								label={t('upload')}></ButtonWrapper>
						</Box>
					</Box>
				}></DialogWrapper>
			{showDeclarationPopup ? (
				<InvestorDeclarationDialog
					state={showDeclarationPopup}
					onClose={(e) => {
						setShowDeclarationPopup(false);
						if (e) {
							onClose();
						}
					}}></InvestorDeclarationDialog>
			) : null}
		</>
	);
};
