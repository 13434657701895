import { Box, Divider, Paper } from '@mui/material';
import { SectionWithHeading } from '../../../../shared/components/common/sectionWithHeading';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { CURRENCY, WEBSITE_URL } from '../../../../config/appConfig';
import { useEffect, useState } from 'react';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import { Spacing } from '../../../../shared/components/common/spacing';
import { InitializeColumns } from './paymentHistoryColumn';
import DataTableGrid from '../../../../shared/components/formUtilities/useTable';
import { BorrowerService } from '../../borrowerService';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';
import { DateHelper } from '../../../../helpers/dateHelper';
import { AppRoutes } from '../../../../constants/appRoutes';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { SummaryLoader } from './summaryLoader';
import { NextPaymentLoader } from './nextPaymentLoader';
import { useTranslation } from 'react-i18next';
import { SystemSettingsService } from '../../../../services/systemSettingsService';
import i18next from 'i18next';

export const BorrowerSummary = () => {
	const borrowerService = new BorrowerService();
	const systemSettingsService = new SystemSettingsService();
	const [summary, setSummary] = useState({});
	const [paidPaymentsCount, setPaidPaymentsCount] = useState(0);
	const [nextPayment, setNextPayment] = useState({});
	const [paymentHistory, setPaymentHistory] = useState([]);
	const [loadingHistory, setLoadingHistory] = useState(true);
	const [loadingSummary, setLoadingSummary] = useState(true);
	const [loadingNextPayment, setLoadingNextPayment] = useState(true);
	const { t } = useTranslation();
	const [columns, setColumns] = useState(InitializeColumns(0, 0, 0, t));

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		getSummary();
		getNextPayment();
		getPaymentHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getSummary = () => {
		setLoadingSummary(true);
		borrowerService
			.getSummary()
			.then((resp) => {
				setLoadingSummary(false);
				setSummary(resp.data);
				setPaidPaymentsCount(
					resp.data?.payments?.filter((resp) => resp.isPaid).length || 0
				);
			})
			.catch((err) => {
				setLoadingSummary(false);
			});
	};
	const getNextPayment = () => {
		setLoadingNextPayment(true);
		borrowerService
			.getNextPayment()
			.then((resp) => {
				setLoadingNextPayment(false);
				setNextPayment(resp.data);
			})
			.catch((err) => {
				setLoadingNextPayment(false);
			});
	};
	const getPaymentHistory = () => {
		setLoadingHistory(true);
		borrowerService
			.getPaymentHistory()
			.then((resp) => {
				let payments = resp.data || [];
				let totalPayment = 0;
				let totalPrincipal = 0;
				let totalInterest = 0;
				setPaymentHistory(payments);
				payments.forEach((payment) => {
					totalPayment += payment.payment;
					totalPrincipal += payment.principal;
					totalInterest += payment.interest;
				});
				setColumns(
					InitializeColumns(totalPayment, totalPrincipal, totalInterest, t)
				);
				setLoadingHistory(false);
			})
			.catch((err) => {
				setLoadingHistory(false);
			});
	};

	return (
		<Box className="flex flex-col flex-0">
			<Box className="grid md:grid-cols-3 grid-cols-1 gap-4">
				<SectionWithHeading
					loading={loadingSummary}
					customLoader={<SummaryLoader></SummaryLoader>}
					classList="md:!col-span-2"
					heading={t('yourLoanSummary')}
					children={
						<Box className="flex flex-col flex-0 justify-between md:!min-h-[200px]">
							<Box className="flex md:flex-row md:space-y-0 space-y-4 flex-col md:justify-between md:items-center">
								<Box>
									<Box className="flex flex-row flex-0 items-baseline">
										<TextWrapper
											classList="!text-primary !font-bold !text-xl md:!text-[24px]"
											text={`${CURRENCY} ${currencyFormatterHelper(summary?.paid)}`}></TextWrapper>
									</Box>
									<Box className="caption text-sm">{t('paid')}</Box>
								</Box>
								<Box>
									<Box className="flex flex-row flex-0 items-baseline">
										<TextWrapper
											classList="!text-primary !font-bold !text-xl md:!text-[24px]"
											text={`${CURRENCY} ${currencyFormatterHelper(
												summary?.balance
											)}`}></TextWrapper>
									</Box>
									<Box className="caption text-sm md:!text-end md:!mx-1">
										{t('balance')}
									</Box>
								</Box>
							</Box>
							<Box className="mt-6">
								<Box className="flex flex-0 mb-2 items-center !caption !text-sm">
									<TextWrapper
										classList="!text-primary"
										text={paidPaymentsCount}></TextWrapper>
									<span className="!mx-1">{t('of')}</span>
									<TextWrapper
										text={summary?.payments?.length || 0}></TextWrapper>
									<span className="!mx-1">{t('paymentsPaid')}</span>
								</Box>
								{summary?.payments?.length ? (
									<Box
										className={`grid gap-1 relative`}
										sx={{
											gridTemplateColumns: `repeat(${summary?.payments?.length},minmax(0,1fr))`,
										}}>
										{summary?.payments.map((payment, index) => {
											return (
												<Box
													key={index}
													className={`h-16 relative cursor-pointer bar-item ${
														payment?.isPaid ? '!primary-bg' : '!bg-red-400'
													}`}>
													<Box
														className={`absolute min-w-[180px] shadow-sm rounded-lg top-[70px] hidden text-center bar-item-hint p-2 ${
															payment?.isPaid ? '!primary-bg' : '!bg-red-400'
														}`}>
														<p className="caption text-xs text-white">
															{t('amountofInstallment')}
														</p>
														<TextWrapper
															classList="!text-center !text-[18px] !font-bold !text-white"
															text={`${CURRENCY} ${currencyFormatterHelper(payment.amount)}`}></TextWrapper>
														<p className="caption text-xs text-white">
															{DateHelper.formatDate(payment?.date)}
														</p>
													</Box>
												</Box>
											);
										})}
									</Box>
								) : null}
							</Box>
						</Box>
					}></SectionWithHeading>
				<SectionWithHeading
					heading={t('nextPayment')}
					loading={loadingNextPayment}
					customLoader={<NextPaymentLoader></NextPaymentLoader>}
					children={
						<Box className="flex flex-col flex-auto justify-between md:!min-h-[200px]">
							<Box className="flex flex-col flex-0">
								<Box className="flex justify-between items-center">
									<TextWrapper
										text={t('amount')}
										classList="!font-bold !text-sm"></TextWrapper>
									<TextWrapper
										text={`${CURRENCY} ${currencyFormatterHelper(
											nextPayment?.amount
										)}`}
										classList="!caption !text-sm"></TextWrapper>
								</Box>
								<Box className="my-3">
									<Divider></Divider>
								</Box>
								<Box className="flex justify-between items-center">
									<TextWrapper
										text={t('date')}
										classList="!font-bold !text-sm"></TextWrapper>
									<TextWrapper
										text={`${DateHelper.formatDate(nextPayment?.date)}`}
										classList="!caption !text-sm"></TextWrapper>
								</Box>
								<Box className="my-3">
									<Divider></Divider>
								</Box>
								<Box className="flex justify-between items-center">
									<TextWrapper
										text={t('frequency')}
										classList="!font-bold !text-sm"></TextWrapper>
									<TextWrapper
										text={`${nextPayment?.frequency || 0}`}
										classList="!caption !text-sm"></TextWrapper>
								</Box>
							</Box>
							<Box className="flex justify-center mt-4">
								<ButtonWrapper
									icon={<PaidOutlinedIcon></PaidOutlinedIcon>}
									target="_self"
									linkTo={`${AppRoutes.Borrower}/${AppRoutes.BTransfers}`}
									label={t('makeAPayment')}></ButtonWrapper>
							</Box>
						</Box>
					}></SectionWithHeading>
			</Box>
			<Spacing space={16}></Spacing>
			<Box>
				<SectionWithHeading
					headerClass="md:!flex-row flex-col"
					actionButtons={
						<TextWrapper
							classList="!caption !text-sm"
							text={t('paymentHistoryText')}></TextWrapper>
					}
					heading={t('paymentHistory')}
					children={
						<Box>
							<DataTableGrid
								name={'paymentHistory'}
								columns={columns}
								records={paymentHistory}
								showEditColumnsButton={false}
								isPagination={false}
								loading={loadingHistory}></DataTableGrid>
						</Box>
					}></SectionWithHeading>
			</Box>
			<Spacing space={16}></Spacing>
			<Box
				className="p-4"
				component={Paper}>
				<p>
					<span className="!info-text">{t('asPerDFSABorrower')}</span>
					<a
						target="_blank"
						rel="noreferrer"
						className="underline-link mx-1"
						href={settings()?.generalTermsConditions}>
						{t('generalTermsOfUse')},
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="underline-link"
						href={settings()?.borrowerTermsAndConditions}>
						{t('termsAndConditions')},
					</a>

					<a
						target="_blank"
						rel="noreferrer"
						className="underline-link mx-1"
						href={settings()?.privacyPolicy}>
						{t('privacyPolicy')},
					</a>

					<a
						target="_blank"
						rel="noreferrer"
						className="underline-link"
						href={`${WEBSITE_URL}${i18next.language}/credit-assessment`}>
						{t('riskAndCreditAssessment')}
					</a>
				</p>
			</Box>
		</Box>
	);
};
