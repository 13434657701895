/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppBar, Box, Divider, MenuItem } from '@mui/material';
import Logo from '../../../../shared/components/common/logo';
import { useEffect, useRef, useState } from 'react';
import MenuPopover from '../../../../shared/layouts/mainLayout/borrowerLayout/shared/components/menuPopover';
import { WEBSITE_URL } from '../../../../config/appConfig';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Logout, getFirstName } from '../../../../services/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../constants/appRoutes';
import { Platform } from '../platform';

export const RegistrationNavBar = ({
	isLogin = false,
	isShowProfile = false,
	name = null,
}) => {
	const { t } = useTranslation();
	const [openUser, setOpenUser] = useState(false);
	const anchorRefUser = useRef(null);
	const [userName, setUserName] = useState(name);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleOpenUser = (event) => {
		setOpenUser(event.currentTarget);
	};

	const handleCloseUser = () => {
		setOpenUser(null);
	};

	useEffect(() => {
		if (!name) {
			setUserName(getFirstName());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<Box className="mb-6">
			<AppBar
				className="h-[80px]  !bg-white flex justify-center items-center app-bar-shadow"
				position="static">
				<Box className="xl:w-[1140px] w-full flex justify-between items-center md:px-0 px-2">
					<Box>
						<Logo
							url={WEBSITE_URL}
							classList="md:!w-[150px] !h-auto !w-[100px] md:-mx-[16px]"></Logo>
					</Box>
					<Box className="flex items-center">
						{!isLogin ? (
							<Box className={`${userName ? 'mx-3' : ''}`}>
								<Platform />
							</Box>
						) : null}

						{userName && !isLogin ? (
							<Box className="w-auto">
								<Box data-testid="userMenu"
									ref={anchorRefUser}
									className="flex flex-0 items-center cursor-pointer !text-[#757575]"
									onClick={handleOpenUser}>
									<AccountCircleOutlinedIcon className="!text-[22px]" />
									<Box
										data-testid="username"
										className="mx-1 !text-[14px] font-[500] md:!block !hidden">
										{userName}
									</Box>
									<KeyboardArrowDownIcon />
								</Box>
								<MenuPopover
									open={Boolean(openUser)}
									anchorEl={openUser}
									onClose={handleCloseUser}
									sx={{
										p: 0,
										mt: 1.5,
										ml: 0.75,
										'& .MuiMenuItem-root': {
											typography: 'body2',
											borderRadius: 0.75,
										},
									}}>
									<MenuItem
										sx={{ px: 1, marginTop: 1 }}
										className="md:!hidden !block">
										<Box className="flex flex-0 items-center py-2">
											<Box className="mx-1 font-[500] overflow-hidden text-ellipsis">
												{userName}
											</Box>
										</Box>
									</MenuItem>
									<Divider className="!m-0 md:!hidden !block"></Divider>
									{isShowProfile ? (
										<Box>
											<MenuItem
												data-testid="myProfile"
												onClick={() => {
													navigate(
														buildLocalizeRoute(
															`${AppRoutes.Investor}/${AppRoutes.Profile}`
														)
													);
												}}
												sx={{ px: 1 }}>
												<Box className="flex flex-0 items-center py-2">
													<AccountCircleOutlinedIcon />
													<Box className="mx-1">{t('myProfile')}</Box>
												</Box>
											</MenuItem>
											<Divider className="!m-0" />
										</Box>
									) : null}
									<MenuItem
										onClick={() => {
											Logout();
											navigate(buildLocalizeRoute(AppRoutes.Login));
										}}
										sx={{ px: 1 }}>
										<Box className="flex flex-0 items-center py-2">
											<LogoutIcon className="!text-red-500" />
											<Box className="mx-1 text-red-500">{t('signOut')}</Box>
										</Box>
									</MenuItem>
								</MenuPopover>
							</Box>
						) : null}
					</Box>
				</Box>
			</AppBar>
		</Box>
	);
};
