import { Box, CircularProgress } from '@mui/material';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../../helpers/formHelper';
import { CommercialRegistrationSchema } from '../../../../../shared/validations/schema';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import { BorrowerRegService } from '../../borrowerRegService';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateBorrowerSteps,
} from '../../../../../services/authService';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { Toaster } from '../../../../../shared/components/common/toaster';

export const CommercialRegistration = ({
	isLoading = false,
	buttonLoading = false,
}) => {
	const borrowerRegService = new BorrowerRegService();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(isLoading);
	const [loadingSubmit, setLoadingSubmit] = useState(buttonLoading);
	const [showCompanies, setShowCompanies] = useState(true);
	const [selectedCompanyId, setSelectedCompanyId] = useState(null);

	const navigate = useNavigate();

	const [companies, setCompanies] = useState([]);

	const form = useFormik({
		initialValues: {
			tradeLicenseNumber: '',
		},
		onSubmit: () => {
			onRegisterCRNumber();
		},
		validationSchema: CommercialRegistrationSchema,
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		getAllData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAllData = async () => {
		try {
			setLoading(true);
			const [companyDetails, businessInformation] = await Promise.all([
				borrowerRegService.requestWathq(),
				borrowerRegService.getBorrowerProfileBusinessInfo(),
			]);
			let companyData = companyDetails.data;
			setCompanies(companyData || []);
			let businessData = businessInformation.data;
			if (businessData?.tradeLicenseNumber) {
				let isIdAvailable = companyData?.some(
					(resp) =>
						resp?.commercialRegistrationNumber ===
						businessData?.tradeLicenseNumber
				);
				if (isIdAvailable) {
					setSelectedCompanyId(businessData?.tradeLicenseNumber);
				} else {
					form.setFieldValue(
						'tradeLicenseNumber',
						businessData?.tradeLicenseNumber
					);
					setShowCompanies(false);
				}
			}
			setLoading(false);
		} catch {
			setLoading(false);
		}
	};

	const onRegisterCRNumber = () => {
		let crNumber =
			showCompanies && companies.length
				? selectedCompanyId
				: form.values.tradeLicenseNumber;
		if (!crNumber) {
			Toaster({ msg: "Cant't find Commercial Registration Number" });
			return;
		}
		setLoadingSubmit(true);
		borrowerRegService
			.registerCRNumber(crNumber)
			.then((resp) => {
				afterRegisterCRNumber(resp);
				setLoadingSubmit(false);
			})
			.catch((err) => {
				setLoadingSubmit(false);
			});
	};

	const afterRegisterCRNumber = (resp) => {
		let registrationModel = resp.data?.registrationModel;
		saveTokenFromRegistration(resp.data?.loginModel?.token);
		let regData = {
			...getRegistrationData(),
			step: registrationModel.step,
			requestedStep: 2,
		};
		setRegistrationData(regData);
		updateBorrowerSteps(registrationModel.step);
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.BorrowerRegistartion}/${AppRoutes.BRegDocumentVarification}`
			)
		);
	};

	return !loading ? (
		<Box className="flex flex-col justify-center items-center">
			<Box className="md:w-[465px] w-full flex flex-col company-wrapper rounded-md">
				{showCompanies && companies.length ? (
					<Box className="flex flex-col">
						<Box className="flex flex-col p-6 pb-3">
							<TextWrapper
								classList="heading !text-[14px] !text-center"
								text={t('companiesWithAssociatedId')}
							/>
							<TextWrapper
								classList="!caption !text-[13px] !text-center"
								text={t('companiesWithAssociatedIdInfo')}
							/>
						</Box>
						<Box className="company-grid px-6 pt-4">
							<Box className="grid grid-cols-1 gap-2 ">
								{companies.map((resp, index) => {
									return (
										<Box
											data-testid="companyItem"
											onClick={() => {
												setSelectedCompanyId(
													resp?.commercialRegistrationNumber
												);
											}}
											key={index}
											className={`bg-white relative company-card rounded-md p-3 flex-col justify-center flex ${
												selectedCompanyId === resp?.commercialRegistrationNumber
													? 'active-company'
													: ''
											}`}>
											<CheckCircleIcon
												className={`header-active-icon ${
													selectedCompanyId ===
													resp?.commercialRegistrationNumber
														? 'opacity-100'
														: 'opacity-0'
												}`}
											/>
											<Box
												data-testid={resp.name}
												title={resp.name}
												className="heading !text-[14px] !text-start">
												{resp.name}
												<span className="!text-[14px] !text-start">
													&nbsp;({resp.commercialRegistrationNumber})&nbsp;
												</span>
											</Box>
										</Box>
									);
								})}
							</Box>
						</Box>
						<Box className="p-7 flex justify-center items-center">
							<ButtonWrapper
								dataTestID="nextBtnCompany"
								disabled={!selectedCompanyId}
								loading={loadingSubmit}
								largeBtn
								label={t('next')}
								onClick={() => {
									onRegisterCRNumber();
								}}
							/>
						</Box>
					</Box>
				) : (
					<Box className="flex flex-col">
						<Box className="flex flex-col p-6 pb-3">
							<TextWrapper
								classList="heading !text-[14px] !text-center"
								text={t('companiesNotAssociatedId')}
							/>
							<TextWrapper
								classList="!caption !text-[13px] !text-center"
								text={t('companiesNotAssociatedIdInfo')}
							/>
						</Box>
						<Box className="px-6 pt-4 pb-7">
							<FormikProvider value={form}>
								<InputWrapper
									dataTestID="tradeLicenseNumber"
									errorStateTestID="tradeLicenseNumberError"
									key={'tradeLicenseNumber'}
									name={'tradeLicenseNumber'}
									label={t('crNumber')}
								/>
								<Box className="mt-7 flex justify-center items-center">
									<ButtonWrapper
										dataTestID="nextBtnTradeLicence"
										loading={loadingSubmit}
										largeBtn
										label={t('next')}
										onClick={() => {
											form.submitForm();
										}}
									/>
								</Box>
							</FormikProvider>
						</Box>
					</Box>
				)}
			</Box>
			{companies.length ? (
				<Box className="flex items-center justify-center mt-6">
					<Box
						className="underline-link text-primary !text-center font-[600]"
						onClick={() => {
							setSelectedCompanyId(null);
							form.setFieldValue('tradeLicenseNumber', '');
							form.setFieldTouched('tradeLicenseNumber', false);
							setShowCompanies(!showCompanies);
						}}>
						{!showCompanies
							? t('DoYouWantAnotherCRnumber')
							: t('DoYouWantRegisterCRnumber')}
					</Box>
				</Box>
			) : null}
		</Box>
	) : (
		<Box className="flex justify-center items-center reg-section-height">
			<CircularProgress />
		</Box>
	);
};
