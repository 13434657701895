import {
	axiosGet,
	axiosPatch,
	axiosPost,
	axiosPut,
} from '../../services/axiosService';
const borrowerSummary = '/BorrowerSummary';
const getPaymentHistoryURI = borrowerSummary + '/PaymentHistory';
const getNextPaymentURI = borrowerSummary + '/NextPayment';
const borrowerURL = '/Borrowers';
const borrowerProfilePersonalInfoURI = borrowerURL + '/Me/PersonalInformation';
const borrowerProfileBusinessInfoURI = borrowerURL + '/Me/BusinessInformation';
const borrowerBaseURI = 'borrowers';

export class BorrowerService {
	getPaymentHistory() {
		return axiosGet(null, getPaymentHistoryURI);
	}

	getNextPayment() {
		return axiosGet(null, getNextPaymentURI);
	}

	getSummary() {
		return axiosGet(null, borrowerSummary);
	}

	getBorrowerProfilePersonalInfo() {
		return axiosGet(null, borrowerProfilePersonalInfoURI);
	}

	activateAccount() {
		return axiosPatch(borrowerURL + '/me/activate', null, {
			hide_success_toaster: true,
		});
	}

	deactivateAccount() {
		return axiosPatch(borrowerURL + '/me/deactivate', null, {
			hide_success_toaster: true,
		});
	}

	updateBorrowerProfilePersonalInfo(form) {
		return axiosPut(form, borrowerProfilePersonalInfoURI);
	}

	getBorrowerProfileBusinessInfo() {
		return axiosGet(null, borrowerProfileBusinessInfoURI);
	}

	updateBorrowerProfileBusinessInfo(form) {
		return axiosPut(form, borrowerProfileBusinessInfoURI);
	}

	registerBorrowerEligibility(form) {
		return axiosPost(form, `${borrowerBaseURI}/eligibility`);
	}

	submitDocuments(documents ={}) {
		return axiosPost(documents, borrowerBaseURI + `/documents`);
	}
}
