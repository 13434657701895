import React, { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import MenuPopover from '../../../shared/components/menuPopover';
import { AvailableLanguages } from '../../../../../../../config/languages';
import { LanguageService } from '../../../../../../../services/languageService';
import { useTranslation } from 'react-i18next';

const LANGS = AvailableLanguages;

export default function LanguagePopover() {
	const languageService = new LanguageService();
	const anchorRef = useRef(null);
	const activeLang = languageService.getActiveLanguageObject();
	const [open, setOpen] = useState(false);
	const { i18n } = useTranslation();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const changeLanguage = (lang) => {
		if (lang !== i18n.language) {
			languageService.changeLanguage(lang);
		}
		handleClose();
	};

	const isAr = () => {
		return languageService.isArabic();
	};

	return (
		<>
			<Box
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					cursor: 'pointer',
					padding: 0,
					...(open && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.focusOpacity
							),
					}),
				}}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
					<Typography
						variant="subtitle2"
						sx={{ color: 'white', marginTop: '2px' }}>
						<span >{activeLang?.name}</span>
					</Typography>
				</Box>
			</Box>

			<MenuPopover
				open={open}
				onClose={handleClose}
				anchorEl={anchorRef.current}
				className={`lang-popup ${isAr() ? 'lang-popup-ar' : ''}`}
				sx={{
					mt: 1.5,
					ml: 0.75,
					width: 180,
					'& .MuiMenuItem-root': {
						px: 1,
						typography: 'body2',
						borderRadius: 0.75,
					},
				}}>
				<Stack spacing={0.75}>
					{LANGS.map((option, index) => (
						<MenuItem
							key={index}
							selected={option.lang === activeLang?.lang}
							onClick={() => changeLanguage(option.lang)}>

							<Box
								className={option.lang === activeLang?.lang ? 'font-bold' : ''}>
								{option.name}
							</Box>
						</MenuItem>
					))}
				</Stack>
			</MenuPopover>
		</>
	);
}
