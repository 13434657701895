import { axiosPatch, axiosPost } from './axiosService';

const getOTPURI = `/otp`;
const updateMobileNumber = '/Users/me/PhoneNumber';

export class OtpService {
	verifyOTP(form) {
		return axiosPost(
			form,
			`${getOTPURI}/verify`,
			{
				hide_success_toaster: true,
			},
			'json'
		);
	}

	sendOTP(corrolationId, type) {
		return axiosPost(
			{
				corrolationId: corrolationId,
				type: type,
			},
			`${getOTPURI}/send`
		);
	}

	unAuthVerifyOTP(form) {
		return axiosPost(form, `${getOTPURI}/UnauthorizedVerify`, {}, 'json');
	}

	unAuthSendOTP(corrolationId, type, userEmail) {
		return axiosPost(
			{
				corrolationId: corrolationId,
				type: type,
				userEmail: userEmail,
			},
			`${getOTPURI}/UnauthorizedSend`
		);
	}

	updateMobileNumber(phoneNumber) {
		return axiosPatch(updateMobileNumber, phoneNumber);
	}
}
