import { Box, Stack } from '@mui/material';
import { DialogWrapper } from '../common/dialogWrapper';
import { ButtonWrapper } from '../formUtilities/useButton';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import { validatePassword } from '../../../services/authService';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../helpers/formHelper';
import { TextWrapper } from '../formUtilities/useTextWrapper';
import ConfirmPasswordImage from '../../../assets/images/security.png';
import { InputWrapper } from '../formUtilities/useInput';
import { useTranslation } from 'react-i18next';

export const ConfirmPasswordDialog = ({ state, onClose = (e) => {} }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const form = useFormik({
		initialValues: {
			password: '',
		},
		...FormHelper.formikProps(),
	});

	const onUpdatePassword = () => {
		setLoading(true);
		validatePassword(form.values)
			.then((resp) => {
				setLoading(false);
				onClose(true);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<DialogWrapper
				maxWidth="xs"
				width={'330px'}
				state={state}
				onClose={() => {
					onClose(false);
				}}
				title={``}
				actionFooterClass="!px-0"
				content={
					<Stack>
						<Box className="flex flex-col flex-0 items-center">
							<img
								className="w-28 mb-3 -mt-[7px]"
								src={ConfirmPasswordImage}
								alt="ConfirmPasswordImage"></img>
							<TextWrapper
								classList="!font-bold !text-xl !mb-3"
								text={t('passwordConfirm')}></TextWrapper>
						</Box>
						<Stack
							gap={2}
							className="text-center">
							<TextWrapper
								classList="!sub-title-text"
								text={t('enterCurrentPassword')}></TextWrapper>
							<form>
								<FormikProvider value={form}>
									<InputWrapper
										type="password"
										key={'password'}
										name={'password'}
										label={t('password')}></InputWrapper>
								</FormikProvider>
							</form>
						</Stack>
					</Stack>
				}
				actions={
					<Box className="!w-full text-center">
						<ButtonWrapper
							loading={loading}
							disabled={!form.values.password}
							icon={<CheckIcon></CheckIcon>}
							type="button"
							onClick={() => {
								onUpdatePassword();
							}}
							label="Confirm"></ButtonWrapper>
					</Box>
				}></DialogWrapper>
		</>
	);
};
