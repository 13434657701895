import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DictionaryService } from '../../../../../services/dictionaryService';
import { useState } from 'react';
import { useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../../../helpers/formHelper';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { RadioWrapper } from '../../../../../shared/components/formUtilities/userRadioButton';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import { BorrowerEligibilitySchema } from '../../../../../shared/validations/schema';
import { LocalStorageKey } from '../../../../../constants/localStorageKeys';
import { CURRENCY, IS_SA } from '../../../../../config/appConfig';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';

export const EligibilityChecker = ({
	onSubmit = () => { },
	regCountryList = [],
	isSA = IS_SA,
}) => {
	const dictionaryService = new DictionaryService();

	const { t } = useTranslation();
	const confirm = useConfirm();

	const [registrationCountryList, setRegistrationCountryList] =
		useState(regCountryList);
	const [annualRevenue, setAnnualRevenue] = useState(null);
	const howMuchAmountNeeded = [
		{ name: `${CURRENCY} 50,000 - 500,000`, displayName: `${CURRENCY} 50,000 - 500,000`, id: 1 },
		{ name: `${CURRENCY} 500,000 - 1,000,000`, displayName: `${CURRENCY} 500,000 - 1,000,000`, id: 2 }
	];

	const howLongFinancing = [
		{ name: t('12MonthsOrLess'), displayName: t('12MonthsOrLess'), id: 1 },
		{ name: `12-18 ${t('monthsLowerCase')}`, displayName: `12-18 ${t('monthsLowerCase')}`, id: 2 },
		{ name: `18-24 ${t('monthsLowerCase')}`, displayName: `18-24 ${t('monthsLowerCase')}`, id: 3 }
	];

	const [operationYears, setOperationYears] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const form = useFormik({
		initialValues: {
			countryOfRegistrationId: '',
			yearsOfOperationId: '',
			revenue: '',
			financialObligations: '',
			howMuchAmountNeededId: '',
			howLongFinancingId: '',
			revenuesFromAlcoholGamblingTabacco: isSA ? false : '',
		},
		validationSchema: BorrowerEligibilitySchema,
		onSubmit: (values) => {
			submit(values);
		},
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		if (regCountryList && regCountryList.length) {
			form.setFieldValue(
				'countryOfRegistrationId',
				getDefaultCountry(regCountryList)?.id || ''
			);
		}
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDefaultCountry = (countryList = []) => {
		return countryList.find((resp) => resp?.id === (isSA ? 2 : 1));
	};

	const fetchData = async () => {
		const [registrationCountryList, annualRevenue, operationYears] =
			await Promise.all([
				dictionaryService.getCountryRegistrations(),
				dictionaryService.getRevenue(),
				dictionaryService.getYearOfOperation(),
			]);
		form.setFieldValue(
			'countryOfRegistrationId',
			getDefaultCountry(registrationCountryList.data)?.id || ''
		);
		setRegistrationCountryList(registrationCountryList.data);
		setAnnualRevenue(annualRevenue.data);
		setOperationYears(operationYears.data);
	};

	const getNameById = (list = [], id) => {
		return list.find((resp) => resp.id === id)?.name;
	};

	const mapEligibilityForm = (formValue) => {
		const formData = formValue;
		return {
			countryOfRegistrationId: formData.countryOfRegistrationId,
			revenue: formData.revenue,
			financialObligations: formData.financialObligations,
			yearsOfOperationId: formData.yearsOfOperationId,
			revenuesFromAlcoholGamblingTabacco:
				formData.revenuesFromAlcoholGamblingTabacco,
		};
	};

	const onBack = () => {
		navigate(buildLocalizeRoute(`${AppRoutes.Login} `));
	};

	const submit = () => {
		const borrowerEligibility = mapEligibilityForm(form.values);
		if (
			borrowerEligibility.ongoingPoliceInvestigation ||
			borrowerEligibility.financialObligations ||
			getNameById(
				registrationCountryList,
				borrowerEligibility.countryOfRegistrationId
			) === 'Other' ||
			getNameById(operationYears, borrowerEligibility.yearsOfOperationId) ===
			'Less than 2 years' ||
			borrowerEligibility.revenuesFromAlcoholGamblingTabacco ||
			borrowerEligibility.revenue === 'AED 0 - 3 million'
		) {
			confirm({
				description: t('sorryWeCannotProceedWithProcessingYourApplicationText'),
				title: t('sorryWeCannotProceedWithProcessingYourApplication'),
				titleProps: { className: '!text-center md:!text-2xl' },
				confirmationButtonProps: {
					className: '!primary-bg !text-white !capitalize',
				},
				contentProps: {
					sx: {
						textAlign: 'center',
					},
				},
				dialogActionsProps: {
					sx: {
						display: 'flex',
						justifyContent: 'center',
						textAlign: 'center',
						paddingBottom: '20px',
					},
				},
				allowClose: false,
				confirmationText: t('ok'),
				hideCancelButton: 'true',
			})
				.then(() => { })
				.catch(() => console.log('cancelled.'));
		} else {
			sessionStorage.setItem(LocalStorageKey.isEligibilityCompleted, '1');
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				onSubmit();
			}, 1000);
		}
	};

	return (
		<Box
			className="flex flex-col space-y-4 items-center"
			data-testid="eligibilityForm">
			<Box className="text-[26px] font-[600] text-center">
				{t('eligibilityChecker')}
			</Box>
			<Box className="md:w-[800px] flex flex-col">
				<p className="text-sm font-[500]">{t('eligibilityCheckerText')}</p>
				<FormikProvider value={form}>
					<Box className="mt-4 flex flex-col space-y-4">
						<SelectWrapper
							dataTestID="countryOfRegistrationId"
							errorStateTestID="countryOfRegistrationIdError"
							name={'countryOfRegistrationId'}
							key={'countryOfRegistrationId'}
							items={registrationCountryList}
							label={'countryOfRegistration'}
						/>
						<Box className='grid grid-cols-1 md:grid-cols-2 gap-4'>
							<SelectWrapper
								errorStateTestID="yearsOfOperationIdError"
								name={'yearsOfOperationId'}
								key={'yearsOfOperationId'}
								items={operationYears}
								label={'yearsOfOperations'}
							/>
							<SelectWrapper
								errorStateTestID="revenueError"
								name={'revenue'}
								key={'revenue'}
								items={annualRevenue}
								label={'revenue'}
							/>
						</Box>
						<Box className='grid grid-cols-1 md:grid-cols-2 gap-4'>
							<SelectWrapper
								errorStateTestID="howMuchAmountNeededError"
								name={'howMuchAmountNeededId'}
								key={'howMuchAmountNeededId'}
								items={howMuchAmountNeeded}
								label={'howMuchAmountNeeded'}
							/>
							<SelectWrapper
								errorStateTestID="howLongFinancingError"
								name={'howLongFinancingId'}
								key={'howLongFinancingId'}
								items={howLongFinancing}
								label={'howLongFinancing'}
							/>
						</Box>

						<Box className="flex flex-col space-y-2">
							<RadioWrapper
								dataTestID="financialObligations"
								errorStateTestID="ongoingPoliceInvestigationError"
								options={[
									{ name: t('yes'), value: true },
									{ name: t('no'), value: false },
								]}
								name={'financialObligations'}
								key={'financialObligations'}
								label={'isThereOngoingPoliceInvestigation'}></RadioWrapper>

							{!isSA && (
								<RadioWrapper
									dataTestID="revenuesFromAlcoholGamblingTabacco"
									errorStateTestID="otherIssuesError"
									options={[
										{ name: t('yes'), value: true },
										{ name: t('no'), value: false },
									]}
									name={'revenuesFromAlcoholGamblingTabacco'}
									key={'revenuesFromAlcoholGamblingTabacco'}
									label={'revenuesFromAlcoholGamblingTabacco'}></RadioWrapper>
							)}
						</Box>
						<Box className="flex md:justify-between !mt-8 justify-center items-center">
							<ButtonWrapper
								classList="!secondary-btn md:!mx-0 !mx-2"
								largeBtn
								label={t('back')}
								onClick={() => {
									onBack();
								}}
							/>
							<ButtonWrapper
								dataTestID="eligibilityNextBtn"
								loading={loading}
								largeBtn
								icon={<ApprovalOutlinedIcon></ApprovalOutlinedIcon>}
								label={t('getApproved')}
								onClick={() => {
									FormHelper.validateForm(form);
								}}
							/>
						</Box>
					</Box>
				</FormikProvider>
			</Box>
		</Box>
	);
};
