export const InputTypes = {
	Radio: 'radio',
	File: 'file',
	Text: 'text',
};

export const QuestionsTypes = {
	None: '',
	Confirm: 'confirm',
	Cancel: 'cancel',
	Questions: 'QA',
};
