import { useTranslation } from 'react-i18next';
import { SectionWithHeading } from '../../../../shared/components/common/sectionWithHeading';
import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { BecomeProService } from '../../../../services/becomeProService';
import questionAnswersJson from './question-answers.json';
import { BecomeProStatuses } from '../../../../constants/becomeProStatus';
import { DateHelper } from '../../../../helpers/dateHelper';
import { InputTypes, QuestionsTypes } from './questionAnswerTypes';
import { UserService } from '../../../../services/userService';
import { UserDocumentTypes } from '../../../../constants/userDocuments';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { FileUploadWrapper } from '../../../../shared/components/common/fileUploadWrapper';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import CheckIcon from '@mui/icons-material/Check';
import { Section } from '../../../../shared/components/common/section';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

export const BecomeProInvestor = () => {
	const becomeProService = new BecomeProService();
	const userService = new UserService();

	const [loading, setLoading] = useState(false);
	const [trackSteps, setTrackSteps] = useState([]);
	const [nextStep, setNextStep] = useState(1);
	const [questionsList, setQuestionsList] = useState(
		questionAnswersJson.questionAnswers
	);
	const [becomeProStatus, setBecomeProStatus] = useState({
		status: null,
		statusSetDate: null,
	});
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
	const [loadingFile, setLoadingFile] = useState(false);
	const { t } = useTranslation();

	const isApproved = () => {
		return becomeProStatus.status === BecomeProStatuses.Approved;
	};

	const isRequested = () => {
		if (
			isApproved() ||
			becomeProStatus.status === BecomeProStatuses.Requested
		) {
			return true;
		}
		return isRejected();
	};

	const isRejected = () => {
		if (becomeProStatus.status === BecomeProStatuses.Rejected) {
			if (DateHelper.dateIsAfter(becomeProStatus.statusSetDate)) {
				return false;
			}
			return true;
		}
		return false;
	};

	useEffect(() => {
		setQuestionsList(questionAnswersJson.questionAnswers);
		getProStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isConfirmOrCancel = (question) => {
		if (question?.type === QuestionsTypes.Cancel) {
			return true;
		}
		if (question?.type === QuestionsTypes.Confirm) {
			return true;
		}
		return false;
	};

	const getProStatus = () => {
		setLoading(true);
		becomeProService
			.getBecomeProStatus()
			.then((resp) => {
				setLoading(false);
				setBecomeProStatus(resp.data);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const checkError = (question) => {
		if (!question.required) {
			return false;
		}
		if (question.required && !question.answer.toString()) {
			return true;
		}
		return false;
	};

	const updateValue = (value, index) => {
		questionsList[index].answer = value;
		setQuestionsList([...questionsList]);
	};

	const removeDuplicateSteps = () => {
		let trackStepsArray = trackSteps.filter(function (item, index, inputArray) {
			return inputArray.indexOf(item) === index;
		});

		setTrackSteps([...trackStepsArray]);
	};

	const onNextStep = (question, index) => {
		let oldTrackSteps = trackSteps;
		oldTrackSteps.push(question.id);
		setTrackSteps([...oldTrackSteps]);
		if (
			question.success_answer &&
			question.success_answer?.toString() === question.answer?.toString()
		) {
			setNextStep(question.next_step.success);
		} else {
			setNextStep(question.next_step.default);
		}
		removeDuplicateSteps();
		questionsList[index].isCompleted = true;
		setQuestionsList([...questionsList]);
	};

	const onPreviousStep = (question, index) => {
		if (trackSteps.length > 1) {
			setNextStep(trackSteps[trackSteps.length - 1]);
			let trackStepsValue = trackSteps;
			trackStepsValue.splice(trackSteps.length - 1, 1);
			setTrackSteps([...trackStepsValue]);
		} else {
			setTrackSteps([]);
			setNextStep(1);
		}
		questionsList[index].isCompleted = false;
		questionsList[index].answer = question?.answer;

		setQuestionsList([...questionsList]);
	};

	const getFormattedQA = (
		exceptInputType,
		includeFileType = InputTypes.File
	) => {
		if (exceptInputType) {
			return questionsList
				.filter(
					(question) =>
						question.isCompleted &&
						question.id !== 1 &&
						exceptInputType !== question.input_type
				)
				.map((resp) => {
					let questionDesc = resp.question_description
						? t(resp.question_description)
						: '';
					let question = t(resp.question) + questionDesc;
					return {
						question: question,
						answer: resp.answer,
					};
				});
		} else if (includeFileType) {
			return questionsList.filter(
				(data) => data.input_type === includeFileType
			);
		}
	};

	const onSubmit = () => {
		setIsLoadingSubmit(true);
		let completedAnswers = getFormattedQA(InputTypes.File);
		let becomePro = {
			netWorthProofUrl: getFormattedQA(null, InputTypes.File)[0].answer,
			questionnaireJson: completedAnswers,
		};

		becomeProService
			.updateQuestionAnswers(becomePro)
			.then((resp) => {
				setIsLoadingSubmit(false);
				getProStatus();
			})
			.catch((err) => {
				setIsLoadingSubmit(false);
			});
	};

	const removeFile = (index) => {
		questionsList[index].fileName = '';
		questionsList[index].answer = '';
		setQuestionsList([...questionsList]);
	};

	const onUploadFile = (file, index) => {
		setLoadingFile(true);
		userService
			.uploadUserDocuments(file, UserDocumentTypes.InvestorNetWorth)
			.then((resp) => {
				setLoadingFile(false);
				let document = resp.data;
				questionsList[index].fileName = document.file.name;
				questionsList[index].answer = document.file.url;
				setQuestionsList([...questionsList]);
			})
			.catch((err) => {
				setLoadingFile(false);
			});
	};

	const termsAndConditions = () => {
		return (
			<Box className="grid grid-flow-row gap-4">
				{[1, 2, 3, 4].map((resp) => {
					return (
						<Box
							key={resp}
							className="flex flex-0 items-start">
							<CheckCircleOutlineIcon
								className="!text-[20px] !mt-1"
								color="primary"></CheckCircleOutlineIcon>
							<TextWrapper
								classList="!text-[16px] !font-[500] !mx-2"
								text={t(`qAConfirmation${resp}`)}></TextWrapper>
						</Box>
					);
				})}
			</Box>
		);
	};

	const getRatioButton = (question, index) => {
		return (
			<FormControl>
				<RadioGroup
					value={question?.answer}
					onChange={(e) => {
						updateValue(
							e.target.value === 'true'
								? true
								: e.target.value === 'false'
								? false
								: e.target.value,
							index
						);
					}}>
					{question?.values?.map((resp, index) => {
						return (
							<FormControlLabel
								key={index}
								value={resp?.value}
								control={<Radio className='!px-[3px] !pb-[3px] !pt-0'/>}
								label={t(resp?.name)}
							/>
						);
					})}
				</RadioGroup>
			</FormControl>
		);
	};

	const getInputs = (question, index) => {
		return (
			<TextField
				onChange={(e) => {
					updateValue(e.target.value, index);
				}}
				value={question?.answer}
				className="w-full"
				variant="outlined"
				size="small"
			/>
		);
	};

	const getFileSection = (question, index) => {
		return (
			<Box className="flex justify-center">
				<Box className="grid grid-flow-row gap-2 md:w-[350px]">
					<FileUploadWrapper
						showStaticImage={true}
						onRemoveFile={() => {
							removeFile(index);
						}}
						onUpload={(file) => {
							onUploadFile(file, index);
						}}
						imageURL={question?.answer}
						loading={loadingFile}
						uploadFileType={['.png', '.jpg', '.pdf']}></FileUploadWrapper>
					<Box className="text-center mt-2 !text-[16px] !font-bold">
						<span>{question?.fileName}</span>
					</Box>
				</Box>
			</Box>
		);
	};

	const getInputsSections = (question, index) => {
		if (InputTypes?.Radio === question?.input_type) {
			return getRatioButton(question, index);
		} else if (InputTypes?.Text === question?.input_type) {
			return getInputs(question, index);
		} else if (InputTypes?.File === question?.input_type) {
			return getFileSection(question, index);
		}
	};

	return (
		<SectionWithHeading
			loading={loading}
			heading={
				<Box className="flex items-center">
					<EmojiEventsOutlinedIcon
						className="!text-[26px]"
						color="primary"></EmojiEventsOutlinedIcon>
					<span className="mx-2">{t('becomePro')}</span>
				</Box>
			}
			children={
				<Box>
					{!isRequested() ? (
						<Box className="flex justify-center">
							{questionsList.map((question, index) => {
								return (
									<Box key={index}>
										{question.id === nextStep ? (
											<Box
												key={index}
												className="md:w-[600px] grid grid-flow-row gap-4">
												{QuestionsTypes.Confirm !== question?.type ? (
													<Box className="grid grid-flow-row gap-4">
														<Box
															className={`flex flex-col flex-0 ${
																question?.type === QuestionsTypes?.Cancel ||
																question?.id === 1
																	? '!justify-center !items-center !text-center'
																	: ''
															}`}>
															<TextWrapper
																classList={`!text-[18px] !font-[600] `}
																text={t(question?.question)}></TextWrapper>
															<TextWrapper
																type="caption"
																text={t(
																	question?.question_description
																)}></TextWrapper>
														</Box>
														<Box>{getInputsSections(question, index)}</Box>
													</Box>
												) : (
													<Box>{termsAndConditions()}</Box>
												)}
												<Box className="flex flex-0 justify-center items-center">
													<ButtonWrapper
														classList="!mx-2 !min-w-[120px]"
														label={t('back')}
														onClick={() => {
															onPreviousStep(question, index);
														}}
														variant={'outlined'}
														hideBtn={nextStep < 2}
														disabled={
															loadingFile || isLoadingSubmit
														}></ButtonWrapper>
													{!isConfirmOrCancel(question) ||
													question?.input_type === QuestionsTypes.Questions ? (
														<ButtonWrapper
															classList="!min-w-[120px] !shadow-none"
															onClick={() => {
																onNextStep(question, index);
															}}
															disabled={checkError(question) || loadingFile}
															label={
																nextStep === 1 ? t('continue') : t('next')
															}></ButtonWrapper>
													) : null}
													<ButtonWrapper
														classList="!min-w-[120px] !shadow-none"
														onClick={() => {
															onSubmit();
														}}
														hideBtn={question?.type !== QuestionsTypes.Confirm}
														loading={isLoadingSubmit}
														label={t('submit')}></ButtonWrapper>
													<ButtonWrapper
														classList="!min-w-[120px] !shadow-none"
														onClick={() => {
															onSubmit();
														}}
														icon={<CheckIcon></CheckIcon>}
														hideBtn={question?.type !== QuestionsTypes.Cancel}
														disabled={isLoadingSubmit}
														label={t('submit')}></ButtonWrapper>
												</Box>
											</Box>
										) : null}
									</Box>
								);
							})}
						</Box>
					) : (
						<Box className="w-full justify-center flex">
							<Section
								classList="md:!w-[400px]"
								children={
									<Box className="flex justify-center items-center flex-col">
										{!isApproved() ? (
											<CheckCircleOutlineOutlinedIcon
												color="primary"
												className="!text-[100px]"></CheckCircleOutlineOutlinedIcon>
										) : (
											<CelebrationOutlinedIcon
												color="primary"
												className="!text-[100px]"></CelebrationOutlinedIcon>
										)}

										<Box className="mt-4">
											<TextWrapper
												classList="!heading"
												text={
													!isApproved() ? t('thankYou') : t('congrats')
												}></TextWrapper>
										</Box>
										<Box className="mt-2 text-center">
											{!isApproved() ? (
												<TextWrapper
													text={
														!isRejected()
															? t('becomeAProThankYouContent')
															: t('becomeProRejectedText')
													}
													classList="!text-[16px]"></TextWrapper>
											) : (
												<TextWrapper
													text={t('youareAProInvestor')}
													classList="!text-[16px]"></TextWrapper>
											)}
										</Box>
									</Box>
								}></Section>
						</Box>
					)}
				</Box>
			}></SectionWithHeading>
	);
};
