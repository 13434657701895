import { InvestorRegistrationStep } from '../constants/investorRegistrationSteps';
import { Roles } from '../constants/roles';
import { BorrowerRegistrationStep } from '../constants/borrowerRegistrationSteps';
import { AppRoutes } from '../constants/appRoutes';
import { IS_SA } from '../config/appConfig';

export const checkIsBorrowerOrInvestor = (registrationData, user) => {
	let route = null;
	if (
		user?.role === Roles.Investor &&
		registrationData?.investorStep !== InvestorRegistrationStep.Declaration
	) {
		route = redirectToInvestorStep(
			registrationData?.investorStep,
			registrationData?.investorType
		);
		return route;
	}

	if (
		user?.role === Roles.Borrower &&
		registrationData?.borrowerStep !== BorrowerRegistrationStep.Declaration
	) {
		route = redirectToBorrowerStep(registrationData.borrowerStep);
		return route;
	}

	return route;
};

function redirectToInvestorStep(step, investorType) {
	let route;
	let mainUrl = `${AppRoutes.InvestorRegistration}`;
	switch (step) {
		case undefined:
		case null:
		case InvestorRegistrationStep.NotStarted:
		case -1:
			route = `${mainUrl}/${
				IS_SA ? AppRoutes.IRegNafathVerification : AppRoutes.IRegPersonalInfo
			}`;
			break;
		case InvestorRegistrationStep.PersonalInformation:
			route = `${mainUrl}/${AppRoutes.IRegPersonalInfo}`;
			break;
		case InvestorRegistrationStep.DocumentVerification:
			route = `${mainUrl}/${AppRoutes.iRegDocumentVarification}`;
			break;
		default:
			route = null;
			break;
	}
	return route;
}

function redirectToBorrowerStep(step) {
	let route;
	let mainUrl = `${AppRoutes.BorrowerRegistartion}`;
	switch (step) {
		case undefined:
		case null:
		case BorrowerRegistrationStep.NotStarted:
			route = IS_SA
				? `${mainUrl}/${AppRoutes.IRegNafathVerification}`
				: `${mainUrl}/${AppRoutes.BRegPersonalInfo}`;
			break;
		case BorrowerRegistrationStep.PersonalInformation:
			route = IS_SA
				? `${mainUrl}/${AppRoutes.CommercialRegistration}`
				: `${mainUrl}/${AppRoutes.BRegPersonalInfo}`;
			break;
		case BorrowerRegistrationStep.BusinessInformation:
			route = IS_SA
				? `${mainUrl}/${AppRoutes.CommercialRegistration}`
				: `${mainUrl}/${AppRoutes.BRegBusinessInfo}`;
			break;
		case BorrowerRegistrationStep.DocumentVerification:
			route = `${mainUrl}/${AppRoutes.BRegDocumentVarification}`;
			break;
		default:
			route = null;
			break;
	}
	return route;
}
