import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AvailableLanguages } from './config/languages';
import { IS_SA } from './config/appConfig';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		initImmediate: false,
		backend: {
			loadPath: `/translations/{{lng}}${IS_SA ? '-sa' : ''}.json`,
			allowMultiLoading: true,
		},
		whitelist: [AvailableLanguages[0].lang, AvailableLanguages[1].lang],
		fallbackLng: [AvailableLanguages[0].lang, AvailableLanguages[1].lang],
		debug: false,
		detection: {
			order: ['path'],
			lookupFromPathIndex: 1,
			checkWhitelist: true,
		},
		interpolation: {
			escapeValue: false,
			formatSeparator: ',',
		},
		react: {
			wait: true,
		},
	});

export default i18n;
