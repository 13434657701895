export class FileHelper {
	static isValidFileExtension(name) {
		const allowedExtensions = ['.jpg', '.jpeg', '.png', '.bmp', '.pdf'];

		const ext = name.substring(name.lastIndexOf('.'));
		if (allowedExtensions.includes(ext.toLowerCase())) {
			return true;
		}
		return false;
	}

	static isPdfFile(url) {
		return url && url.endsWith('.pdf');
	}

	static isImage(type) {
		if (!type) {
			return true;
		}

		if (type.includes('jpeg') || type.includes('png') || type.includes('jpg')) {
			return true;
		} else {
			return false;
		}
	}

	static dataURLtoFile(dataURI, filename) {
		// tslint:disable-next-line:one-variable-per-declaration
		let arr = dataURI.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}

	static dataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(',');
		const byteString =
			splitDataURI[0].indexOf('base64') >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	}
}
