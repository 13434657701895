import { Box, Skeleton, Stack } from '@mui/material';

export const RepaymentChartLoader = () => {
	return (
		<Stack
			className="w-full"
			gap={1}>
			<Box className="flex justify-start">
				<Skeleton
					variant="text"
					sx={{ fontSize: '10px', width: '160px' }}
				/>
			</Box>
			<Box className="flex justify-center mt-4">
				<Box className="grid grid-cols-10 gap-6 justify-center">
					{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((resp) => {
						return (
							<Skeleton key={resp}
								height={'280px'}
								variant="rectangular"
								sx={{ fontSize: '10px', width: '20px' }}
							/>
						);
					})}
				</Box>
			</Box>
		</Stack>
	);
};
