import { Box } from '@mui/material';
import { DialogWrapper } from '../../../../shared/components/common/dialogWrapper';
import { Link } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../constants/appRoutes';
import { useTranslation } from 'react-i18next';

export const RegistrationDialog = ({ state, onClose = (e) => {} }) => {
	const { t } = useTranslation();
	return (
		<>
			<DialogWrapper
				testId="regPopup"
				width={'640px'}
				state={state}
				onClose={() => {
					onClose(false);
				}}
				titleClass="!whitespace-break-spaces md:mx-0 mx-8"
				title={t('register')}
				actionFooterClass="!px-0"
				content={
					<Box className="flex flex-col  flex-0 justify-center items-center !text-sm mb-4 mt-2">
						<Box className="grid md:grid-cols-2 grid-cols-1 gap-4">
							<Link
								className="reg-section  md:min-w-[250px]"
								to={buildLocalizeRoute(
									`${AppRoutes.InvestorRegistration}/${AppRoutes.IRegFirstStep}`
								)}>
								<p className="reg-title">{t('investor')}</p>
								<p className="reg-sub-text md:w-[170px] w-full">
									{t('iWantToBecomeAnInvestorText')}
								</p>
							</Link>
							<Link
								className="reg-section md:min-w-[250px]"
								to={buildLocalizeRoute(
									`${AppRoutes.BorrowerRegistartion}/${AppRoutes.BRegFirstStep}`
								)}>
								<p className="reg-title">{t('borrower')}</p>
								<p className="reg-sub-text md:w-[170px] w-full">
									{t('iWantToBecomeABorrowerText')}
								</p>
							</Link>
						</Box>
					</Box>
				}></DialogWrapper>
		</>
	);
};
