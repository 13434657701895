export const UserDocumentTypes = {
	InvestorIdentifier: 0,
	InvestorPassport: 1,
	InvestorAddressProof: 2,
	InvestorIncorporationDocument: 3,
	InvestorNetWorth: 4,
	BorrowerIdentifier: 5,
	BorrowerPassport: 6,
	BorrowerAddressProof: 7,
	BorrowerIban: 8,
	InvestorIban: 9,
	CorporateInvestorTradeLicense: 10,
	CorporateInvestorMoaArticle: 11,
	CorporateInvestorDelegationAuthority: 12,
	CorporateInvestorPassportCopy: 13,
	CorporateInvestorEvidenceEntityFund: 14,
	CorporateInvestorClientClassificationQn: 15,
	CorporateInvestorCRSCelfDeclForm: 16,
	InvestorProofOfEligibility: 17,
	BorrowerTradeLicense: 18,
	BorrowerArticleAssociationOrMomorandum: 19,
	BorrowerUBOID: 20,
	Borrower12MonthBankStatement: 21,
	BorrowerFinancialStatements: 22,
	BorrowerOtherSupportingDocuments: 23,
	BorrowerVatReport: 24,
};
