import { LocalStorageKey } from '../constants/localStorageKeys';
import { axiosGet } from './axiosService';

const baseSystemSettingsURI = '/SystemSettings';

export class SystemSettingsService {
	systemSettings() {
		let settings = localStorage.getItem(LocalStorageKey.systemSettings);
		if (settings) {
			return JSON.parse(settings);
		}
		return null;
	}

	getAllFront() {
		return axiosGet(null, baseSystemSettingsURI + '/GetForFront')
			.then((resp) => {
				if (resp.data) {
					const settings = {};
					resp.data?.forEach((s) => {
						if (s.inputType === 'checkbox') {
							settings[s.key] = s.value === 'true';
						} else {
							settings[s.key] = s.value;
						}
					});
					localStorage.setItem(
						LocalStorageKey.systemSettings,
						JSON.stringify(settings)
					);
					return settings;
				}
			})
			.catch((err) => {});
	}
}
