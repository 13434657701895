/* eslint-disable no-unused-vars */
import { Box, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { Section } from '../../../../../shared/components/common/section';
import { MarketplaceService } from '../marketplaceService';
import { InvestorService } from '../../../investorService';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import LogoPlaceholder from '../../../../../assets/images/logo-placeholder.jpg';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import {
	CURRENCY,
	IS_SA,
	MIN_INVESTMENT,
	WEBSITE_URL,
} from '../../../../../config/appConfig';
import { MarketplaceStatuses } from '../../../../../constants/marketplaceStatuses';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { InvestBehalfDialog } from '../businessDetails/marketplaceInvestDialog/investBehalfDialog';
import Countdown from 'on-react-countdown';
import { BusinessFinancialRatios } from './businessFinancialRatios';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { InvestSuccessDialog } from './investSuccessDialog';
import { LanguageService } from '../../../../../services/languageService';
import { MarketplaceDetailLoader } from '../../../../../shared/components/investors/loaders/marketplaceDetailLoader';
import { Observables } from '../../../../../observables';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { useTranslation } from 'react-i18next';
import { InvestErrorDialog } from './investErrorDialog';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';
import { IRRCalculation } from '../../../../../helpers/irrCalculationHelper';
import { checkImageRatio } from '../../../../../helpers/imageRatioHelper';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { CustomProgressBar } from '../../../../../shared/components/formUtilities/useProgressBar';
import { CustomTooltip } from '../../../../../shared/components/common/tooltip';

export const MarketplaceDetails = () => {
	const marketplaceService = new MarketplaceService();
	const investorService = new InvestorService();
	const languageService = new LanguageService();
	const systemSettingsService = new SystemSettingsService();
	const [availableCash, setAvailableCash] = useState(0);
	const [businessDetails, setBusinessDetails] = useState({});
	const [openInvest, setOpenInvest] = useState(false);
	const [isOpenOTPDialog, setIsOpenOTPDialog] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [status, setStatus] = useState(searchParams.get('status'));
	const [investDetails, setInvestDetails] = useState({});
	const [investedAmount, setInvestedAmount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [isOpenInvestSuccess, setIsOpenInvestSuccess] = useState(false);
	const [isOpenInvestError, setIsOpenInvestError] = useState(false);
	const [investErrorMessage, setInvestErrorMessage] = useState(false);
	const [isImageRatioFit, setisImageRatioFit] = useState(false);
	const [loadingAvailableCash, setLoadingAvailableCash] = useState(false);
	const params = useParams();
	const [bucketId, setBucketId] = useState(params?.id);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		setBucketId(params?.id);
		setStatus(searchParams.get('status'));
		getMarketplaceDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.id]);

	useEffect(() => {
		checkImageRatio(businessDetails?.logo).then((resp) => {
			setisImageRatioFit(resp);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessDetails]);

	const isTimeRemaining = () => {
		return businessDetails.timeRemaining > 0 ? true : false;
	};

	const getRemainingTime = () => {
		return businessDetails.timeRemaining;
	};

	const isHistory = () => {
		return MarketplaceStatuses.Past === status;
	};

	const calculateMinAmount = () => {
		const availableLoans =
			businessDetails?.loans?.filter(
				(l) => l.loanAmount - l.investedAmount > 0
			) || [];
		return availableLoans.length * MIN_INVESTMENT;
	};

	const getPercentage = (item) => {
		if (!isHistory()) {
			let investPercentage =
				(businessDetails?.investedAmount / businessDetails?.loanAmount) * 100;
			let formattedValue = parseFloat(
				(Math.floor(investPercentage * 100) / 100).toString().substring(0, 4)
			);
			return formattedValue;
		}
		return 100;
	};

	const getMarketplaceDetails = () => {
		setLoading(true);
		marketplaceService
			.getMarketplaceDetails(bucketId)
			.then((resp) => {
				setBusinessDetails(resp.data);
				investorSummaryShort();
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const investorSummaryShort = () => {
		setLoadingAvailableCash(true);
		investorService
			.getInvestorSummaryShort()
			.then((response) => {
				setAvailableCash(response.data?.availableCash);
				setLoadingAvailableCash(false);
			})
			.catch((err) => {
				setLoadingAvailableCash(false);
			});
	};

	const getRemainingAmount = () => {
		return +businessDetails.loanAmount - +businessDetails.investedAmount;
	};

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	const isEnableInvestButton = () => {
		return (
			isTimeRemaining() &&
			settings()?.enableMarketplace &&
			MarketplaceStatuses.Current === status &&
			businessDetails.loanAmount > businessDetails.investedAmount
		);
	};

	const onInvest = () => {
		if (availableCash !== 0) {
			setOpenInvest(true);
		} else {
			navigate(
				buildLocalizeRoute(
					`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`
				)
			);
		}
	};

	const onInvestment = (amount) => {
		setLoading(true);
		setOpenInvest(false);
		setInvestedAmount(amount);
		marketplaceService
			.investMarketplace(bucketId, amount)
			.then((resp) => {
				if (resp.data) {
					setLoading(false);
					setInvestDetails(resp.data);
					if (resp.data?.otpRequested) {
						setIsOpenOTPDialog(true);
					} else {
						onSuccessInvest(resp.data);
					}
				}
			})
			.catch((err) => {
				const errors = err?.response?.data?.errors || err?.response?.data;
				if (errors) {
					const key = Object.keys(errors)[0];
					setInvestErrorMessage(errors[key][0] || '');
				} else {
					setInvestErrorMessage('Something went wrong, request not completed');
				}

				setLoading(false);
				setTimeout(() => {
					setIsOpenInvestError(true);
				});
			});
	};

	const onSuccessInvest = (marketPlaceDetails = null) => {
		setIsOpenInvestSuccess(true);
		setBusinessDetails(
			marketPlaceDetails
				? marketPlaceDetails
				: investDetails?.marketPlaceDetails
		);
	};

	const onCloseOTPAuth = (value) => {
		setIsOpenOTPDialog(false);
		if (value) {
			onSuccessInvest();
		}
	};

	const onSuccessInvestClose = (e) => {
		Observables.investorSummaryRefresh.next(1);
		navigate(
			buildLocalizeRoute(`${AppRoutes.Investor}/${AppRoutes.MyInvestments}`)
		);
	};

	const subItems = (name, value, isLast) => {
		return (
			<Section
				isShadow={false}
				classList={`!rounded-none !p-0 !flex !justify-center !items-center ${!isLast
						? languageService.isArabic()
							? 'md:border-l-2'
							: 'md:border-r-2'
						: ''
					}  `}
				children={
					<Stack className="text-center">
						<TextWrapper
							classList="!font-bold"
							text={`${value}`}></TextWrapper>
						<TextWrapper
							type="caption"
							text={t(name)}></TextWrapper>
					</Stack>
				}></Section>
		);
	};

	return (
		<Box className="grid grid-flow-row gap-4">
			{!loading ? (
				<>
					<Section
						classList="!p-0"
						children={
							<Box className="grid grid-cols-1 md:grid-cols-3">
								<Box className="bg-[#F9F9F9] flex justify-center rounded-md items-center m-3 p-2 md:h-[350px] h-[240px]">
									<img
										className={`!h-full object-contain`}
										src={
											businessDetails?.logo
												? businessDetails?.logo
												: LogoPlaceholder
										}
										alt="Marketplace Logo"></img>
								</Box>
								<Box className="md:col-span-2 p-4">
									<Stack
										gap={1}
										direction={'column'}>
										<Box className="flex justify-between items-center">
											<Stack
												gap={1}
												className="flex !flex-col md:!flex-row">
												{businessDetails?.rating === 'A' && !isHistory() ? (
													<Box className="flex justify-center items-center px-2 py-1 w-fit border rounded-md">
														<TextWrapper
															type="caption"
															classList="!text-primary !font-[600]"
															text={
																<Stack
																	gap={0.5}
																	direction={'row'}
																	className="flex flex-0 items-center">
																	<CheckCircleOutlineOutlinedIcon className="!text-[16px]"></CheckCircleOutlineOutlinedIcon>
																	<Box>{`${t('guaranteedbyShams')} ${t(
																		'shams'
																	)}`}</Box>
																</Stack>
															}></TextWrapper>
													</Box>
												) : null}
												<Box className="flex justify-center items-center px-2 py-1 w-auto border rounded-md">
													<TextWrapper
														type="caption"
														classList="!text-primary !font-[600]"
														text={
															<Stack
																gap={0.5}
																direction={'row'}
																className="flex flex-0 items-center">
																<Box>{`${t('shariahCompliant')}`}</Box>
															</Stack>
														}></TextWrapper>
												</Box>
											</Stack>
											{getRemainingTime() > 0 ? (
												<Box className="flex items-center !text-sm !text-[#9d9d9d] justify-end">
													<AccessTimeOutlinedIcon className="mx-1 !text-[18px]" />
													<Countdown
														className="marketplace-countdown"
														wordsEndingOff={true}
														text={{
															days: 'd :',
															hours: 'h : ',
															minutes: 'm ',
														}}
														endInSeconds={true}
														end={getRemainingTime() || 0}
													/>
												</Box>
											) : null}
										</Box>
										<Box className="flex flex-col md:flex-row md:justify-between md:items-center">
											<TextWrapper
												classList="!heading"
												text={businessDetails?.businessName}></TextWrapper>
										</Box>
										<Box className="relative cursor-pointer">
											<Box className="flex justify-between items-center mb-4">
												<Stack>
													<TextWrapper
														classList="!font-[500]"
														type="caption"
														text={t('requested')}></TextWrapper>
													<TextWrapper
														classList="!text-primary"
														text={
															<Stack
																className="flex flex-0 items-center !font-bold !text-[18px]"
																direction={'row'}
																gap={0.5}>
																<Box>{CURRENCY}</Box>
																<Box className="flex flex-0 items-center !font-bold">
																	{currencyFormatterHelper(
																		businessDetails?.loanAmount,
																		0
																	)}
																</Box>
															</Stack>
														}></TextWrapper>
												</Stack>
												<Stack>
													<TextWrapper
														classList="!font-[500] !text-end"
														type="caption"
														text={t('funded')}></TextWrapper>
													<TextWrapper
														text={
															<Stack
																className="flex flex-0 items-center !font-bold !text-[14px]"
																direction={'row'}
																gap={0.5}>
																<Box>{CURRENCY}</Box>
																<Box className="flex flex-0 items-center !font-bold">
																	{currencyFormatterHelper(
																		businessDetails?.investedAmount,
																		0
																	)}
																</Box>
															</Stack>
														}></TextWrapper>
												</Stack>
											</Box>
											<Box className="marketplace-business-name mb-3">
												<CustomProgressBar
													value={getPercentage()}></CustomProgressBar>
												<Box className="flex marketplace-funded absolute w-full bottom-0 top-[20px] justify-between items-center !text-[10px]">
													<Stack
														gap={0.5}
														direction={'row'}>
														<Box>{t('remaining')}:</Box>
														<Box className="!font-[600]">{`${CURRENCY} ${currencyFormatterHelper(
															getRemainingAmount()
														)}`}</Box>
													</Stack>
												</Box>
											</Box>
										</Box>
										<Box className="grid grid-cols-1 md:grid-cols-2 gap-3   rounded-md">
											<Box className="grid grid-flow-row gap-3 bg-[#F9F9F9] p-3 rounded-md">
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={
																<span>
																	<span>{t('roi')}</span>
																	<CustomTooltip
																		id="netYieldInfoId2"
																		text="netYieldInfo"></CustomTooltip>
																</span>
															}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.fixed}%`}></TextWrapper>
													</Box>
												</Box>
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={
																<span>
																	<span>{t('irr')}</span>
																	<CustomTooltip
																		id="irrInfoId2"
																		text="irrInfo"></CustomTooltip>
																</span>
															}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.investorIRR || '25.9'
																}%`}></TextWrapper>
													</Box>
												</Box>
											</Box>
											<Box className="grid grid-flow-row gap-3 bg-[#F9F9F9] p-3 rounded-md">
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={t('tenure')}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.month} ${t(
																'monthsLowerCase'
															)}`}></TextWrapper>
													</Box>
												</Box>
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={t('rating')}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.rating}`}></TextWrapper>
													</Box>
												</Box>
											</Box>
										</Box>
										<Box className="mt-2">
											<span className="text-[14px]">
												{t('myOrdersIn')}&nbsp;
												<strong>{businessDetails?.businessName}</strong>&nbsp;
												{t('are')}&nbsp;
												<strong>{`${CURRENCY} ${currencyFormatterHelper(
													businessDetails?.personalPartsAmount
												)}`}</strong>
											</span>
										</Box>

										{isEnableInvestButton() ? (
											<Box className="flex justify-center mt-3">
												<ButtonWrapper
													loading={loadingAvailableCash}
													onClick={() => {
														onInvest();
													}}
													classList="md:!min-w-[120px]"
													label={t('invest')}></ButtonWrapper>
											</Box>
										) : null}
									</Stack>
								</Box>
							</Box>
						}></Section>
					<Section
						children={
							<Box className="grid grid-cols-1 md:grid-cols-3 gap-3">
								{subItems('industry', `${businessDetails?.industry || '---'}`)}
								{subItems(
									'yearsTrading',
									`${businessDetails?.yearsTrading || '---'}`
								)}
								{subItems(
									'country',
									`${businessDetails?.region || '---'}`,
									true
								)}
							</Box>
						}></Section>
					<Section
						hide={!businessDetails?.description}
						children={
							<Box>
								<p
									dangerouslySetInnerHTML={{
										__html: `${businessDetails?.description}`,
									}}
									className="!sub-title-text"></p>
							</Box>
						}></Section>

					{businessDetails?.borrowerId ? (
						<BusinessFinancialRatios
							loanId={businessDetails?.borrowerId}></BusinessFinancialRatios>
					) : null}
					<Box>
						{businessDetails?.rating === 'A' ? (
							<Box className="caption !text-[12px] mb-2">
								{t('shamsMoreInfo')}
							</Box>
						) : null}

						{!IS_SA ? (
							<Box className="caption !text-[12px] mb-2">
								{t('businessListingDisclosure')}
							</Box>
						) : null}

						<Box className="caption !text-[12px]">
							{t('allDataIsProvidedByBusiness1')}&nbsp;
							<a
								className="underline-link !text-[12px]"
								target="_blank"
								rel="noreferrer"
								href={`${WEBSITE_URL}en/credit-assessment`}>
								{t('here')}
							</a>
							.&nbsp;{t('allDataIsProvidedByBusiness2')}
							<Box className="h-2"></Box>
							{t('allDataIsProvidedByBusiness')} {t('assignedRisk')}{' '}
							{t('approvedDFSA')}
							<Box className="h-2"></Box>
							{t('pleaseNoteYearToDate')}
						</Box>
						<Box className="h-2"></Box>
					</Box>
					<InvestBehalfDialog
						onInvestment={(amount) => {
							onInvestment(amount);
						}}
						remainingAmount={getRemainingAmount()}
						minInvestmentAmount={calculateMinAmount()}
						state={openInvest}
						onClose={(e) => {
							setOpenInvest(false);
						}}></InvestBehalfDialog>
					{isOpenOTPDialog ? (
						<OTPAuthenticationDialog
							onClose={(e) => {
								onCloseOTPAuth(e);
							}}
							type={OTPTypes.Transaction}
							corrolationId={investDetails?.correlationId}
							state={isOpenOTPDialog}></OTPAuthenticationDialog>
					) : null}
					{isOpenInvestSuccess ? (
						<InvestSuccessDialog
							amount={investedAmount}
							state={isOpenInvestSuccess}
							onClose={(e) => {
								onSuccessInvestClose(e);
							}}></InvestSuccessDialog>
					) : null}
					{isOpenInvestError ? (
						<InvestErrorDialog
							amount={investedAmount}
							errorMessage={investErrorMessage}
							state={isOpenInvestError}
							onClose={() => {
								setIsOpenInvestError(false);
							}}></InvestErrorDialog>
					) : null}
				</>
			) : (
				<MarketplaceDetailLoader></MarketplaceDetailLoader>
			)}
		</Box>
	);
};
