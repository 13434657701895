import { Box, Button } from '@mui/material';
import { TextWrapper } from '../../formUtilities/useTextWrapper';
import { LanguageService } from '../../../../services/languageService';
import { ButtonWrapper } from '../../formUtilities/useButton';
import { InvestorGuidelineService } from '../../../../services/guidelineService';
import { InvestorGuidelineSteps } from '../constants/guidlineSteps';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { useTranslation } from 'react-i18next';

export const InvestorGuidelinePopover = ({
	state,
	top,
	left,
	right,
	bottom,
	message = '',
	redirect = '',
	arrowPosition = 'up',
	classList = '',
	onNext = (count) => {},
}) => {
	const guideSteps = InvestorGuidelineSteps;
	const languageService = new LanguageService();
	const guideService = new InvestorGuidelineService();
	const [redirectTo, setRedirectTo] = useState(redirect);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const stepCount = () => {
		return guideService.stepCount();
	};

	const updateCount = (count) => {
		return guideService.updateSetpCount(count);
	};
	const cancel = () => {
		guideService.removeThanksPopupValue();
		try {
			setLoading(true);
			updateCount(guideSteps.AddFund)
				.then((count) => {
					setLoading(false);
					guideService.setStepCountStorage(count.data + 1);
					guideService.guideStepCount$.next(count.data + 1);
					onNext(count.data + 1);
				})
				.catch((err) => {
					setLoading(false);
				});
		} catch {
		} finally {
		}
	};

	const onOk = () => {
		let count = stepCount();
		if (!guideService.getIsMobile()) {
			if (count <= guideSteps.AddFund) {
				setCount(count);
			} else {
				cancel();
			}
		} else {
			if (count === guideSteps.PauseInvest) {
				//skip deposit step in mobile
				setRedirectTo('investor/transfers/deposit');
				setCount(guideSteps.PauseInvest + 1);
			} else {
				setCount(count);
			}
		}
	};

	const setCount = (count) => {
		if (count === guideSteps.AddFund) {
			guideService.setShowThanksPopup(1);
		}
		try {
			setLoading(true);
			updateCount(count)
				.then((resp) => {
					guideService.setStepCountStorage(resp.data + 1);
					guideService.guideStepCount$.next(resp.data + 1);
					setLoading(false);
					onNext(resp.data + 1);
					if (redirectTo) {
						navigate(buildLocalizeRoute(redirectTo));
					}
				})
				.catch((err) => {
					setLoading(false);
				});
		} catch {
		} finally {
		}
	};

	return (
		<>
			{state ? (
				<>
					<Box
						className={`absolute shadow-md md:w-[300px] md:m-0 m-2 rounded-lg z-[999999]  ${classList} ${
							arrowPosition + '-arrow'
						}`}
						sx={{ top: top, left: left, right: right, bottom: bottom }}>
						<Box className="h-10 primary-bg  p-4 rounded-tr-lg rounded-tl-lg flex justify-start items-center">
							<Button
								onClick={() => {
									languageService.changeLanguage(
										languageService.toggleLanguage()
									);
								}}>
								<TextWrapper
									type="caption"
									classList="!text-white"
									text={
										languageService.isArabic() ? 'English' : 'العربية'
									}></TextWrapper>
							</Button>
						</Box>
						<Box className="p-4 !bg-white rounded-bl-lg rounded-br-lg flex flex-col items-center">
							<TextWrapper
								classList="!text-[14px] !text-black !text-center"
								text={t(message)}></TextWrapper>
							<ButtonWrapper
								loading={loading}
								onClick={() => {
									onOk();
								}}
								classList="!mt-4"
								label={
									stepCount() < guideSteps.AddFund ? t('next') : t('ok')
								}></ButtonWrapper>
						</Box>
					</Box>
				</>
			) : null}
		</>
	);
};
