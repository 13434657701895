import { FormikProvider, getIn, useFormik } from 'formik';
import { FormHelper } from '../../../../../helpers/formHelper';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { BorrowerRegService } from '../../borrowerRegService';
import { useNavigate } from 'react-router-dom';
import { IS_PROD, IS_SA } from '../../../../../config/appConfig';
import { RegistrationHelper } from '../../../../../helpers/registrationHelper';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
	getRegistrationData,
	saveTokenFromRegistration,
	setRegistrationData,
	updateBorrowerSteps,
} from '../../../../../services/authService';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import CRSDoc from '../../../../../assets/docs/CRS_Self_Certification_Entities_260521_for_borrowers.pdf';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { ArrowIcons } from '../../../../../shared/components/common/arrowIcons';
import { DialogWrapper } from '../../../../../shared/components/common/dialogWrapper';
import { SAMLAuthDialog } from '../../../components/samlAuthDialog';
import { BorrowerDeclarationSchema } from '../../../../../shared/validations/schema';

export const BorrowerDeclarationDailog = ({ state, onClose = (e) => {} }) => {
	const systemSettingsService = new SystemSettingsService();
	const borrowerRegService = new BorrowerRegService();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openSAMLAuthPopup, setOpenSAMLAuthPopup] = useState(false);
	const [registrationModel, setRegistrationModel] = useState(null);

	const form = useFormik({
		initialValues: {
			termsConditionsForBusinessAccepted: false,
			informationProvidedAccurateAccepted: false,
			inquireMyselfAnDBusinessAtCreditBureaus: !IS_SA ? true : false,
			classifiedAsServiceBasedProfessionalClient: IS_SA ? true : false,
		},
		validationSchema: BorrowerDeclarationSchema,
		onSubmit: (values) => {
			submitForm(values);
		},
		...FormHelper.formikProps(),
	});

	const selectAll = getIn(form.values, 'selectAll');
	const termsConditionsForBusinessAccepted = getIn(
		form.values,
		'termsConditionsForBusinessAccepted'
	);

	const { t } = useTranslation();

	const systemSettings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		if (IS_SA) {
			form.setFieldValue('classifiedAsServiceBasedProfessionalClient', true);
		} else {
			form.setFieldValue('inquireMyselfAnDBusinessAtCreditBureaus', true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [termsConditionsForBusinessAccepted]);

	useEffect(() => {
		checkAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll]);

	const checkAll = () => {
		Object.keys(form.initialValues).forEach((key) => {
			if (key !== 'selectAll') {
				form.setFieldValue(key, form.values.selectAll, true);
			}
		});
	};

	const checkAllData = () => {
		let allChecked = true;
		Object.keys(form.initialValues).forEach((key) => {
			if (key !== 'selectAll') {
				if (!form.values[key]) {
					allChecked = false;
				}
			}
		});
		return allChecked;
	};

	const onBack = (closeIwillDoLaterPopup = false) => {
		onClose(closeIwillDoLaterPopup);
	};

	const submitForm = (formValue) => {
		submit(formValue);
	};

	const submit = (formValue) => {
		let form = formValue;
		if (IS_SA) {
			delete form.classifiedAsServiceBasedProfessionalClient;
		} else {
			delete form.inquireMyselfAnDBusinessAtCreditBureaus;
		}
		setLoading(true);
		let declarationData = [];
		Object.keys(form).forEach((key) => {
			if (form[key] && key !== 'selectAll') {
				declarationData.push(key);
			}
		});

		borrowerRegService
			.registerBorrowerDeclaration(declarationData)
			.then((resp) => {
				setLoading(false);
				let registrationModel = resp.data?.registrationModel;
				saveTokenFromRegistration(resp.data?.loginModel?.token);
				setRegistrationModel(resp.data);
				if (
					IS_PROD &&
					RegistrationHelper.ifSaudiArabia(
						getRegistrationData()?.nationality,
						getRegistrationData()?.addressCountry
					)
				) {
					setOpenSAMLAuthPopup(true);
				} else {
					let regData = {
						...getRegistrationData(),
						step: registrationModel.step,
					};
					setRegistrationData(regData);
					updateBorrowerSteps(registrationModel.step);
					onBack(true);
					setTimeout(() => {
						navigate(
							buildLocalizeRoute(`${AppRoutes.Borrower}/${AppRoutes.ThankYou}`)
						);
					});
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onSAMLClose = (value) => {
		setOpenSAMLAuthPopup(false);
		let registrationData = registrationModel?.registrationModel;
		let regData = {
			...getRegistrationData(),
			step: registrationData.step,
		};
		setRegistrationData(regData);
		updateBorrowerSteps(registrationData?.step);
		onBack(true);
	};

	const getAllChecked = (form) => {
		let formValue = form.values;
		let checkedAll = Object.keys(formValue).every((key) => formValue[key]);
		return checkedAll;
	};

	const getSomeChecked = (form) => {
		console.log(form.values);
		if (Object.keys(form.values).every((key) => form.values[key])) {
			return false;
		}
		if (Object.keys(form.values).some((key) => form.values[key])) {
			return true;
		}
	};

	const getForm = () => {
		return (
			<FormikProvider value={form}>
				<Box className="border-b border-[#ccc] p-4 pb-2 flex items-center ">
					<FormControlLabel
						label={<span>{t('pleaseConfirmFollowingTickingBoxes')} </span>}
						control={
							<Checkbox
								className="!px-[3px] !pb-[3px] !pt-0"
								onChange={(e) => {
									form.setFieldValue('selectAll', e.target.checked);
								}}
								name={'selectAll'}
								indeterminate={getSomeChecked(form)}
								checked={form.values.selectAll}
								value={getAllChecked(form)}
							/>
						}
					/>
				</Box>
				<Box className="flex flex-col justify-center items-start space-y-2 p-4">
					{getCheckBox(
						form,
						'informationProvidedAccurateAccepted',
						'confirmAllInformationProvidedBestOfMyKnowledge'
					)}
					{!IS_SA ? (
						<>
							{getCheckBox(
								form,
								'classifiedAsServiceBasedProfessionalClient',
								'classifiedAsServiceBasedProfessionalClient'
							)}
						</>
					) : (
						<>
							{getCheckBox(
								form,
								'inquireMyselfAnDBusinessAtCreditBureaus',
								'consentFundingSouq'
							)}
						</>
					)}

					<FormControlLabel
						label={
							<span>
								{t('iAgreeToFundingSouq')}{' '}
								<a
									className="underline-link"
									href={systemSettings()?.borrowerTermsAndConditions}
									target="_blank"
									rel="noreferrer">
									{t('termsAndConditionsForBusiness')}
								</a>
								,
								<a
									className="underline-link mx-1"
									href={systemSettings()?.privacyPolicy}
									target="_blank"
									rel="noreferrer">
									{t('privacyPolicy')}
								</a>
								{t('and')}
								<a
									className="underline-link mx-1"
									href={CRSDoc}
									target="_blank"
									rel="noreferrer">
									{t('crsSelfDeclarationForm')}
								</a>
							</span>
						}
						control={
							<Checkbox
								className="!px-[3px] !pb-[3px] !pt-0"
								onChange={(e) => {
									form.setFieldValue(
										'termsConditionsForBusinessAccepted',
										e.target.checked
									);
								}}
								name={'termsConditionsForBusinessAccepted'}
								checked={form.values.termsConditionsForBusinessAccepted}
								value={form.values.termsConditionsForBusinessAccepted}
							/>
						}
					/>
					<Box className="flex justify-center w-full">
						<ButtonWrapper
							disabled={loading}
							icon={<ArrowIcons isInvert></ArrowIcons>}
							classList="!secondary-btn !mx-2"
							label={t('back')}
							onClick={() => {
								onBack();
							}}></ButtonWrapper>
						<ButtonWrapper
							loading={loading}
							disabled={!checkAllData()}
							icon={<SaveOutlinedIcon></SaveOutlinedIcon>}
							label={t('submit')}
							onClick={() => {
								FormHelper.validateForm(form);
							}}></ButtonWrapper>
					</Box>
				</Box>
			</FormikProvider>
		);
	};

	const getCheckBox = (form, formControl, label) => {
		return (
			<FormControlLabel
				label={<span>{t(label)} </span>}
				control={
					<Checkbox
						className="!px-[3px] !pb-[3px] !pt-0"
						onChange={(e) => {
							form.setFieldValue(formControl, e.target.checked);
						}}
						name={formControl}
						checked={form.values[formControl]}
						value={form.values[formControl]}
					/>
				}
			/>
		);
	};

	return (
		<>
			<DialogWrapper
				testId='borrowerDelcaration'
				maxWidth="md"
				state={state}
				onClose={() => {
					onClose(false);
				}}
				title={t('declaration')}
				actionFooterClass="!px-0"
				content={
					<Box className="flex flex-col border">
						<Box>{getForm()}</Box>
					</Box>
				}
				actions={
					<Box className="flex flex-col space-y-6"></Box>
				}></DialogWrapper>

			{openSAMLAuthPopup ? (
				<SAMLAuthDialog
					state={openSAMLAuthPopup}
					onClose={(e) => {
						onSAMLClose(e);
					}}></SAMLAuthDialog>
			) : null}
		</>
	);
};
