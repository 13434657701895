import * as yup from 'yup';
import { IS_SA } from '../../config/appConfig';

export const RegistrationSchema = yup.object({
	email: yup
		.string()
		.matches(
			'[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}',
			'invalidEmail'
		)
		.required('required'),
	password: yup
		.string()
		.required('min8CharLong1Capital1Number')
		.min(8, 'min8CharLong1Capital1Number')
		.matches(/[A-Z]+/, 'min8CharLong1Capital1Number')
		.matches(/\d+/, 'min8CharLong1Capital1Number'),
	contactNumber: yup
		.object({
			code: yup.string().nullable(),
			number: yup.string().required('required').nullable(),
		})
		.nullable(),
	termsConditionsForInvestmentsAccepted: yup.boolean().required('required'),
	nationalId: yup.string().when([], {
		is: () => IS_SA,
		then: yup
			.string()
			.required('required')
			.matches('^(0|[1-9][0-9]*)$', { message: 'userWithNationalIdExists' }),
		otherwise: yup.string().notRequired(),
	}),
	// passwordConfirmation: yup
	// 	.string()
	// 	.required('required')
	// 	.oneOf([yup.ref('password')], 'passwordDoNotMatch'),
});

export const investorPersonalInfoRegSchema = yup.object({
	firstName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	lastName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	birthDate: yup
		.string()
		.required('required')
		.test('birthDate', 'enterValidDOB', function (value, ctx) {
			const dob = new Date(value);
			const validDate = new Date();
			const valid = dob.getTime() <= validDate.getTime();
			return !valid ? ctx.createError() : valid;
		})
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	placeOfBirth: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	nationalityCountryId: yup.number().required('required').nullable(),
	address: yup
		.object({
			addressLine: yup
				.string()
				.required('required')
				.nullable()
				.trim()
				.transform((v, o) => (o === '' ? null : v)),
			countryId: yup.number().required('required').nullable(),
		})
		.nullable(),
	publicFunction: yup
		.object({
			valueId: yup.number().required('required').nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 6,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
});

export const TaxResidenciesSchema = yup
	.object({
		countryId: yup.mixed().required('required').nullable(),
		taxIdElsewhere: yup
			.string()
			.when('countryId', {
				is: (val) => val && typeof val === 'number',
				then: () => yup.string().required('required').nullable(),
				otherwise: () => yup.string().nullable(),
			})
			.nullable(),
	})
	.nullable();

export const InvestorRegPersonalInfoExtraSchema = yup.object({
	employmentStatusId: yup.number().required('required').nullable(),
	companyName: yup
		.string()
		.when('employmentStatusId', {
			is: (val) => val === 1 || val === 4,
			then: () =>
				yup
					.string()
					.required('required')
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
			otherwise: () =>
				yup
					.string()
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
		})
		.nullable(),
	jobTitle: yup
		.string()
		.when('employmentStatusId', {
			is: (val) => val === 1 || val === 4,
			then: () =>
				yup
					.string()
					.required('required')
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
			otherwise: () =>
				yup
					.string()
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
		})
		.nullable(),
	// bankName: yup
	// 	.string()
	// 	.required('required')
	// 	.nullable()
	// 	.trim()
	// 	.transform((v, o) => (o === '' ? null : v)),
	// bankCountryId: yup.number().required('required').nullable(),
	annualIncomeId: yup.number().required('required').nullable(),
	estimatedAnnualInvestmentId: yup.number().required('required').nullable(),
	netWorthId: yup.number().required('required').nullable(),
	// taxResidency: yup.object().when([], {
	// 	is: () => IS_SA === false,
	// 	then: TaxResidenciesSchema,
	// }),
	incomeSource: yup
		.object({
			valueId: yup.number().required('required').nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 8,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
});

export const InvestorBusinessKSASchema = yup.object({
	tradeLicenseNumber: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
});

export const BorrowerEligibilitySchema = yup.object({
	countryOfRegistrationId: yup.number().required('required'),
	yearsOfOperationId: yup.number().required('required'),
	howMuchAmountNeededId: yup.number().required('required'),
	howLongFinancingId: yup.number().required('required'),
	revenue: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	financialObligations: yup.boolean().required('required'),
	revenuesFromAlcoholGamblingTabacco: yup.boolean().required('required'),
});

export const BorrowerPersonalInfoSchema = yup.object({
	firstName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	lastName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	nationalityOfOwnerId: yup.number().required('required'),
	propertyOwnershipId: yup.number().required('required'),
	maritalStatusId: yup.number().required('required'),
	numberOfChildrenId: yup.number().required('required'),
	residentialAddress: yup
		.object({
			addressLine: yup
				.string()
				.required('required')
				.nullable()
				.trim()
				.transform((v, o) => (o === '' ? null : v)),
			countryId: yup.number().required('required').nullable(),
		})
		.nullable(),
	publicFunction: yup
		.object({
			valueId: yup.number().required('required').nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 6,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
	howDidHearAboutUs: yup
		.object({
			valueId: yup.number().notRequired().nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 8,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
});

export const BorrowerRegBusinessSchema = yup.object({
	businessName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	tradeLicenseNumber: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	industryId: yup.number().required('required').nullable(),
	companyTypeId: yup.number().required('required').nullable(),
	businessAddress: yup
		.object({
			addressLine: yup
				.string()
				.required('required')
				.nullable()
				.trim()
				.transform((v, o) => (o === '' ? null : v)),
			city: yup.mixed().required('required').nullable(),
		})
		.nullable(),
	needFundsFor: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	registrationPlaceId: yup.number().when([], {
		is: () => !IS_SA,
		then: yup.number().required('required'),
		otherwise: yup.number().notRequired(),
	}),
});

export const BorrowerFinancialSchema = yup.object({
	revenue: yup
		.number()
		.required('required')
		.integer()
		.min(0, 'minShouldBeZero')
		.max(100000000, 'maxShouldBe10000000')
		.nullable(),
	debtInLastYear: yup
		.number()
		.required('required')
		.integer()
		.min(0, 'minShouldBeZero')
		.max(100000000, 'maxShouldBe10000000')
		.nullable(),
	netProfit: yup
		.number()
		.required('required')
		.integer()
		.min(0, 'minShouldBeZero')
		.max(100000000, 'maxShouldBe10000000')
		.nullable(),
	totalOutstandingDebt: yup
		.number()
		.required('required')
		.integer()
		.min(0, 'minShouldBeZero')
		.max(100000000, 'maxShouldBe10000000')
		.nullable(),
	numberOfBanksId: yup.number().required('required').nullable(),
	businessHasOverdraft: yup.boolean().required('required').nullable(),
	businessHaveYearEndFinancials: yup.boolean().required('required').nullable(),
});

export const InvestorDeclarationSchema = yup.object({
	capitalAtRiskAccepted: yup.boolean().oneOf([true], 'required'),
	investableAssetsAccepted: yup.boolean().oneOf([true], 'required'),
	informationProvidedAccurateAccepted: yup.boolean().oneOf([true], 'required'),
	termsConditionsForInvestmentsAccepted: yup
		.boolean()
		.oneOf([true], 'required'),
	notUsResident: yup.boolean().oneOf([true], 'required'),
	longTermResidentialAddressСonfirmed: yup.boolean().oneOf([true], 'required'),
});

export const BorrowerDeclarationSchema = yup.object({
	termsConditionsForBusinessAccepted: yup.boolean().oneOf([true], 'required'),
	informationProvidedAccurateAccepted: yup.boolean().oneOf([true], 'required'),
	inquireMyselfAnDBusinessAtCreditBureaus: yup.boolean().when([], {
		is: () => IS_SA,
		then: yup.boolean().oneOf([true], 'required'),
		otherwise: yup.boolean().notRequired(),
	}),
	classifiedAsServiceBasedProfessionalClient: yup.boolean().when([], {
		is: () => !IS_SA,
		then: yup.boolean().oneOf([true], 'required'),
		otherwise: yup.boolean().notRequired(),
	}),
});

export const InvestorDeclarationCorporateSchema = yup.object({
	corporateDulyAuthorisedConfirmed: yup.boolean().oneOf([true], 'required'),
	corporateMayLoseMoneyConfirmed: yup.boolean().oneOf([true], 'required'),
	corporateReadRiskDisclosureConfirmed: yup.boolean().oneOf([true], 'required'),
	corporateProvidedInformationIsAccurateConfirmed: yup
		.boolean()
		.oneOf([true], 'required'),
	corporateNotUsResidentConfirmed: yup.boolean().oneOf([true], 'required'),
	corporateDocumentsAreMineConfirmed: yup.boolean().oneOf([true], 'required'),
	corporateTermsConditionsConfirmed: yup.boolean().oneOf([true], 'required'),
});

export const InvestorRegistrationBusinesSchema = yup.object({
	businessName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	tradeLicenseNumber: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	registrationCountryId: yup.number().required('required').nullable(),
	// taxResidency: yup.object().when([], {
	// 	is: () => IS_SA === false,
	// 	then: TaxResidenciesSchema,
	// }),
});

export const BorrowerChangePasswordSchema = yup.object({
	newPassword: yup
		.string()
		.matches(
			'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-zd$@$!%*?&].{7,}',
			'min8CharLong1Capital1Number'
		)
		.required('required')
		.nullable(),
	oldPassword: yup
		.string()
		.matches(
			'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-zd$@$!%*?&].{7,}',
			'min8CharLong1Capital1Number'
		)
		.required('required')
		.nullable(),
	confirmPassword: yup
		.string()
		.required('required')
		.oneOf([yup.ref('newPassword'), null], 'Passwords must match')
		.nullable(),
});

export const BorrowerDepositSchema = yup.object({
	amount: yup.number().required('required').min(1, 'Min value is 1').nullable(),
});

export const transferSchema = yup.object({
	amount: yup.number().required('required').min(1, 'Min value is 1').nullable(),
});

export const OTPAuthenticationSchema = yup.object({
	contactNumber: yup
		.object({
			code: yup.string().nullable(),
			number: yup.string().required('required').nullable(),
		})
		.nullable(),
});

export const BorrowerPersonalSchema = yup.object({
	firstName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	lastName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	nationalityId: yup.number().required('required').nullable(),
	maritalStatusId: yup.number().required('required').nullable(),
	propertyOwnershipId: yup.number().required('required').nullable(),
	howDidHearAboutUs: yup
		.object({
			valueId: yup.number().notRequired().nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 8,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
	publicFunction: yup
		.object({
			valueId: yup.number().required('required').nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 6,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
});

export const BorrowerBusinessSchema = yup.object({
	businessName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	tradeLicenseNumber: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	industryId: yup.number().required('required').nullable(),
	companyTypeId: yup.number().required('required').nullable(),
	address: yup
		.object({
			addressLine: yup
				.string()
				.required('required')
				.nullable()
				.trim()
				.transform((v, o) => (o === '' ? null : v)),
			city: yup.mixed().required('required').nullable(),
		})
		.nullable(),
	needFundsFor: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	registrationPlaceId: yup.number().when([], {
		is: () => !IS_SA,
		then: yup.number().required('required'),
		otherwise: yup.number().notRequired(),
	}),
});

export const InvestorWithdrawSchema = yup.object({
	withdrawAmount: yup
		.number()
		.required('required')
		.min(1, 'Min value is 1')
		.nullable(),
});

export const InvestorDepositSchema = yup.object({
	depositAmount: yup
		.number()
		.required('required')
		.min(1, 'Min value is 1')
		.nullable(),
});

export const InvestAmountSchema = yup.object().shape({
	// name: yup.string().required('required').nullable(),
	amount: yup
		.number()
		.required('required')
		.min(10, 'Amount should be greater than 10')
		.test('', 'enteredMustMultipleOf10', (value) => {
			if (value % 10 > 0) {
				return false;
			}
			return true;
		})
		.nullable(),
});

export const InvestorProfileUpdateSchema = yup.object({
	firstName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),
	contactNumber: yup
		.object({
			code: yup.string().nullable(),
			number: yup.string().required('required').nullable(),
		})
		.nullable(),
	lastName: yup
		.string()
		.required('required')
		.nullable()
		.trim()
		.transform((v, o) => (o === '' ? null : v)),

	nationalityId: yup.number().required('required').nullable(),
	employmentStatusId: yup.number().required('required').nullable(),
	companyName: yup
		.string()
		.when('employmentStatusId', {
			is: (val) => val === 1 || val === 4,
			then: () =>
				yup
					.string()
					.required('required')
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
			otherwise: () =>
				yup
					.string()
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
		})
		.nullable(),
	jobTitle: yup
		.string()
		.when('employmentStatusId', {
			is: (val) => val === 1 || val === 4,
			then: () =>
				yup
					.string()
					.required('required')
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
			otherwise: () =>
				yup
					.string()
					.nullable()
					.trim()
					.transform((v, o) => (o === '' ? null : v)),
		})
		.nullable(),
	annualIncomeId: yup.number().required('required').nullable(),
	// taxResidency: yup.object().when([], {
	// 	is: () => IS_SA === false,
	// 	then: TaxResidenciesSchema,
	// }),
	address: yup
		.object({
			addressLine: yup
				.string()
				.required('This is a mandatory field')
				.nullable()
				.trim()
				.transform((v, o) => (o === '' ? null : v)),
			countryId: yup.number().required('This is a mandatory field').nullable(),
		})
		.nullable(),
	incomeSource: yup
		.object({
			valueId: yup.number().required('required').nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 8,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
	publicFunction: yup
		.object({
			valueId: yup.number().required('required').nullable(),
			valueSpecified: yup
				.string()
				.when('valueId', {
					is: 6,
					then: yup
						.string()
						.required('required')
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
					otherwise: yup
						.string()
						.nullable()
						.trim()
						.transform((v, o) => (o === '' ? null : v)),
				})
				.nullable(),
		})
		.nullable(),
});

export const InvestorCorporateSchema = yup.object({
	businessName: yup.string().when([], {
		is: () => IS_SA === false,
		then: () => yup.string().required('This is a mandatory field').nullable(),
		otherwise: () => yup.string().notRequired(),
	}),
	tradeLicenseNumber: yup
		.mixed()
		.required('This is a mandatory field')
		.nullable(),
	// corporateTaxResidency: yup.object().when([], {
	// 	is: () => IS_SA === false,
	// 	then: TaxResidenciesSchema,
	// }),
});

export const ResetPasswordSchema = yup.object({
	password: yup
		.string()
		.required('min8CharLong1Capital1Number')
		.min(8, 'min8CharLong1Capital1Number')
		.matches(/[A-Z]+/, 'min8CharLong1Capital1Number')
		.matches(/\d+/, 'min8CharLong1Capital1Number'),
	passwordConfirmation: yup
		.string()
		.required('required')
		.oneOf([yup.ref('password'), null], 'Passwords must match')
		.nullable(),
});

export const FeedbackSuggestionSchema = yup.object({
	whatAreMissing: yup.string().required('required').nullable(),
	whatProblemWouldThatSolve: yup.string().required('required').nullable(),
});

export const FeedbackBugReportSchema = yup.object({
	moreAboutTheIssue: yup.string().required('required').nullable(),
});

export const CommercialRegistrationSchema = yup.object({
	tradeLicenseNumber: yup.string().required('required').nullable(),
});

export const FinancialStatementSchema = yup.object({
	assets: yup.object({
		cashOnHandAtBank: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		accountReceivables: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		inventory: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		other: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		ppAndE: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		totalAssets: yup.array(
			yup.object({
				value: yup.number().min(1).required('required').nullable(),
			})
		),
	}),
	liability: yup.object({
		accountPayable: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		borrowings: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		other: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		totalLiabilities: yup.array(
			yup.object({
				value: yup.number().min(1).required('required').nullable(),
			})
		),
		paidInCapital: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		retainedEarnings: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		currentYearNetProfit: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		ownersEquityAccount: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		totalOwnersEquity: yup.array(
			yup.object({
				value: yup.number().min(1).required('required').nullable(),
			})
		),
		totalLiabilityAndEquity: yup.array(
			yup.object({
				value: yup.number().min(1).required('required').nullable(),
			})
		),
	}),
	incomeStatements: yup.object({
		totalRevenue: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		totalExpenses: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
		netProfit: yup.array(
			yup.object({
				value: yup.mixed().required('required').nullable(),
			})
		),
	}),
});
