import { LocalStorageKey } from '../constants/localStorageKeys';
import { UtilitiesService } from '../services/utilitiesService';

export const signOut = () => {
	const utilitiesService = new UtilitiesService();
	utilitiesService.removeLocalStorage({ item: LocalStorageKey.users });
	utilitiesService.removeLocalStorage({ item: LocalStorageKey.token });
	utilitiesService.removeLocalStorage({ item: LocalStorageKey.systemSettings });
	utilitiesService.removeLocalStorage({
		item: LocalStorageKey.loadAllCollection,
	});
	utilitiesService.removeLocalStorage({
		item: LocalStorageKey.investorsCollections,
	});
	utilitiesService.removeLocalStorage({
		item: LocalStorageKey.borrowersCollections,
	});
	utilitiesService.removeLocalStorage({
		item: LocalStorageKey.notificationType,
	});
	utilitiesService.removeLocalStorage({
		item: LocalStorageKey.registrationInfo,
	});
	utilitiesService.removeLocalStorage({
		item: LocalStorageKey.registrationData,
	});
	utilitiesService.removeLocalStorage({ item: 'guideStepCount' });
	utilitiesService.removeLocalStorage({ item: LocalStorageKey.nafathVerified });
	utilitiesService.removeLocalStorage({ item: 'sessionExpired' });
};
