/* eslint-disable no-unused-vars */
import { Box, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { Section } from '../../../../../shared/components/common/section';
import { MarketplaceService } from '../marketplaceService';
import { InvestorService } from '../../../investorService';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import LogoPlaceholder from '../../../../../assets/images/logo-placeholder.jpg';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { CURRENCY, MIN_INVESTMENT } from '../../../../../config/appConfig';
import { msToTime } from '../../../../../helpers/countdownHelper';
import { MarketplaceStatuses } from '../../../../../constants/marketplaceStatuses';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import DataTableGrid from '../../../../../shared/components/formUtilities/useTable';
import { initializeCollectionLoansColumns } from './collectionColumns';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { InvestBehalfDialog } from '../businessDetails/marketplaceInvestDialog/investBehalfDialog';
import Countdown from 'on-react-countdown';
import { useTranslation } from 'react-i18next';
import { LanguageService } from '../../../../../services/languageService';
import { MarketplaceDetailLoader } from '../../../../../shared/components/investors/loaders/marketplaceDetailLoader';
import { InvestSuccessDialog } from '../businessDetails/investSuccessDialog';
import { InvestErrorDialog } from '../businessDetails/investErrorDialog';
import { checkImageRatio } from '../../../../../helpers/imageRatioHelper';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';
import { CustomProgressBar } from '../../../../../shared/components/formUtilities/useProgressBar';
import { CustomTooltip } from '../../../../../shared/components/common/tooltip';

export const MarketplaceCollectionDetails = () => {
	const marketplaceService = new MarketplaceService();
	const investorService = new InvestorService();
	const languageService = new LanguageService();
	const systemSettingsService = new SystemSettingsService();
	const [availableCash, setAvailableCash] = useState(0);
	const [businessDetails, setBusinessDetails] = useState({});
	const [openInvest, setOpenInvest] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [status, setStatus] = useState(searchParams.get('status'));
	const [loading, setLoading] = useState(true);
	const [isOpenInvestSuccess, setIsOpenInvestSuccess] = useState(false);
	const [investErrorMessage, setInvestErrorMessage] = useState(false);
	const [investedAmount, setInvestedAmount] = useState(0);
	const [isOpenInvestError, setIsOpenInvestError] = useState(false);
	const [isImageRatioFit, setisImageRatioFit] = useState(false);
	const [loadingAvailableCash, setLoaderAvailableCash] = useState(false);
	const params = useParams();
	const [bucketId, setBucketId] = useState(params?.id);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const columns = initializeCollectionLoansColumns(
		searchParams.get('status'),
		t
	);

	useEffect(() => {
		setBucketId(params?.id);
		setStatus(searchParams.get('status'));
		getMarketplaceCollectionDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.id]);

	useEffect(() => {
		checkImageRatio(businessDetails?.logo).then((resp) => {
			setisImageRatioFit(resp);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessDetails]);


	const getRemainingTime = () => {
		return businessDetails.timeRemaining;
	};

	const isHistory = () => {
		return MarketplaceStatuses.Past === status;
	};

	const calculateMinAmount = () => {
		const availableLoans =
			businessDetails?.loans?.filter(
				(l) => l.loanAmount - l.investedAmount > 0
			) || [];
		return availableLoans.length * MIN_INVESTMENT;
	};

	const getPercentage = () => {
		if (!isHistory()) {
			let investPercentage =
				(businessDetails?.investedAmount / businessDetails?.loanAmount) * 100;
			let formattedValue = parseFloat(
				(Math.floor(investPercentage * 100) / 100).toString().substring(0, 4)
			);
			return formattedValue;
		}
		return 100;
	};

	const getMarketplaceCollectionDetails = () => {
		setLoading(true);
		marketplaceService
			.getMarketplaceCollectionDetails(bucketId)
			.then((resp) => {
				setBusinessDetails(resp.data);
				investorSummaryShort();
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const investorSummaryShort = () => {
		setLoaderAvailableCash(true);
		investorService
			.getInvestorSummaryShort()
			.then((response) => {
				setAvailableCash(response.data?.availableCash);
				setLoaderAvailableCash(false);
			})
			.catch((err) => {
				setLoaderAvailableCash(false);
			});
	};

	const getRemainingAmount = () => {
		return +businessDetails.loanAmount - +businessDetails.investedAmount;
	};

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	const isEnableInvestButton = () => {
		return (
			settings()?.enableMarketplace &&
			MarketplaceStatuses.Current === status &&
			businessDetails.loanAmount > businessDetails.investedAmount
		);
	};

	const onInvest = () => {
		if (availableCash !== 0) {
			setOpenInvest(true);
		} else {
			navigate(
				buildLocalizeRoute(
					`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`
				)
			);
		}
	};

	const onSuccessInvestClose = (e) => {
		navigate(
			buildLocalizeRoute(`${AppRoutes.Investor}/${AppRoutes.Marketplace}`)
		);
	};

	const onInvestment = (amount) => {
		setLoading(true);
		setOpenInvest(false);
		setInvestedAmount(amount);
		marketplaceService
			.investMarketplaceCollection(bucketId, amount)
			.then((resp) => {
				setIsOpenInvestSuccess(true);
				setLoading(false);
			})
			.catch((err) => {
				const errors = err?.response?.data?.errors || err?.response?.data;
				if (errors) {
					const key = Object.keys(errors)[0];
					setInvestErrorMessage(errors[key][0] || "");
				} else {
					setInvestErrorMessage("Something went wrong, request not completed");
				}

				setLoading(false);
				setTimeout(() => {
					setIsOpenInvestError(true);
				});
			});
	};

	return (
		<Box className="grid grid-flow-row gap-4">
			{!loading ? (
				<>
					<Section
						classList="!p-0"
						children={
							<Box className="grid grid-cols-1 md:grid-cols-3">
								<Box className="bg-[#F9F9F9] flex justify-center rounded-md items-center m-3 p-2 md:h-[350px] h-[240px]">
									<img
										className={`!h-full object-contain `}
										src={
											businessDetails?.logo
												? businessDetails?.logo
												: LogoPlaceholder
										}
										alt="Marketplace Logo"></img>
								</Box>
								<Box className="md:col-span-2 p-4">
									<Stack
										gap={1}
										direction={'column'}>
										<Box className="flex justify-between items-center">
											<Stack
												gap={1}
												className="flex !flex-col md:!flex-row">
												{businessDetails?.rating === 'A' && !isHistory() ? (
													<Box className="flex justify-center items-center px-2 py-1 w-fit border rounded-md">
														<TextWrapper
															type="caption"
															classList="!text-primary !font-[600]"
															text={
																<Stack
																	gap={0.5}
																	direction={'row'}
																	className="flex flex-0 items-center">
																	<CheckCircleOutlineOutlinedIcon className="!text-[16px]"></CheckCircleOutlineOutlinedIcon>
																	<Box>{`${t('guaranteedbyShams')} ${t(
																		'shams'
																	)}`}</Box>
																</Stack>
															}></TextWrapper>
													</Box>
												) : null}
												<Box className="flex justify-center items-center px-2 py-1 w-auto border rounded-md">
													<TextWrapper
														type="caption"
														classList="!text-primary !font-[600]"
														text={
															<Stack
																gap={0.5}
																direction={'row'}
																className="flex flex-0 items-center">
																<Box>{`${t('shariahCompliant')}`}</Box>
															</Stack>
														}></TextWrapper>
												</Box>
											</Stack>
											{getRemainingTime() > 0 ? (
												<Box className="flex items-center !text-sm !text-[#9d9d9d] justify-end">
													<AccessTimeOutlinedIcon className="mx-1 !text-[18px]" />
													<Countdown
														className="marketplace-countdown"
														wordsEndingOff={true}
														text={{
															days: 'd :',
															hours: 'h : ',
															minutes: 'm ',
														}}
														endInSeconds={true}
														end={getRemainingTime() || 0}
													/>
												</Box>
											) : null}
										</Box>
										<Box className="flex flex-col md:flex-row md:justify-between md:items-center">
											<TextWrapper
												classList="!heading"
												text={businessDetails?.businessName}></TextWrapper>
										</Box>
										<Box className="relative cursor-pointer">
											<Box className="flex justify-between items-center mb-4">
												<Stack>
													<TextWrapper
														classList="!font-[500]"
														type="caption"
														text={t('requested')}></TextWrapper>
													<TextWrapper
														classList="!text-primary"
														text={
															<Stack
																className="flex flex-0 items-center !font-bold !text-[18px]"
																direction={'row'}
																gap={0.5}>
																<Box>{CURRENCY}</Box>
																<Box className="flex flex-0 items-center !font-bold">
																	{currencyFormatterHelper(
																		businessDetails?.loanAmount,
																		0
																	)}
																</Box>
															</Stack>
														}></TextWrapper>
												</Stack>
												<Stack>
													<TextWrapper
														classList="!font-[500] !text-end"
														type="caption"
														text={t('funded')}></TextWrapper>
													<TextWrapper
														text={
															<Stack
																className="flex flex-0 items-center !font-bold !text-[14px]"
																direction={'row'}
																gap={0.5}>
																<Box>{CURRENCY}</Box>
																<Box className="flex flex-0 items-center !font-bold">
																	{currencyFormatterHelper(
																		businessDetails?.investedAmount,
																		0
																	)}
																</Box>
															</Stack>
														}></TextWrapper>
												</Stack>
											</Box>
											<Box className="marketplace-business-name mb-3">
												<CustomProgressBar
													value={getPercentage()}></CustomProgressBar>
												<Box className="flex marketplace-funded absolute w-full bottom-0 top-[20px] justify-between items-center !text-[10px]">
													<Stack
														gap={0.5}
														direction={'row'}>
														<Box>{t('remaining')}:</Box>
														<Box className="!font-[600]">{`${CURRENCY} ${currencyFormatterHelper(
															getRemainingAmount()
														)}`}</Box>
													</Stack>
												</Box>
											</Box>
										</Box>
										<Box className="grid grid-cols-1 md:grid-cols-2 gap-3   rounded-md">
											<Box className="grid grid-flow-row gap-3 bg-[#F9F9F9] p-3 rounded-md">
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={
																<span>
																	<span>{t('roi')}</span>
																	<CustomTooltip
																		id="netYieldInfoId1"
																		text="netYieldInfo"></CustomTooltip>
																</span>
															}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.fixed}%`}></TextWrapper>
													</Box>
												</Box>
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={
																<span>
																	<span>{t('irr')}</span>
																	<CustomTooltip
																		id="irrInfoId1"
																		text="irrInfo"></CustomTooltip>
																</span>
															}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.investorIRR || '25.9'}%`}></TextWrapper>
													</Box>
												</Box>
											</Box>
											<Box className="grid grid-flow-row gap-3 bg-[#F9F9F9] p-3 rounded-md">
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={t('tenure')}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.month} ${t(
																'monthsLowerCase'
															)}`}></TextWrapper>
													</Box>
												</Box>
												<Box className="flex justify-between items-center">
													<Box>
														<TextWrapper
															classList="!font-[500]"
															type="caption"
															text={t('rating')}></TextWrapper>
													</Box>
													<Box>
														<TextWrapper
															classList="!font-bold !text-[14px]"
															text={`${businessDetails?.rating}`}></TextWrapper>
													</Box>
												</Box>
											</Box>
										</Box>
										<Box className="mt-2">
											<span className="text-[14px]">
												{t('myOrdersIn')}&nbsp;
												<strong>{businessDetails?.businessName}</strong>&nbsp;
												{t('are')}&nbsp;
												<strong>{`${CURRENCY} ${currencyFormatterHelper(
													businessDetails?.personalPartsAmount
												)}`}</strong>
											</span>
										</Box>

										{isEnableInvestButton() ? (
											<Box className="flex justify-center mt-3">
												<ButtonWrapper
													loading={loadingAvailableCash}
													onClick={() => {
														onInvest();
													}}
													classList="md:!min-w-[120px]"
													label={t('invest')}
													icon={
														<PaymentsOutlinedIcon></PaymentsOutlinedIcon>
													}></ButtonWrapper>
											</Box>
										) : null}
									</Stack>
								</Box>
							</Box>
						}></Section>
					<DataTableGrid
						loading={loading}
						name={'marketplaceLoanTbl'}
						columns={columns}
						records={businessDetails?.loans}
						showEditColumnsButton={false}
						isPagination={false}></DataTableGrid>
					<TextWrapper
						type="caption"
						text={t('assignedRisk')}></TextWrapper>
					<InvestBehalfDialog
						onInvestment={(amount) => {
							onInvestment(amount);
						}}
						remainingAmount={getRemainingAmount()}
						minInvestmentAmount={calculateMinAmount()}
						state={openInvest}
						onClose={(e) => {
							setOpenInvest(false);
						}}></InvestBehalfDialog>

					{isOpenInvestSuccess ? (
						<InvestSuccessDialog
							amount={investedAmount}
							state={isOpenInvestSuccess}
							onClose={(e) => {
								onSuccessInvestClose(e);
							}}></InvestSuccessDialog>
					) : null}
					{isOpenInvestError ? (
						<InvestErrorDialog
							amount={investedAmount}
							errorMessage={investErrorMessage}
							state={isOpenInvestError}
							onClose={() => {
								setIsOpenInvestError(false);
							}}></InvestErrorDialog>
					) : null}
				</>
			) : (
				<MarketplaceDetailLoader></MarketplaceDetailLoader>
			)}
		</Box>
	);
};
