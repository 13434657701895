import { axiosGet, axiosPost } from '../../../../services/axiosService';

const baseMarketplaceURI = 'MarketPlace';

export class MarketplaceService {
	getMarketplace() {
		return axiosGet(null, baseMarketplaceURI);
	}

	getOpportunitiesCount() {
		return axiosGet(null, baseMarketplaceURI + '/HistoryCount');
	}

	getMarketplaceDetails(id) {
		return axiosGet(null, baseMarketplaceURI + `/${id}`);
	}

	getMarketplaceHistory(withLimit) {
		return axiosGet(
			null,
			`${baseMarketplaceURI}/History?withlimit=${withLimit}`
		);
	}

	getMarketplaceUpcoming() {
		return axiosGet(null, baseMarketplaceURI + '/upcoming');
	}

	getMarketplaceHistoryDetails(id) {
		return axiosGet(null, baseMarketplaceURI + `/History/${id}`);
	}

	investMarketplace(id, amount) {
		const body = { amount };
		return axiosPost(body, baseMarketplaceURI + `/${id}`, {
			hide_toaster: true,
		});
	}

	investMarketplaceOnbehalfOfInvestor(id, investorID, amount) {
		const body = { lenderId: investorID, amount: amount };
		return axiosPost(
			body,
			baseMarketplaceURI + `/invest/${id}/on-behalf-of-lender`
		);
	}

	getFinancialRatios(loanId) {
		return axiosGet(null, baseMarketplaceURI + `/${loanId}/ratios`);
	}

	getMarketplaceCollection() {
		return axiosGet(null, `${baseMarketplaceURI}/Collection`);
	}

	getMarketplaceCollectionDetails(bucketId) {
		return axiosGet(null, baseMarketplaceURI + `/Collection/${bucketId}`);
	}

	investMarketplaceCollection(bucketId, amount) {
		const body = { bucketId, amount };
		return axiosPost(body, baseMarketplaceURI + `/InvestCollection`, {
			hide_toaster: true,
		});
	}
}
