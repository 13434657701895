/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from '@mui/material';
import { DocumentUploadSection } from '../documentUploadSection';
import { useTranslation } from 'react-i18next';
import { UserDocumentTypes } from '../../../constants/userDocuments';
import { useEffect, useState } from 'react';
import { FinancialStatementDialog } from '../finacialStatmentDialog';
import { FinancialStatementDateHelper } from '../../../helpers/financialStatementDateHelper';
import { UserService } from '../../../services/userService';

export const FinancialDocument = ({
	documents = [],
	onFileUploaded = (e) => { },
}) => {
	const userService = new UserService();
	const { t } = useTranslation();
	const [showFSPopup, setShowFSPopup] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isMultiple, setIsMultiple] = useState(true);
	const [financialStatement, setFinancialStatement] = useState(null);
	const [financialStatementRatios, setFinancialStatementRatios] =
		useState(null);

	useEffect(() => {
		let docs = getFilteredDocuments(
			documents,
			UserDocumentTypes.BorrowerFinancialStatements
		);
		setFinancialStatement(docs);
		setIsMultiple(true);
	}, [documents]);

	useEffect(() => {
		getFinancialStatementRatios();
	}, []);

	useEffect(() => {
		onFileUploaded(!!financialStatement);
		console.log("file-0");
	}, [financialStatement]);

	useEffect(() => {
		setIsMultiple(checkIsMultiple());
		onFileUploaded(!!financialStatementRatios);
		console.log("file-1");
	}, [financialStatementRatios]);

	const checkIsMultiple = () => {
		if (financialStatement || financialStatement?.length) {
			return true;
		}

		if (financialStatementRatios) {
			return false;
		}

		return true;
	};

	const getFinancialStatementRatios = () => {
		setLoading(true);
		userService
			.getFinancialStatementsRatios()
			.then((resp) => {
				setFinancialStatementRatios(resp.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const getFilteredDocuments = (fileDetails = [], type) => {
		let files = fileDetails.filter((data) => data.type === type);
		return files?.length ? files : null;
	};

	const predefinedFile = {
		file: { name: 'Financial Statements', url: 'mockURL' }, type: 22, id: null
	};

	const showRatioLink = () => {
		return (!financialStatement || !financialStatement?.length) && !financialStatementRatios;
	};

	return (
		<Box>
			<DocumentUploadSection
				isMultiple={isMultiple}
				isExternalFileRemove={true}
				isExternalFileOpen={true}
				onOpenFile={() => {
					if (!financialStatement) {
						setShowFSPopup(true);
					}
				}}
				onRemoveFile={(resp) => {
					if (!resp) {
						setFinancialStatementRatios(null);
					}
					setFinancialStatement(resp && resp?.length ? resp : null);
				}}
				onUploadedFiles={(e) => {
					setFinancialStatement(e?.file);
				}}
				showOTPAuth={true}
				actionSubElements={
					showRatioLink() ? (
						<Box
							data-testid="financialStatementBtn"
							className="flex items-start"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowFSPopup(true);
							}}>
							<small className="!caption">
								{t('orUseFinancial')}{' '}
								<span className="underline text-primary hover:no-underline">
									{t('here')}
								</span>
							</small>
						</Box>
					) : null
				}
				fileName={`financialStatementForThe`}
				subFileNameKey="date"
				subFileNameValue={`${FinancialStatementDateHelper().year1} & ${FinancialStatementDateHelper().year2
					} ${t('internalOrExternal')}`}
				file={
					financialStatement && financialStatement?.length
						? financialStatement
						: financialStatementRatios
							? predefinedFile
							: null
				}
				isLoading={loading}
				fileType={
					UserDocumentTypes.BorrowerFinancialStatements
				}></DocumentUploadSection>

			{showFSPopup ? (
				<FinancialStatementDialog
					financialStatementRatios={financialStatementRatios}
					state={showFSPopup}
					onClose={(e) => {
						setShowFSPopup(false);
					}}
					onSubmitForm={(e) => {
						setShowFSPopup(false);
						setFinancialStatementRatios(e);
					}}
				/>
			) : null}
		</Box>
	);
};
