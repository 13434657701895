import { cultures } from '../constants/cultures';
export class FormHelper {
	static formikProps(props) {
		return {
			enableReinitialize: true,
			validateOnMount: true,
			validateOnBlur: true,
			validateOnChange: true,
			...props,
		};
	}

	static buildAddressFormGroup(
		isDisabled = true,
		countryValue = null,
		values = null
	) {
		return {
			addressLine: values?.addressLine || null,
			additionalAddressLine: values?.additionalAddressLine || null,
			city: values?.city || null,
			postalCode: values?.postalCode || null,
			countryId: countryValue ? countryValue : values?.country?.id || null,
		};
	}

	static buildOtherFormGroup(isDisabled = true, values) {
		return {
			valueId: values?.value?.id || '',
			valueSpecified: values?.valueSpecified || '',
		};
	}

	static getComplexOther(formGroupValue) {
		return {
			valueId: formGroupValue?.value?.id,
			valueSpecified: formGroupValue?.valueSpecified,
		};
	}

	static getTaxResidencies(taxResidency = {}) {
		let taxResidencyObj = {
			taxId: '',
			areYouTaxedElsewhere: false,
			countryId: '',
			taxIdElsewhere: '',
			country: null,
			type: null,
		};

		if (taxResidency && Object.keys(taxResidency).length) {
			taxResidencyObj = {
				...taxResidency,
				countryId: taxResidency?.country?.id
					? taxResidency?.country?.id
					: taxResidency?.type
					? 'no'
					: '',
			};
		}
		delete taxResidencyObj?.country;
		return taxResidencyObj;
	}

	static buildRiskFactorsFormGroup(values) {
		return {
			agreement: values?.agreement || null,
			riskRating: values?.riskRating || '',
			rationale: values?.rationale || '',
		};
	}

	static createLanguageFormArray({
		withTitle = true,
		withDescription = true,
		withJoinDescription = false,
		fullname = false,
		isFAQ = false,
		addPoster = false,
		withFullName = false,
	}) {
		const formGroups = [];
		for (const key in cultures) {
			const culture = cultures[key];
			let cultureFormGroup = {
				culture: culture,
			};
			if (withTitle) {
				cultureFormGroup = {
					...cultureFormGroup,
					[!isFAQ ? 'title' : 'question']: '',
				};
			}
			if (withDescription) {
				cultureFormGroup = {
					...cultureFormGroup,
					[!isFAQ ? 'description' : 'answer']: '',
				};
			}
			if (withJoinDescription) {
				cultureFormGroup = {
					...cultureFormGroup,
					joinDescription: '',
				};
			}

			if (fullname) {
				cultureFormGroup = {
					...cultureFormGroup,
					fullname: '',
				};
			}

			if (withFullName) {
				cultureFormGroup = {
					...cultureFormGroup,
					fullName: '',
				};
			}

			if (addPoster) {
				cultureFormGroup = {
					...cultureFormGroup,
					imageUrl: '',
					file: '',
				};
			}

			formGroups.push(cultureFormGroup);
		}
		return formGroups;
	}

	static validateForm(form) {
		form.submitForm();
	}
}
