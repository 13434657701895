import { Box } from '@mui/material';
import { ButtonWrapper } from '../../../../../../shared/components/formUtilities/useButton';
import { useTranslation } from 'react-i18next';
import { DialogWrapper } from '../../../../../../shared/components/common/dialogWrapper';
import { TextWrapper } from '../../../../../../shared/components/formUtilities/useTextWrapper';
import { currencyFormatterHelper } from '../../../../../../helpers/currencyFormatterHelper';
import { CURRENCY } from '../../../../../../config/appConfig';

export const InvestSuccessDialog = ({
	state,
	onClose = (e) => {},
	amount = 0,
}) => {
	const { t } = useTranslation();

	const getAmount = () => {
		return currencyFormatterHelper(amount);
	};
	return (
		<DialogWrapper
			hideCloseBtn={true}
			maxWidth="xs"
			width={'350px'}
			state={state}
			onClose={() => {
				onClose(false);
			}}
			content={
				<>
					<Box className="grid grid-flow-row gap-4 invest-success justify-center">
						<Box className="flex justify-center">
							<svg
								viewBox="0 0 26 26"
								xmlns="http://www.w3.org/2000/svg">
								<g
									stroke="currentColor"
									stroke-width="2"
									fill="none"
									fill-rule="evenodd"
									stroke-linecap="round"
									stroke-linejoin="round">
									<path
										class="circle"
										d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
									/>
									<path
										class="tick"
										d="M6.5 13.5L10 17 l8.808621-8.308621"
									/>
								</g>
							</svg>
						</Box>
						<Box className="flex justify-center items-center">
							<TextWrapper
								classList="!font-bold !sub-heading !text-center"
								text={t('thankYouInvestAgain')}></TextWrapper>
						</Box>
						<Box className="text-center">
							<TextWrapper
								classList="!sub-title-text"
								text={t('thankYouInvestAgainBody')}></TextWrapper>
							<TextWrapper
								classList="!sub-title-text !font-bold"
								text={`${CURRENCY} ${getAmount()}`}></TextWrapper>
							<TextWrapper
								classList="!sub-title-text"
								text={t('thankYouInvestAgainBody1')}></TextWrapper>
						</Box>
					</Box>
				</>
			}
			actions={
				<Box className="flex items-center">
					<ButtonWrapper
						variant={'outlined'}
						label={t('ok')}
						onClick={() => {
							onClose(false);
						}}></ButtonWrapper>
				</Box>
			}></DialogWrapper>
	);
};
