/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { BucketService } from '../../../../services/bucketService';
import { InvestorService } from '../../investorService';
import { BecomeProService } from '../../../../services/becomeProService';
import { SystemSettingsService } from '../../../../services/systemSettingsService';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../constants/appRoutes';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ConfirmAutoInvestDialog } from './confirmAutoInvestDailog';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';
import { Section } from '../../../../shared/components/common/section';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { BucketTypes } from '../../../../constants/bucketTypes';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import { BecomeProStatuses } from '../../../../constants/becomeProStatus';
import { DateHelper } from '../../../../helpers/dateHelper';
import { CustomTooltip } from '../../../../shared/components/common/tooltip';
import { OTPTypes } from '../../../../constants/otpTypes';
import { AutoInvestLoader } from '../../../../shared/components/investors/loaders/autoInvestmentLoader';
import { InvestorGuidelinePopover } from '../../../../shared/components/investors/investorGuidlinePopover';
import { InvestorGuidelineService } from '../../../../services/guidelineService';
import { InvestorGuidelineSteps } from '../../../../shared/components/investors/constants/guidlineSteps';
import { OTPAuthenticationDialog } from '../../../../shared/components/otpAuthenticationDialog';
import { WEBSITE_URL } from '../../../../config/appConfig';
import { LanguageService } from '../../../../services/languageService';

export const AutoInvesting = () => {
	const bucketService = new BucketService();
	const investorService = new InvestorService();
	const becomeProService = new BecomeProService();
	const systemSettingsService = new SystemSettingsService();
	const languageService = new LanguageService();

	const [proStatus, setProStatus] = useState(null);
	const [summary, setSummary] = useState(null);
	const [availableCash, setAvailableCash] = useState(0);
	const [buckets, setBuckets] = useState([]);
	const [activatedBucket, setActivatedBucket] = useState({});
	const [selectedBucket, setSelectedBucket] = useState(null);
	const [showOTPDialog, setShowOTPDialog] = useState(false);
	const [isOpenBucketDialog, setIsOpenBucketDialog] = useState(false);
	const guideService = new InvestorGuidelineService();
	const [currentStep, setCurrentStep] = useState(guideService.stepCount());
	const [activeBtnGuideStep, setActiveBtnGuideStep] = useState(false);
	const [pauseGuideStep, setPauseGuideStep] = useState(false);
	const guideSteps = InvestorGuidelineSteps;

	const currentYear = new Date().getFullYear();

	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (guideService.stepCount() === guideSteps.ActivatedBalance) {
			setActiveBtnGuideStep(true);
			setPauseGuideStep(false);
			scrollTo('activeBtn');
		} else if (guideService.stepCount() === guideSteps.PauseInvest) {
			setActiveBtnGuideStep(false);
			setPauseGuideStep(true);
			scrollTo('pauseBtn');
		} else {
			setActiveBtnGuideStep(false);
			setPauseGuideStep(false);
		}
	}, [currentStep]);

	const scrollTo = (id) => {
		try {
			setTimeout(() => {
				if (id && document.getElementById(id)) {
					document.getElementById(id)?.scrollIntoView({
						behavior: 'smooth',
					});
				}
			}, 500);
		} catch {}
	};

	const isArabic = () => {
		return languageService.isArabic();
	};

	const isNotInvested = () => {
		return !buckets.find((bucket) => bucket.id === selectedBucket);
	};

	const becomeProRejected = () => {
		if (proStatus?.status === BecomeProStatuses.Rejected) {
			if (DateHelper.dateIsAfter(proStatus?.statusSetDate)) {
				return false;
			}
			return true;
		}
		return false;
	};

	const isDisableBecomeProBtn = () => {
		if (proStatus?.status === BecomeProStatuses.Requested) {
			return true;
		}

		return becomeProRejected();
	};

	const isShowBecomeProBtn = () => {
		if (
			proStatus?.status == null ||
			proStatus?.status === '' ||
			proStatus?.status === undefined
		) {
			return false;
		}

		return proStatus?.status !== BecomeProStatuses.Approved;
	};

	const initData = async () => {
		const [
			shortSummary,
			buckets,
			selectedBucketData,
			becomeProStatus,
			summaryData,
		] = await Promise.all([
			investorService.getInvestorSummaryShort(),
			bucketService.getAll(),
			bucketService.getIndividualLendingSettings(),
			becomeProService.getBecomeProStatus(),
			investorService.getInvestorsSummary(),
		]).catch((err) => {
			setLoading(false);
		});
		setSummary(summaryData?.data);
		setAvailableCash(shortSummary.data?.availableCash);
		setProStatus(becomeProStatus.data);
		setLoading(false);
		if (buckets.data && buckets.data?.length) {
			let enabledBuckets = buckets.data.filter((b) => b.isEnabled);
			setBuckets(enabledBuckets);
			setSelectedBucket(
				enabledBuckets.find(
					(b) =>
						b.id.toLowerCase() ===
						selectedBucketData.data?.modeId?.toLowerCase()
				)?.id
			);
			if (guideService.stepCount() === guideSteps.ActivatedBalance) {
				scrollTo('activeBtn');
				setActiveBtnGuideStep(true);
				setPauseGuideStep(false);
			} else if (guideService.stepCount() === guideSteps.PauseInvest) {
				scrollTo('pauseBtn');
				setActiveBtnGuideStep(false);
				setPauseGuideStep(true);
			}
		}
	};

	const activateBucket = () => {
		setSelectedBucket(activatedBucket?.id);
		bucketService
			.setInvestingMode(activatedBucket?.id)
			.then((resp) => {
				setSelectedBucket(activatedBucket?.id);
				if (availableCash <= 0) {
					navigate({
						pathname: buildLocalizeRoute(
							`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`
						),
						search: `forAutoInvestment = true`,
					});
				}
			})
			.catch((err) => {});
	};

	const onBucketSelect = (bucket) => {
		setActivatedBucket(bucket);
		setTimeout(() => {
			setIsOpenBucketDialog(true);
		});
	};

	const onCloseBucketDialog = (value) => {
		setIsOpenBucketDialog(false);
		if (value) {
			if (systemSettingsService.systemSettings()?.enabledOtp) {
				setShowOTPDialog(true);
			} else {
				activateBucket();
			}
		}
	};

	const onOTPClose = (value) => {
		setShowOTPDialog(false);
		if (value) {
			activateBucket();
		}
	};

	const onBecomePro = () => {
		navigate(
			buildLocalizeRoute(
				`${AppRoutes.Investor}/${AppRoutes.Profile}/${AppRoutes.BecomeAPro}`
			)
		);
	};

	const investBanner = (text, link, linkText) => {
		return (
			<TextWrapper
				text={
					<span>
						{t(text)}
						{link ? (
							<a
								className="underline-link mx-1 !text-[16px]"
								href={link}>
								{t(linkText)}
							</a>
						) : null}
					</span>
				}></TextWrapper>
		);
	};

	const bucketPoints = (
		text,
		tooltipText = '',
		id = null,
		secondaryText = ''
	) => {
		return (
			<Box className="flex flex-0 items-start">
				<Box>
					<CheckCircleOutlineIcon color="primary"></CheckCircleOutlineIcon>
				</Box>
				<Box className="mx-2">
					<p className="!text-[16px]">
						{t(text)}
						{tooltipText ? (
							<CustomTooltip
								tooltipClass="md:!max-w-[300px]"
								iconClassList="!m-0 !text-[13px] !relative !top-[-6px] !left-[2px] !right-[2px]"
								id={id}
								text={t(tooltipText)}></CustomTooltip>
						) : null}
						{secondaryText ? t(secondaryText) : null}
					</p>
				</Box>
			</Box>
		);
	};

	// const bucketRiskRatingPoints = (bucket, classList = null) => {
	// 	return (
	// 		<Box className={`grid grid-flow-col gap-16 justify-center ${classList}`}>
	// 			{bucket?.riskRatingEnabledA ? (
	// 				<Box className="auto-invest-risk">A</Box>
	// 			) : null}
	// 			{bucket?.riskRatingEnabledB ? (
	// 				<Box className="auto-invest-risk">B</Box>
	// 			) : null}
	// 			{bucket?.riskRatingEnabledC ? (
	// 				<Box className="auto-invest-risk">C</Box>
	// 			) : null}
	// 		</Box>
	// 	);
	// };

	return (
		<Box className="grid grid-flow-row gap-4">
			{!loading ? (
				<>
					{isNotInvested()
						? investBanner('activateAutoInvestText')
						: investBanner(
								'activatedAutoInvestText',
								'my-portfolio',
								'myInvestments'
						  )}
					<Box className="flex justify-center items-center my-4">
						<Box className="md:px-4">
							<Box className="grid md:grid-cols-2 grid-cols-1 gap-6">
								{buckets?.length
									? buckets?.map((bucket, index) => {
											return (
												<Section
													key={index}
													classList={`!p-0 !relative ${
														!isArabic()
															? 'md:!min-h-[590px]'
															: 'md:!min-h-[465px]'
													}`}
													children={
														<Box
															key={index}
															className={`grid grid-flow-row gap-2 md:w-[460px] bucket ${
																bucket?.id === selectedBucket ? 'active' : ''
															}`}>
															<Box className="flex justify-between">
																<Box className="p-3 flex items-center  bucket-heading border-b-2 border-color-primary w-full">
																	<TextWrapper
																		text={bucket?.title}
																		classList="!text-[17px] md:!w-[270px] !font-bold md:!overflow-hidden md:!whitespace-nowrap md:!text-ellipsis"></TextWrapper>
																</Box>
																<Box className="px-1 py-3 flex flex-col items-center bucket-roi min-w-[140px] justify-center">
																	<p className="text-[16px] font-bold">
																		<span className="!text-[11px] mx-1">
																			{t('upToPercentage')}
																		</span>
																		{currencyFormatterHelper(
																			bucket?.roiPercent
																		)}
																		%
																	</p>

																	<p className="!text-[11px]">
																		{t('projectedReturn')}
																	</p>
																</Box>
															</Box>
															<Box className="bucket-body md:p-6 p-4 grid grid-flow-row gap-12">
																{bucket?.type === BucketTypes.Balanced ? (
																	<Box
																		className={`${
																			!isArabic() ? 'md:mb-[26px]' : ''
																		}`}>
																		{bucketPoints(
																			'autoInvestmentBalancedPortfolioText'
																		)}
																	</Box>
																) : (
																	bucketPoints(
																		'autoInvestmentConservativePortfolioText'
																	)
																)}

																{bucket?.type === BucketTypes.Balanced
																	? bucketPoints(
																			'autoInvestmentAutomateInvestText'
																	  )
																	: bucketPoints(
																			'autoInvestmentHigherProjectReturnsConservativeText'
																	  )}
																{bucket?.type === BucketTypes.Balanced
																	? bucketPoints(
																			'autoInvestmentRiskIsMinimizedConservativeText',
																			'autoInvestmentRiskIsMinimizedConservativeTextTooltipText',
																			'autoInvestmentRiskIsMinimizedConservativeTextTooltipText',
																			''
																	  )
																	: bucketPoints(
																			'autoInvestmentRiskIsMinimizedConservativeText1',
																			'autoInvestmentRiskIsMinimizedConservativeText1TooltipText',
																			'autoInvestmentRiskIsMinimizedConservativeText1TooltipText'
																	  )}
																{/* {bucket?.type === BucketTypes.Balanced
																	? bucketPoints(
																			'autoInvestmentStrategyAimText'
																	  )
																	: bucketPoints(
																			'autoInvestmentAutomateInvestConservativeText'
																	  )} */}
															</Box>
															<Box
																id="activeBtn"
																className={`bucket-footer flex justify-center items-center md:absolute bottom-0 w-full p-4 ${
																	bucket.id === selectedBucket
																		? 'primary-bg'
																		: 'md:px-16'
																}`}>
																{bucket.id !== selectedBucket ? (
																	<Box className="relative w-full">
																		<ButtonWrapper
																			onClick={() => {
																				onBucketSelect(bucket);
																			}}
																			labelClass="!text-[18px] !font-bold"
																			classList="!w-full !border-2 !border-color-primary"
																			variant={'outlined'}
																			label={t('activate')}></ButtonWrapper>
																	</Box>
																) : (
																	<TextWrapper
																		classList="!sub-heading !font-[500] !text-white"
																		text={t('activated')}></TextWrapper>
																)}
																{activeBtnGuideStep && index === 0 ? (
																	<InvestorGuidelinePopover
																		arrowPosition="down"
																		onNext={(count) => {
																			setCurrentStep(count);
																		}}
																		classList="!bottom-[60px]"
																		state={activeBtnGuideStep}
																		message={
																			'guideActivatedBalance'
																		}></InvestorGuidelinePopover>
																) : null}
															</Box>
														</Box>
													}></Section>
											);
									  })
									: null}
							</Box>
						</Box>
					</Box>
					<Section
						hide={!isShowBecomeProBtn()}
						children={
							<Box className="flex flex-0 md:flex-row flex-col items-center">
								<span className="mb-4 md:mb-0">
									<TextWrapper
										type="span"
										classList="!font-bold"
										text={t('becomePro') + ' - '}></TextWrapper>
									<TextWrapper
										type="span"
										text={t('becomeProHintText', {
											depositRemained: currencyFormatterHelper(
												summary?.depositRemained
											),
											year: currentYear,
										})}></TextWrapper>
								</span>
								{!isDisableBecomeProBtn() ? (
									<ButtonWrapper
										onClick={() => {
											onBecomePro();
										}}
										label={t('upgrade')}
										classList="md:!w-[200px] md:!mx-2 !w-full"></ButtonWrapper>
								) : (
									<Box>
										<CustomTooltip
											id="pro"
											text={
												becomeProRejected()
													? `${t('becomeProRejectedText')}`
													: proStatus?.status === BecomeProStatuses.Requested
													? `${t('becomeProRequestCompleted')}`
													: ''
											}
											children={
												<ButtonWrapper
													disabled
													label={t('pending')}
													classList="md:!w-[120px] !w-full"></ButtonWrapper>
											}></CustomTooltip>
									</Box>
								)}
							</Box>
						}></Section>
					<Box
						className="relative"
						id="pauseBtn">
						<Section
							hide={selectedBucket === 'Manual' || !buckets?.length}
							children={
								<Box>
									{pauseGuideStep ? (
										<InvestorGuidelinePopover
											arrowPosition="down"
											redirect={`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`}
											classList="bottom-[40px]"
											onNext={(count) => {
												setCurrentStep(count);
											}}
											state={pauseGuideStep}
											message={'guidePauseInvest'}></InvestorGuidelinePopover>
									) : null}
									<span>
										<TextWrapper
											type="span"
											classList="!font-bold"
											text={t('pauseInvesting') + ' - '}></TextWrapper>
										<TextWrapper
											type="span"
											text={
												<span className="relative">
													{t('pauseInvestingText')}{' '}
													<a
														className="underline-link !text-[16px]"
														onClick={() => {
															onBucketSelect(null);
														}}>
														{t('here')}
													</a>
												</span>
											}></TextWrapper>
									</span>
								</Box>
							}></Section>
					</Box>
					{isNotInvested() ? (
						<Box>
							<a
								className="underline-link"
								href={`${WEBSITE_URL}${i18n.language}/faq?q=WhatisAutoInvesting`}
								target="_blank"
								rel="noreferrer">
								{t('findOutMoreAboutAutoInesting')}
							</a>
						</Box>
					) : null}

					<Box>
						<p className="caption text-xs font-normal">
							{t('autoInvestmentFooterText')}
						</p>
					</Box>
				</>
			) : (
				<AutoInvestLoader></AutoInvestLoader>
			)}

			{isOpenBucketDialog ? (
				<ConfirmAutoInvestDialog
					state={isOpenBucketDialog}
					bucket={activatedBucket}
					onClose={(e) => {
						onCloseBucketDialog(e);
					}}></ConfirmAutoInvestDialog>
			) : null}

			{showOTPDialog ? (
				<OTPAuthenticationDialog
					type={OTPTypes.Other}
					state={showOTPDialog}
					onClose={(e) => {
						onOTPClose(e);
					}}></OTPAuthenticationDialog>
			) : null}
		</Box>
	);
};
