import { useEffect, useState } from 'react';
import { BorrowerService } from '../../../borrowerService';
import { useTranslation } from 'react-i18next';
import { BorrowerStatus } from '../../../../../constants/borrowerStatus';
import { Box } from '@mui/material';
import { BorrowerPersonalInfo } from '../personalInformation';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { Section } from '../../../../../shared/components/common/section';
import { BorrowerDocuments } from '../documents';
import { BorrowerBusinessInfo } from '../businessInformation';

export const BorrowerUpdateDetails = () => {
	const borrowerService = new BorrowerService();
	const [borrowerPersonalInfo, setBorrowerPersonalInfo] = useState({});
	const { t } = useTranslation();

	useEffect(() => {
		getBorrowerProfilePersonalInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSavePersonalInfo = () => {
		getBorrowerProfilePersonalInfo();
	};

	const getBorrowerProfilePersonalInfo = () => {
		borrowerService
			.getBorrowerProfilePersonalInfo()
			.then((resp) => {
				setBorrowerPersonalInfo(resp.data);
			})
			.catch((err) => {});
	};

	const isApprovedByCEO = () => {
		return borrowerPersonalInfo?.status?.name === BorrowerStatus.ApprovedByCO;
	};

	return (
		<Box className="grid-flow-row grid gap-3">
			<Box className="grid-flow-row grid gap-3">
				{!isApprovedByCEO() ? <BorrowerDocuments></BorrowerDocuments> : null}
				<BorrowerPersonalInfo
					borrowerPersonalInfo={borrowerPersonalInfo}
					onSavePersonalInfo={() => {
						onSavePersonalInfo();
					}}></BorrowerPersonalInfo>
				<BorrowerBusinessInfo></BorrowerBusinessInfo>
			</Box>
			<Section
				children={
					<TextWrapper
						classList="!info-text"
						text={
							<div>
								{t('changeSignInDetailsAdminText')}
								<a
									className="underline-link mx-1"
									href="mailto:support@fundingsouq.com">
									support@fundingsouq.com
								</a>
							</div>
						}></TextWrapper>
				}></Section>
		</Box>
	);
};
