import { axiosGet, axiosPost } from './axiosService';

export class SAMLService {
	getAuthNumber(nationalID) {
		return axiosPost({ nationalId: nationalID }, 'Saml/Authorize', {
			hide_success_toaster: true,
		});
	}

	isUserVerified(showError = false) {
		return axiosGet(null, 'Saml/IsNafathCompleted', null, null, {
			hide_toaster: showError,
		});
	}
}
