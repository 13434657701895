import { Box, LinearProgress, Typography } from '@mui/material';
import { Section } from '../../../../../../shared/components/common/section';
import { TextWrapper } from '../../../../../../shared/components/formUtilities/useTextWrapper';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PaymentService } from '../../../../../../services/paymentService';
import { currencyFormatterHelper } from '../../../../../../helpers/currencyFormatterHelper';
import { CURRENCY } from '../../../../../../config/appConfig';
import { SectionWithHeading } from '../../../../../../shared/components/common/sectionWithHeading';
import DataTableGrid from '../../../../../../shared/components/formUtilities/useTable';
import { initializeColumns } from './column';
import { useTranslation } from 'react-i18next';

const PaymentStatus = {
	Paid: 'Paid',
	PaidLate: 'Paid late',
	Overdue: 'Overdue',
	Planned: 'Planned',
};

export const PaymentSchedule = () => {
	const paymentService = new PaymentService();
	const [paymentsSchedule, setPaymentsSchedule] = useState([]);
	const [loading, setLoading] = useState(false);
	const params = useParams();
	const [paidPaymentsCount, setPaidPaymentsCount] = useState(0);
	const [paidPaymentsPercent, setPaidPaymentsPercent] = useState(0);
	const [totalPaymentsCount, setTotalPaymentsCount] = useState(0);
	const [remainingTotal, setRemainingTotal] = useState(0);
	const [overallTotal, setOverallTotal] = useState(0);
	const { t } = useTranslation();
	const columns = initializeColumns(t);

	useEffect(() => {
		if (params?.id) {
			setLoading(true)
			paymentService
				.getPaymentScheduleById(params.id)
				.then((resp) => {
					setLoading(false);
					setPaymentsSchedule(resp.data);
					calculatePaidPaymentsCount(resp.data);
					calculatePaidPaymentsPercent(resp.data);
					calculateOverallPaymentsTotal(resp.data);
					calculateRemainingPaymentsTotal(resp.data);
				})
				.catch((err) => {
					setLoading(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const calculatePaidPaymentsCount = (payments = []) => {
		setPaidPaymentsCount(getPaidPaymentCount(payments));
	};

	const getPaidPaymentCount = (payments = []) => {
		return (
			payments.filter(
				(x) =>
					x.status === PaymentStatus.Paid || x.status === PaymentStatus.PaidLate
			).length || 0
		);
	};

	const calculatePaidPaymentsPercent = (payments = []) => {
		let paidPaymentsCounts = getPaidPaymentCount(payments);
		setTotalPaymentsCount(payments.length);
		setPaidPaymentsPercent(
			payments.length > 0 ? (paidPaymentsCounts * 100) / payments.length : 0
		);
	};
	const calculateOverallPaymentsTotal = (payments = []) => {
		let count = 0;
		payments.forEach((payment) => {
			count += payment.total;
		});
		setOverallTotal(count);
	};
	const calculateRemainingPaymentsTotal = (payments = []) => {
		let count = 0;
		const remainingPayments = payments.filter(
			(x) =>
				x.status === PaymentStatus.Planned || x.status === PaymentStatus.Overdue
		);
		remainingPayments.forEach((payment) => {
			count += payment.total;
		});
		setRemainingTotal(count);
	};

	return (
		<Box className="flex flex-col flex-0">
			<Section
				children={
					<Box className="flex flex-col md:flex-row justify-center md:justify-between">
						<Box className="flex flex-col justify-center items-center md:min-w-[200px]">
							<TextWrapper
								classList="!heading"
								text={`${paidPaymentsCount} of ${totalPaymentsCount}`}></TextWrapper>
							<TextWrapper
								type="caption"
								text={t('Payments made')}></TextWrapper>
						</Box>
						<Box
							className="card flex flex-0 flex-col items-start relative mt-4 mb-2 md:mx-12"
							sx={{ width: '100%' }}>
							<LinearProgress
								className="w-full !h-9 rounded-lg"
								variant="determinate"
								value={paidPaymentsPercent}
							/>
							<Box className="w-full absolute">
								<Typography
									style={{
										padding: '0px 10px',
										width: `${
											+paidPaymentsPercent >= 10 ? +paidPaymentsPercent : '100'
										}%`,
									}}
									className="absolute !font-bold text-end top-[9px]  !text-white"
									variant="body2"
									color="text.secondary">
									{paidPaymentsPercent?.toFixed(2)}%
								</Typography>
							</Box>
						</Box>
						<Box className="flex flex-col justify-center items-center md:min-w-[200px]">
							<TextWrapper
								classList="!heading"
								text={`${CURRENCY} ${currencyFormatterHelper(
									remainingTotal
								)}`}></TextWrapper>
							<TextWrapper
								type="caption"
								text={`Remaining of ${CURRENCY} ${currencyFormatterHelper(
									overallTotal
								)}`}></TextWrapper>
						</Box>
					</Box>
				}></Section>
			<SectionWithHeading
				classList="!mt-4"
				heading={t('paymentSchedule')}
				children={
					<DataTableGrid
						loading={loading}
						name={'paymentScheduleTbl'}
						columns={columns}
						records={paymentsSchedule}
						showEditColumnsButton={false}
						isPagination={false}></DataTableGrid>
				}></SectionWithHeading>
		</Box>
	);
};
