import { useTranslation } from 'react-i18next';
import { DialogWrapper } from '../common/dialogWrapper';
import { Box } from '@mui/material';
import { ButtonWrapper } from '../formUtilities/useButton';
import { useEffect, useState } from 'react';
import { FinancialStatementDateHelper } from '../../../helpers/financialStatementDateHelper';
import { FormikProvider, useFormik } from 'formik';
import { FormHelper } from '../../../helpers/formHelper';
import { InputWrapper } from '../formUtilities/useInput';
import { UserService } from '../../../services/userService';
import { FinancialStatementSchema } from '../../validations/schema';
import { Toaster } from '../common/toaster';
import { CURRENCY } from '../../../config/appConfig';
import { SystemSettingsService } from '../../../services/systemSettingsService';

export const FinancialStatementDialog = ({
	financialStatementRatios = null,
	state = false,
	onClose = () => { },
	onSubmitForm = (values) => { },
}) => {
	const systemSettingsService = new SystemSettingsService();
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const userService = new UserService();
	const initialValues = {
		assets: {
			cashOnHandAtBank: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			accountReceivables: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			inventory: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			other: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			ppAndE: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			totalAssets: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
		},
		liability: {
			accountPayable: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			borrowings: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			other: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			totalLiabilities: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			paidInCapital: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			retainedEarnings: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			currentYearNetProfit: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			ownersEquityAccount: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			totalOwnersEquity: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			totalLiabilityAndEquity: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
		},
		incomeStatements: {
			totalRevenue: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			totalExpenses: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
			netProfit: [
				{ year: FinancialStatementDateHelper().year1Key, value: '' },
				{ year: FinancialStatementDateHelper().year2, value: '' },
			],
		},
	};

	const settings = () => systemSettingsService.systemSettings();
	const form = useFormik({
		initialValues: initialValues,
		validationSchema: FinancialStatementSchema,
		onSubmit: () => {
			onSubmit();
		},
		...FormHelper.formikProps(),
	});
	const { t } = useTranslation();

	useEffect(() => {
		if (financialStatementRatios) {
			form.setValues(financialStatementRatios);
		} else {
			form.setValues(initialValues);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [financialStatementRatios]);

	const onSubmit = () => {
		let isError = false;
		let totalAssets = form.values.assets.totalAssets;
		let totalLiabilityAndEquity = form.values.liability.totalLiabilityAndEquity;
		if (
			parseFloat(totalAssets[0].value) !==
			parseFloat(totalLiabilityAndEquity[0].value)
		) {
			isError = true;
			form.setFieldError(`assets.totalAssets[0].value`, 'notvalid');
			form.setFieldError(
				`liability.totalLiabilityAndEquity[0].value`,
				'notvalid'
			);
		}

		if (
			parseFloat(totalAssets[1].value) !==
			parseFloat(totalLiabilityAndEquity[1].value)
		) {
			isError = true;

			form.setFieldError(`assets.totalAssets[1].value`, 'notvalid');
			form.setFieldError(
				`liability.totalLiabilityAndEquity[1].value`,
				'notvalid'
			);
		}

		if (!isError) {
			setLoadingSubmit(true);
			userService
				.addFinancialStatementRatios(form.values)
				.then((resp) => {
					setLoadingSubmit(false);
					onSubmitForm(form.values);
				})
				.catch((err) => {
					setLoadingSubmit(false);
				});
		} else {
			Toaster({
				msg: 'Total Assests sould be equal to Total Liability & Equity',
			});
		}
	};

	const calculateTotalAssets = (index) => {
		let cashOnHandAtBank = form.values.assets.cashOnHandAtBank[index].value;
		let accountReceivables = form.values.assets.accountReceivables[index].value;
		let inventory = form.values.assets.inventory[index].value;
		let other = form.values.assets.other[index].value;
		let ppAndE = form.values.assets.ppAndE
			? form.values.assets.ppAndE[index].value
			: 0;
		let totalAssets =
			parseFloat(cashOnHandAtBank || 0) +
			parseFloat(accountReceivables || 0) +
			parseFloat(inventory || 0) +
			parseFloat(other || 0) +
			parseFloat(ppAndE || 0);
		form.setFieldValue(`assets.totalAssets[${index}].value`, totalAssets);
	};

	const calculateLiabilities = (index) => {
		let accountPayable = form.values.liability.accountPayable[index].value;
		let borrowings = form.values.liability.borrowings[index].value;
		let other = form.values.liability.other[index].value;

		let totalLiabilities =
			parseFloat(accountPayable || 0) +
			parseFloat(borrowings || 0) +
			parseFloat(other || 0);
		form.setFieldValue(
			`liability.totalLiabilities[${index}].value`,
			totalLiabilities
		);
		let totalOwnersEquity =
			form.values.liability.totalOwnersEquity[index].value;
		let total = parseFloat(totalOwnersEquity || 0) + totalLiabilities;
		calcuteTotalLiablityAndEquity(index, total);
	};
	const calculateTotalEquity = (index) => {
		let paidInCapital = form.values.liability.paidInCapital[index].value;
		let retainedEarnings = form.values.liability.retainedEarnings[index].value;
		let currentYearNetProfit =
			form.values.liability.currentYearNetProfit[index].value;
		let ownersEquityAccount =
			form.values.liability.ownersEquityAccount[index].value;

		let totalOwnersEquity =
			parseFloat(paidInCapital || 0) +
			parseFloat(retainedEarnings || 0) +
			parseFloat(currentYearNetProfit || 0) +
			parseFloat(ownersEquityAccount || 0);
		form.setFieldValue(
			`liability.totalOwnersEquity[${index}].value`,
			totalOwnersEquity
		);
		let totalLiabilities = form.values.liability.totalLiabilities[index].value;
		let total = parseFloat(totalLiabilities || 0) + totalOwnersEquity;
		calcuteTotalLiablityAndEquity(index, total);
	};

	const calcuteTotalLiablityAndEquity = (index, total) => {
		form.setFieldValue(
			`liability.totalLiabilityAndEquity[${index}].value`,
			total
		);
	};

	return (
		<DialogWrapper
			maxWidth="md"
			state={state}
			onClose={() => {
				onClose(false);
			}}
			centerHeading
			subTitle={t('financialStatementText')}
			title={t('financialStatement')}
			actionFooterClass="!px-0"
			content={
				<Box data-testid="financialStatementPopup">
					<Box className="flex flex-col border rounded-lg p-3">
						<FormikProvider value={form}>
							<Box className="grid grid-cols-1 md:grid-cols-2 gap-4 financial-statement-grid">
								<Box className="flex flex-col space-y-3">
									<Box className="flex flex-row items-center">
										<Box className="w-[50%] item-heading">
											<span>{t('assets')}</span>
											<span className="mx-1 caption">({CURRENCY})</span>
										</Box>
										<Box className="w-[25%] item-heading">
											{FinancialStatementDateHelper().year1}
										</Box>
										<Box className="w-[25%] item-heading">
											{FinancialStatementDateHelper().year2}
										</Box>
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('cashOnHandAtBank')}</Box>
										{form.values.assets.cashOnHandAtBank?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateTotalAssets(i);
															}}
															type="number"
															key={`assets.cashOnHandAtBank[${i}].value`}
															name={`assets.cashOnHandAtBank[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('accountReceivables')}</Box>
										{form.values.assets.accountReceivables?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateTotalAssets(i);
															}}
															type="number"
															key={`assets.accountReceivables[${i}].value`}
															name={`assets.accountReceivables[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('inventory')}</Box>
										{form.values.assets.inventory?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateTotalAssets(i);
															}}
															type="number"
															key={`assets.inventory[${i}].value`}
															name={`assets.inventory[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('other')}</Box>
										{form.values.assets.other?.map((resp, i, { length }) => {
											return (
												<Box
													className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
														}`}
													key={i}>
													<InputWrapper
														hideErrorMessage
														onBlur={(e) => {
															calculateTotalAssets(i);
														}}
														type="number"
														key={`assets.other[${i}].value`}
														name={`assets.other[${i}].value`}
													/>
												</Box>
											);
										})}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('PP&E')}</Box>
										{form.values.assets.ppAndE?.map((resp, i, { length }) => {
											return (
												<Box
													className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
														}`}
													key={i}>
													<InputWrapper
														hideErrorMessage
														onBlur={(e) => {
															calculateTotalAssets(i);
														}}
														type="number"
														key={`assets.ppAndE[${i}].value`}
														name={`assets.ppAndE[${i}].value`}
													/>
												</Box>
											);
										})}
									</Box>
									<Box className="flex flex-row items-center md:!mt-[172px]">
										<Box className="w-[50%] item-heading">
											{t('totalAssets')}
										</Box>
										{form.values.assets.totalAssets?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															readOnly
															classlist="disabled"
															type="number"
															key={`assets.totalAssets[${i}].value`}
															name={`assets.totalAssets[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
								</Box>
								<Box className="flex flex-col space-y-2">
									<Box className="flex flex-row items-center">
										<Box className="w-[50%] item-heading">
											<span>{t('liability')}</span>
											<span className="mx-1 caption">({CURRENCY})</span>
										</Box>
										<Box className="w-[25%] item-heading">
											{FinancialStatementDateHelper().year1}
										</Box>
										<Box className="w-[25%] item-heading">
											{FinancialStatementDateHelper().year2}
										</Box>
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('accountPayable')}</Box>
										{form.values.liability.accountPayable?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateLiabilities(i);
															}}
															type="number"
															key={`liability.accountPayable[${i}].value`}
															name={`liability.accountPayable[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('borrowings')}</Box>
										{form.values.liability.borrowings?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateLiabilities(i);
															}}
															type="number"
															key={`liability.borrowings[${i}].value`}
															name={`liability.borrowings[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('other')}</Box>
										{form.values.liability.other?.map((resp, i, { length }) => {
											return (
												<Box
													className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
														}`}
													key={i}>
													<InputWrapper
														hideErrorMessage
														onBlur={(e) => {
															calculateLiabilities(i);
														}}
														type="number"
														key={`liability.other[${i}].value`}
														name={`liability.other[${i}].value`}
													/>
												</Box>
											);
										})}
									</Box>

									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('totalLiabilities')}</Box>
										{form.values.liability.totalLiabilities?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															readOnly
															classlist="disabled"
															type="number"
															key={`liability.totalLiabilities[${i}].value`}
															name={`liability.totalLiabilities[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box></Box>
									<Box className="flex flex-row items-center">
										<Box className="item-heading">{t('ownersEquity')}</Box>
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('paidInCapital')}</Box>
										{form.values.liability.paidInCapital?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateTotalEquity(i);
															}}
															type="number"
															key={`liability.paidInCapital[${i}].value`}
															name={`liability.paidInCapital[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('retainedEarnings')}</Box>
										{form.values.liability.retainedEarnings?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateTotalEquity(i);
															}}
															type="number"
															key={`liability.retainedEarnings[${i}].value`}
															name={`liability.retainedEarnings[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('currentYearNetProfit')}</Box>
										{form.values.liability.currentYearNetProfit?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateTotalEquity(i);
															}}
															type="number"
															key={`liability.currentYearNetProfit[${i}].value`}
															name={`liability.currentYearNetProfit[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('ownersEquityAccount')}</Box>
										{form.values.liability.ownersEquityAccount?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															onBlur={(e) => {
																calculateTotalEquity(i);
															}}
															type="number"
															key={`liability.ownersEquityAccount[${i}].value`}
															name={`liability.ownersEquityAccount[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('totalOwnersEquity')}</Box>
										{form.values.liability.totalOwnersEquity?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															readOnly
															classlist="disabled"
															type="number"
															key={`liability.totalOwnersEquity[${i}].value`}
															name={`liability.totalOwnersEquity[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box></Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%] item-heading">
											{t('totalliabilityandEquity')}
										</Box>
										{form.values.liability.totalLiabilityAndEquity?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															readOnly
															classlist="disabled"
															type="number"
															key={`liability.totalLiabilityAndEquity[${i}].value`}
															name={`liability.totalLiabilityAndEquity[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box></Box>
									<Box className="flex flex-row items-center">
										<Box className="item-heading md:w-[50%]">
											{t('incomeStatement12Years')}
										</Box>
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('totalRevenue')}</Box>
										{form.values.incomeStatements.totalRevenue?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															type="number"
															key={`incomeStatements.totalRevenue[${i}].value`}
															name={`incomeStatements.totalRevenue[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('totalExpenses')}</Box>
										{form.values.incomeStatements.totalExpenses?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															type="number"
															key={`incomeStatements.totalExpenses[${i}].value`}
															name={`incomeStatements.totalExpenses[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
									<Box className="flex flex-row items-center">
										<Box className="w-[50%]">{t('netProfit')}</Box>
										{form.values.incomeStatements.netProfit?.map(
											(resp, i, { length }) => {
												return (
													<Box
														className={`w-[25%] ${length - 1 === i ? 'mx-1' : ''
															}`}
														key={i}>
														<InputWrapper
															hideErrorMessage
															type="number"
															key={`incomeStatements.netProfit[${i}].value`}
															name={`incomeStatements.netProfit[${i}].value`}
														/>
													</Box>
												);
											}
										)}
									</Box>
								</Box>
							</Box>
						</FormikProvider>
					</Box>
				</Box>
			}
			actions={
				<Box className="flex flex-col">
					<Box className="flex justify-center items-center">
						<ButtonWrapper
							classList="!secondary-btn !mx-2"
							largeBtn
							label={t('close')}
							onClick={() => {
								onClose(false);
							}}
						/>
						<ButtonWrapper
							loading={loadingSubmit}
							largeBtn
							label={t('submit')}
							onClick={() => {
								form.submitForm();
							}}
						/>
					</Box>
					{settings()?.financialInformationExampleFile ? (
						<a
							download={'financial-statement-template.xlsx'}
							target="_blank"
							href={settings()?.financialInformationExampleFile}
							className="underline-link mt-4 !text-[12px] font-[600] text-primary text-center"
							rel="noreferrer">
							{t('downloadFormAsAnExcelFile')}
						</a>
					) : null}
				</Box>
			}></DialogWrapper>
	);
};
