import axios from 'axios';
import { API_KEY } from '../config/appConfig';
import { Toaster } from '../shared/components/common/toaster';
import { CultureHelper } from '../helpers/cultureHelper';
import {
	getExtractedUserFromToken,
	Logout,
	refreshTokenIfNecessary,
	saveToken,
	setUser,
} from './authService';
import { buildLocalizeRoute } from '../helpers/languageHelper';
import { AppRoutes } from '../constants/appRoutes';

let retry = false;

const axiosNew = axios.create({
	baseURL: `${API_KEY}/api/`,
	headers: {
		'Content-Type': 'application/json',
	},
	validateStatus: function (status) {
		if (status === 403 || status === 401) {
			Toaster({ msg: 'Session expired, Please login!' });
			Logout();
			window.location.href = buildLocalizeRoute(AppRoutes.Login);
			return;
		}
		if (status >= 200 && status < 300) {
			return true;
		}

		return false;
	},
});

axiosNew.interceptors.request.use(function (config) {
	config.params = {
		...config.params,
		...{ culture: CultureHelper.currentCulture() },
	};
	const token = localStorage.getItem('token');
	refreshTokenIfNecessary(token);
	config.headers.Authorization = token ? `Bearer ${token}` : '';
	return config;
});

axiosNew.interceptors.response.use(
	(res) => {
		if (
			res.status >= 200 &&
			res.status < 300 &&
			res.config.method !== 'get' &&
			!res.config?.headers?.hide_success_toaster
		) {
			// Toaster({ msg: 'Request Successfully Completed', isError: false });
		}
		return res;
	},
	async (error) => {
		const originalConfig = error.config;
		if (
			originalConfig.url !== buildLocalizeRoute(AppRoutes.Login) &&
			error.response
		) {
			if (error.response.status === 401 && !retry) {
				retry = true;
				try {
					const response = await axiosNew.post(
						'/authentication/refreshtoken',
						null,
						{
							responseType: 'text',
						}
					);
					if (response.status === 401) {
						Toaster({ msg: 'Session expired, Please login!' });
						Logout();
						window.location.href = buildLocalizeRoute(AppRoutes.Login);
					} else if (response) {
						saveToken(response);
						const user = getExtractedUserFromToken();
						setUser(user);
						return axiosNew(originalConfig);
					}
				} catch (_err) {
					return Promise.reject(_err);
				}
			}
		}
		if (
			!error?.config?.headers?.hide_toaster &&
			!error?.response?.data?.UserLockedOut &&
			!error?.response?.data?.loginEmailNotFound
		) {
			Toaster({
				msg:
					formatError(error?.response?.data) ||
					'Something went wrong, request not completed',
			});
		}
		return Promise.reject(error);
	}
);

export const axiosGet = (params, url, id, responseType = '', headers) => {
	let fullUrl = id ? `${url}/${id}` : url;
	return axiosNew.get(fullUrl, {
		params: params,
		headers: headers,
		responseType: responseType,
	});
};

export const axiosPost = (body, url, headers, responseType) => {
	return axiosNew.post(url, body, {
		headers: headers,
		responseType: responseType,
	});
};

export const axiosPut = (body, url, headers, responseType) => {
	return axiosNew.put(url, body, {
		headers: headers,
		responseType: responseType,
	});
};

export const axiosPatch = (
	url,
	body,
	headers,
	responseType,
	onUploadProgress
) => {
	return axiosNew.patch(url, body, {
		headers: headers,
		responseType: responseType,
		onUploadProgress: onUploadProgress,
	});
};

export const axiosDelete = (url, headers, responseType) => {
	return axiosNew.delete(url, {
		headers: headers,
		responseType: responseType,
	});
};

export { axiosNew };

const formatErrorObject = (error) => {
	const keys = Object.keys(error);
	const errors = keys.map((k) => error[k]);
	return errors.join('<br />');
};

const formatError = (error) => {
	if (!error) {
		return 'Something went wrong, request not completed';
	}

	if (typeof error === 'string') {
		let parseValues = JSON.parse(error);
		if (Object.keys(parseValues).length) {
			return formatErrorObject(parseValues);
		}
		return error;
	}

	if (error?.errors && typeof error?.errors == 'object') {
		return formatErrorObject(error?.errors);
	}

	if (typeof error == 'object') {
		return formatErrorObject(error);
	}

	return error;
};
