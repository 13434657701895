import { Typography } from '@mui/material';

export const TextWrapper = ({
	type = 'subtitle1',
	text = '',
	classList = '',
	color = '',
	icon = null,
	children,
	testID = '',
}) => {
	if (type === 'caption') {
		classList = classList + ' caption';
	}
	return (
		<>
			<Typography
				data-testid={testID}
				component={'span'}
				color={color ? color : ''}
				className={`common-text-wrapper  ${classList ? classList : ''}`}
				variant={type}>
				{icon ? icon : null} {text}
			</Typography>
		</>
	);
};
