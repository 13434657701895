/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack } from '@mui/material';
import { Section } from '../../../../shared/components/common/section';
import { lazy, useEffect, useState } from 'react';
import { BecomeProService } from '../../../../services/becomeProService';
import MenuIcon from '@mui/icons-material/Menu';
import { InvestorService } from '../../investorService';
import { BucketService } from '../../../../services/bucketService';
import { EarningsTerm } from '../../../../constants/earningTerms';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';
import { CURRENCY, WEBSITE_URL } from '../../../../config/appConfig';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../constants/appRoutes';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { chartData } from '../../../../helpers/investorChartOptions';
import { InvestorChartTypes } from '../../../../constants/investorChartTypes';
import { CapitalBarGraphData } from '../../../../helpers/investorRepaymentData';
import { DateHelper } from '../../../../helpers/dateHelper';
import { CustomTooltip } from '../../../../shared/components/common/tooltip';
import { BecomeProStatuses } from '../../../../constants/becomeProStatus';
import { useTranslation } from 'react-i18next';
import { LanguageService } from '../../../../services/languageService';
import { SummaryLoader } from '../../../../shared/components/investors/loaders/summaryLoader';
import { RepaymentChartLoader } from '../../../../shared/components/investors/loaders/summaryLoader/repaymentChartLoader';
import { CsvHelper } from '../../../../helpers/csvHelper';
import { saveAs } from 'file-saver';
import i18next from 'i18next';
import { DialogWrapper } from '../../../../shared/components/common/dialogWrapper';
const ReactApexChart = lazy(() => import('react-apexcharts'));

export const InvestorSummary = () => {
	const becomeProService = new BecomeProService();
	const investorService = new InvestorService();
	const bucketService = new BucketService();
	const languageService = new LanguageService();
	const navigate = useNavigate();

	const [proStatus, setProStatus] = useState(null);
	const [netEarnings, setNetEarnings] = useState(null);
	const [activeInvestmentCount, setActiveInvestmentCount] = useState(0);
	const [activeInvestments, setActiveInvestments] = useState([]);
	const [isShowCSVMenu, setShowCSVMenu] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingRepayment, setLoadingRepayment] = useState(true);
	const [repaymentChartOptions, setRepaymentChartOptions] = useState(
		chartData(InvestorChartTypes.RepaymentChart, languageService.isArabic())
	);

	const [summary, setSummary] = useState(null);
	const [showHowWorks, setShowHowWorks] = useState(false);
	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();

	const hasActiveInvestment = () => {
		return activeInvestments?.length && activeInvestmentCount ? true : false;
	};

	const isAr = () => {
		return languageService.isArabic();
	};

	useEffect(() => {
		getInvestorsSummary();
		loadData();
		setRepaymentChartOptions(chartData(InvestorChartTypes.RepaymentChart));
		getBecomeProStatus();
		loadNetEarnings();
	}, []);

	const loadData = async () => {
		setLoading(true);
		try {
			const [activeInvestmentCount] = await Promise.all([
				bucketService.activateInvestmentsCount(),
			]).catch((err) => {
				setLoading(false);
			});
			setLoading(false);
			getActiveInvestments(activeInvestmentCount.data);
		} catch { }
	};

	const getActiveInvestments = (activeInvestmentCount) => {
		setLoadingRepayment(true);
		investorService
			.activateInvestments()
			.then((activeInvestments) => {
				setActiveInvestmentCount(activeInvestmentCount);
				setActiveInvestments(activeInvestments.data);
				setLoadingRepayment(false);
				setTimeout(() => {
					if (activeInvestments.data?.length && activeInvestmentCount) {
						setValueCapitalChart(activeInvestments.data);
					} else {
						setDefaultValueToBarChart();
					}
				});
			})
			.catch((err) => {
				setLoadingRepayment(false);
			});
	};

	const getBecomeProStatus = () => {
		becomeProService
			.getBecomeProStatus()
			.then((resp) => {
				setProStatus(resp.data);
			})
			.catch((err) => { });
	};

	const loadNetEarnings = () => {
		investorService
			.getNetEarnings(EarningsTerm.AllTime)
			.then((resp) => {
				setNetEarnings(resp.data);
			})
			.catch((err) => { });
	};

	const setValueCapitalChart = (activeInvestments = []) => {
		try {
			repaymentChartOptions.options.plotOptions.bar.columnWidth = '60%';
			repaymentChartOptions.series = [
				{
					name: t('principal'),
					data: activeInvestments.map((data) => Math.round(data.principal)),
				},
				{
					name: t('profit'),
					data: activeInvestments.map((data) => Math.round(data.profit)),
				},
			];
			repaymentChartOptions.options.xaxis = {
				categories: activeInvestments.map((data) =>
					DateHelper.formatDate(data.date, 'MMM')
				),
			};
			repaymentChartOptions.options.responsive[0].options.xaxis.categories =
				activeInvestments.map((data) =>
					DateHelper.formatDate(data.date, 'MMM')
				);
			setRepaymentChartOptions({ ...repaymentChartOptions });
		} catch { }
	};

	const onDownloadCSV = () => {
		let series = [];
		if (activeInvestments?.length && activeInvestmentCount) {
			activeInvestments.forEach((data) => {
				series.push({
					month: DateHelper.formatDate(data?.date, 'MMM'),
					profit: data?.profit,
					principal: data?.principal,
					total: data?.profit + data?.principal,
				});
			});
		} else {
			repaymentChartOptions.series[0].data.forEach((resp) => {
				series.push({
					month: resp.x,
					repayments: resp.y,
				});
			});
		}

		const date = DateHelper.formatDate(new Date());
		const fileName = `Repayment-Schedule-${date}.csv`;
		const csv = CsvHelper.serializeArrayToCsv(series);
		const blob = new Blob([csv], {
			type: 'application/json',
		});
		saveAs(blob, fileName);
	};

	// const onDownloadSVG = () => {
	// 	ApexCharts.getChartByID('repaymentChart').exports.exportToSVG();
	// };
	// const onDownloadPNG = () => {
	// 	ApexCharts.getChartByID('repaymentChart').exports.exportToPng();
	// };

	const customDownloadBtn = () => {
		return (
			<Box
				className={`cursor-pointer absolute  ${isAr() ? 'left-0' : 'right-0'}`}>
				<ButtonWrapper
					classList="!bg-white"
					onClick={() => {
						setShowCSVMenu(!isShowCSVMenu);
					}}
					icon={<MenuIcon className={`!text-[20px] !text-[#6e8192]`} />}
					iconButton></ButtonWrapper>
				{isShowCSVMenu ? (
					<Box
						className={`absolute border border-solid text-center p-1 rounded-[4px] min-w-[120px] right-[6px] z-[999] bg-white ${isAr() ? '!right-auto left-[6px]' : ''
							}`}>
						<p
							onClick={() => {
								onDownloadCSV();
								setShowCSVMenu(!isShowCSVMenu);
							}}
							className="p-1 hover:bg-[#eee] !text-[12px] ">
							{t('downloadCSV')}
						</p>
					</Box>
				) : null}
			</Box>
		);
	};

	const setDefaultValueToBarChart = () => {
		let dataLabels = {
			enabled: true,
			enabledOnSeries: [0],
			textAnchor: 'middle',
			formatter(val) {
				return val.toLocaleString('en-us', { maximumFractionDigits: 0 });
			},
			offsetY: -16,
			style: {
				textAlign: 'center',
				fontWeight: 600,
				fontSize: '9px',
				colors: ['black'],
			},
		};
		repaymentChartOptions.options.dataLabels = dataLabels;
		repaymentChartOptions.options.responsive[0].options.dataLabels = {
			...dataLabels,
			offsetY: -14,
			style: '6px',
		};
		repaymentChartOptions.series[0].data =
			CapitalBarGraphData.sortByCurrentDate();
		repaymentChartOptions.series[0].name = t('repayments');
		setRepaymentChartOptions({ ...repaymentChartOptions });
	};

	const getInvestorsSummary = () => {
		investorService
			.getInvestorsSummary()
			.then((resp) => {
				setSummary(resp.data);
			})
			.catch((err) => { });
	};

	const isShowBecomeProBtn = () => {
		return proStatus?.status !== BecomeProStatuses.Approved;
	};

	return (
		<Box>
			{!loading ? (
				<Stack gap={1.5}>
					<Box className={`grid grid-cols-1 gap-3 md:grid-cols-3`}>
						<Section
							classList="p-4"
							children={
								<Box className="flex flex-col h-full justify-between space-y-4">
									<Box>
										<Box className="flex items-center justify-between">
											<TextWrapper
												classList="!font-bold !text-[14px]"
												text={t('totalAssets')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!font-bold !text-primary !text-[14px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.portfolioTotal,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[14px]"
															iconClassList="!text-primary !top-0"
															id="totalAssets"
															text="totalAssetsSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
										<Divider className="!mt-2" />
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('availableFunds')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.availableCash,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="availableFunds"
															text="availableFundsSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('orders')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.ordersAmount,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="orders"
															text="ordersSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('investments')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.outstandingAmount,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="investments"
															text="investmentsSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
									</Box>
									<Box>
										<ButtonWrapper
											onClick={() => {
												navigate(
													buildLocalizeRoute(
														`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`
													)
												);
											}}
											label={t('depositFunds')}
											classList="!min-w-[120px] md:!mt-0 !mt-4 h-[33px]"></ButtonWrapper>
									</Box>
								</Box>
							}></Section>
						<Section
							classList="p-4"
							children={
								<Box className="flex flex-col justify-between space-y-4">
									<Box>
										<Box className="flex items-center justify-between">
											<TextWrapper
												classList="!font-bold !text-[14px]"
												text={t('netYieldExpected')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!font-bold !text-primary !text-[14px]">{`${summary?.netYield}%`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[14px]"
															iconClassList="!text-primary !top-0"
															id="expectedNetYield"
															text="expectedNetYieldSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
										<Divider className="!mt-2" />
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('profitReceived')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															netEarnings?.realisedEarnings,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="profitReceived"
															text="profitReceivedSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('profitForecast')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															netEarnings?.unRealisedEarnings,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="profitForecast"
															text="profitForecastSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('losses')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															netEarnings?.losses,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="losses"
															text="lossesSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px] !font-semibold"
												text={t('totalIncome')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px] !font-semibold">{`${CURRENCY} ${currencyFormatterHelper(
															netEarnings?.netEarnings,
															0
														)}`}</Box>
														<CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="totalIncome"
															text="totalIncomeSummaryInfo"></CustomTooltip>
													</Box>
												}></TextWrapper>
										</Box>
									</Box>
								</Box>
							}></Section>
						<Section
							classList="p-4"
							children={
								<Box className="flex flex-col justify-between space-y-4">
									<Box>
										<Box className="flex items-center justify-between">
											<TextWrapper
												classList="!font-bold !text-[14px]"
												text={t('investments')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!font-bold !text-primary !text-[14px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.outstandingAmount,
															0
														)}`}</Box>
														{/* <CustomTooltip
															iconSizeClass="!text-[14px]"
															iconClassList="!text-primary !top-0"
															id="investments"
															text="investmentsSummaryInfo"></CustomTooltip> */}
													</Box>
												}></TextWrapper>
										</Box>
										<Divider className="!mt-2" />
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('current')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.outstandingAmount,
															0
														)}`}</Box>
														{/* <CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="current"
															text="currentSummaryInfo"></CustomTooltip> */}
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('pastDue1to30')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.pastDue30,
															0
														)}`}</Box>
														{/* <CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="pastDue1to30"
															text="pastDue1to30SummaryInfo"></CustomTooltip> */}
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('pastDue31to90')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.pastDue3090,
															0
														)}`}</Box>
														{/* <CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="pastDue31to90"
															text="pastDue31to90SummaryInfo"></CustomTooltip> */}
													</Box>
												}></TextWrapper>
										</Box>
										<Box className="flex items-center justify-between mt-4">
											<TextWrapper
												classList="!text-[12px]"
												text={t('pastDue90Plus')}
											/>
											<TextWrapper
												text={
													<Box className="flex flex-0 items-center justify-center">
														<Box className="!text-[12px]">{`${CURRENCY} ${currencyFormatterHelper(
															summary?.pastDueMoreThan90,
															0
														)}`}</Box>
														{/* <CustomTooltip
															iconSizeClass="!text-[12px]"
															iconClassList="!top-0"
															id="pastDue90Plus"
															text="pastDue90PlusSummaryInfo"></CustomTooltip> */}
													</Box>
												}></TextWrapper>
										</Box>
									</Box>
									<Box>
										<ButtonWrapper
											onClick={() => {
												navigate(
													buildLocalizeRoute(
														`${AppRoutes.Investor}/${AppRoutes.Marketplace}`
													)
												);
											}}
											label={t('invest')}
											classList="!min-w-[120px] md:!mt-0 !mt-4 h-[33px]"></ButtonWrapper>
									</Box>
								</Box>
							}></Section>
					</Box>
					<Box className="grid grid-cols-1 md:grid-cols-3 gap-3">
						<Section
							classList="md:!col-span-2 p-6"
							children={
								!loadingRepayment ? (
									<>
										<Stack className="w-full relative">
											<Box className="text-start">
												<TextWrapper
													classList="!sub-heading"
													text={
														<Box className="flex flex-0 items-start justify-start">
															<Box>{t('repaymentSchedule')}</Box>
															<CustomTooltip
																iconClassList="!top-[5px]"
																id="repayment"
																text="repaymentScheduleExpectedInfo"></CustomTooltip>
														</Box>
													}></TextWrapper>
											</Box>
											{customDownloadBtn()}
											<ReactApexChart
												height={'300px'}
												options={repaymentChartOptions.options}
												series={repaymentChartOptions.series}
												type="bar"
											/>
											{!hasActiveInvestment() ? (
												<TextWrapper
													type="caption"
													text={t('repaymentScheduleNoData')}></TextWrapper>
											) : null}
										</Stack>
									</>
								) : (
									<RepaymentChartLoader />
								)
							}></Section>
						<Section
							classList="p-6"
							children={
								<Stack
									className="w-full"
									gap={1}>
									<Box className="text-start">
										<TextWrapper
											classList="!sub-heading"
											text={t('quickLinks')}></TextWrapper>
									</Box>
									<Box className="flex flex-col space-y-4 mt-4">
										<Link
											onClick={() => {
												setShowHowWorks(true);
											}}
											className="underline-link !text-start">
											{t('howItWorks')}
										</Link>
										{isShowBecomeProBtn() ? (
											<Link
												to={buildLocalizeRoute(
													`${AppRoutes.Investor}/${AppRoutes.Profile}/${AppRoutes.BecomeAPro}`
												)}
												className="underline-link !text-start">
												<span>{t('upgradeToProfessionalInvestor')}</span>
												<CustomTooltip
													id="becomeProHintText"
													text={t('becomeProHintText', {
														depositRemained: currencyFormatterHelper(
															summary?.depositRemained
														),
														year: currentYear,
													})}></CustomTooltip>
											</Link>
										) : null}
										<Link
											to={buildLocalizeRoute(
												`${AppRoutes.Investor}/${AppRoutes.ITransactionStatements}`
											)}
											className="underline-link !text-start">
											{t('transactionStatements')}
										</Link>
										<Link
											to={buildLocalizeRoute(
												`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.ITransactionHistory}`
											)}
											className="underline-link !text-start">
											{t('viewTransferHistory')}
										</Link>
										<Link
											target='_blank'
											to={`${WEBSITE_URL}${i18next.language}/credit-assessment`}
											className="underline-link !text-start">
											{t('creditScoringProcess')}
										</Link>
										<Link
											target='_blank'
											to={`${WEBSITE_URL}${i18next.language}/statistics`}
											className="underline-link !text-start">
											{t('statistics')}
										</Link>
										<Link
											target='_blank'
											to={`${WEBSITE_URL}${i18next.language}/faq`}
											className="underline-link !text-start">
											{t('faqs')}
										</Link>
										<Link
											target='_blank'
											to={`${WEBSITE_URL}${i18next.language}/contact-us`}
											className="underline-link !text-start">
											{t('contactUs')}
										</Link>
									</Box>
								</Stack>
							}></Section>
					</Box>
				</Stack>
			) : (
				<SummaryLoader></SummaryLoader>
			)}
			{showHowWorks ? <DialogWrapper
				dialogClass='how-it-works-popup'
				titleClass="!w-full !items-center"
				maxWidth="md"
				state={showHowWorks}
				onClose={() => {
					setShowHowWorks(false);
				}}
				title={t('howItWorks')}
				content={
					<Box className="!text-sm text-center">
						<iframe title='how it works' src={`https://www.youtube.com/embed/${isAr() ? '2FeTZsQWWSQ' : '6wlwbc4LhR8'}?autoplay=1&showinfo=0`}
							style={{ border: '0px' }}
							className='md:!min-h-[500px] min-h-[330px]'
							name="howitworks" scrolling="no"
							frameborder="1" marginheight="0px"
							marginwidth="0px" height="100%" width="100%"
							allowFullScreen
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						></iframe>
					</Box>
				}
			></DialogWrapper> : null}
		</Box>
	);
};
