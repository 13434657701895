import { Box, Skeleton } from '@mui/material';
import { Section } from '../../../common/section';

export const AutoInvestLoader = () => {
	const bucketPoints = () => {
		return (
			<Box className="flex flex-0 items-start">
				<Box>
					<Skeleton
						height={'20px'}
						variant="circular"
						sx={{ fontSize: '10px', width: '20px' }}
					/>
				</Box>
				<Box className="mx-2 w-full">
					<Skeleton
						variant="text"
						sx={{ fontSize: '10px', width: '100%' }}
					/>
					<Skeleton
						variant="text"
						sx={{ fontSize: '10px', width: '100%' }}
					/>
					<Skeleton
						variant="text"
						sx={{ fontSize: '10px', width: '100%' }}
					/>
				</Box>
			</Box>
		);
	};

	return (
		<Box className="grid grid-flow-row gap-4">
			<Skeleton
				variant="text"
				sx={{ fontSize: '10px', width: '60%' }}
			/>
			<Box className="flex justify-center items-center my-4">
				<Box className="md:px-4">
					<Box className="grid md:grid-cols-2 grid-cols-1 md:gap-12 gap-6">
						{[1, 2].map((res) => {
							return (
								<Section key={res}
									classList="!p-0 !min-h-[400px] !relative"
									children={
										<Box
											className={`grid grid-flow-row gap-2 md:w-[440px] bucket`}>
											<Box className="flex justify-between">
												<Box className="p-3 flex items-center bucket-heading border-b-2 border-color-primary w-full">
													<Skeleton
														variant="text"
														sx={{ fontSize: '10px', width: '200px' }}
													/>
												</Box>
												<Box className="p-3 flex flex-col items-center bucket-roi min-w-[120px]">
													<p className="text-[20px] font-bold">
														<Skeleton
															variant="text"
															sx={{ fontSize: '15px', width: '50px' }}
														/>
													</p>

													<p className="!text-[11px]">
														<Skeleton
															variant="text"
															sx={{ fontSize: '10px', width: '100px' }}
														/>
													</p>
												</Box>
											</Box>
											<Box className="bucket-body p-3 grid grid-flow-row gap-12">
												{bucketPoints()}
												{bucketPoints()}
												{bucketPoints()}
											</Box>
											<Box
												className={`bucket-footer flex justify-center items-center md:absolute bottom-0 w-full p-4`}>
												<Skeleton
													variant="text"
													sx={{ fontSize: '2rem', width: '60%' }}
												/>
											</Box>
										</Box>
									}></Section>
							);
						})}
					</Box>
				</Box>
			</Box>
			<Section
				children={
					<Box className="flex flex-0 md:flex-row flex-col items-center">
						<span className="mb-4 md:mb-0 w-full">
							<Skeleton
								variant="text"
								sx={{ fontSize: '10px', width: '100%' }}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: '10px', width: '100%' }}
							/>
						</span>
					</Box>
				}></Section>
			<Section
				children={
					<Box className="flex flex-0 md:flex-row flex-col items-center">
						<span className="mb-4 md:mb-0 w-full">
							<Skeleton
								variant="text"
								sx={{ fontSize: '10px', width: '100%' }}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: '10px', width: '100%' }}
							/>
						</span>
					</Box>
				}></Section>
		</Box>
	);
};
