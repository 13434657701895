import { axiosGet, axiosPatch } from '../../../../services/axiosService';

const basePortfolioURL = 'portfolio';
export class PortfolioService {
	getPortfolioSummary() {
		return axiosGet(null, basePortfolioURL + '/summary');
	}

	getPortfolioLoans(currentPage = 1, perPage = 10) {
		return axiosGet(
			null,
			basePortfolioURL +
				`/loans?Paging.CurrentPage=${currentPage}&Paging.PerPage=${perPage}`
		);
	}

	getPortfolioLoanParts() {
		return axiosGet(null, basePortfolioURL + '/loanparts');
	}

	getPortfolioPastLoans(currentPage = 1, perPage = 10) {
		return axiosGet(
			null,
			basePortfolioURL +
				`/pastLoans?Paging.CurrentPage=${currentPage}&Paging.PerPage=${perPage}`
		);
	}

	getOrders() {
		return axiosGet(null, basePortfolioURL + '/orders');
	}

	getComments(currentPage = 1, perPage = 3) {
		return axiosGet(
			null,
			basePortfolioURL +
				`/comments?Paging.CurrentPage=${currentPage}&Paging.PerPage=${perPage}`
		);
	}

	getPastComments(currentPage = 1, perPage = 3) {
		return axiosGet(
			null,
			basePortfolioURL +
				`/PastComments?Paging.CurrentPage=${currentPage}&Paging.PerPage=${perPage}`
		);
	}

	cancelAutoInvestment(loanId) {
		return axiosPatch(basePortfolioURL + `/orders/${loanId}`, {});
	}

	exportPastLoans() {
		return axiosGet(null, basePortfolioURL + '/Export/PastLoans', null, 'blob');
	}

	exportActiveLoans() {
		return axiosGet(null, basePortfolioURL + '/Export/Loans', null, 'blob');
	}
}
