/* eslint-disable no-unused-vars */
import { Box, Skeleton } from '@mui/material';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { CURRENCY, IS_SA } from '../../../../../config/appConfig';
import { FormikProvider, useFormik } from 'formik';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { useRef, useState } from 'react';
import { TransferService } from '../../../../../services/transferService';
import { useEffect } from 'react';
import DepositSuccess from './depositSuccess';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { transferSchema } from '../../../../../shared/validations/schema';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import { FormHelper } from '../../../../../helpers/formHelper';
import { InvestorTransferHistory } from '../history';
import { FSBankDetails } from '../../../../../shared/components/fsBankDetails';

export const InvestorDeposit = () => {
	const systemSettingsService = new SystemSettingsService();
	const getTransactions = useRef(null);
	const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);
	const [showReviewScreen, setShowReviewScreen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [transferId, setTransferId] = useState(searchParams.get('tId'));
	const [userBankDetails, setUserBankDetails] = useState();
	const [showOTPAuth, setShowOTPAuth] = useState(false);
	const [loading, setLoading] = useState(true);
	const [forAutoInvestment, setForAutoInvestment] = useState(
		searchParams.get('forAutoInvestment')
	);

	const [isNotification, setIsNotification] = useState(
		searchParams.get('isNotificationTransaction')
	);

	const navigate = useNavigate();
	const transferService = new TransferService();
	const { t } = useTranslation();
	const confirm = useConfirm();
	const form = useFormik({
		initialValues: { amount: searchParams.get('amount') || '' },
		validationSchema: transferSchema,
		onSubmit: () => {
			if (settings()?.enableDeposits) {
				setIsLoadingDeposit(true);
				transferService
					.addTransferIn(form.values.amount)
					.then((resp) => {
						setIsLoadingDeposit(false);
						if (resp.data?.otpRequested) {
							setTransferId(resp?.data?.correlationId || null);
							setTimeout(() => {
								setShowOTPAuth(true);
							});
						} else {
							showReceiptScreen(resp?.data?.correlationId);
						}
					})
					.catch((err) => {
						setIsLoadingDeposit(false);
					});
			} else {
				confirm({
					description: t('hasPausedAcceptingDeposits'),
					title: 'We Paused Deposits!',
					confirmationButtonProps: {
						className: '!primary-bg !text-white',
					},
					hideCancelButton: 'true',
					confirmationText: t('ok'),
				})
					.then(() => {})
					.catch(() => console.log('cancelled.'));
			}
		},
		...FormHelper.formikProps(),
	});

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	useEffect(() => {
		setForAutoInvestment(searchParams.get('forAutoInvestment'));
		setIsNotification(searchParams.get('isNotificationTransaction'));
		setTransferId(searchParams.get('tId'));
		getUserBankDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (form.values.amount && transferId) {
			setShowReviewScreen(true);
		}
	}, [form.values.amount, transferId]);

	useEffect(() => {
		if (isNotification === 'true') {
			setTimeout(() => {
				document
					.getElementById('isNotification')
					.scrollIntoView({ behavior: 'smooth' });
			}, [600]);
		}
	}, [isNotification]);

	const getUserBankDetails = () => {
		setLoading(true);
		transferService
			.getUserBankDetails()
			.then((resp) => {
				setUserBankDetails(resp.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const showReceiptScreen = (transferId = null) => {
		if (transferId) {
			setTransferId(transferId);
		}
		setShowReviewScreen(true);
		getTransactions?.current();
		navigate(
			{
				pathname: buildLocalizeRoute(
					`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`
				),
				search: createSearchParams({
					tId: transferId,
					amount: form.values.amount,
				}).toString(),
			},
			{ relative: `path` }
		);
	};

	const onOTPClose = (value) => {
		setShowOTPAuth(false);
		if (value) {
			showReceiptScreen();
		}
	};

	const onDoneTransaction = () => {
		if (forAutoInvestment) {
			confirm({
				description: t('addFundsForAutoInvestmentDialogText'),
				title: t('congratulations'),
				confirmationButtonProps: {
					className: '!primary-bg !text-white',
				},
				hideCancelButton: 'true',
				confirmationText: t('ok'),
			})
				.then(() => {
					navigate(
						buildLocalizeRoute(
							`${AppRoutes.Investor}/${AppRoutes.InvestmentSettings}`
						)
					);
				})
				.catch(() => console.log('cancelled.'));
		} else {
			navigate(
				buildLocalizeRoute(`${AppRoutes.Investor}/${AppRoutes.Marketplace}`)
			);
		}
	};

	const getLoader = () => {
		return (
			<Skeleton
				variant="text"
				sx={{ fontSize: '10px', width: '100px' }}
			/>
		);
	};

	return (
		<Box className="flex flex-col space-y-4">
			<Box className="grid md:grid-cols-2 grid-cols-1 gap-4">
				<SectionWithHeading
					classList="!h-auto"
					heading={
						<Box className="flex items-center">
							<span className="mx-2">
								{!showReviewScreen
									? t('depositNotification')
									: t('depositRequestReceived')}
							</span>
						</Box>
					}
					children={
						!showReviewScreen ? (
							<Box className="grid grid-flow-row gap-6">
								<Box>
									<TextWrapper
										classList="caption !text-sm"
										text={t('pleaseEnterTransferAmount')}></TextWrapper>
									<FormikProvider value={form}>
										<Box className="mt-3 flex flex-0 md:w-[380px]">
											<InputWrapper
												type="number"
												label={`${t('amount')} ${CURRENCY}`}
												key={'amount'}
												name="amount"></InputWrapper>
											<ButtonWrapper
												onClick={() => {
													form.submitForm();
												}}
												loading={isLoadingDeposit}
												icon={
													<NotificationsNoneOutlinedIcon></NotificationsNoneOutlinedIcon>
												}
												classList="!min-w-[130px] !mt-[22px] !mx-3"
												label={t('notify')}></ButtonWrapper>
										</Box>
									</FormikProvider>
								</Box>
								<Box className="flex flex-col flex-0 justify-between h-full">
									<TextWrapper
										classList="!text-lg !font-[700]"
										text={t('importantInfo')}></TextWrapper>
									<Box className="grid grid-flow-row gap-3 mt-3">
										<Box>
											<TextWrapper
												classList="caption !text-sm"
												text={t(
													'depositMoneyIntoFundinSouqAccountText'
												)}></TextWrapper>
										</Box>
										<Box className="grid grid-flow-row gap-3">
											<Box className="flex flex-0 items-start">
												<CheckCircleOutlineOutlinedIcon
													className="!text-[20px]"
													color="primary"></CheckCircleOutlineOutlinedIcon>
												<TextWrapper
													classList="caption !text-sm !mx-1"
													text={t(
														'depositMoneyIntoFundinSouqAccountText1'
													)}></TextWrapper>
											</Box>
											<Box className="flex flex-0 items-start">
												<CheckCircleOutlineOutlinedIcon
													className="!text-[20px]"
													color="primary"></CheckCircleOutlineOutlinedIcon>
												<TextWrapper
													classList="caption !text-sm !mx-1"
													text={t(
														'depositMoneyIntoFundinSouqAccountText2'
													)}></TextWrapper>
											</Box>
											<Box className="flex flex-0 items-start">
												<CheckCircleOutlineOutlinedIcon
													className="!text-[20px]"
													color="primary"></CheckCircleOutlineOutlinedIcon>
												<TextWrapper
													classList="caption !text-sm !mx-1"
													text={
														IS_SA
															? t('yourFundsAreSecurelyANB')
															: t('yourFundsAreSecurely')
													}></TextWrapper>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						) : (
							<DepositSuccess
								onDoneTransaction={() => {
									onDoneTransaction();
								}}
								onCancel={() => {
									getTransactions?.current();
									setTransferId(null);
									setShowReviewScreen(false);
									navigate(
										{
											pathname: buildLocalizeRoute(
												`${AppRoutes.Investor}/${AppRoutes.ITransfer}/${AppRoutes.IDeposit}`
											),
										},
										{ relative: `path` }
									);
								}}
								amount={form.values.amount}
								transferId={transferId}
								userBankDetails={userBankDetails}></DepositSuccess>
						)
					}></SectionWithHeading>
				<SectionWithHeading
					classList="!h-auto"
					bodyClass="!h-full"
					heading={
						<Box className="flex items-center">
							<span className="mx-2">{t('depositInfo')}</span>
						</Box>
					}
					children={
						<Box className="flex flex-col space-y-4">
							<Box className="grid grid-flow-row gap-3">
								<TextWrapper
									classList="!text-lg !font-[700]"
									text={t('yourDepositReference')}></TextWrapper>
								<Box className="grid grid-cols-3  items-center">
									<TextWrapper
										classList="!caption !text-sm"
										text={t('reference')}></TextWrapper>
									<TextWrapper
										classList="!font-bold !text-sm md:!col-span-2 !mx-2"
										text={
											!loading ? userBankDetails?.reference : getLoader()
										}></TextWrapper>
								</Box>
								<Box className="grid grid-cols-3 items-center">
									<TextWrapper
										classList="!caption !text-sm"
										text={t('bankName')}></TextWrapper>
									<TextWrapper
										classList="!font-bold !text-sm md:!col-span-2 !mx-2"
										text={
											!loading ? userBankDetails?.bankName : getLoader()
										}></TextWrapper>
								</Box>
								<Box className="grid grid-cols-3 items-center">
									<TextWrapper
										classList="!caption !text-sm"
										text={t('accountName')}></TextWrapper>
									<TextWrapper
										classList="!font-bold !text-sm md:!col-span-2 !mx-2"
										text={
											!loading ? userBankDetails?.accountName : getLoader()
										}></TextWrapper>
								</Box>
							</Box>
							<FSBankDetails></FSBankDetails>
						</Box>
					}></SectionWithHeading>
			</Box>
			<Box id="isNotification">
				<InvestorTransferHistory
					getTransactions={getTransactions}
					// type={TransactionTypes.Deposit}
				/>
			</Box>
			{showOTPAuth ? (
				<OTPAuthenticationDialog
					type={OTPTypes.Transaction}
					state={showOTPAuth}
					corrolationId={transferId}
					onClose={(e) => {
						onOTPClose(e);
					}}></OTPAuthenticationDialog>
			) : null}
		</Box>
	);
};
