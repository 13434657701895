/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, CircularProgress, Skeleton } from '@mui/material';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { useTranslation } from 'react-i18next';
import NafathLogo from '../../../../assets/images/Nafath_logo.svg';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { SAMLService } from '../../../../services/samlService';
import { useEffect, useState } from 'react';
import {
	getRegistrationData,
	getUser,
	saveTokenFromRegistration,
	setRegistrationData,
	updateBorrowerSteps,
	updateInvestorSteps,
} from '../../../../services/authService';
import { IS_SA, OTP_TIMEOUT } from '../../../../config/appConfig';
import { useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../helpers/languageHelper';
import { AppRoutes } from '../../../../constants/appRoutes';
import { Subject, concatMap, interval, takeUntil } from 'rxjs';
import { Toaster } from '../../../../shared/components/common/toaster';
import { LocalStorageKey } from '../../../../constants/localStorageKeys';
import { ButtonWrapper } from '../../../../shared/components/formUtilities/useButton';
import { Roles } from '../../../../constants/roles';

export const NafathVerification = ({
	isLoading = false,
	skipSAMLVerification = false,
	isSA = IS_SA,
}) => {
	const samlService = new SAMLService();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [resendCounter, setResendCounter] = useState(0);
	const [authNumber, setAuthNumber] = useState(null);
	const [loading, setLoading] = useState(isLoading);
	const [loadingNafath, setLoadingNafath] = useState(false);
	const [stopTimer, setStopTimer] = useState(false);
	const counter = OTP_TIMEOUT;

	useEffect(() => {
		resendCounter > 0 &&
			setTimeout(() => setResendCounter(resendCounter - 1), 1000);
	}, [resendCounter]);

	useEffect(() => {
		getSAMLNumber();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		resendCounter > 0 &&
			setTimeout(() => setResendCounter(resendCounter - 1), 1000);
	}, [resendCounter]);

	const onResendCode = () => {
		setResendCounter(counter);
		getSAMLNumber();
	};

	useEffect(() => {
		if (skipSAMLVerification) {
			let response = {
				isNafathCompleted: true,
				loginModel: {
					token: '',
					investorStep: 2,
					borrowerStep: 2,
				},
			};
			afterVerification(response);
			return;
		} else {
			const stopTimer$ = new Subject();
			const subscription = interval(3000)
				.pipe(
					takeUntil(stopTimer$),
					takeUntil(interval(300000)),
					concatMap(async () => await samlService.isUserVerified())
				)
				.subscribe({
					next: (resp) => {
						if (resp.data?.isNafathCompleted) {
							afterVerification(resp?.data);
							subscription.unsubscribe();
							stopTimer$.next();
							stopTimer$.complete();
						}
					},
					error: (err) => {
						subscription.unsubscribe();
					},
					complete: () => {
						setStopTimer(true);
						subscription.unsubscribe();
						stopTimer$.next();
						stopTimer$.complete();
					},
				});

			return () => {
				if (stopTimer) {
					stopTimer$.next();
					stopTimer$.complete();
				}
				if (subscription) {
					subscription.unsubscribe();
				}
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authNumber]);

	const afterVerification = (response) => {
		Toaster({ msg: 'Successfully Verified', isError: false });
		localStorage.setItem(LocalStorageKey.nafathVerified, '1');
		let loginModel = response?.loginModel;
		saveTokenFromRegistration(loginModel?.token);
		const user = getUser();
		if (user?.role === Roles.Investor) {
			let regData = {
				...getRegistrationData(),
				step: loginModel?.investorStep,
				requestedStep: 1,
			};
			setRegistrationData(regData);
			updateInvestorSteps(loginModel?.investorStep);
			navigate(
				buildLocalizeRoute(
					`${AppRoutes.InvestorRegistration}/${AppRoutes.IRegPersonalInfo}`
				)
			);
		} else if (user?.role === Roles.Borrower) {
			let regData = {
				...getRegistrationData(),
				step: loginModel.borrowerStep,
				requestedStep: 0,
			};
			setRegistrationData(regData);
			updateBorrowerSteps(loginModel.borrowerStep);
			let route = isSA
				? AppRoutes.CommercialRegistration
				: AppRoutes.BRegPersonalInfo;
			navigate(
				buildLocalizeRoute(`${AppRoutes.BorrowerRegistartion}/${route}`)
			);
		}
	};

	const getSAMLNumber = () => {
		setLoading(true);
		let nationalID = getRegistrationData()?.nationalId || '';
		samlService
			.getAuthNumber(nationalID)
			.then((resp) => {
				let loginModel = resp.data?.loginModel;
				saveTokenFromRegistration(loginModel?.token);
				setAuthNumber(resp.data?.number);
				setLoading(false);
			})
			.catch((err) => {
				setResendCounter(0);
				setLoading(false);
			});
	};

	const onManuallyVerify = () => {
		setLoadingNafath(true);
		samlService
			.isUserVerified(true)
			.then((resp) => {
				if (resp.data) {
					setLoadingNafath(false);
					afterVerification(resp.data);
				}
			})
			.catch((err) => {
				setLoadingNafath(false);
			});
	};

	return (
		<Box className="flex flex-col justify-center items-center reg-section-height">
			<Box className="md:w-[450px] w-full p-6 flex flex-col justify-center items-center shadow-md rounded-md">
				<Box className="max-w-[100px] w-[100px] h-[100px]">
					<img
						src={NafathLogo}
						alt="nafathlogo"
						className="w-full h-full"></img>
				</Box>
				<Box className="flex flex-col items-center my-4">
					<TextWrapper
						classList="sub-heading !text-center"
						text={t('samlInfoText')}
					/>
					<Box className="rounded-full mt-4 text-primary relative flex justify-center items-center border w-[74px] h-[74px] font-bold text-[28px]">
						{loading ? (
							<CircularProgress className="!w-[74px] !h-[74px] !absolute" />
						) : null}
						<span className="!absolute">
							{!loading ? (
								<span data-testid="authNumber">{authNumber}</span>
							) : (
								<Skeleton
									variant="circular"
									className="!w-[30px] !h-[30px]"></Skeleton>
							)}
						</span>
					</Box>
				</Box>
				<Box className="flex justify-center items-center mb-4">
					{!stopTimer ? (
						<Box className="flex justify-center items-center">
							<CircularProgress className="!h-[14px] !w-[14px]" />
							<Box className="w-[6px]"></Box>
							<Box>
								<TextWrapper
									classList="caption !text-sm !text-center"
									text={t('awaitingResponse')}
								/>
							</Box>
						</Box>
					) : (
						<Box className="flex justify-center rounded-md items-center my-[10px] p-[5px] bg-[#ff00000f]">
							<Box className="!text-center">
								<TextWrapper
									classList="caption !text-sm "
									text={t('nafathNoResponse')}
								/>
							</Box>
						</Box>
					)}
				</Box>
				<Box className="flex justify-center items-center !text-[14px] mb-4">
					{resendCounter === 0 ? (
						<Box className="text-center flex justify-center items-center">
							<RestartAltOutlinedIcon
								color="primary"
								className="!text-[20px]"
							/>
							<a
								data-testid="resendcode"
								onClick={() => {
									onResendCode();
								}}
								className={`underline-link ${
									loading ? 'pointer-events-none cursor-default' : ''
								}`}>
								{t('resendCode')}
							</a>
						</Box>
					) : (
						<span data-testid="sentResendcode">
							{t('resendCodeAfter')}&nbsp;{resendCounter}&nbsp;{t('seconds')}
						</span>
					)}
				</Box>
				{stopTimer ? (
					<ButtonWrapper
						loading={loadingNafath}
						largeBtn
						label={t('verify')}
						onClick={() => {
							onManuallyVerify();
						}}
					/>
				) : null}
			</Box>
		</Box>
	);
};
