import { CURRENCY } from '../../../../../config/appConfig';
import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';
import { DateHelper } from '../../../../../helpers/dateHelper';

export const initializeColumns = (t) => {
	return [
		{
			header: t('date'),
			accessorKey: 'date',
			Cell: ({ cell }) => {
				return DateHelper.formatDate(cell?.getValue(), 'MMM dd, yyyy hh:mm');
			},
		},

		{
			header: `${t('amount')} (${CURRENCY})`,
			accessorKey: 'receivedAmount',
			Cell: ({ cell }) => {
				return currencyFormatterHelper(cell?.getValue());
			},
		},

		{
			header: t('status'),
			accessorKey: 'status',
		},
	];
};
