import i18next from 'i18next';
import { RegionService } from '../services/regionService';

export const buildLocalizeRoute = (
	path,
	region = null,
	removeSlash = false,
	language = null
) => {
	const regionService = new RegionService();
	return `${removeSlash ? '' : '/'}${
		region ? region : regionService.currentRegion()
	}/${language ? language : i18next.language || 'en'}/${path}`;
};
