import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import Router from './router/router';
import { Suspense, useEffect } from 'react';
import { LanguageService } from './services/languageService';
import { SystemSettingsService } from './services/systemSettingsService';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { RegionTypes } from './config/regions';
import { Provider } from 'react-redux';
import { Store } from './redux/store';

// Create rtl cache

export const App = () => {
	const languageService = new LanguageService();
	const systemSettingsService = new SystemSettingsService();

	const cacheRtl = createCache({
		key: 'muirtl',
		stylisPlugins: languageService.isArabic() ? [prefixer, rtlPlugin] : [],
	});

	useEffect(() => {
		if (!localStorage.getItem('region')) {
			localStorage.setItem('region', RegionTypes.Global);
		}
		languageService.handleWrongLanguage();
		systemSettingsService
			.getAllFront()
			.then((resp) => {})
			.catch((resp) => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const theme = createTheme({
		direction: languageService.isArabic() ? 'rtl' : 'ltr',
		palette: {
			primary: {
				main: '#33CD87',
			},
			secondary: {
				main: '#7850F2',
			},
		},
	});
	return (
		<Provider store={Store}>
			<Suspense fallback={null}>
				<CacheProvider value={cacheRtl}>
					<ThemeProvider theme={theme}>
						<ConfirmProvider>
							<main className="App">
								<Router></Router>
							</main>
						</ConfirmProvider>
					</ThemeProvider>
				</CacheProvider>
			</Suspense>
		</Provider>
	);
};
