import { Box, Paper } from '@mui/material';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { AppRoutes } from '../../../../constants/appRoutes';
import { useTranslation } from 'react-i18next';

export const BorrowerTransfers = () => {
	const { t } = useTranslation();
	return (
		<Box className="flex flex-0 justify-center items-center md:h-[80vh]">
			<Box
				component={Paper}
				className="md:w-[400px] w-full flex justify-center hover:shadow-2xl">
				<Box className="flex flex-col items-center justify-between">
					<Box className="flex flex-col items-center justify-center h-[500px] p-4">
						<AccountBalanceOutlinedIcon
							className="!text-[130px] "
							color="primary"></AccountBalanceOutlinedIcon>
						<TextWrapper
							classList="!info-text !text-center !mt-4"
							text={t('takes13WorkingDays')}></TextWrapper>
						<TextWrapper
							classList="!text-2xl !font-[600]"
							text={t('bankTransfer')}></TextWrapper>
						<TextWrapper
							classList="!info-text !text-center !mt-4"
							text={t('TransfermoneyFromBankText')}></TextWrapper>
					</Box>
					<Box
						component={'a'}
						href={AppRoutes.BBankTransfer}
						className="w-full p-2 flex justify-center items-center primary-bg cursor-pointer">
						<Box className="flex items-center justify-center">
							<RemoveRedEyeOutlinedIcon className="!text-white mx-1"></RemoveRedEyeOutlinedIcon>
							<TextWrapper
								text={t('ViewBankDeatils')}
								classList="!text-white"></TextWrapper>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
