import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InvestorStepperContext } from '../../../../context/investorStepperContext';
import {
	getRegistrationData,
	setRegistrationData,
} from '../../../../services/authService';
import { TextWrapper } from '../../../../shared/components/formUtilities/useTextWrapper';
import { InvestorRegService } from '../../investor/investorRegService';

export const InvestorTypeHeader = ({
	children,
	onSelectInvestorType = () => {},
	disabled = false,
	isBack = false,
}) => {
	const investorRegService = new InvestorRegService();
	const [activeType, setActiveType] = useState(null);
	const [tempType, setTempType] = useState(null);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { handleStepper, investorType } = useContext(InvestorStepperContext);
	const { investorCollections } = useSelector(
		(state) => state.investorCollectionReducer
	);
	const investorTypes = investorCollections?.investorTypeCollection || [];

	useEffect(() => {
		onSetActiveType();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investorTypes]);

	useEffect(() => {
		if (activeType) {
			setTempType(activeType);
		}
	}, [activeType]);

	useEffect(() => {
		if (isBack) {
			onSelectActiveType(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isBack]);

	const onSetActiveType = () => {
		onSelectActiveType(
			investorTypes.find((resp) => resp?.name === investorType)
		);
	};

	const onSelectType = (type) => {
		if (disabled) {
			return;
		}
		if (activeType?.id === type?.id) {
			return;
		}
		onSelectActiveType(type, true);
	};

	const onSelectActiveType = (type, updateAPI = false) => {
		let regData = {
			...getRegistrationData(),
			investorType: type?.name,
		};
		setRegistrationData(regData);
		setActiveType(type);
		onSelectInvestorType(type);
		if (type?.name) {
			handleStepper(type?.name);
		}
		if (updateAPI) {
			setLoading(true);
			investorRegService
				.updateInvestorType(type?.id)
				.then((resp) => {
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	};

	return (
		<>
			{investorTypes?.length ? (
				<Box>
					{!activeType ? (
						<Box
							className={`investor-reg-box-section reg-section-height !h-auto md:!mt-[90px] md:flex md:justify-center md:items-center`}>
							<TextWrapper
								classList="!font-[500] !mb-3"
								text={t('whichTypeInvestorYouAre')}></TextWrapper>
							<Box className="md:flex md:justify-center md:items-center md:w-auto w-full mt-4 mb-8">
								<Box
									className={`md:grid md:grid-cols-3 md:gap-6 flex flex-col items-center justify-center md:space-y-0 space-y-4`}>
									{investorTypes.map((resp, index) => {
										return (
											<Box
												onClick={() => {
													onSelectType(resp);
												}}
												className={`p-4 investor-type-box !items-start md:w-auto w-[80%] relative cursor-pointer text-center tab-header-shadow rounded-md bg-white md:min-w-[200px] ${
													tempType?.id === resp?.id ? 'active-header' : ''
												} ${disabled || loading ? '!pointer-events-none' : ''}`}
												key={index}>
												<Box
													className={`flex relative flex-col items-center md:!text-[16px] !text-[12px]`}>
													<Box
														data-testid={resp?.name?.toLocaleLowerCase()}>{`${t(
														resp?.name?.toLocaleLowerCase()
													)}`}</Box>
													<Box className="relative !text-end">
														{t('investor')}{' '}
														{/* <CustomTooltip
															iconSizeClass="!text-[13px]"
															iconClassList="!absolute !top-[1px] type-tooltip"
															tooltipClass="md:!max-w-[200px]"
															id={`investorTypeInfo${resp?.name?.toLocaleLowerCase()}`}
															text={t(
																`investorTypeInfo${resp?.name?.toLocaleLowerCase()}`
															)}></CustomTooltip> */}
													</Box>
													<small
														className={`caption text-[10px] mt-2 font-[400]`}>
														{t(
															`investorTypeInfo${resp?.name?.toLocaleLowerCase()}`
														)}
													</small>
												</Box>
												{tempType?.id === resp?.id ? (
													!loading ? (
														<CheckCircleIcon className="header-active-icon" />
													) : (
														<CircularProgress className="header-active-icon p-[1px] !h-[20px] !w-[20px]" />
													)
												) : null}
											</Box>
										);
									})}
								</Box>
							</Box>
						</Box>
					) : null}
					{activeType ? <Box>{children}</Box> : null}
				</Box>
			) : (
				<Box className="flex justify-center items-center reg-section-height">
					<CircularProgress />
				</Box>
			)}
		</>
	);
};
