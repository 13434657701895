import { Box, Skeleton } from '@mui/material';

export const SummaryLoader = () => {
	return (
		<Box className="flex flex-col flex-0 justify-between md:!min-h-[200px]">
			<Box className="flex justify-between items-center">
				<Box>
					<Box className="flex flex-row flex-0 items-baseline">
						<Skeleton
							variant="text"
							className="w-[100px]"
						/>
					</Box>
					<Box className="flex justify-start">
						<Skeleton
							variant="text"
							sx={{ fontSize: '1rem', width: '80px' }}
						/>
					</Box>
				</Box>
				<Box>
					<Box className="flex flex-row flex-0 items-baseline">
						<Skeleton
							variant="text"
							className="w-[100px]"
						/>
					</Box>
					<Box className="flex justify-end">
						<Skeleton
							variant="text"
							sx={{ fontSize: '1rem', width: '80px' }}
						/>
					</Box>
				</Box>
			</Box>
			<Box className="mt-6">
				<Box className="flex flex-0 mb-2 items-center !caption !text-sm">
					<span>
						<Skeleton
							variant="text"
							sx={{ fontSize: '1rem', width: '80px' }}
						/>
					</span>
				</Box>
				<Box
					className={`grid gap-1 relative`}
					sx={{
						gridTemplateColumns: `repeat(7,minmax(0,1fr))`,
					}}>
					{[1, 2, 3, 4, 5, 6, 7].map((payment, index) => {
						return (
							<Box key={index}
								className={`h-16 relative cursor-pointer bar-item bg-[#e4e4e4]`}>
								<Box className="absolute shadow-sm rounded-lg top-[70px] !primary-bg hidden text-center bar-item-hint p-2">
									<p className="caption text-xs text-white">
										<Skeleton
											variant="text"
											sx={{ fontSize: '1rem', width: '80px' }}
										/>
									</p>
									<p>
										<Skeleton
											variant="text"
											sx={{ fontSize: '1rem', width: '80px' }}
										/>
									</p>
									<p className="caption text-xs text-white">
										<Skeleton
											variant="text"
											sx={{ fontSize: '1rem', width: '80px' }}
										/>
									</p>
								</Box>
							</Box>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
};
