/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Stack } from '@mui/material';
import { DialogWrapper } from '../../../shared/components/common/dialogWrapper';
import { TextWrapper } from '../../../shared/components/formUtilities/useTextWrapper';
import { ButtonWrapper } from '../../../shared/components/formUtilities/useButton';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import OTPImage from '../../../assets/images/one-time-p.png';
import CallImage from '../../../assets/images/call.png';
import { OTPTypes } from '../../../constants/otpTypes';
import { InternationalPhone } from '../../../shared/components/formUtilities/useInternationalPhone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { getUser, loginUsingToken } from '../../../services/authService';
import OTPInput from 'otp-input-react';
import { useState } from 'react';
import { OtpService } from '../../../services/otpService';
import { useEffect } from 'react';
import { OTP_TIMEOUT } from '../../../config/appConfig';
import { FormikProvider, useFormik } from 'formik';
import { OTPAuthenticationSchema } from '../../../shared/validations/schema';
import { FormHelper } from '../../../helpers/formHelper';
import { Toaster } from '../../../shared/components/common/toaster';
import { useTranslation } from 'react-i18next';

export const OTPAuthenticationDialog = ({
	state,
	onClose = (e) => {},
	isUnAuth = false,
	roles = [],
	userEmail = '',
	corrolationId = '',
	type = '',
}) => {
	const otpService = new OtpService();
	const [OTP, setOTP] = useState('');
	const [loading, setLoading] = useState(false);
	const [counter, setCounter] = useState(0);
	const [mobileNumber, setMobileNumber] = useState(null);
	const [showChangeMobile, setShowChangeMobile] = useState(false);
	const { t } = useTranslation();
	const user = getUser();
	const form = useFormik({
		initialValues: {
			contactNumber: '',
		},
		validationSchema: OTPAuthenticationSchema,
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		onSendOTP();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	const onVerify = (form) => {
		setLoading(true);
		if (!isUnAuth) {
			otpService
				.verifyOTP(form)
				.then((resp) => {
					afterSuccessOTP(resp.data);
					Toaster({ msg: t('otpVerficationSuccess'), isError: false });
				})
				.catch((err) => {});
		} else {
			form['UserEmail'] = userEmail;
			otpService
				.unAuthVerifyOTP(form)
				.then((resp) => {
					afterSuccessOTP(resp.data);
				})
				.catch((err) => {});
		}
	};

	const isApproved = () => {
		return !isUnAuth ? user?.isApproved : true;
	};

	const sendOTP = () => {
		if (isUnAuth) {
			return otpService.unAuthSendOTP(corrolationId, type, userEmail);
		} else {
			return otpService.sendOTP(corrolationId, type);
		}
	};

	const onSendOTP = () => {
		sendOTP()
			.then((resp) => {
				const phone = `${resp?.data?.phoneNumber?.code} ${resp?.data?.phoneNumber?.number}`;
				setMobileNumber(phone);
				form.setFieldValue('contactNumber', phone);
				setCounter(OTP_TIMEOUT);
			})
			.catch((err) => {});
	};

	const onResend = () => {
		onSendOTP();
	};

	const onSubmit = () => {
		if (OTP) {
			const form = {
				code: OTP,
				type: type,
			};
			onVerify(form);
		}
	};

	const afterSuccessOTP = (token) => {
		if (type === OTPTypes.Authentication) {
			loginUsingToken(token);
		}
		onClose(true);
	};

	const onChangeNumber = () => {
		setShowChangeMobile(true);
	};

	const onUpdateMobile = () => {
		let phoneNumber = form.values.contactNumber;
		if (phoneNumber) {
			let phone = {
				code: phoneNumber?.dialCode,
				number: phoneNumber?.number,
			};
			otpService
				.updateMobileNumber(phone)
				.then((resp) => {
					setCounter(0);
					setShowChangeMobile(false);
					onResend();
				})
				.catch((err) => {});
		}
	};

	const onBackToOTP = () => {
		setShowChangeMobile(false);
		form.resetForm();
	};

	const getOTPTextSection = () => {
		return (
			<Box className="text-center mb-6">
				{!showChangeMobile ? (
					<TextWrapper
						classList="!info-text !text-center"
						text={
							<p>
								{!mobileNumber ? (
									`${t('otpText')}`
								) : (
									<p>
										{t('otpText')}
										{!isApproved() ? (
											<>
												<br></br>
												{mobileNumber}
												<a
													onClick={() => onChangeNumber()}
													className="mx-1 underline-link !text-[13px]">
													{t('wrongMobileNumber')}
												</a>
											</>
										) : (
											<span className="mx-1">{t('yourMobileNumber')}</span>
										)}
									</p>
								)}
							</p>
						}></TextWrapper>
				) : (
					<TextWrapper
						classList="!info-text !text-center"
						text={t('pleaseEnterYourMobileNumber')}></TextWrapper>
				)}
			</Box>
		);
	};

	const getOTPSection = () => {
		return (
			<Box className="!w-full">
				{!showChangeMobile ? (
					<Stack
						className="text-center"
						gap={2}>
						<TextWrapper
							classList="!info-text !font-semibold"
							text={t('verificationCode')}></TextWrapper>
						<Box className="flex justify-center">
							<OTPInput
								className="!grid grid-cols-4 gap-3"
								inputClassName="border-b-2 nav-active rounded-md focus-visible:!outline-none border-[#33CD87] rounded-sm !m-0 !h-[40px] !w-[40px] !text-[20px]"
								value={OTP}
								onChange={setOTP}
								autoFocus
								OTPLength={4}
								otpType="number"
							/>
						</Box>
						<Box className="flex flex-col flex-0 items-center mt-2">
							{counter === 0 ? (
								<>
									<TextWrapper
										text={t('resendOtpText')}
										classList="!info-text"></TextWrapper>
									<a
										onClick={() => {
											onResend();
										}}
										className="underline-link !text-[13px]"
										style={{ width: 'fit-content' }}>
										{t('resend')}
									</a>
								</>
							) : (
								<Stack gap={2}>
									<Box className="flex flex-col py-2 px-3 items-center !primary-light-bg !rounded-md">
										<TextWrapper
											text={`${t('otpSuccessfullySent')}`}
											classList="!info-text !text-primary"></TextWrapper>
									</Box>
									<Box>
										<TextWrapper
											text={t('otpWillExpireIn')}
											classList="!info-text"></TextWrapper>
										<span className="text-primary mx-1">{counter}</span>
										<TextWrapper
											text={t('seconds')}
											classList="!info-text"></TextWrapper>
									</Box>
								</Stack>
							)}
						</Box>
					</Stack>
				) : (
					<Box className="mb-3">
						<FormikProvider value={form}>
							<InternationalPhone
								phoneWithCode={mobileNumber}
								name={'contactNumber'}
								key={'contactNumber'}
								label={'contactNumber'}></InternationalPhone>
						</FormikProvider>
					</Box>
				)}
			</Box>
		);
	};
	return (
		<>
			<DialogWrapper
				maxWidth="xs"
				width={'330px'}
				state={state}
				onClose={() => {
					onClose(false);
				}}
				title={
					<p className="text-[17px]">{`${
						showChangeMobile ? 'Change Mobile Number' : 'Verify Account'
					}`}</p>
				}
				actionFooterClass="!px-0"
				content={
					<Box className="flex flex-col flex-0 justify-center items-center !text-sm">
						<Box className="nav-active flex justify-center items-center w-32 h-32 rounded-full mb-6 mt-3">
							<img
								className={`w-16 ${!showChangeMobile ? 'ml-[-10px]' : ''}`}
								src={showChangeMobile ? CallImage : OTPImage}
								alt="otpImage"></img>
						</Box>
						{getOTPTextSection()}
						{getOTPSection()}
					</Box>
				}
				actions={
					<Box className="!w-full">
						{!showChangeMobile ? (
							<Box className="flex items-center w-full">
								<ButtonWrapper
									classList="!w-full"
									loading={loading}
									disabled={!OTP || OTP?.length < 4}
									icon={<HowToRegOutlinedIcon></HowToRegOutlinedIcon>}
									type="button"
									onClick={() => {
										onSubmit();
									}}
									label="Verify"></ButtonWrapper>
							</Box>
						) : (
							<Box className="grid grid-cols-2 gap-2">
								<ButtonWrapper
									classList="!w-full"
									loading={loading}
									variant={'outlined'}
									icon={<ArrowBackIcon></ArrowBackIcon>}
									type="button"
									onClick={() => {
										onBackToOTP();
									}}
									label="Back"></ButtonWrapper>
								<ButtonWrapper
									classList="!w-full"
									loading={loading}
									disabled={!form.values.contactNumber}
									icon={<SaveOutlinedIcon></SaveOutlinedIcon>}
									type="button"
									onClick={() => {
										onUpdateMobile();
									}}
									label="Update"></ButtonWrapper>
							</Box>
						)}
					</Box>
				}></DialogWrapper>
		</>
	);
};
