import { Box } from '@mui/material';
import { CURRENCY } from '../../../../config/appConfig';
import { DateHelper } from '../../../../helpers/dateHelper';
import { currencyFormatterHelper } from '../../../../helpers/currencyFormatterHelper';

export const InitializeColumns = (
	totalDeposits = 0,
	totalInvestments = 0,
	totalReserved = 0,
	totalRepayment = 0,
	totalEarnings = 0,
	totalFees = 0,
	totalLoses = 0,
	totalWithdrawals = 0,
	t
) => {
	return [
		{
			header: t('date'),
			accessorKey: 'date',
			size: 80,
			enablePinning: true,
			Cell: ({ cell }) => {
				return DateHelper.formatDate(cell?.getValue(), 'MMM dd, yyyy');
			},
			Footer: () => <Box>Total</Box>,
		},
		{
			header: t('openingBalance'),
			accessorKey: 'openingBalance',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
		},
		{
			header: t('deposits'),
			accessorKey: 'deposits',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalDeposits?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('investments'),
			accessorKey: 'investments',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalInvestments?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('reserved'),
			accessorKey: 'reserved',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalReserved?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('repayments'),
			accessorKey: 'repayment',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalRepayment?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('earnings'),
			accessorKey: 'earnings',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalEarnings?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('fees'),
			accessorKey: 'fees',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalFees?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('loses'),
			accessorKey: 'loses',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalLoses?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},

		{
			header: t('withdrawals'),
			accessorKey: 'withdrawals',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
			Footer: () => (
				<Box>
					{totalWithdrawals?.toLocaleString?.('en-US', {
						style: 'currency',
						currency: CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}) || 0}
				</Box>
			),
		},
		{
			header: t('closingBalance'),
			accessorKey: 'closingBalance',
			size: 80,
			Cell: ({ cell }) => {
				return (
					<Box className="text-center">
						{currencyFormatterHelper(cell?.getValue())}
					</Box>
				);
			},
		},
	];
};
