import { Box, IconButton, InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../../services/authService';
import { Toaster } from '../../../shared/components/common/toaster';
import { TextWrapper } from '../../../shared/components/formUtilities/useTextWrapper';
import { InputWrapper } from '../../../shared/components/formUtilities/useInput';
import { ButtonWrapper } from '../../../shared/components/formUtilities/useButton';
import { useTranslation } from 'react-i18next';
import { ArrowIcons } from '../../../shared/components/common/arrowIcons';
import * as yup from 'yup';
import { RegistrationNavBar } from '../../../pages/registration/components/registrationNavBar';
import { buildLocalizeRoute } from '../../../helpers/languageHelper';
import { AppRoutes } from '../../../constants/appRoutes';

export const ForgotPassword = () => {
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onSubmit = (values) => {
		setLoading(true);
		forgotPassword(values)
			.then((resp) => {
				setLoading(false);
				setIsSuccess(true);
			})
			.catch((err) => {
				Toaster({ msg: 'Email not found!' });
				setLoading(false);
				setIsSuccess(false);
			});
	};

	const forgotPasswordSchema = yup.object({
		email: yup.string().email().required(t('required')),
	});

	return (
		<>
			<RegistrationNavBar isLogin={true}></RegistrationNavBar>
			<div className="flex  flex-col flex-auto items-center justify-center p-4 min-w-0 md:h-[calc(100%-130px)] auth-card-wrapper bg-white">
				<div className="w-full relative flex sm:w-auto rounded-2xl shadow-md bg-card bg-white">
					<div className="w-full md:p-12 p-8 pt-12 max-w-[400px] sm:w-[400px] mx-auto sm:mx-0">
						<div className="w-full flex flex-col flex-0 relative">
							<TextWrapper
								classList={`!text-center !text-center ${
									isSuccess ? '!text-primary' : ''
								}`}
								type="h6"
								text={
									!isSuccess
										? t('enterYourEmailAddress')
										: t('emailSentSuccessfully')
								}
							/>
						</div>
						{!isSuccess ? (
							<>
								<Formik
									initialValues={{
										email: '',
									}}
									enableReinitialize={true}
									validationSchema={forgotPasswordSchema}
									validateOnMount={true}>
									{(formik) => {
										return (
											<Form className="mt-6">
												<InputWrapper
													key={'email'}
													label={t('email')}
													size="small"
													error={formik.errors.email && formik.touched.email}
													helperText={
														formik.errors.email && formik.touched.email
															? formik.errors.email
															: ''
													}
													variant="outlined"
													fullWidth={true}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton className="!bg-transparent text-primary">
																	<EmailOutlinedIcon className="text-md"></EmailOutlinedIcon>
																</IconButton>
															</InputAdornment>
														),
													}}
													type={'email'}
													name="email"></InputWrapper>
												<Box className="grid grid-cols-2 gap-4">
													<ButtonWrapper
														variant={'outlined'}
														onClick={() => {
															navigate(-1);
														}}
														icon={<ArrowIcons isInvert></ArrowIcons>}
														label={t('back')}
														classList="w-full !mt-8"
														type="button"></ButtonWrapper>
													<ButtonWrapper
														icon={<ForwardToInboxOutlinedIcon />}
														loading={loading}
														label={t('sendEmail')}
														classList="w-full !mt-8"
														onClick={() => {
															formik.setFieldTouched('email', true);
															if (formik.isValid) {
																onSubmit(formik.values);
															}
														}}></ButtonWrapper>
												</Box>
											</Form>
										);
									}}
								</Formik>
							</>
						) : (
							<>
								<Box className="grid grid-flow-row gap-6 mt-4">
									<TextWrapper
										type="span"
										classList="caption !text-center"
										text={t('forgotPasswordMailSentText')}></TextWrapper>
									<ButtonWrapper
										variant={'outlined'}
										onClick={() => {
											navigate(buildLocalizeRoute(AppRoutes.Login));
										}}
										icon={<ArrowIcons isInvert></ArrowIcons>}
										label={t('backToLogin')}
										classList="w-full"
										type="button"></ButtonWrapper>
								</Box>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
