/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Skeleton } from '@mui/material';
import { SectionWithHeading } from '../../../../../shared/components/common/sectionWithHeading';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import { useTranslation } from 'react-i18next';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { FormikProvider, useFormik } from 'formik';
import { InputWrapper } from '../../../../../shared/components/formUtilities/useInput';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import { CURRENCY } from '../../../../../config/appConfig';
import { useEffect, useRef, useState } from 'react';
import { SystemSettingsService } from '../../../../../services/systemSettingsService';
import { TransferService } from '../../../../../services/transferService';
import { useSearchParams } from 'react-router-dom';
import { transferSchema } from '../../../../../shared/validations/schema';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { ConfirmPasswordDialog } from '../../../../../shared/components/confirmPasswordDialog';
import { UserService } from '../../../../../services/userService';
import { UserDocumentTypes } from '../../../../../constants/userDocuments';
import { OTPTypes } from '../../../../../constants/otpTypes';
import { UploadIBANDocument } from '../../../../../shared/components/uploadIbanDocumentDialog';
import { OTPAuthenticationDialog } from '../../../../../shared/components/otpAuthenticationDialog';
import SelectWrapper from '../../../../../shared/components/formUtilities/useSelect';
import { DictionaryService } from '../../../../../services/dictionaryService';
import { FormHelper } from '../../../../../helpers/formHelper';
import { InvestorService } from '../../../investorService';
import { WithdrawWarningDialog } from './withdrawWarningDialog';
import { InvestorTransferHistory } from '../history';
import { WithdrawSuccess } from './withdrawSuccess';
import { Observables } from '../../../../../observables';
import { useSelector } from 'react-redux';
import { AppConstants } from '../../../../../config/appConstants';

const Operations = {
	Withdraw: 1,
	EditBankDetails: 2,
};

export const InvestorWithdraw = () => {
	const systemSettingsService = new SystemSettingsService();
	const transferService = new TransferService();
	const userService = new UserService();
	const investorService = new InvestorService();
	const dictionaryService = new DictionaryService();
	const { investorCollections } = useSelector(
		(state) => state.investorCollectionReducer
	);
	const getTransactions = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showOTPAuth, setShowOTPAuth] = useState({
		value: false,
		operation: Operations.Withdraw,
	});
	const [searchParams, setSearchParams] = useSearchParams();
	const [transferId, setTransferId] = useState(null);
	const [allDocuments, setAllDocuments] = useState([]);
	const [bankDetails, setBankDetails] = useState({});
	const [disableAccountName, setDisableAccountName] = useState(false);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	const [isShowIbanDialog, setIsShowIbanDialog] = useState(false);
	const [showWithdrawWarning, setShowWithdrawWarning] = useState(false);
	const [onSaveLoading, setSaveLoading] = useState(false);
	const [editBankDetails, setEditBankDetails] = useState(false);

	const [isNotification, setIsNotification] = useState(
		searchParams.get('isNotificationTransaction')
	);

	const bankCountries = investorCollections?.countryCollection || [];
	const { t } = useTranslation();

	const form = useFormik({
		initialValues: { amount: searchParams.get('amount') || '' },
		validationSchema: transferSchema,
		onSubmit: (value) => {
			if (value) {
				if (isNonUAE()) {
					setShowWithdrawWarning(true);
				} else {
					checkWithdrawalProcess();
				}
			}
		},
		...FormHelper.formikProps(),
	});

	const editForm = useFormik({
		initialValues: {
			accountName: null,
			bankName: null,
			bankCountry: null,
			iban: null,
		},
		...FormHelper.formikProps(),
	});

	useEffect(() => {
		setIsNotification(searchParams.get('isNotificationTransaction'));
		getAllDocuments();
		getBankDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isNotification === 'true') {
			setTimeout(() => {
				document
					.getElementById('isNotification')
					.scrollIntoView({ behavior: 'smooth' });
			}, [600]);
		}
	}, [isNotification]);

	const getAllDocuments = () => {
		userService
			.getAllDocuments()
			.then((resp) => {
				setAllDocuments(resp.data);
			})
			.catch((err) => {});
	};

	const isNonUAE = () => {
		if (bankDetails?.bankCountry?.id) {
			return bankDetails.bankCountry.id !== AppConstants.UnitedArabEmirates;
		}
		return false;
	};

	const getBankDetails = () => {
		setLoading(true);
		transferService
			.getUserBankDetails()
			.then((resp) => {
				setBankDetails(resp.data);
				let details = {
					...resp.data,
					bankCountryId: resp.data?.bankCountry?.id,
				};
				editForm.setValues(details);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const checkIbanDocument = () => {
		return allDocuments.find(
			(resp) => resp.type === UserDocumentTypes.InvestorIban
		)?.file?.url;
	};

	const settings = () => {
		return systemSettingsService.systemSettings();
	};

	const checkWithdrawalProcess = () => {
		if (
			settings()?.verifyInvestorIbanDocumentUploaded &&
			!checkIbanDocument()
		) {
			setIsShowIbanDialog(true);
		} else {
			onWithdrawAmount();
		}
	};

	const onWarningClose = (state) => {
		setShowWithdrawWarning(false);
		if (state) {
			checkWithdrawalProcess();
		}
	};

	const onWithdrawAmount = () => {
		setIsLoading(true);
		transferService
			.addTransferOut(form.values.amount)
			.then((resp) => {
				setIsLoading(false);
				if (resp.data?.otpRequested) {
					setTransferId(resp?.data?.correlationId || null);
					setTimeout(() => {
						setShowOTPAuth({ value: true, operation: Operations.Withdraw });
					});
				} else {
					onSuccessWithdrawal();
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	const onSuccessWithdrawal = () => {
		setIsSuccess(true);
		Observables.investorSummaryRefresh.next(1);
		getTransactions?.current();
	};

	const onBankDetailsSave = () => {
		setSaveLoading(true);
		if (settings()?.enabledOtp) {
			setShowOTPAuth({ value: true, operation: Operations.EditBankDetails });
		} else {
			setShowPasswordDialog(true);
		}
	};

	const onPasswordDialogClose = (e) => {
		setShowPasswordDialog(false);
		if (e) {
			uploadUserBankDetails();
		} else {
			setSaveLoading(false);
		}
	};

	const uploadUserBankDetails = () => {
		const userBankDetails = editForm.values;
		delete userBankDetails?.bankCountry;
		transferService
			.updateUserBankDetails(userBankDetails)
			.then((resp) => {
				setSaveLoading(false);
				getBankDetails();
				setEditBankDetails(false);
			})
			.catch((err) => {
				setSaveLoading(false);
			});
	};

	const onCloseOTPAuth = (e) => {
		let operation = showOTPAuth.operation;
		setShowOTPAuth({ value: false });
		if (e) {
			if (operation === Operations.Withdraw) {
				onSuccessWithdrawal();
			} else if (operation === Operations.EditBankDetails) {
				uploadUserBankDetails();
			}
		} else {
			setSaveLoading(false);
		}
	};

	const getLoader = () => {
		return (
			<Skeleton
				variant="text"
				sx={{ fontSize: '10px', width: '100px' }}
			/>
		);
	};

	return (
		<Box className="flex flex-col space-y-4">
			<Box className="grid md:grid-cols-2 grid-cols-1 gap-4">
				<SectionWithHeading
					bodyClass="md:!min-h-[164px]"
					heading={
						<Box className="flex items-center">
							<span className="mx-2">
								{!isSuccess
									? t('withdrawNotification')
									: t('withdrawRequestReceived')}
							</span>
						</Box>
					}
					children={
						<Box>
							{isSuccess ? (
								<WithdrawSuccess
									onDone={() => {
										form.resetForm();
										setIsSuccess(false);
									}}
									amount={form.values.amount}></WithdrawSuccess>
							) : (
								<>
									<TextWrapper
										classList="caption !text-sm"
										text={`${t('pleaseEnterTransferAmount')} ${t(
											'minimizeBankCharges'
										)}`}></TextWrapper>
									<FormikProvider value={form}>
										<Box className="mt-3 flex flex-0 md:w-[380px]">
											<InputWrapper
												type="number"
												label={`${t('amount')} ${CURRENCY}`}
												key={'amount'}
												name="amount"></InputWrapper>
											<ButtonWrapper
												onClick={() => {
													form.getFieldHelpers('amount').setTouched(true);
													form.submitForm();
												}}
												disabled={editBankDetails}
												loading={isLoading}
												icon={<MoneyOutlinedIcon></MoneyOutlinedIcon>}
												classList="!min-w-[130px] !mt-[22px] !mx-3"
												label={t('withdraw')}></ButtonWrapper>
										</Box>
									</FormikProvider>
								</>
							)}
						</Box>
					}></SectionWithHeading>
				<SectionWithHeading
					classList={isSuccess ? '!h-auto' : ''}
					heading={
						<Box className="flex items-center">
							<span className="mx-2">{t('yourBankDetails')}</span>
						</Box>
					}
					children={
						<Box className="grid grid-flow-row gap-3">
							<Box className="grid md:grid-cols-3 grid-cols-2 items-center">
								<TextWrapper
									classList="!caption !text-sm"
									text={t('IBAN')}></TextWrapper>
								<TextWrapper
									classList="!font-bold !text-sm md:!col-span-2"
									text={
										!loading ? bankDetails?.iban : getLoader()
									}></TextWrapper>
							</Box>
							<Box className="grid md:grid-cols-3 grid-cols-2 items-center">
								<TextWrapper
									classList="!caption !text-sm"
									text={t('bankName')}></TextWrapper>
								<TextWrapper
									classList="!font-bold !text-sm md:!col-span-2"
									text={
										!loading ? bankDetails?.bankName : getLoader()
									}></TextWrapper>
							</Box>
							<Box className="grid md:grid-cols-3 grid-cols-2 items-center">
								<TextWrapper
									classList="!caption !text-sm"
									text={t('accountName')}></TextWrapper>
								<TextWrapper
									classList="!font-bold !text-sm md:!col-span-2"
									text={
										!loading ? bankDetails?.accountName : getLoader()
									}></TextWrapper>
							</Box>
							<Box
								className={`w-full flex md:justify-end justify-center md:mt-0 mt-2 items-center ${
									editBankDetails ? 'opacity-0 pointer-events-none' : ''
								}`}>
								<ButtonWrapper
									onClick={() => {
										if (!editForm.values.accountName) {
											setDisableAccountName(false);
										} else {
											setDisableAccountName(true);
										}
										setEditBankDetails(true);
									}}
									variant={'outlined'}
									icon={
										<EditIcon
											color="primary"
											className="!text-[18px] !mx-0.5"></EditIcon>
									}
									label={t('editBankDeatils')}></ButtonWrapper>
							</Box>
						</Box>
					}></SectionWithHeading>
			</Box>
			{editBankDetails ? (
				<SectionWithHeading
					heading={
						<Box className="flex items-center">
							<span className="mx-2">{t('editBankDetails')}</span>
						</Box>
					}
					children={
						<FormikProvider value={editForm}>
							<Box className="grid grid-flow-row gap-3">
								<Box className="grid grid-cols-1 md:grid-cols-2 gap-3">
									<InputWrapper
										disabled={disableAccountName}
										key={'accountName'}
										name={'accountName'}
										label={'accountName'}></InputWrapper>
									<InputWrapper
										key={'bankName'}
										name={'bankName'}
										label={'bankName'}></InputWrapper>
									<SelectWrapper
										name={'bankCountryId'}
										key={'bankCountryId'}
										items={bankCountries}
										label={'bankCountry'}
									/>
									<InputWrapper
										key={'iban'}
										name={'iban'}
										label={'iban'}></InputWrapper>
								</Box>
								<Box className="flex flex-row justify-center items-center">
									<ButtonWrapper
										loading={onSaveLoading}
										onClick={() => {
											setEditBankDetails(false);
										}}
										icon={<ClearIcon></ClearIcon>}
										variant={'outlined'}
										label={t('cancel')}></ButtonWrapper>
									<ButtonWrapper
										loading={onSaveLoading}
										onClick={() => {
											onBankDetailsSave();
										}}
										icon={<SaveOutlinedIcon></SaveOutlinedIcon>}
										classList="!mx-3"
										label={t('save')}></ButtonWrapper>
								</Box>
							</Box>
						</FormikProvider>
					}></SectionWithHeading>
			) : null}
			<Box id="isNotification">
				<InvestorTransferHistory getTransactions={getTransactions} />
			</Box>
			{showPasswordDialog ? (
				<ConfirmPasswordDialog
					state={showPasswordDialog}
					onClose={(e) => {
						onPasswordDialogClose(e);
					}}></ConfirmPasswordDialog>
			) : null}

			{showOTPAuth.value ? (
				<OTPAuthenticationDialog
					type={OTPTypes.Transaction}
					state={showOTPAuth.value}
					corrolationId={transferId}
					onClose={(e) => {
						onCloseOTPAuth(e);
					}}></OTPAuthenticationDialog>
			) : null}
			{isShowIbanDialog ? (
				<UploadIBANDocument
					state={isShowIbanDialog}
					onClose={(e) => {
						setIsShowIbanDialog(false);
						if (e) {
							onWithdrawAmount();
						}
					}}></UploadIBANDocument>
			) : null}
			{showWithdrawWarning ? (
				<WithdrawWarningDialog
					state={showWithdrawWarning}
					onClose={(e) => {
						onWarningClose(e);
					}}
				/>
			) : null}
		</Box>
	);
};
