import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactPasswordChecklist from 'react-password-checklist';
import { TextWrapper } from '../formUtilities/useTextWrapper';

export const PasswordValidationPopover = ({
	controllerValue = "",
	showErrorPopover = false,
	classList = '',
}) => {
	const { t } = useTranslation();
	return (
		<>
			{showErrorPopover ? (
				<Box
					className={`absolute !p-3 z-50 rounded-sm !text-start password-validator-popover bg-[#f5f5f5] -top-[103px] w-full ${
						classList || ''
					}`}>
					<TextWrapper
						text={t('passwordMustBe')}
						classList="!text-sm !font-[500]"
					/>
					<ReactPasswordChecklist
						validColor="#33CD87"
						invalidColor="#D32F2F"
						invalidTextColor="#D32F2F"
						validTextColor="#33CD87"
						rules={['minLength', 'number', 'capital']}
						minLength={8}
						className="password-strength-meter"
						iconSize={10}
						value={controllerValue || ''}
						style={{ fontWeight: 'normal' }}
						messages={{
							minLength: t('min8Character'),
							number: t('passwordShouldContain1Number'),
							capital: t('passwordShouldContain1CapitalLetter'),
						}}
					/>
				</Box>
			) : null}
		</>
	);
};
