/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	Badge,
	Box,
	CircularProgress,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Skeleton,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import MenuPopover from '../../../../borrowerLayout/shared/components/menuPopover';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { TextWrapper } from '../../../../../../components/formUtilities/useTextWrapper';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DateHelper } from '../../../../../../../helpers/dateHelper';
import { LanguageService } from '../../../../../../../services/languageService';
import { LocalStorageKey } from '../../../../../../../constants/localStorageKeys';
import { NotificationsService } from '../../../../../../../services/notificationsService';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { formatDistance } from 'date-fns';
import { ArrowIcons } from '../../../../../../../shared/components/common/arrowIcons';
import { useLocation, useNavigate } from 'react-router-dom';
import { buildLocalizeRoute } from '../../../../../../../helpers/languageHelper';

export const Notification = {
	All: 'all',
	UnRead: 'unRead',
};

const eventLists = [
	{
		name: 'DepositApproved',
		actionUrl: buildLocalizeRoute(
			'investor/transfers/deposit?isNotificationTransaction=true'
		),
	},
	{
		name: 'DepositRejected',
		actionUrl: buildLocalizeRoute(
			'investor/transfers/deposit?isNotificationTransaction=true'
		),
	},
	{
		name: 'WithdrawalApproved',
		actionUrl: buildLocalizeRoute(
			'investor/transfers/withdraw?isNotificationTransaction=true'
		),
	},
	{
		name: 'WithdrawalRejected',
		actionUrl: buildLocalizeRoute(
			'investor/transfers/withdraw?isNotificationTransaction=true'
		),
	},
	{
		name: 'NewLoanNote',
		actionUrl: buildLocalizeRoute(
			'investor/my-portfolio?isNotificationComment=true'
		),
	},
];

const NotificationType = {
	Operations: 1,
	Compliance: 2,
	Credit: 3,
};

export default function NotificationsPopover() {
	const languageService = new LanguageService();
	const notificationsService = new NotificationsService();
	const anchorRef = useRef(null);
	const navigate = useNavigate();
	const [hasMore, setHasMore] = useState(false);
	const [allRead, setAllRead] = useState(false);
	const [loading, setLoading] = useState(true);
	const [allCount, setAllCount] = useState(0);
	const [unReadCount, setunReadCount] = useState(0);
	const [loadingScroll, setLoadingScroll] = useState(true);
	const [notifications, setNotifications] = useState([]);
	const pagingItems = {
		currentPage: 1,
		pageCount: 0,
		perPage: 10,
		totalCount: 0,
	};
	const [paging, setPaging] = useState(pagingItems);
	const pathName = useLocation();

	const [open, setOpen] = useState(null);
	const { t } = useTranslation();

	const handleOpen = (event, type) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
		setTimeout(() => {
			localStorage.setItem(
				LocalStorageKey.notificationType,
				Notification.UnRead
			);
			setPaging(pagingItems);
			setNotifications([]);
		}, 400);
	};

	const isAr = () => {
		return languageService.isArabic();
	};

	const getNext = () => {
		if (notifications.length >= paging?.totalCount) {
			setHasMore(false);
			return;
		}
		let pageNumber = paging.currentPage + 1;
		getNotifications(pageNumber, false, true);
	};

	useEffect(() => {
		getCount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathName]);

	const getCount = () => {
		getUnReadCount();
		getAllCount();
	};

	const getUnReadCount = () => {
		notificationsService
			.getUnReadNotifications(1, 1)
			.then((resp) => {
				setunReadCount(resp.data?.paging?.totalCount);
			})
			.catch((err) => { });
	};

	const getAllCount = () => {
		notificationsService
			.getAllNotifications(1, 1)
			.then((resp) => {
				setAllCount(resp.data?.paging?.totalCount);
			})
			.catch((err) => { });
	};

	useEffect(() => {
		if (open) {
			getNotifications(null, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		const ids = notifications
			.filter((resp) => !resp.isRead)
			.map((data) => {
				return data?.id;
			});
		if (ids?.length) {
			setAllRead(true);
		} else {
			setAllRead(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications]);

	const getNotificationType = () => {
		return (
			localStorage.getItem(LocalStorageKey.notificationType) ||
			Notification.UnRead
		);
	};

	const getNotifications = (
		pageNumber = null,
		skeletonLoader = false,
		isFromScroll = false
	) => {
		if (skeletonLoader) {
			setLoading(true);
		} else {
			setLoadingScroll(true);
		}
		let pageIndex = pageNumber ? pageNumber : paging.currentPage;
		if (getNotificationType() === Notification.UnRead) {
			getUnReadNotifications(pageIndex, isFromScroll);
		} else {
			getAllNotifications(pageIndex, isFromScroll);
		}
	};

	const getAllNotifications = (pageIndex, isFromScroll = false) => {
		notificationsService
			.getAllNotifications(pageIndex, paging?.perPage)
			.then((resp) => {
				if (isFromScroll) {
					setNotifications((notif) => [...notif, ...resp.data?.items]);
				} else {
					setNotifications(resp.data?.items);
				}
				setPaging(resp.data?.paging);
				setNotificationLength(resp.data?.items, resp.data?.paging);
				setLoading(false);
				setLoadingScroll(false);
			})
			.catch((err) => {
				setLoading(false);
				setLoadingScroll(false);
			});
	};

	const getUnReadNotifications = (pageIndex, isFromScroll = false) => {
		notificationsService
			.getUnReadNotifications(pageIndex, paging?.perPage)
			.then((resp) => {
				if (isFromScroll) {
					setNotifications((notif) => [...notif, ...resp.data?.items]);
				} else {
					setNotifications(resp.data?.items);
				}
				setPaging(resp.data?.paging);
				setNotificationLength(resp.data?.items, resp.data?.paging);
				setLoading(false);
				setLoadingScroll(false);
			})
			.catch((err) => {
				setLoading(false);
				setLoadingScroll(false);
			});
	};

	const setNotificationLength = (notification = [], paging = {}) => {
		if (notification?.length && notification?.length < paging?.totalCount) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	};

	const changeTab = (type) => {
		setNotifications([]);
		localStorage.setItem(LocalStorageKey.notificationType, type);
		setTimeout(() => {
			getNotifications(1, true);
		});
	};

	const onEventClick = (notification) => {
		let actionUrl = eventLists.find(
			(resp) => resp.name === notification?.key
		).actionUrl;
		if (actionUrl) {
			handleClose();
			markAsRead(notification);
			setTimeout(() => {
				navigate(actionUrl);
			});
		}
	};

	const markAsRead = (notification) => {
		if (!notification?.isRead) {
			notificationsService
				.markAsReadNotification([notification?.id])
				.then((resp) => {
					getCount();
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	};

	const markAsAllRead = () => {
		const ids = notifications
			.filter((resp) => !resp.isRead)
			.map((data) => {
				return data?.id;
			});
		notificationsService
			.markAsReadNotification(ids)
			.then((resp) => {
				getCount();
				let currentNotifications = notifications || [];
				currentNotifications?.forEach((resp) => {
					resp.isRead = true;
				});
				setNotifications([...currentNotifications]);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const getLoaderSkeleton = () => {
		return [1, 2, 3, 4].map((value, index) => (
			<Box className="mt-2" key={index}>
				<ListItem
					disablePadding
					key={index}
					className={`hover:bg-gray-100 cursor-pointer !px-4`}>
					<ListItemText
						primaryTypographyProps={{ fontSize: '14px' }}
						secondaryTypographyProps={{ fontSize: '12px' }}
						primary={
							<Box className="flex justify-between items-center">
								<span className="font-[500]">
									<Skeleton
										variant="text"
										sx={{ fontSize: '10px', width: '120px' }}
									/>
								</span>
								<Box className="min-w-[100px] flex justify-end mb-2">
									<Skeleton
										variant="circular"
										sx={{ width: '30px', height: '30px' }}
									/>
								</Box>
							</Box>
						}
						secondary={
							<Box className="flex justify-between">
								<span>
									<Skeleton
										variant="text"
										sx={{ fontSize: '7px', width: '80px' }}
									/>
								</span>
								<span>
									<Skeleton
										variant="text"
										sx={{ fontSize: '7px', width: '60px' }}
									/>
								</span>
							</Box>
						}
					/>
				</ListItem>
				<Divider></Divider>
			</Box>
		));
	};

	return (
		<>
			<IconButton
				className="!p-0 !mx-3"
				ref={anchorRef}
				color={open ? 'primary' : 'default'}
				onClick={(event) => {
					handleOpen(event, NotificationType.Operations);
				}}
				sx={{ width: 40, height: 40 }}>
				<Badge
					badgeContent={unReadCount}
					showZero={true}
					color="error">
					<NotificationsNoneOutlinedIcon className="!text-white" />
				</Badge>
			</IconButton>

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{ width: 350, p: 0, mt: 1.5, ml: 0.75 }}
				className={`notification-popup ${isAr() ? 'notification-popup-ar' : ''
					}`}>
				<Box className="flex flex-col">
					<Box className="p-3 text-center border-b w-full">
						<TextWrapper
							classList="!font-[500] !text-[18px]"
							text={t('notifications')}></TextWrapper>
					</Box>
					<Box className="px-4 pb-2 pt-3">
						<Box className="flex justify-start items-center w-full bg-[#f9f9f9] p-1 rounded-[4px]">
							<Box
								onClick={() => {
									changeTab(Notification.UnRead);
								}}
								className={`notification-tab ${getNotificationType() === Notification.UnRead
									? 'active-tab'
									: ''
									}`}>
								<span>{t('unRead')}</span>
								<span className="mx-1">({unReadCount})</span>
							</Box>
							<Box className="w-4"></Box>
							<Box
								onClick={() => {
									changeTab(Notification.All);
								}}
								className={`notification-tab ${getNotificationType() === Notification.All ? 'active-tab' : ''
									}`}>
								<span>{t('all')}</span>
								<span className="mx-1">({allCount})</span>
							</Box>
						</Box>
					</Box>
					<InfiniteScroll
						height={notifications?.length > 4 && !loading ? 400 : 'auto'}
						dataLength={paging.currentPage * 10}
						next={getNext}
						hasMore={hasMore}>
						<List
							className="!p-0"
							sx={{
								width: '100%',
								maxWidth: 400,
								bgcolor: 'background.paper',
							}}>
							{loading ? (
								getLoaderSkeleton()
							) : notifications?.length > 0 ? (
								<>
									{notifications.map((value, index) => (
										<Box>
											<ListItem
												onClick={() => {
													onEventClick(value);
												}}
												disablePadding
												key={index}
												className={`hover:bg-gray-100 ${value?.isRead ? 'opacity-50 hover:opacity-100' : ''} cursor-pointer !px-4 min-h-[78px] notification-content ${index === 0 ? 'border-t' : ''
													}`}>
												<ListItemText
													primaryTypographyProps={{ fontSize: '14px' }}
													secondaryTypographyProps={{ fontSize: '12px' }}
													primary={
														<Box className="flex justify-between items-center">
															<Box>
																<Box className="font-[500]">
																	{t(value?.key)}
																</Box>
																<Box className="text-[12px] caption">
																	{t(`${value?.key}Text`)}
																</Box>
															</Box>
															<ArrowIcons
																classList={`!text-[#5d5d5d] notification-arrow !text-[17px] ${isAr() ? '!mr-2' : '!ml-2'
																	}`}
																isKeyboardArrow></ArrowIcons>
														</Box>
													}
													secondary={
														<Box className="flex justify-between mt-1">
															<span>
																{DateHelper.formatDate(
																	value?.date,
																	'MMM dd, yyyy hh:mm'
																)}
															</span>
															<span dir="ltr">
																{formatDistance(
																	new Date(value?.date),
																	new Date(),
																	{
																		addSuffix: true,
																	}
																)?.replace('about ', '')}
															</span>
														</Box>
													}
												/>
											</ListItem>
											<Divider></Divider>
										</Box>
									))}
									{loadingScroll ? (
										<Box className="flex justify-center items-center my-2">
											<CircularProgress className="!w-[20px] !h-[20px]"></CircularProgress>
										</Box>
									) : null}
								</>
							) : (
								<Box className="min-h-[200px] flex flex-col items-center justify-center">
									<NotificationsNoneOutlinedIcon
										color="primary"
										className="md:!text-[70px] !text-[50px]"
									/>
									<Box className="mt-3 font-[500] !text-[16px]">
										{t('noNotificationsYet')}
									</Box>
									<TextWrapper
										type="caption"
										text={t('youHaveNoNotifications')}></TextWrapper>
								</Box>
							)}
						</List>
					</InfiniteScroll>
					<Box className="flex justify-center p-5">
						{allRead && !loading ? (
							<a
								onClick={() => {
									markAsAllRead();
								}}
								className="underline-link flex items-center">
								<DoneAllIcon className="!text-[18px]" />
								<span className="mx-1">{t('markAllAsRead')}</span>
							</a>
						) : null}
					</Box>
				</Box>
			</MenuPopover>
		</>
	);
}
