import { Box, Stack } from '@mui/material';
import { Section } from '../../../../../shared/components/common/section';
import { MarketplaceStatuses } from '../../../../../constants/marketplaceStatuses';
import LogoPlaceholder from '../../../../../assets/images/logo-placeholder.jpg';
import { TextWrapper } from '../../../../../shared/components/formUtilities/useTextWrapper';
import { CURRENCY } from '../../../../../config/appConfig';
import { ButtonWrapper } from '../../../../../shared/components/formUtilities/useButton';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../../../constants/appRoutes';
import { buildLocalizeRoute } from '../../../../../helpers/languageHelper';
import { useTranslation } from 'react-i18next';
import { currencyFormatterHelper } from '../../../../../helpers/currencyFormatterHelper';
import { CustomProgressBar } from '../../../../../shared/components/formUtilities/useProgressBar';
import { LanguageService } from '../../../../../services/languageService';
import { CustomTooltip } from '../../../../../shared/components/common/tooltip';

export const MarketplaceItems = ({ item, isCollection, status }) => {
	const languageService = new LanguageService();
	const { t } = useTranslation();

	const isHistory = () => {
		return status === MarketplaceStatuses.Past;
	};

	const getPercentage = (item) => {
		if (!isHistory()) {
			let investPercentage = (item?.investedAmount / item?.loanAmount) * 100;
			let formattedValue = parseFloat(
				(Math.floor(investPercentage * 100) / 100).toString().substring(0, 4)
			);
			return formattedValue;
		}
		return 100;
	};

	const showInvestBtn = () => {
		return status === MarketplaceStatuses.Current;
	};

	const getDetailURL = () => {
		let path = '';
		path = isCollection
			? `${AppRoutes.Investor}/${AppRoutes.Marketplace}/collection`
			: `${AppRoutes.Investor}/${AppRoutes.Marketplace}/business-details`;
		path = `${path}/${item?.id}?status=${status}`;
		return buildLocalizeRoute(path);
	};

	const getRemainingAmount = () => {
		return +item.loanAmount - +item.investedAmount;
	};

	const isAr = () => {
		return languageService.isArabic();
	};

	return (
		<Box>
			<Link
				onClick={(e) => {
					if (isHistory()) {
						e.preventDefault();
					}
				}}
				className={`!text-start ${
					isHistory() ? '!cursor-default' : 'cursor-pointer'
				}`}
				to={!isHistory() ? getDetailURL() : ''}>
				<Section
					classList="!p-0"
					children={
						<Box>
							{!isHistory() ? (
								<Box className="p-3 pb-0">
									<Box className="overflow-hidden flex justify-center items-center p-2 h-[220px]">
										<img
											className={`object-contain w-full`}
											src={item?.logo ? item?.logo : LogoPlaceholder}
											alt="Marketplace Logo"></img>
									</Box>
								</Box>
							) : null}

							<Stack
								gap={1}
								className={`p-4  ${!isHistory() ? 'md:min-h-[375px]' : ''}`}>
								<Stack
									direction={'row'}
									gap={1}
									className="flex flex-0 items-center">
									{item?.rating === 'A' && !isHistory() ? (
										<Box className="flex justify-center items-center px-2 py-1 w-auto border rounded-md">
											<TextWrapper
												type="caption"
												classList="!text-primary !font-[600]"
												text={
													<Stack
														gap={0.5}
														direction={'row'}
														className="flex flex-0 items-center">
														{/* <CheckCircleOutlineOutlinedIcon className="!text-[16px]"></CheckCircleOutlineOutlinedIcon> */}
														<Box
															className={`${
																isAr() ? '!text-[10px]' : ''
															}`}>{`${t('guaranteedbyShams')} ${t(
															'shams'
														)}`}</Box>
													</Stack>
												}></TextWrapper>
										</Box>
									) : null}
									<Box className="flex justify-center items-center px-2 py-1 w-auto border rounded-md">
										<TextWrapper
											type="caption"
											classList="!text-primary !font-[600]"
											text={
												<Stack
													gap={0.5}
													direction={'row'}
													className="flex flex-0 items-center">
													<Box
														className={`${isAr() ? '!text-[10px]' : ''}`}>{`${t(
														'shariahCompliant'
													)}`}</Box>
												</Stack>
											}></TextWrapper>
									</Box>
								</Stack>
								<Stack gap={1}>
									{!isHistory() ? (
										<TextWrapper
											classList="!font-bold !truncate-word"
											text={item?.businessName}></TextWrapper>
									) : (
										<TextWrapper
											classList="!font-bold !truncate-word blured-text"
											text={'************ ****** ****** ******'}></TextWrapper>
									)}
									<Box className="relative ">
										<Box className="flex justify-between items-center mb-4">
											<Stack>
												<TextWrapper
													classList="!font-[500]"
													type="caption"
													text={t('requested')}></TextWrapper>
												<TextWrapper
													classList="!text-primary"
													text={
														<Stack
															className="flex flex-0 items-center !font-bold !text-[18px]"
															direction={'row'}
															gap={0.5}>
															<Box>{CURRENCY}</Box>
															<Box className="flex flex-0 items-center !font-bold">
																{currencyFormatterHelper(item?.loanAmount, 0)}
															</Box>
														</Stack>
													}></TextWrapper>
											</Stack>
											{!isHistory() ? (
												<Stack>
													<TextWrapper
														classList="!font-[500] !text-end"
														type="caption"
														text={t('funded')}></TextWrapper>
													<TextWrapper
														text={
															<Stack
																className="flex flex-0 items-center !font-bold !text-[14px]"
																direction={'row'}
																gap={0.5}>
																<Box>{CURRENCY}</Box>
																<Box className="flex flex-0 items-center !font-bold">
																	{currencyFormatterHelper(
																		item?.investedAmount,
																		0
																	)}
																</Box>
															</Stack>
														}></TextWrapper>
												</Stack>
											) : null}
										</Box>
										<Box className="marketplace-business-name mb-3">
											<CustomProgressBar
												value={getPercentage(item)}></CustomProgressBar>
											{!isHistory() ? (
												<Box className="flex marketplace-funded absolute w-full bottom-0 top-[20px] justify-between items-center !text-[10px]">
													<Stack
														gap={0.5}
														direction={'row'}>
														<Box>{t('remaining')}:</Box>
														<Box className="!font-[600]">{`${CURRENCY} ${currencyFormatterHelper(
															getRemainingAmount()
														)}`}</Box>
													</Stack>
												</Box>
											) : null}
										</Box>
									</Box>
								</Stack>
								<Box className="flex flex-col space-y-1 bg-[#F9F9F9] p-3 rounded-md">
									{/* <Box className="flex justify-between items-center">
										<Box>
											<TextWrapper
												classList="!font-[500]"
												type="caption"
												text={`${t('rating')}`}></TextWrapper>
										</Box>
										<Box>
											<TextWrapper
												classList="!font-bold !text-[14px]"
												text={`${item?.rating}`}></TextWrapper>
										</Box>
									</Box> */}
									<Box className="flex justify-between items-center">
										<Box>
											<TextWrapper
												classList="!font-[500]"
												type="caption"
												text={
													<span>
														<span>{t('roi')}</span>
														<CustomTooltip
															id="netYieldInfoId"
															text="yieldTooltipInfo"></CustomTooltip>
													</span>
												}></TextWrapper>
										</Box>
										<Box>
											<TextWrapper
												classList="!font-bold !text-[14px]"
												text={`${item?.fixed}%`}></TextWrapper>
										</Box>
									</Box>

									<Box className="flex justify-between items-center">
										<Box>
											<TextWrapper
												classList="!font-[500]"
												type="caption"
												text={
													<span>
														<span>{t('irr')}</span>
														<CustomTooltip
															id="irrInfoId"
															text="IrrTooltipInfo"></CustomTooltip>
													</span>
												}></TextWrapper>
										</Box>
										<Box>
											<TextWrapper
												classList="!font-bold !text-[14px]"
												text={`${item?.investorIRR || '25.9'}%`}></TextWrapper>
										</Box>
									</Box>

									<Box className="flex justify-between items-center">
										<Box>
											<TextWrapper
												classList="!font-[500]"
												type="caption"
												text={t('tenure')}></TextWrapper>
										</Box>
										<Box>
											<TextWrapper
												classList="!font-bold !text-[14px]"
												text={`${item?.month} ${t('months')}`}></TextWrapper>
										</Box>
									</Box>
								</Box>
								{showInvestBtn() ? (
									<Box className="flex justify-center mt-2 md:h-full items-end">
										<ButtonWrapper
											classList="md:!min-w-[120px]"
											label={t('invest')}
											icon={
												<PaymentsOutlinedIcon></PaymentsOutlinedIcon>
											}></ButtonWrapper>
									</Box>
								) : null}
							</Stack>
						</Box>
					}></Section>
			</Link>
		</Box>
	);
};
