import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const qAQuestions = [
	{
		question: 'removeChromePermission',
		answers: [
			'clickAppleMenu',
			'selectSecurity',
			'selectFileAndFolder',
			'scollToChrome',
			'launchChrome',
		],
	},
	{
		question: 'addChromeDiskAccess',
		answers: [
			'clickAppleMenu',
			'selectSecurity',
			'clickLockIcon',
			'selectFullDiskAccess',
			'addChrome',
			'lauchChromeUploadFile',
		],
	},
];

export const DocumentUploadIssueInfo = () => {
	const { t } = useTranslation();
	return (
		<Box>
			<Accordion className="!shadow-none">
				<AccordionSummary
					className="!px-0"
					aria-controls="panel1a-content"
					id="panel1a-header">
					<span className="underline-link text-primary">
						{t('uploadIssueText')}
					</span>
				</AccordionSummary>
				<AccordionDetails>
					<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
						{qAQuestions.map((resp, index) => {
							return (
								<Box
									key={index}
									className="flex flex-col space-y-4">
									<Box className="text-md font-bold text-center">
										{t(resp?.question)}
									</Box>
									<ul>
										{resp.answers.map((item, i) => {
											return (
												<li
													className="text-sm font-[500]"
													key={i}>
													{t(item)}
												</li>
											);
										})}
									</ul>
								</Box>
							);
						})}
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};
