export class UtilitiesService {
	setLocalStorage({ item, value }) {
		localStorage.setItem(item, value);
	}
	getLocalStorage({ item }) {
		return localStorage.getItem(item) || null;
	}
	removeLocalStorage({ item = null, isAll = false }) {
		isAll ? localStorage.clear() : localStorage.removeItem(item);
	}

	removeWhiteSpace = (value = '') => {
		if (value) {
			return value.replace(/ /g, '');
		}

		return '';
	};
}
