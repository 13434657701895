export const AppConstants = {
	saudiArabia: 'Saudi Arabia',
	MinInvestment: 100,
	UnitedArabEmirates: 2,
	UTMSource: 'utm_source',
	UTMMedium: 'utm_medium',
	UTMTerm: 'utm_term',
	UTMCampaign: 'utm_campaign',
	UTMContent: 'utm_content',
};
